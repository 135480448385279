// action
import {
    ADD_CONFIGRABLE_PRODUCT,
    ADD_CONFIGRABLE_PRODUCT_FAIL,
    ADD_CONFIGRABLE_PRODUCT_SUCCESS,
    ADD_CONFIGRABLE_PRODUCT_RESET
} from '../actions/addConfigrableProductAction'

// initialState
const initialState = {
    apiState: "",
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_CONFIGRABLE_PRODUCT:
            return {
                ...state,
                apiState: "loading"
            }
        case ADD_CONFIGRABLE_PRODUCT_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case ADD_CONFIGRABLE_PRODUCT_SUCCESS:
            return {
                ...state,
                apiState: "success"
            }
        case ADD_CONFIGRABLE_PRODUCT_RESET:
            return initialState

        default:
            return state
    }
}