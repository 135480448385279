import {
  FETCH_POLICY_CMS_REQUEST,
  FETCH_POLICY_CMS_SUCCESS,
  FETCH_POLICY_CMS_FAIL,
} from "../actions/fetchPolicyCmsAction";
import get from "lodash/get";


const initialState = {
  fetchPolicyCmsData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_POLICY_CMS_REQUEST:
      return {
        ...state,
      };

    case FETCH_POLICY_CMS_FAIL:
      return {
        ...state,
      };

    case FETCH_POLICY_CMS_SUCCESS:
      return {
        ...state,
        fetchPolicyCmsData: get(action, "response.data.cmsPage"),
      };

    default:
      return state;
  }
}
