import { Button } from 'antd'
import styled, { keyframes } from 'styled-components'

export const CookiePolicyWrapper = styled.div`
    max-width: 500px;
    padding: 20px;
    box-shadow: 1px 1px 4px #1010107a;
    position: fixed;
    right: 16px;
    bottom: 16px;
    z-index: 1000;
    background: #ffffff;
    transition: .8s ease-in-out;
    overflow: hidden;
    box-sizing: border-box;
    animation-name: topFadeOut;
    animation-duration: .8s;

    @media ${(props) => props.theme.device.tablet} {
      left: 16px;
    }


@keyframes topFadeOut {
  0% {
    position: absolute;
    bottom: -10rem;
    opacity: 0;
  }

  75% {
    position: absolute;
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

`
export const CookieHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
`
export const CookieBody = styled.div`
    width: 100%;
    height: 100%;
`
export const CookieText = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
`
export const AcceptBtn = styled(Button)`
      color: #fff;
    background-color: #f59120;
    font-size: 16px;
    padding: 8px 30px;
    height: auto;
    &:hover{
        background-color: #f59120;
        color: #fff;
    }
`
export const CloseBtn = styled(Button)`
    display: block;
    border: none;
    font-size: 20px;

`
export const Text = styled.p`
    margin-bottom: 0px;
    line-height: 24px;
`
export const FreeShippingContainer = styled.span`
    font-size: 14px;
`
export const FreeShippingIconContainer = styled.span`
    margin-right: 8px;
`

