import {
    CREATE_CUSTOMER_CART,
    CREATE_CUSTOMER_CART_SUCCESS,
    CREATE_CUSTOMER_CART_FAIL,
    CREATE_CUSTOMER_CART_RESET
} from '../actions/createCustomerCartAction'


const initialState = {
    apiState: "", // loading, success, error
    data: [],
    refreshCart: false,
    refreshItemCount: false,
    preventAddToCart: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_CUSTOMER_CART:
            return {
                ...state,
                apiState: "loading",
                refreshCart: state.refreshCart,
                refreshItemCount: state.refreshItemCount,
                preventAddToCart: state.preventAddToCart
            }

        case CREATE_CUSTOMER_CART_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case CREATE_CUSTOMER_CART_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case CREATE_CUSTOMER_CART_RESET:
            return initialState


        default:
            return state
    }
}
