export const ADD_CONFIGRABLE_PRODUCT = "ADD_CONFIGRABLE_PRODUCT"
export const ADD_CONFIGRABLE_PRODUCT_FAIL = "ADD_CONFIGRABLE_PRODUCT_FAIL"
export const ADD_CONFIGRABLE_PRODUCT_SUCCESS = "ADD_CONFIGRABLE_PRODUCT_SUCCESS"
export const ADD_CONFIGRABLE_PRODUCT_RESET = "ADD_CONFIGRABLE_PRODUCT_RESET"

export const addConfigrableProduct = (params) => {
    return { type: ADD_CONFIGRABLE_PRODUCT, params }
}
export const addConfigrableProductFail = (response) => {
    return { type: ADD_CONFIGRABLE_PRODUCT_FAIL, response }
}
export const addConfigrableProductSuccess = (response) => {
    return { type: ADD_CONFIGRABLE_PRODUCT_SUCCESS, response }
}
export const addConfigrableProductReset = () => {
    return { type: ADD_CONFIGRABLE_PRODUCT_RESET }
}