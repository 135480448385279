import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  FETCH_HELP_CMS_REQUEST,
  fetchHelpCmsSuccess,
  fetchHelpCmsFail,
} from "../actions/fetchHelpCmsAction";
import get from 'lodash/get';

import fetchHelpCmsApi from "../apis/fetchHelpCmsApi";



function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(...otherParams);
  }
}

export function* fetchHelpCmsSaga(action) {

  try {

    const response = yield call(() => fetchHelpCmsApi(action));

    if (response.errors) {
      yield put(fetchHelpCmsFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(fetchHelpCmsSuccess(response, action));
      yield common(action, true, response);
    }
  } catch (e) {
    yield put(fetchHelpCmsFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(FETCH_HELP_CMS_REQUEST, fetchHelpCmsSaga);
}
