import {
    GET_PRODUCT_DETAIL,
    GET_PRODUCT_DETAIL_SUCCESS,
    GET_PRODUCT_DETAIL_FAIL,
    GET_PRODUCT_DETAIL_RESET

} from '../actions/productActions'
import get from 'lodash/get';


const initialState = {
    apiState: "", // loading, success, error
    product: {},
    loading: true,
    productTagData: "",
    productTagData1: "",
    productUid: null,
    configureProduct: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_DETAIL:
            return {
                ...state,
                apiState: "loading",
                loading: true,
                productTagData: state.productTagData,
                productTagData1: state.productTagData1
            }

        case GET_PRODUCT_DETAIL_SUCCESS:
            let valueData = get(action, 'response.data.products.items[0].options')
            let configureProductData = get(action, 'response.data.products.items[0].configurable_options')
            return {
                ...state,
                apiState: "success",
                loading: false,
                product: (action.response.data.products.items) ? action.response.data.products.items[0] : {},
                productUid: valueData,
                configureProduct: configureProductData,
            }

        case GET_PRODUCT_DETAIL_FAIL:
            return {
                ...state,
                apiState: "error",
            }
        case GET_PRODUCT_DETAIL_RESET:
            return initialState

        default:
            return state
    }
}
