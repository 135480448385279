import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    CREATE_GUEST_CART,
    createGuestCartSuccess,
    createGuestCartFail,
} from '../actions/createGuestCartAction'

import createGuestCartApi from '../apis/createGuestCartApi'


export function* createGuestCartSaga(action) {
    try {
        const response = yield call(() => createGuestCartApi(action.params))

        if (response.errors) {
            yield put(createGuestCartFail(response, action))
        } else {
            yield put(createGuestCartSuccess(response, action))
        }
    } catch (e) {
        yield put(createGuestCartFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(CREATE_GUEST_CART, createGuestCartSaga);
}