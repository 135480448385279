import {
    SET_SHIPPING_METHOD,
    SET_SHIPPING_METHOD_SUCCESS,
    SET_SHIPPING_METHOD_FAIL,
    SET_SHIPPING_METHOD_RESET
} from '../actions/setShippingMethodAction'



const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SHIPPING_METHOD:
            return {
                ...state,
                apiState: "loading"
            }
        case SET_SHIPPING_METHOD_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }
        case SET_SHIPPING_METHOD_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case SET_SHIPPING_METHOD_RESET:
            return initialState
        default:
            return state
    }
}