import {
    GUEST_SET_SHIPPING_ADDRESS,
    GUEST_SET_SHIPPING_ADDRESS_SUCCESS,
    GUEST_SET_SHIPPING_ADDRESS_FAIL,
    GUEST_SET_SHIPPING_ADDRESS_RESET
} from "../actions/guestSetShippingAddressAction";
import get from "lodash/get";

const initialState = {
    apiState: "",
    data: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GUEST_SET_SHIPPING_ADDRESS:
            return {
                ...state,
                apiState: "loading"
            };

        case GUEST_SET_SHIPPING_ADDRESS_FAIL:
            return {
                ...state,
                apiState: "error"
            };

        case GUEST_SET_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                data: action.response.data,
                apiState: "success"
            };
        case GUEST_SET_SHIPPING_ADDRESS_RESET:
            return initialState;

        default:
            return state;
    }
}
