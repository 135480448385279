
export const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS"
export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = "UPDATE_CUSTOMER_ADDRESS_SUCCESS"
export const UPDATE_CUSTOMER_ADDRESS_FAIL = "UPDATE_CUSTOMER_ADDRESS_FAIL"
export const UPDATE_CUSTOMER_ADDRESS_RESET = "UPDATE_CUSTOMER_ADDRESS_RESET"

export const updateCustomerAddress = (params) => {
    return { type: UPDATE_CUSTOMER_ADDRESS, params }
}
export const updateCustomerAddressSuccess = (response) => {
    return { type: UPDATE_CUSTOMER_ADDRESS_SUCCESS, response }
}
export const updateCustomerAddressFail = (response) => {
    return { type: UPDATE_CUSTOMER_ADDRESS_FAIL, response }
}
export const updateCustomerAddressReset = () => {
    return { type: UPDATE_CUSTOMER_ADDRESS_RESET }
}