export const REMOVE_CART_PRODUCT = "REMOVE_CART_PRODUCT"
export const REMOVE_CART_PRODUCT_SUCCESS = "REMOVE_CART_PRODUCT_SUCCESS"
export const REMOVE_CART_PRODUCT_FAIL = "REMOVE_CART_PRODUCT_FAIL"
export const REMOVE_CART_PRODUCT_RESET = "REMOVE_CART_PRODUCT_RESET"


export const removeCartProduct = (params) => {
    return { type: REMOVE_CART_PRODUCT, params }
}

export const removeCartProductSuccess = (response) => {
    return { type: REMOVE_CART_PRODUCT_SUCCESS, response }
}

export const removeCartProductFail = (response) => {
    return { type: REMOVE_CART_PRODUCT_FAIL, response }
}

export const removeCartProductReset = () => {
    return { type: REMOVE_CART_PRODUCT }
}