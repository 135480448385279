export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS"
export const SET_SHIPPING_ADDRESS_SUCCESS = "SET_SHIPPING_ADDRESS_SUCCESS"
export const SET_SHIPPING_ADDRESS_FAIL = "SET_SHIPPING_ADDRESS_FAIL"
export const SET_SHIPPING_ADDRESS_RESET = "SET_SHIPPING_ADDRESS_RESET"


export const setShippingAddress = (params) => {
    return { type: SET_SHIPPING_ADDRESS, params }
}
export const setShippingAddressSuccess = (response) => {
    return { type: SET_SHIPPING_ADDRESS_SUCCESS, response }
}
export const setShippingAddressFail = (response) => {
    return { type: SET_SHIPPING_ADDRESS_FAIL, response }
}
export const setShippingAddressReset = () => {
    return { type: SET_SHIPPING_ADDRESS_RESET }
}
