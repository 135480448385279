export const MERGE_CARTS = 'MERGE_CARTS'
export const MERGE_CARTS_SUCCESS = 'MERGE_CARTS_SUCCESS'
export const MERGE_CARTS_FAIL = 'MERGE_CARTS_FAIL'
export const MERGE_CARTS_RESET = 'MERGE_CARTS_RESET'

export const mergeCarts = (params) => {
    return { type: MERGE_CARTS, params }
}

export const mergeCartsSuccess = (response) => {
    return { type: MERGE_CARTS_SUCCESS, response }
}

export const mergeCartsFail = (response) => {
    return { type: MERGE_CARTS_FAIL, response }
}

export const mergeCartsReset = () => {
    return { type: MERGE_CARTS_RESET }
}