import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, PartnerHeading } from "./style";
import { fetchPartnerCmsRequest } from "../../actions/fetchPartnerCmsAction";
import Footer from "../../components/Footer/Footer";


const Partner = (props) => {
  const { fetchPartnerCmsRequest, fetchPartnerCmsData, history } = props;

  useEffect(() => {
    fetchPartnerCmsRequest({
      errorAction: (data) => { },
      successAction: (data) => {
      },
    });
  }, []);

  return (
    <>
      <Container>
        <PartnerHeading>
          <div
            dangerouslySetInnerHTML={{
              __html: (fetchPartnerCmsData && fetchPartnerCmsData.content) || null,
            }}
          />
        </PartnerHeading>
      </Container>
      <Footer isBackHeader history={history} />

    </>
  );
};

const mapStateToProps = (state) => ({
  fetchPartnerCmsData: state.partnerCms.fetchPartnerCmsData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPartnerCmsRequest: () => dispatch(fetchPartnerCmsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Partner);
