import {
    FETCH_STATE_REQUEST,
    FETCH_STATE_SUCCESS,
    FETCH_STATE_FAIL,
  } from "../actions/fetchStateAction";
  import get from 'lodash/get';

  const initialState = {
   stateData:null,
  };
  
  export default function (state = initialState, action) {
   
    switch (action.type) {
      case FETCH_STATE_REQUEST:
        return {
          ...state,
        };
  
      case FETCH_STATE_FAIL:
        return {
          ...state,
  
        };
  
      case FETCH_STATE_SUCCESS:
        return {
          ...state,
          stateData:get(action,"response.data.country.available_regions"),

        };
  
      default:
        return state;
    }
  }
  