import React, { useEffect, useState } from "react"
import { Button, Col, Form, Input, Row, Select, notification } from "antd"
import { AudioOutlined } from '@ant-design/icons';
import { connect } from "react-redux"

// actions
import { createCustomerAddress, createCustomerAddressReset } from "../../actions/createCustomerAddressAction"
import { fetchStateRequest } from "../../actions/fetchStateAction"

// others
import { getCustomerToken } from "../../utils"

const NewAddressForm = props => {
    // variables
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        state_code: "",
        state_id: "",
        mobile: "",
    })

    const {
        createCustomerAddress, createCustomerAddressReset, createCustomerAddressState,
        fetchStateRequest, fetchStateData
    } = props

    const prefix = (
        <Button style={{ border: "none" }}>+ 91</Button>
    )


    // callbacks
    useEffect(() => {
        fetchStateRequest()
    }, [])




    useEffect(() => {
        if (createCustomerAddressState.apiState === "success") {
            notification["success"]({
                message: 'New address saved',
                placement: "bottomRight"
            });
            props.closeModal()
            createCustomerAddressReset()
        }

        if (createCustomerAddressState.apiState === "error") {
            notification["error"]({
                message: createCustomerAddressState.message,
                placement: "bottomRight"
            });
            createCustomerAddressReset()
        }
    }, [createCustomerAddressState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        createCustomerAddress({
            first_name: formData.first_name,
            last_name: formData.last_name,
            address: formData.address,
            pincode: formData.pincode,
            city: formData.city,
            state: formData.state,
            state_code: formData.state_code,
            state_id: formData.state_id,
            mobile: formData.mobile,
            customerToken: getCustomerToken(),
        })

    }

    const handleSelect = (v) => {
        setFormData({
            ...formData,
            ["state_id"]: v.split("-")[0],
            ["state_code"]: v.split("-")[1],
            ["state"]: v.split("-")[2],
        })
    }

    return (
        <>
            <Form
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Row gutter="16" >
                    <Col span="12" >
                        <Form.Item
                            name="first_name"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="first_name" placeholder="First Name" onChange={handleOnChange} />
                        </Form.Item>
                    </Col>

                    <Col span="12" >
                        <Form.Item
                            name="last_name"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="last_name" placeholder="Last Name" onChange={handleOnChange} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="address"
                    rules={[
                        { required: true, message: 'Required' },
                    ]}
                >
                    <Input name="address" placeholder="Address" onChange={handleOnChange} />
                </Form.Item>

                <Row gutter="16" >
                    <Col span="12" >
                        <Form.Item
                            name="pincode"
                            rules={[
                                { required: true, message: 'Required' },
                                {
                                    pattern: new RegExp("^[0-9]{6}$"),
                                    message: 'Enter valid pincode.',
                                }
                            ]
                            }
                        >
                            <Input name="pincode" placeholder="PIN code" onChange={handleOnChange} />
                        </Form.Item>
                    </Col>

                    <Col span="12" >
                        <Form.Item
                            name="city"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="city" placeholder="City" onChange={handleOnChange} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter="16" >
                    <Col span="12" >
                        <Form.Item
                            name="state"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Select name="state" placeholder="State"
                                showSearch
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={handleSelect}
                            >
                                {(fetchStateData || []).map(item => (
                                    <Select.Option
                                        key={`${item.id}-${item.code}-${item.name}`} value={`${item.id}-${item.code}-${item.name}`} >
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span="12" >
                        <Form.Item
                            name="mobile"
                            enterButton={<button>+ 91</button>}
                            rules={[
                                { required: true, message: 'Required' },
                                {
                                    pattern: new RegExp('^[6-9]\\d{9}$'),
                                    message: 'Mobile number is not valid',
                                }
                            ]}
                        >
                            <Input style={{ padding: 0 }} name="mobile" placeholder="Mobile" prefix={prefix} onChange={handleOnChange} />
                        </Form.Item>
                    </Col>
                </Row>


                <Button type="primary"
                    htmlType="submit"
                    loading={createCustomerAddressState.apiState === "loading" ? true : false} >Submit</Button>
            </Form>
        </>
    )
}

const mapStateToProps = (state) => ({
    createCustomerAddressState: state.createCustomerAddress,
    fetchStateData: state.fetchState.stateData,
});

const mapDispatchToProps = (dispatch) => ({
    createCustomerAddress: (params) => dispatch(createCustomerAddress(params)),
    createCustomerAddressReset: () => dispatch(createCustomerAddressReset()),
    fetchStateRequest: () => dispatch(fetchStateRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewAddressForm);