import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_RESET,
} from "../actions/loginAction";
import get from 'lodash/get';

const initialState = {
    apiState: "", // loading, success, error
    token: null,
    errorMessage: "",
};

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                apiState: "loading",
            };

        case LOGIN_FAIL:
            return {
                ...state,
                apiState: "error",
                errorMessage: action.response.errors[0].message
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                apiState: "success",
                token: get(action, "response.data.generateCustomerToken.token"),
            };

        case LOGIN_RESET:
            return initialState;

        default:
            return state;
    }
}
