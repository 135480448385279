import {
    REMOVE_CART_PRODUCT,
    REMOVE_CART_PRODUCT_SUCCESS,
    REMOVE_CART_PRODUCT_FAIL,
    REMOVE_CART_PRODUCT_RESET
} from '../actions/removeCartProductAction'

const initialState = {
    apiState: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case REMOVE_CART_PRODUCT:
            return {
                ...state,
                apiState: "loading"
            }
        case REMOVE_CART_PRODUCT_SUCCESS:
            return {
                ...state,
                apiState: "success",
            }
        case REMOVE_CART_PRODUCT_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case REMOVE_CART_PRODUCT_RESET:
            return initialState

        default:
            return state
    }
}