import { FETCH_QUANTITY_REQUEST } from "../actions/detailActions";

const initialState = {
  getQuantity: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_QUANTITY_REQUEST:
      return {
        ...state,
        getQuantity: action.payload,
      };
    default:
      return state;
  }
}
