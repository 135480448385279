import styled from "styled-components"

export const Container = styled.div`
    cursor: pointer;
    padding:10px;
    display: flex;
    width: max-content;

    &:hover {
        background: #ddd;
        border-radius: 50%;
    }
`