import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const getCustomerInfoApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                    customer {
                        id
                        firstname
                        lastname
                        email
                        gender
                        orders(currentPage:${params.currentPage},pageSize: 5) {
                            total_count
                            page_info {
                                current_page
                            }
                            items {
                                order_date
                                items {
                                    product_name
                                    quantity_ordered
                                    product_url_key
                                     ... on OrderItemInterface {
                                    selected_options{
                                    label
                                    value
                                    }
                                    entered_options{
                                    label
                                    value
                                    }
                                     product_sale_price{
                                    value
                                    }

                                }
                                }
                                status
                                 shipments{
                                    id
                                    comments{
                                    message
                                    timestamp
                                    }
                                    tracking{
                                    carrier
                                    number
                                    }
                                }
                                total{
                                    subtotal {
                                        value
                                    }
                                    total_tax{
                                    value
                                    }
                                    shipping_handling{
                                        total_amount {
                                        value
                                        }
                                    }
                                    discounts{
                                        label
                                        amount {
                                        currency
                                        value
                                        }
                                    }
                                    grand_total{
                                        value
                                    }
                                }
                                number
                                billing_address {
                                    firstname
                                    lastname
                                    street
                                    postcode
                                    city
                                    region
                                    telephone
                                }
                                shipping_address {
                                    firstname
                                    lastname
                                    street
                                    postcode
                                    city
                                    region
                                    telephone
                                }
                            }
                        }
                        addresses   {
                            id
                            firstname
                            lastname
                            city
                            default_billing
                            default_shipping
                            postcode
                            telephone
                            region {
                                region
                                region_code
                                region_id
                            }
                            street
                        }
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default getCustomerInfoApi;
