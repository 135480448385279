export const UPDATE_PRODUCT_CART = 'UPDATE_PRODUCT_CART'
export const UPDATE_PRODUCT_CART_SUCCESS = 'UPDATE_PRODUCT_CART_SUCCESS'
export const UPDATE_PRODUCT_CART_FAIL = 'UPDATE_PRODUCT_CART_FAIL'
export const UPDATE_PRODUCT_CART_RESET = 'UPDATE_PRODUCT_CART_RESET'

export const updateProductCart = (params) => {
    return { type: UPDATE_PRODUCT_CART, params }
}

export const updateProductCartSuccess = (response) => {
    return { type: UPDATE_PRODUCT_CART_SUCCESS, response }
}

export const updateProductCartFail = (response) => {
    return { type: UPDATE_PRODUCT_CART_FAIL, response }
}
export const updateProductCartReset = () => {
    return { type: UPDATE_PRODUCT_CART_RESET }
}
