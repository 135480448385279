import styled from "styled-components"

export const Container = styled.div`
    margin: 16px;
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
`


export const SubContainer = styled.div`
    border-radius: 8px;
    width: 33.33333%;
    padding: 16px;  
    border-radius: 18px;
    @media screen and (max-width: 991px){
       width: 50%;
    }
    @media screen and (max-width: 500px){
       width: 100%;
    }
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input{
        border-radius: 16px !important;
    }
`


