import styled from 'styled-components'
import { Skeleton } from "antd";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;
`
export const CardContainer = styled.div`
  width: 45%;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  @media ${props => props.theme.device.mobileL} {
        width: 100%;
    }
`
export const ImageSkeleton = styled(Skeleton.Image)`

&&.ant-skeleton-element .ant-skeleton-image{
    height: 150px;
    width: 200px;
    margin-bottom: 10px;
}

`
export const Xskeleton = styled(Skeleton.Button)`
    width: 100%;
    
    &&.ant-skeleton.ant-skeleton-active .ant-skeleton-button{
        width: 100%;
        height: 150px;
        margin-bottom: 10px;
        border-radius: 5px;
    }
`

