import {
  GLOBAL_LOADER_REQUEST,
  GLOBAL_LOADER_SUCCESS,
  GLOBAL_LOADER_FAIL,
} from "../actions/showGlobalLoaderAction";

const initialState = {
  isLoading: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GLOBAL_LOADER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GLOBAL_LOADER_FAIL:
      return {
        ...state,
        isLoading: false,

      };

    case GLOBAL_LOADER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
