import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const removeItemCartApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        removeItemFromCart(
          input: {
            cart_id: "${data.params.cart_id}",
            cart_item_id: ${data.params.cart_item_id}
          }
        )
      {
        cart {
         
          items {
            id
            product {
              sku
              stock_status
              url_key
              jaipur_only_delivery
              name
              price_range{
                minimum_price{
                  final_price{
                    currency
                    value
                  }
                }
              }
              thumbnail{
                url
              }
              image {
                  url
              }
              media_gallery{
                position
                url
              }
              short_description{
                html
              }
              description{
                  html
              }
            }
            quantity
              prices{
                row_total_including_tax{
                  value
                }
              }
             ... on SimpleCartItem {
                customizable_options{
              label
              id
            values{
              value
              label
          }
          }
              prices{
                row_total_including_tax{
                  value
                }
              }
            }


          }
           prices{
            subtotal_including_tax{
              value
            }

            grand_total{
              value
              currency
            }
            discounts{
              amount{
                value
              }
              label
            }
          }

        }
       }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default removeItemCartApi;
