import { call, put, takeLatest } from 'redux-saga/effects'


import { GET_GUEST_CART_DATA, getGuestCartDataSuccess, getGuestCartDataFail } from '../actions/getGuestCartDataAction'

import getGuestCartDataApi from '../apis/getGuestCartDataApi'


export function* getGuestCartDataSaga(action) {
    try {
        const response = yield call(() => getGuestCartDataApi(action.params))
        if (response.errors) {
            yield put(getGuestCartDataFail(response, action))
        } else {
            yield put(getGuestCartDataSuccess(response, action))
        }
    } catch (e) {
        yield put(getGuestCartDataFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_GUEST_CART_DATA, getGuestCartDataSaga);
}