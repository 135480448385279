import axios from 'axios'

const checkGuestServiceAvailabilityApi = async (params) => {
    const response = await axios({
        method: 'post',
        url: `https://v1thirdpartyapi.shpgodev.xyz/serviceAndRates`,
        data: {
            "source": 302001, "destination": params.pincode, "paymentType": "ppd"
        },
        headers: {
            "Authorization": `${params.token}`,
            "content-type": "application/json",
            "platform": "shippigo"
        }
    })
    return response.data

}

export default checkGuestServiceAvailabilityApi