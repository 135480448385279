export const HOME_PAGE_BANNER = 'HOME_PAGE_BANNER'
export const HOME_PAGE_BANNER_SUCCESS = 'HOME_PAGE_BANNER_SUCCESS'
export const HOME_PAGE_BANNER_FAIL = 'HOME_PAGE_BANNER_FAIL'
export const HOME_PAGE_BANNER_RESET = 'HOME_PAGE_BANNER_RESET'

export const homePageBanner = (params) => {
    return { type: HOME_PAGE_BANNER, params }
}

export const homePageBannerSuccess = (response) => {
    return { type: HOME_PAGE_BANNER_SUCCESS, response }
}

export const homePageBannerFail = (response) => {
    return { type: HOME_PAGE_BANNER_FAIL, response }
}

export const homePageBannerReset = () => {
    return { type: HOME_PAGE_BANNER_RESET }
}