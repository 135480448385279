export const GUEST_REMOVE_CART_PRODUCT = "GUEST_REMOVE_CART_PRODUCT"
export const GUEST_REMOVE_CART_PRODUCT_SUCCESS = "GUEST_REMOVE_CART_PRODUCT_SUCCESS"
export const GUEST_REMOVE_CART_PRODUCT_FAIL = "GUEST_REMOVE_CART_PRODUCT_FAIL"
export const GUEST_REMOVE_CART_PRODUCT_RESET = "GUEST_REMOVE_CART_PRODUCT_RESET"


export const guestRemoveCartProduct = (params) => {
    return { type: GUEST_REMOVE_CART_PRODUCT, params }
}

export const guestRemoveCartProductSuccess = (response) => {
    return { type: GUEST_REMOVE_CART_PRODUCT_SUCCESS, response }
}

export const guestRemoveCartProductFail = (response) => {
    return { type: GUEST_REMOVE_CART_PRODUCT_FAIL, response }
}

export const guestRemoveCartProductReset = () => {
    return { type: GUEST_REMOVE_CART_PRODUCT_RESET }
}