import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_BRAND_INFO,
    getBrandInfoSuccess,
    getBrandInfoFail,
} from '../actions/getBrandInfoAction'

import getBrandInfoApi from '../apis/getBrandInfoApi'


export function* getBrandInfoSaga(action) {
    try {
        const response = yield call(() => getBrandInfoApi(action.params))

        if (response.errors) {
            yield put(getBrandInfoFail(response, action))
        } else {
            yield put(getBrandInfoSuccess(response, action))
        }
    } catch (e) {
        yield put(getBrandInfoFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_BRAND_INFO, getBrandInfoSaga);
}