import {
    REQUEST_PASSWORD_RESET_EMAIL,
    REQUEST_PASSWORD_RESET_EMAIL_SUCCESS,
    REQUEST_PASSWORD_RESET_EMAIL_FAIL,
    REQUEST_PASSWORD_RESET_EMAIL_RESET,
} from '../actions/requestPasswordResetEmailAction'


const initialState = {
    apiState: "", // loading, success, error
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_PASSWORD_RESET_EMAIL:
            return {
                ...state,
                apiState: "loading",
            }

        case REQUEST_PASSWORD_RESET_EMAIL_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case REQUEST_PASSWORD_RESET_EMAIL_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.errors[0] && action.response.errors[0].message || "Somthing is not right"
            }

        case REQUEST_PASSWORD_RESET_EMAIL_RESET:
            return initialState


        default:
            return state
    }
}
