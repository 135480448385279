import {
    REMOVE_ITEM_CART,
    REMOVE_ITEM_CART_SUCCESS,
    REMOVE_ITEM_CART_FAIL,
    REMOVE_ITEM_CART_RESET,
} from "../actions/removeItemCartAction";
import get from 'lodash/get';

const initialState = {
    apiState: "", // loading, success, error
    removeListItem: null,
    removeListPrices: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REMOVE_ITEM_CART:
            return {
                ...state,
                apiState: "loading",
            };


        case REMOVE_ITEM_CART_FAIL:
            return {
                ...state,
                apiState: "error",
            };

        case REMOVE_ITEM_CART_SUCCESS:
            return {
                ...state,
                apiState: "success",
                removeListItem: get(action, "response.data.removeItemFromCart.cart.items"),
                removeListPrices: get(action, "response.data.removeItemFromCart.cart.prices")
            };

        case REMOVE_ITEM_CART_RESET:
            return initialState;

        default:
            return state;
    }
}
