import {
    RE_ORDER,
    RE_ORDER_SUCCESS,
    RE_ORDER_FAIL,
    RE_ORDER_RESET,
} from '../actions/reOrderActions'


const initialState = {
    apiState: "", // loading, success, error
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case RE_ORDER:
            return {
                ...state,
                apiState: "loading",
            }

        case RE_ORDER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.reorderItems.userInputErrors
            }

        case RE_ORDER_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case RE_ORDER_RESET:
            return initialState

        default:
            return state
    }
}
