import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    DELETE_CUSTOMER_ADDRESS,
    deleteCustomerAddressSuccess,
    deleteCustomerAddressFail,
} from '../actions/deleteCustomerAddressAction'

import deleteCustomerAddressApi from '../apis/deleteCustomerAddressApi'


export function* deleteCustomerAddressSaga(action) {
    try {
        const response = yield call(() => deleteCustomerAddressApi(action.params))

        if (response.errors) {
            yield put(deleteCustomerAddressFail(response, action))
        } else {
            yield put(deleteCustomerAddressSuccess(response, action))
        }
    } catch (e) {
        yield put(deleteCustomerAddressFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(DELETE_CUSTOMER_ADDRESS, deleteCustomerAddressSaga);
}