import { combineReducers } from "redux";

import category from "./reducers/categoryReducer";
import product from "./reducers/productReducer";
import detail from "./reducers/detailReducer";
import productList from "./reducers/productListReducer";
import cartId from "./reducers/cartIdReducer";
import productCart from "./reducers/productCartReducer";
import removeItemCart from "./reducers/removeItemCartReducer";
import updateItem from "./reducers/updateCartReducer";
import signUp from "./reducers/signUpReducer";
import login from "./reducers/loginReducer";
import cartData from "./reducers/fetchCartReducer";
import customerCartId from "./reducers/customerCartIdReducer";
import mergeCartId from "./reducers/mergeCartReducer";
import fetchState from "./reducers/fetchStateReducer";
import addAddress from "./reducers/addAddressReducer";
import fetchAddress from "./reducers/fetchAddressReducer";
import loader from './reducers/globalLoaderReducer';
import fetchSearch from './reducers/fetchSearchReducer';
import searchText from './reducers/updateSearchTextReducer';
import setShip from './reducers/setShippingReducer';
import fetchShipAmount from './reducers/fetchShippingAddressReducer';
import addCoupon from './reducers/addCouponReducer';
import removeCoupon from './reducers/removeCouponReducer';
import paymentMethod from './reducers/fetchPaymentMethodReducer';
import placeOrder from './reducers/fetchPlaceOrderReducer';
import setBillingAddress from './reducers/setBillingAddressReducer';
import toggleCoupon from './reducers/toggleCouponReducer';
import orders from './reducers/fetchOrderaReducer';
import updateAddress from './reducers/updateAddressReducer';
import getRelatedProducts from './reducers/fetchRelatedProductReducer';
import aboutCms from './reducers/fetchAboutReducer';
import helpCms from './reducers/fetchHelpCmsReducer';
import policyCms from './reducers/fetchPolicyCmsReducer';
import partnerCms from './reducers/fetchPartnerCmsReducer';
import addWishList from './reducers/addWishListReducer';
import removeWishList from './reducers/removeWishListReducer';
import createGuestCart from './reducers/createGuestCartReducer';
import addProductToCart from './reducers/addProductToCartReducer';
import createCustomerCart from './reducers/createCustomerCartReducer';
import mergeCarts from './reducers/mergeCartsReducer';
import getCartCount from './reducers/getCartCountReducer';
import getCustomerInfo from './reducers/getCustomerInfoReducer';
import getProductList from './reducers/getProductListReducer';
import getBrandInfo from './reducers/getBrandInfoReducer';
import createCustomer from './reducers/createCustomerReducer';
import requestPasswordResetEmail from './reducers/requestPasswordResetEmailReducer';
import resetPassword from './reducers/resetPasswordReducer';
import updateCustomerInfo from './reducers/updateCustomerInfoReducer';
import changeCustomerPassword from './reducers/changeCustomerPasswordReducer';
import deleteCustomerAddress from './reducers/deleteCustomerAddressReducer';
import createCustomerAddress from './reducers/createCustomerAddressReducer';
import getCmsPage from './reducers/getCmsPageReducer';
import getTopProduct from './reducers/getTopProductReducer';
import checkDelivery from './reducers/checkDeliveryReducer';
import addProductToWishList from './reducers/addProductToWishListReducer';
import getWishList from './reducers/getWishListReducer';
import deleteWishList from './reducers/deleteWishListReducer'
import getCartData from './reducers/getCartDataReducer'
import updateCartProduct from './reducers/updateCartProductReducer'
import removeCartProduct from './reducers/removeCartProductReducer'
import applyCouponToCart from './reducers/applyCouponToCartReducer'
import removeCouponToCart from './reducers/removeCouponToCartReducer'
import salesPlaceOrder from './reducers/salesPlaceOrderReducer'
import setPaymentMethod from './reducers/setPaymentMethodReducer'
import getShippigoToken from './reducers/getShippigoTokenReducer'
import checkServiceAvailability from './reducers/checkServiceAvailabilityReducer'
import billingAddress from './reducers/billingAddressReducer'
import setShippingAddress from './reducers/setShippingAddressReducer'
import setShippingMethod from './reducers/setShippingMethodReducer'
import getBrandList from './reducers/getBrandListReducer'
import getBrandDetails from './reducers/getBrandDetailsReducer'
import getFeaturedImageList from './reducers/getFeaturedImageListReducer'
import createGuestCustomerAddress from './reducers/createGuestCustomerAddressReducer'
import fetchGuestState from './reducers/fetchGuestStateReducer'
import guestBillingAddress from './reducers/guestBillingAddressReducer'
import guestSetShippingAddress from './reducers/guestSetShippingAddressReducer'
import setGuestEmailCart from './reducers/setGuestEmailCartReducer'
import getGuestShippigoToken from './reducers/getGuestShippigoTokenReducer'
import checkGuestServiceAvailability from './reducers/checkGuestServiceAvailabilityReducer'
import getGuestCartData from './reducers/getGuestCartDataReducer'
import fetchGuestCartData from './reducers/fetchGuestCartDataReducer'
import guestRemoveCartProduct from './reducers/guestRemoveCartProductReducer'
import guestApplyCouponToCart from './reducers/guestApplyCouponToCartReducer'
import guestRemoveCouponToCart from './reducers/guestRemoveCouponToCartReducer'
import guestSetPaymentMethod from './reducers/guestSetPaymentMethodReducer'
import guestSalesPlaceOrder from './reducers/guestSalesPlaceOrderReducer'
import updateCustomerAddress from './reducers/updateCustomerAddressReducer'
import placeRazorpayOrder from './reducers/placeRazorpayOrderReducer'
import setRzpPaymentDetailsOnCart from './reducers/setRzpPaymentDetailsOnCartReducer'
import fetchBrandById from './reducers/fetchBrandByIdReducer'
import addConfigrableProduct from './reducers/addConfigrableProductReducer'
import subscribeEmail from './reducers/subscribeEmailReducer'
import showOnHomePageBurniSection from './reducers/showOnHomePageBurniSectionReducer'
import showOnHomePageBrandSection from './reducers/showOnHomePageBrandSectionReducer'
import homePageBanner from './reducers/homePageBannerReducer'
import homePageBannerMobile from "./reducers/homePageBannerMobile";
import reOrder from "./reducers/reOrderReducer";

export default combineReducers({
  category,
  product,
  detail,
  productList,
  cartId,
  productCart,
  removeItemCart,
  updateItem,
  signUp,
  login,
  cartData,
  customerCartId,
  mergeCartId,
  fetchState,
  addAddress,
  fetchAddress,
  loader,
  fetchSearch,
  searchText,
  setShip,
  fetchShipAmount,
  addCoupon,
  removeCoupon,
  paymentMethod,
  placeOrder,
  setBillingAddress,
  toggleCoupon,
  orders,
  updateAddress,
  getRelatedProducts,
  aboutCms,
  helpCms,
  policyCms,
  partnerCms,
  addWishList,
  removeWishList,
  createGuestCart,
  addProductToCart,
  createCustomerCart,
  mergeCarts,
  getCartCount,
  getCustomerInfo,
  getProductList,
  getBrandInfo,
  createCustomer,
  requestPasswordResetEmail,
  resetPassword,
  updateCustomerInfo,
  changeCustomerPassword,
  deleteCustomerAddress,
  createCustomerAddress,
  getCmsPage,
  getTopProduct,
  checkDelivery,
  addProductToWishList,
  getWishList,
  deleteWishList,
  getCartData,
  updateCartProduct,
  removeCartProduct,
  applyCouponToCart,
  removeCouponToCart,
  salesPlaceOrder,
  setPaymentMethod,
  getShippigoToken,
  checkServiceAvailability,
  billingAddress,
  setShippingAddress,
  setShippingMethod,
  getBrandList,
  getBrandDetails,
  getFeaturedImageList,
  createGuestCustomerAddress,
  fetchGuestState,
  guestBillingAddress,
  guestSetShippingAddress,
  setGuestEmailCart,
  getGuestShippigoToken,
  checkGuestServiceAvailability,
  getGuestCartData,
  fetchGuestCartData,
  guestRemoveCartProduct,
  guestApplyCouponToCart,
  guestRemoveCouponToCart,
  guestSetPaymentMethod,
  guestSalesPlaceOrder,
  updateCustomerAddress,
  placeRazorpayOrder,
  setRzpPaymentDetailsOnCart,
  fetchBrandById,
  addConfigrableProduct,
  subscribeEmail,
  showOnHomePageBurniSection,
  showOnHomePageBrandSection,
  homePageBanner,
  homePageBannerMobile,
  reOrder,
});
