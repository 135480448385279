import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_BRAND_LIST,
    getBrandListSuccess,
    getBrandListFail,
} from '../actions/getBrandListAction'

import getBrandListApi from '../apis/getBrandListApi'


export function* getBrandListSaga(action) {
    try {
        const response = yield call(() => getBrandListApi(action.params))

        if (response.errors) {
            yield put(getBrandListFail(response, action))
        } else {
            yield put(getBrandListSuccess(response, action))
        }
    } catch (e) {
        yield put(getBrandListFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_BRAND_LIST, getBrandListSaga);
}