export const FETCH_ABOUT_REQUEST = 'FETCH_ABOUT_REQUEST'
export const FETCH_ABOUT_SUCCESS = 'FETCH_ABOUT_SUCCESS'
export const FETCH_ABOUT_FAIL = 'FETCH_ABOUT_FAIL'

export const fetchAboutRequest = (params) => {
    return { type: FETCH_ABOUT_REQUEST, params }
}

export const fetchAboutSuccess = (response) => {
    return { type: FETCH_ABOUT_SUCCESS, response }
}

export const fetchAboutFail = (response) => {
    return { type: FETCH_ABOUT_FAIL, response }
}