import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const updateCustomerInfoApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        updateCustomer(
                            input: {
                                firstname: "${params.first_name}",
                                lastname: "${params.last_name}",
                                email: "${params.email}",
                                gender: ${params.gender}
                            }
                        ) {
                            customer {
                                firstname
                            }
                        }
                    }
                    `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data;
};

export default updateCustomerInfoApi;
