import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Card, Row, Col, Tag } from "antd";
import { connect } from "react-redux";
import { Xgrid, XgridCard } from "../../components/Xcomponent";
import {
    SmileTwoTone,
    HeartTwoTone,
    HeartFilled,
    ArrowLeftOutlined,
} from "@ant-design/icons";
//import { CARD_CONFIG } from "./constant";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import {
    CardContainer,
    HeartContainer,
    ProductContainer,
    ProductCard,
    ProductListName,
    CardItem,
    CardName,
    CardPrice,
    ProductCardItem,
    ProductHeader,
    ProductCount,
    BrandName,
    ArrowNameContainer,
    ArrowContainer,
} from "./style";
import { fetchFavouriteCount } from "../../actions/productListAction";
import { fetchListCategoriesRequest } from "../../actions/productListAction";
import { getProductDetail } from "../../actions/productActions";
import { brandRequest } from '../../actions/fetchBrandAction';
import CardListSkeleton from "../../components/CardListSkeleton/CardListSkeleton";
import NoDataContianer from "../../components/NoDataContainer/NoData";
import { SITE_URL } from "../../utils";

const New = (props) => {
    const {
        fetchFavouriteCount,
        productListData,
        fetchListCategoriesRequest,
        getProductDetail,
        history,
        isLoading,
        isDetailLoading,
        brandRequest,
    } = props;
    // const categoryKey = props.match.params.categoryKey;
    const [cardData, setCardData] = useState();
    const [favouritesCount, setFavouritesCount] = useState(null);
    const [brandData, setBrandData] = useState(null);
    const { Meta } = Card;
    const previousPath = useHistory();

    useEffect(() => {
        fetchListCategoriesRequest({ id: 67, product_tag: 'product_tag' });
        brandRequest({
            errorAction: (data) => {
            },
            successAction: (data) => {
                const brandItems = data.data.customAttributeMetadata.items[0].attribute_options
                setBrandData(brandItems)
            },
        })
    }, []);

    useEffect(() => {
        fetchFavouriteCount(favouritesCount);
    }, [favouritesCount]);

    useEffect(() => {
        if (productListData) {
        }
    }, [productListData]);

    const onOutlineHeartClick = (e, data) => {
        e.stopPropagation()
        const updatedData = [...cardData];
        updatedData.map((item) => {
            if (item.id === data.id) {
                item.isHeartFilled = true;
                setFavouritesCount((favouritesCount) => favouritesCount + 1);
            }
        });

        setCardData(updatedData);
    };
    const onFilledHeartClick = (e, data) => {
        e.stopPropagation();
        const updatedData = [...cardData];
        updatedData.map((item) => {
            if (item.id === data.id) {
                item.isHeartFilled = false;
                setFavouritesCount((favouritesCount) => favouritesCount - 1);
            }
        });
        setCardData(updatedData);
    };

    const productListClick = (item) => {
        history.push({
            pathname: `/product-detail/${item.url_key}`,
            //  state: response.data.custom_attributes,
        });
        // getProductDetail({
        //   urlKey: item.url_key,
        //   errorAction: (data) => {
        //   },
        //   successAction: (data) => {

        //     const skuKey = data.data.products.items[0].sku;
        //     handleproductRestApi(skuKey, item.url_key);
        //   },
        // });
    };

    const handleproductRestApi = (skuKey, url_key) => {
        const AuthStr = "Bearer ".concat("uchz0at3uos0eagb4ngmmnespkermn3g");
        axios
            .get(`${SITE_URL()}/rest/default/V1/products/${skuKey}`, {
                headers: { Authorization: AuthStr },
            })
            .then((response) => {
                // If request is good...
                history.push({
                    pathname: `/product-detail/${url_key}`,
                    state: response.data.custom_attributes,
                });
            })
            .catch((error) => {
            });
    };

    const goToPreviousPath = () => {
        previousPath.goBack();
    };

    return (
        <>
            <ProductHeader>
                <ArrowNameContainer>
                    <ArrowContainer onClick={goToPreviousPath}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                            alt="ladu-india"
                            width="36"
                        />
                        <h1 style={{ margin: "0 0 0 10px", fontWeight: "bold" }} >New Products</h1>
                    </ArrowContainer>
                    <ProductListName>
                    </ProductListName>
                </ArrowNameContainer>
                <ProductCount>
                    Showing {productListData && productListData.length} Results{" "}
                </ProductCount>
            </ProductHeader>
            {(isLoading) && (
                <ProductContainer>
                    <CardListSkeleton rows={12} />
                </ProductContainer>
            )}
            {(!isLoading) && (
                <ProductContainer noData={productListData.length}>
                    {productListData && productListData.length > 0 ? (
                        productListData.map((item) => {
                            return (
                                <ProductCard onClick={() => productListClick(item)}>
                                    {/* <ProductCardItem> */}
                                    {/* <CardContainer> */}
                                    {!item.isHeartFilled ? (
                                        <HeartContainer onClick={(e) => onOutlineHeartClick(e, item)}>
                                            <HeartTwoTone
                                                twoToneColor="orange"
                                                style={{ fontSize: "28px" }}
                                            />
                                        </HeartContainer>
                                    ) : (
                                        <HeartContainer onClick={(e) => onFilledHeartClick(e, item)}>
                                            <HeartFilled
                                                style={{ fontSize: "28px", color: "#e75480" }}
                                            />
                                        </HeartContainer>
                                    )}

                                    <CardItem
                                        hoverable
                                        //  style={{ width: 240 }}
                                        cover={
                                            <img
                                                alt="example"
                                                height="100%"
                                                src={item.thumbnail.url}
                                            //  src="https://staging.laduindia.com/pub/media/catalog/product/cache/e43961bd10b230de7ab49ab3c322a3d4/d/s/dscf0644.jpg"
                                            />
                                        }
                                    >
                                        <Meta title={item.name} />
                                        {/* <CardName> {item.name}</CardName> */}
                                        <BrandName>Brand A</BrandName>
                                        <CardPrice>
                                            {" "}
                                            {/* {item.price_range.minimum_price.final_price.currency}{" "} */}
                                            ₹ {item.price_range.minimum_price.final_price.value}
                                        </CardPrice>
                                    </CardItem>
                                    {/* </CardContainer> */}
                                    {/* </ProductCardItem> */}
                                </ProductCard>
                            );
                        })
                    ) : (
                        <NoDataContianer animationType={'searchAnimation'} />
                    )}
                </ProductContainer>
            )}
            <Footer isShop history={history} />
        </>
    );
};
const mapStateToProps = (state) => ({
    productListData: state.productList.productListData,
    isLoading: state.productList.loading,
    isDetailLoading: state.product.loading,

});

const mapDispatchToProps = (dispatch) => ({
    fetchFavouriteCount: (params) => dispatch(fetchFavouriteCount(params)),
    fetchListCategoriesRequest: (params) =>
        dispatch(fetchListCategoriesRequest(params)),
    getProductDetail: (params) => dispatch(getProductDetail(params)),
    brandRequest: (params) => dispatch(brandRequest(params)),

});

export default connect(mapStateToProps, mapDispatchToProps)(New);
