export const REMOVE_COUPON_REQUEST = "REMOVE_COUPON_REQUEST";
export const REMOVE_COUPON_SUCCESS = "REMOVE_COUPON_SUCCESS";
export const REMOVE_COUPON_FAIL = "REMOVE_COUPON_FAIL";

export const removeCouponRequest = (params) => {
  return { type: REMOVE_COUPON_REQUEST, params };
};

export const removeCouponSuccess = (response) => {
  return { type: REMOVE_COUPON_SUCCESS, response };
};

export const removeCouponFail = (response) => {
  return { type: REMOVE_COUPON_FAIL, response };
};
