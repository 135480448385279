import React, { useEffect, useState, useRef } from "react"

import { connect } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
//Components
import Footer from '../../components/Footer/Footer'
import Loader from '../../components/Loader/Loader'
import BackButton from '../../components/BackButton/BackButton'

//Style
import { Container, MainContainer } from "./style"
// Actions
import { getFeaturedImageList, getFeaturedImageListReset } from "../../actions/getFeaturedImageListAction";
import { Link } from "react-router-dom";

const BackHomePage = props => {
    const { history } = props;
    // variables
    const {
        getFeaturedImageList, getFeaturedImageListState, getFeaturedImageListReset
    } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [featuredImageData, setFeaturedImageData] = useState([])
    const [count, setCount] = useState(0)
    const [renderList, setRenderList] = useState("") // loading, success, error
    const prevCurrentPageRef = useRef()


    // Callback
    useEffect(() => {
        prevCurrentPageRef.current = currentPage
    }, [])
    const prevPage = prevCurrentPageRef.current

    useEffect(() => {
        window.scrollTo(0, 0)
        return (() => {
            getFeaturedImageListReset()
        })
    }, [])
    useEffect(() => {
        getFeaturedImageList({ attributeType: 'showcase_on_back_home_page', currentPage: currentPage })
    }, [currentPage])
    useEffect(() => {
        if (featuredImageData.length === 0) {
            setRenderList("loading")
        }
        if (getFeaturedImageListState.apiState === "success") {
            const imageListTotalCount = getFeaturedImageListState.data.products.total_count
            if (currentPage !== prevPage) {
                let data = [...featuredImageData]
                getFeaturedImageListState.data.products.items.map((item) => {
                    data.push(item)
                })
                setFeaturedImageData(data)
                handleCount(imageListTotalCount, data.length)
            }
            else {
                setFeaturedImageData(getFeaturedImageListState.data.products.items)
                handleCount(imageListTotalCount, getFeaturedImageListState.data.products.items.length)
            }
        }

    }, [getFeaturedImageListState])

    useEffect(() => {
        if (count > 0) {
            setRenderList("success")
        }
    }, [count])

    // functions
    const handleCount = (imageListTotalCount, dataLength) => {
        if (imageListTotalCount > dataLength) {
            setHasMore(true)
        }
        else {
            setHasMore(false)
        }
        setCount(count + 1)
        getFeaturedImageListReset()
    }




    return (
        <>
            {(renderList === "loading") &&
                <Loader height={60} width={80} />
            }
            {featuredImageData.length > 0 &&
                <>
                    <InfiniteScroll
                        dataLength={featuredImageData.length}
                        next={() => {
                            setCurrentPage(currentPage + 1)
                        }
                        }
                        hasMore={hasMore}
                    >
                        {(renderList === "success" && featuredImageData.length > 0) &&
                            <Container >
                                <MainContainer>
                                    <div >
                                        {/* <Link to="/">
                                            <BackButton style={{ marginLeft: 14 }} />
                                        </Link> */}
                                        <div id="columns">
                                            {featuredImageData.map((item) => {
                                                return (
                                                    <>
                                                        <figure>
                                                            {item.is_brand === 1 ?
                                                                <Link
                                                                    to={`/brand/${item.url_key}`}>
                                                                    <LazyLoadImage
                                                                        src={item.small_image.url}
                                                                        effect="blur"
                                                                    />
                                                                </Link>
                                                                :
                                                                <Link to={`/product-detail/${item.url_key}`}>
                                                                    <LazyLoadImage
                                                                        src={item.small_image.url}
                                                                        effect="blur"
                                                                    />
                                                                </Link>
                                                            }

                                                        </figure>

                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </MainContainer>
                            </Container>
                        }
                    </InfiniteScroll>
                    <div style={{ paddingBottom: 60, paddingTop: 10, display: "flex", justifyContent: "center" }} >
                        {getFeaturedImageListState.apiState === "loading" &&
                            <Loader />
                        }
                    </div>
                </>
            }
            <Footer isBackHeader history={history} />
        </>
    )
}

const mapStateToProps = (state) => ({
    searchTextState: state.searchText,
    getFeaturedImageListState: state.getFeaturedImageList,
});

const mapDispatchToProps = (dispatch) => ({
    getFeaturedImageList: (params) => dispatch(getFeaturedImageList(params)),
    getFeaturedImageListReset: () => dispatch(getFeaturedImageListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BackHomePage);
