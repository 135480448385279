import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import { Container, LeftSection, RightSection, IconContainer, Title, SubTitle, Xskeleton, ImageSkeleton } from './style'


const CardListSkeleton = props => {
    const CardSkeleton = () => (
        <Container>
            <LeftSection>
                {/* <IconContainer><Skeleton.Avatar shape={'circle'}  active /></IconContainer> */}
                <ImageSkeleton size={70} />
            </LeftSection>
            <RightSection>
                <Title hideMobile><Xskeleton type="text" w="100%" h="15px" mb="5px" active /></Title>
                <SubTitle>
                    <Xskeleton type="text" w="200px" h="10px" active />
                </SubTitle>
                <SubTitle>
                    <Xskeleton type="text" w="200px" h="10px" shape="square" active />
                </SubTitle>


            </RightSection>
        </Container>
    )

    let rs = []
    for (var row = 1; row <= props.rows; row++) {
        rs.push(<CardSkeleton />)
    }

    return rs
}

CardListSkeleton.propTypes = {
    rows: PropTypes.number,
}

CardListSkeleton.defaultProps = {
    rows: 4
}

export default CardListSkeleton