import styled from "styled-components"

export const attribute_config = [
  { "27": "Awadhesh Kumar " },
  { "28": "K's Kitchen" },
  { "32": "Gardenia" }
]

export const attribute_config_return = [
  { "16": "Non Returnable" },
  { "17": "Returnable within 7 days" },
]



export const attribute_config_dispatch = [
  { "33": "Ships in 1 day" },
  { "34": "Ships in 3 days" },
  { "35": "Ships in 7 days" },

]

export const atrribute_data = [
  {
    attribute_code: "short_description",
    "value": "",
    "name": "Short Description"
  },
  {
    attribute_code: "package_contents",
    "value": "1 pc",
    "name": "Package Contents",

  },
  // {
  //   attribute_code: "dispatch_readiness",
  //   "value": "33",
  //   "name":"Dispatch Readiness"

  // },
  {
    attribute_code: "product_dimensions",
    "value": "10 cm (l) x  9.2 cm (b) x 15.5cm (h)",
    "name": "Product Dimensions"

  },

  {
    attribute_code: "net_weight",
    "value": "330 g ",
    "name": "Net Weight"

  },
  // {
  //   attribute_code: "made_in",
  //   "value": "Pune, Maharashra",
  //   "name":"Made In"

  // },
  {
    attribute_code: "ingredients",
    "value": "Ingredients: Sugar, Alphonso mango pulp (45%). Alphonso mango dices, Water, Acidity regulator (INS 330), Gelling agent (INS 440), Preservative (INS 211) and Saffron.\r\n\r\nAllergen declaration: Processed in a facility that also handles soya, sesame and nuts.",
    "name": "Ingredients"

  },
  {
    attribute_code: "shelf_life",
    "value": "1 year",
    "name": "Shelf Life"

  },
  {
    attribute_code: "ladu_points",
    "value": "30",
    "name": "Ladu Points"

  },
  {
    attribute_code: "material",
    "value": "Pure Cotton and Leather used for strong built.",
    "name": "Material"
  },
  {
    attribute_code: 'care_instructions',
    "name": 'Care Instructions'
  },

]