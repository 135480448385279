export const SUBSCRIBE_EMAIL = 'SUBSCRIBE_EMAIL'
export const SUBSCRIBE_EMAIL_SUCCESS = 'SUBSCRIBE_EMAIL_SUCCESS'
export const SUBSCRIBE_EMAIL_FAIL = 'SUBSCRIBE_EMAIL_FAIL'
export const SUBSCRIBE_EMAIL_RESET = 'SUBSCRIBE_EMAIL_RESET'

export const subscribeEmail = (params) => {
    return { type: SUBSCRIBE_EMAIL, params }
}

export const subscribeEmailSuccess = (response) => {
    return { type: SUBSCRIBE_EMAIL_SUCCESS, response }
}

export const subscribeEmailFail = (response) => {
    return { type: SUBSCRIBE_EMAIL_FAIL, response }
}
export const subscribeEmailReset = () => {
    return { type: SUBSCRIBE_EMAIL_RESET }
}
