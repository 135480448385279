import {
    GET_BRAND_DETAILS,
    GET_BRAND_DETAILS_SUCCESS,
    GET_BRAND_DETAILS_FAIL,
    GET_BRAND_DETAILS_RESET
} from '../actions/getBrandDetailsAction'


const initialState = {
    apiState: "", // loading, success, error
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BRAND_DETAILS:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_BRAND_DETAILS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.products.items.length > 0 ? action.response.data.products.items[0] : {}
            }

        case GET_BRAND_DETAILS_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_BRAND_DETAILS_RESET:
            return initialState


        default:
            return state
    }
}
