export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST'
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS'
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL'

export const addAddressRequest = (params) => {
    return { type: ADD_ADDRESS_REQUEST, params }
}

export const addAddressSuccess = (response) => {
    return { type: ADD_ADDRESS_SUCCESS, response }
}

export const addAddressFail = (response) => {
    return { type: ADD_ADDRESS_FAIL, response }
}