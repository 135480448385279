import { call, put, takeLatest } from 'redux-saga/effects'

// Action
import {
    SET_SHIPPING_ADDRESS,
    setShippingAddressSuccess,
    setShippingAddressFail
} from '../actions/setShippingAddressAction'

// Api
import setShippingAddressApi from '../apis/setShippingAddressApi'


export function* setShippingAddressSaga(action) {
    try {
        const response = yield call(() => setShippingAddressApi(action.params))
        if (response.data.setShippingAddressesOnCart === null) {
            yield put(setShippingAddressFail(response, action))
        }
        else {
            yield put(setShippingAddressSuccess(response, action))
        }
    }
    catch (e) {
        yield put(setShippingAddressFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(SET_SHIPPING_ADDRESS, setShippingAddressSaga);
}