import React, { useEffect, useState } from "react"
import { Button, Form, Input, Alert, notification } from "antd"
import { connect } from "react-redux";
import { Redirect, Link, useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Lottie from "react-lottie"

// components
import { Container, Box } from "./LoginPageStyle"

// actions
import { loginRequest, loginReset } from '../../actions/loginAction'
import { createCustomerCart, createCustomerCartReset } from '../../actions/createCustomerCartAction'
import { mergeCarts, mergeCartsReset } from '../../actions/mergeCartsActions'

// others
import { getCartId, setCartId, setCustomerToken, getCustomerToken } from '../../utils'
import manShoppingAnimation from "../../lottie/man-shopping.json"
import { getCartCount, getCartCountReset } from "../../actions/getCartCountAction";

const LoginPage = props => {
    // variables
    let query = new URLSearchParams(useLocation().search)
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    })
    const {
        loginRequest, loginReset, loginState,
        createCustomerCart, createCustomerCartReset, createCustomerCartState,
        mergeCarts, mergeCartsReset, mergeCartsState, handleCloseDrawer, handleRefreshClick, handleForgetPassword,
        getCartCount, getCartCountReset
    } = props



    const [redirect, setRedirect] = useState([false, ""])


    const guestCartId = getCartId();
    const customerToken = getCustomerToken();
    const cart_id = getCartId()
    let redirectToCheckout = query.get("r")
    const manShoppingOptions = {
        loop: true,
        autoplay: true,
        animationData: manShoppingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const previousPath = useHistory();

    // callbacks
    useEffect(() => {
        return (() => {
            loginReset()
            createCustomerCartReset()
            mergeCartsReset()
            getCartCountReset()
        })
    }, [])

    useEffect(() => {
        if (loginState.apiState === "success") {

            notification["success"]({
                message: 'Good to see you again!',
                placement: "bottomRight"
            });
            createCustomerCartState.preventAddToCart = true
            /* creating customer cart */
            createCustomerCart({
                customerToken: loginState.token
            })

            setCustomerToken(loginState.token)
        }

    }, [loginState])


    useEffect(() => {
        if (createCustomerCartState.apiState === "success") {
            setCartId(createCustomerCartState.data.customerCart.id)
            createCustomerCartState.refreshCart = true
            createCustomerCartState.refreshItemCount = true
            handleCloseDrawer();
            if (guestCartId) {
                /* merging carts */
                mergeCarts({
                    source_cart_id: guestCartId,
                    destination_cart_id: createCustomerCartState.data.customerCart.id,
                    customerToken: loginState.token
                })
            } else {
                if (redirectToCheckout === "1") {
                    setRedirect([true, "/checkout"])
                } else {
                    // setRedirect([true, previousPath.goBack()])
                }
            }
            handleRefreshClick()
        }
    }, [createCustomerCartState])

    useEffect(() => {
        if (mergeCartsState.apiState === "success") {
            getCartCount({
                cart_id: cart_id,
                customerToken: customerToken
            })
            if (redirectToCheckout === "1") {
                setRedirect([true, "/checkout"])
            } else {
                // setRedirect([true, "/"])
            }

        }
    }, [mergeCartsState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        loginRequest({
            email: formData.email,
            password: formData.password,
        })
    }
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <Container>
                <Box>
                    <div style={{ display: "flex", justifyContent: "center" }} >
                        <img src={`${process.env.PUBLIC_URL}/images/Bauji.jpg`} style={{ width: "100%", height: "100%" }} />
                        {/* <Lottie
                            options={manShoppingOptions}
                            height={200}
                            width={200}
                            style={{ margin: "none" }}
                        /> */}
                    </div>
                    {loginState.apiState === "error" &&
                        <Alert message={loginState.errorMessage} type="error" style={{ marginBottom: 16 }} />
                    }
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Required' },
                                { type: 'email', message: 'Email is not valid' },
                            ]}
                        >
                            <Input name="email" placeholder="Email" onChange={handleOnChange} />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input.Password name="password" placeholder="Password" onChange={handleOnChange} />
                        </Form.Item>
                        <Button type="primary" htmlType="submit" block loading={loginState.apiState === "loading" ? true : false}  >Login</Button>
                        {/* <p style={{ marginTop: 16 }} >Don't have an account? <Link to="/signup" ><b>Signup</b></Link></p> */}
                        <p style={{ marginTop: 16, textTransform: 'capitalize' }}>forgot your password? <Link onClick={handleForgetPassword} ><b>Click here</b></Link></p>
                    </Form>
                </Box>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    loginState: state.login,
    createCustomerCartState: state.createCustomerCart,
    mergeCartsState: state.mergeCarts,
    getCartCountState: state.getCartCount
});

const mapDispatchToProps = (dispatch) => ({
    loginRequest: (params) => dispatch(loginRequest(params)),
    loginReset: () => dispatch(loginReset()),
    createCustomerCart: (params) => dispatch(createCustomerCart(params)),
    createCustomerCartReset: () => dispatch(createCustomerCartReset()),
    mergeCarts: (params) => dispatch(mergeCarts(params)),
    mergeCartsReset: () => dispatch(mergeCartsReset()),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);