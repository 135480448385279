import {
  SET_BILLING_ADDRESSS_REQUEST,
  SET_BILLING_ADDRESSS_SUCCESS,
  SET_BILLING_ADDRESSS_FAIL,
} from "../actions/setBillingAddressAction";
import get from "lodash/get";

const initialState = {
  apiState: "",
  setBillingAddressData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BILLING_ADDRESSS_REQUEST:
      return {
        ...state,
        apiState: "loading"
      };

    case SET_BILLING_ADDRESSS_FAIL:
      return {
        ...state,
        apiState: "error"
      };

    case SET_BILLING_ADDRESSS_SUCCESS:
      return {
        ...state,
        apiState: "success"
        //  setBillingAddressData
      };

    default:
      return state;
  }
}
