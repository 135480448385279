import React from "react"
import { ShoppingCartOutlined, UserOutlined, HomeOutlined, PoweroffOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"

// custom component
import { Li } from "./ProfileTabsStyle"

const ProfileTabs = props => {
    return (
        <div style={{
            background: "#f5f5f5",
            padding: 10,
            borderRadius: 5
        }} >
            <ul style={{ margin: 0 }} >
                <Link to="/orders" style={{ color: props.active === "orders" ? "#eb5202" : "#000" }} >
                    <Li>
                        <ShoppingCartOutlined style={{ fontSize: 18 }} />
                        <span style={{ marginLeft: 8, fontWeight: props.active === "orders" ? 500 : 400 }} >My Orders</span>
                    </Li>
                </Link>
                <Link to="/my-profile" style={{ color: props.active === "my-profile" ? "#eb5202" : "#000" }} >
                    <Li>
                        <UserOutlined style={{ fontSize: 18 }} />
                        <span style={{ marginLeft: 8, fontWeight: props.active === "my-profile" ? 500 : 400 }} >My Profile</span>
                    </Li>
                </Link>
                <Link to="/logout" style={{ color: "#000" }} >
                    <Li>
                        <PoweroffOutlined style={{ fontSize: 18 }} />
                        <span style={{ marginLeft: 8, fontWeight: 400 }} >Logout</span>
                    </Li>
                </Link>
            </ul>
        </div>
    )
}

export default ProfileTabs