import styled from "styled-components";

export const Container = styled.div`
    height: 100%;
`
export const ContactHeading = styled.div`
color:${(props) => props.theme.color.black};
padding-top: 20px;
`
export const ContactWrapper = styled.div`
    width: 100%;
    height: 100%;
    @media screen and (max-width: 800px){
        img{
            width: 100%
        }
    }
`

