import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    ADD_CONFIGRABLE_PRODUCT,
    addConfigrableProductFail,
    addConfigrableProductSuccess
} from '../actions/addConfigrableProductAction'

// api 
import addConfigrableProductApi from '../apis/addConfigrableProductApi'

export function* addConfigrableProductSaga(action) {
    try {
        const response = yield call(() => addConfigrableProductApi(action.params))
        yield put(addConfigrableProductSuccess(response, action))
    }
    catch (e) {
        yield put(addConfigrableProductFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(ADD_CONFIGRABLE_PRODUCT, addConfigrableProductSaga)
}