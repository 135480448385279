export const CREATE_GUEST_CUSTOMER_ADDRESS = 'CREATE_GUEST_CUSTOMER_ADDRESS'
export const CREATE_GUEST_CUSTOMER_ADDRESS_SUCCESS = 'CREATE_GUEST_CUSTOMER_ADDRESS_SUCCESS'
export const CREATE_GUEST_CUSTOMER_ADDRESS_FAIL = 'CREATE_GUEST_CUSTOMER_ADDRESS_FAIL'
export const CREATE_GUEST_CUSTOMER_ADDRESS_RESET = 'CREATE_GUEST_CUSTOMER_ADDRESS_RESET'

export const createGuestCustomerAddress = (params) => {
    return { type: CREATE_GUEST_CUSTOMER_ADDRESS, params }
}

export const createGuestCustomerAddressSuccess = (response) => {
    return { type: CREATE_GUEST_CUSTOMER_ADDRESS_SUCCESS, response }
}

export const createGuestCustomerAddressFail = (response) => {
    return { type: CREATE_GUEST_CUSTOMER_ADDRESS_FAIL, response }
}

export const createGuestCustomerAddressReset = () => {
    return { type: CREATE_GUEST_CUSTOMER_ADDRESS_RESET }
}