// action
import {
    SET_GUEST_EMAIL_CART,
    SET_GUEST_EMAIL_CART_SUCCESS,
    SET_GUEST_EMAIL_CART_FAIL,
    SET_GUEST_EMAIL_CART_RESET
} from '../actions/setGuestEmailCartAction'

const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_GUEST_EMAIL_CART:
            return {
                ...state,
                apiState: "loading"
            };
        case SET_GUEST_EMAIL_CART_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            };
        case SET_GUEST_EMAIL_CART_FAIL:
            return {
                ...state,
                apiState: "error"
            };
        case SET_GUEST_EMAIL_CART_RESET:
            return initialState
        default:
            return state
    }
}