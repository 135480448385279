export const GET_PRODUCT_CART_LIST = 'GET_PRODUCT_CART_LIST'
export const GET_PRODUCT_CART_LIST_SUCCESS = 'GET_PRODUCT_CART_LIST_SUCCESS'
export const GET_PRODUCT_CART_LIST_FAIL = 'GET_PRODUCT_CART_LIST_FAIL'

export const getProductCartList = (params) => {
    return { type: GET_PRODUCT_CART_LIST, params }
}

export const getProductCartListSuccess = (response) => {
    return { type: GET_PRODUCT_CART_LIST_SUCCESS, response }
}

export const getProductCartListFail = (response) => {
    return { type: GET_PRODUCT_CART_LIST_FAIL, response }
}

