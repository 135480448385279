import styled, { css } from "styled-components";
import { Skeleton } from "antd";
export const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: flex-start;
  border-radius: 10px;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
`;

export const LeftSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media ${(props) => props.theme.device.tablet} {
    margin: 10px;
  }
  .ant-skeleton-button{
    width: 350px;
    height: 170px;
  }
`;

export const RightSection = styled.div`
  width: 50%;
  text-align: center;
  margin-left: 20px;
  margin-top: 20px;

  @media ${(props) => props.theme.device.tablet} {
    margin: 10px;
  }
`;

export const ImageSkeletonLeft = styled(Skeleton.Button)`
margin: 10px;


`;
export const LeftBlock = styled.div`
display: flex;
align-items: center;
`

export const ImageSkeletonRight = styled(Skeleton.Button)`


`


