import styled from "styled-components"


export const Container = styled.div`
    margin: 16px;
    position: relative;
 .ant-skeleton-input{
        border-radius: 18px !important;
    }
`
export const SubContainer = styled.div`
    background: #f5f5f5;
    border-radius: 18px;
    width: 100%;
   .ant-skeleton-input{
        border-radius: 18px !important;
    }

`

