import styled from "styled-components"
import { Button } from "antd"

export const Container = styled.div`
    display: flex;
    padding: 24px;

    @media ${props => props.theme.device.tablet} {
       flex-direction: column;
    }
`

export const Left = styled.div`
    width: 20%;

    @media ${props => props.theme.device.tablet} {
       width: 100%;
       margin-bottom: 24px;
    }
`
export const EnteredOptionLabel = styled.div`
font-size: 14px;
color: rgb(86, 85, 85);
font-weight: bold;
`
export const EnteredOptionValue = styled.div`
font-size: 14px;
color: rgb(86, 85, 85);
font-weight: bold;
margin-left: 5px;
`
export const Price = styled.div`
    font-size: 14px;
    color: rgb(86,85,85);
    font-weight: bold;
`

export const Right = styled.div`
    width: 80%;
    padding-left: 24px;

    @media ${props => props.theme.device.tablet} {
       width: 100%;
       padding-left: 0px;
    }
`

export const OrderContainer = styled.div`
    border-radius: 5px;
    background: rgb(245, 246, 248);
    margin-bottom: 8px;
    padding: 10px 20px;
    border: solid 1px rgb(235 236 239);
`

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
`

export const PayBtn = styled(Button)`
    background-color: #f78c3e;
    color: #fff;
    border-radius: ${(props) => props.theme.border.borderRadius};
    margin-top: 8px;
    span {
        padding: 0 16px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: #f78c3e;
        color: #fff;
    }
`
export const AddressInfo = styled.div`
    display: flex;
    margin-top: 16px;

    @media ${(props) => props.theme.device.tablet}{
        display: flex;
        flex-direction: column;
    }
`

export const ShippingAddressInfo = styled.div`
    width: 50%;
    font-size: 12px;

    @media ${(props) => props.theme.device.tablet}{
        width: 100%;
        margin-bottom: 16px;
    }
`
export const BillingAddressInfo = styled.div`
    width: 50%;
    font-size: 12px;

    @media ${(props) => props.theme.device.tablet}{
        width: 100%;
        margin-bottom: 16px;
    }
`

export const ReorderButton = styled(Button)`
    background-color: ${(props) => props.theme.backgroundColor.orange};
    color: ${(props) => props.theme.color.white};
    border-color: white;
    &:hover,
    &:focus,
    &:active {
        background-color: ${(props) => props.theme.backgroundColor.orange};
        color: ${(props) => props.theme.color.white};
        border-color: white;
    }
`