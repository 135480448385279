import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    UPDATE_CUSTOMER_INFO,
    updateCustomerInfoSuccess,
    updateCustomerInfoFail,
} from '../actions/updateCustomerInfoAction'

import updateCustomerInfoApi from '../apis/updateCustomerInfoApi'


export function* updateCustomerInfoSaga(action) {
    try {
        const response = yield call(() => updateCustomerInfoApi(action.params))

        if (response.errors) {
            yield put(updateCustomerInfoFail(response, action))
        } else {
            yield put(updateCustomerInfoSuccess(response, action))
        }
    } catch (e) {
        yield put(updateCustomerInfoFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(UPDATE_CUSTOMER_INFO, updateCustomerInfoSaga);
}