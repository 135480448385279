import styled from 'styled-components'
import { Button } from 'antd'

export const GuestCheckoutWrepper = styled.div`
    margin-top: 30px;

`
export const BlockWrapper = styled.div`
 display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
  

`
export const LeftBlock = styled.div`
    flex: 3 1 0%;
       @media screen and (max-width: 768px){
       width: 100%;
    }

`
export const RightBlock = styled.div`
    flex: 1.5 1 0%;
       @media screen and (max-width: 768px){
       width: 100%;
    }
`
export const SummaryContainer = styled.div`
background-color: #f8f8f8;
padding:20px;
margin:20px;
border-radius:5px;
`
export const OrderHeading = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
padding: 0 20px;
color: ${(props) => props.theme.color.black};
`
export const ContentPrice = styled.div`
    flex:1;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
//text-align: center;
margin-left:20px;
color: ${(props) => props.theme.color.black};
`
export const HorizontalLine = styled.div`
 border: solid 1px #707070;
 margin: 12px 0;
 `
export const ContentName = styled.div`

 flex:1;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
 `
export const LeftSection = styled.div`
display: flex;
    flex-flow: row wrap;
`

export const SummaryContent = styled.div`
padding: 0 20px;
display: flex;
align-items: center;
justify-content:space-between;
`
export const AddAddressBlock = styled.div`
border: 1px dashed rgb(204, 204, 204);
    flex: 0 0 40%;
    padding: 20px;
    margin: 20px;
    display: flex;
    cursor: pointer;
    border-radius: 8px;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;

`
export const AddressHead = styled.span`
text-transform: uppercase;
    /* margin: 20px; */
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: Mandali, sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;


`
export const MobileGuestCheckoutWrepper = styled.div`
  /* display: flex; */
  width: 100%;
    margin-top: 30px;
`
export const MobileBlockWrapper = styled.div`
  /* display: flex; */
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const MobileLeftBlock = styled.div`
/* display: flex; */
margin-bottom: 10px;
width: 100%;
/* padding: 15px; */
padding: 0px 15px 15px 15px;
`
export const MobileRightBlock = styled.div`
margin-bottom: 10px;
width: 100%;
`
export const MobileAddressHead = styled.div`
text-transform: uppercase;
    margin: 20px 20px 0px 20px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: Mandali, sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
`

export const MobileSummaryContainer = styled.div`
background-color: #f8f8f8;
padding:20px;
margin:20px;
border-radius:5px;
`
export const MobileOrderHeading = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
padding: 0 20px;
color: ${(props) => props.theme.color.black};
`
export const MobileSummaryContent = styled.div`
padding: 0 20px;
display: flex;
align-items: center;
justify-content:space-between;
`
export const MobileContentName = styled.div`
 flex:1;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
`
export const MobileContentPrice = styled.div`
    flex:1;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
//text-align: center;
margin-left:20px;
color: ${(props) => props.theme.color.black};
`
export const MobileHorizontalLine = styled.div`
 border: solid 1px #707070;
 margin: 12px 0;
`
export const SelectButton = styled(Button)`
width: 100%;
//height:100%;
padding: 8px 20px;
height: auto;
border-radius: ${(props) => props.theme.border.borderRadius};
background-color: ${(props) => props.theme.backgroundColor.orange};
color: ${(props) => props.theme.color.white};
border-color: white;
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
&:hover,
&:focus,
&:active {
  background-color: ${(props) => props.theme.backgroundColor.orange};
  color: ${(props) => props.theme.color.white};
  border-color: white;
}
@media ${(props) => props.theme.device.tablet} {
  margin: 10px 0;
}

`
export const MobileSelectButton = styled(Button)`
width: 100%;
//height:100%;
padding: 8px 20px;
height: auto;
border-radius: ${(props) => props.theme.border.borderRadius};
background-color: ${(props) => props.theme.backgroundColor.orange};
color: ${(props) => props.theme.color.white};
border-color: white;
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
&:hover,
&:focus,
&:active {
  background-color: ${(props) => props.theme.backgroundColor.orange};
  color: ${(props) => props.theme.color.white};
  border-color: white;
}
@media ${(props) => props.theme.device.tablet} {
  margin: 10px 0;
}

`
export const DeliverySuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  text-transform: capitalize;
  font-size: 14px;
  color: #73b255;
  font-weight: 600;
  transition: 0.8s ease-in-out;
  /* margin-top: -22px; */
`
export const DeliveryErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  text-transform: capitalize;
  font-size: 14px;
  color: #ff4d4f;
  font-weight: 600;
  transition: 0.8s ease-in-out;
  /* margin-top: -20px; */
`