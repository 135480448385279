import React from "react"

// custom components
import ProfileTabs from '../../components/ProfileTabs/ProfileTabs'
import { Container, Left, Right } from "./ManageAddressStyle"

const ManageAddress = (props) => {
    return (
        <Container>
            <Left>
                <ProfileTabs active="manage-address" />
            </Left>
            <Right>
                <h1>Manage Address: Under development</h1>
            </Right>
        </Container>
    );
};

export default ManageAddress;
