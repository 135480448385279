export const GET_GUEST_CART_DATA = 'GET_GUEST_CART_DATA'
export const GET_GUEST_CART_DATA_SUCCESS = 'GET_GUEST_CART_DATA_SUCCESS'
export const GET_GUEST_CART_DATA_FAIL = 'GET_GUEST_CART_DATA_FAIL'
export const GET_GUEST_CART_DATA_RESET = 'GET_GUEST_CART_DATA_RESET'

export const getGuestCartData = (params) => {
    return { type: GET_GUEST_CART_DATA, params }
}

export const getGuestCartDataSuccess = (response) => {
    return { type: GET_GUEST_CART_DATA_SUCCESS, response }
}

export const getGuestCartDataFail = (response) => {
    return { type: GET_GUEST_CART_DATA_FAIL, response }
}

export const getGuestCartDataReset = () => {
    return { type: GET_GUEST_CART_DATA_RESET }
}
