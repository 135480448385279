import React, { useEffect, useState } from "react"
import { Button, Col, Form, Input, Row, Select, notification } from "antd"
import { connect } from "react-redux"

// actions
import { changeCustomerPassword, changeCustomerPasswordReset } from "../../actions/changeCustomerPasswordAction"

// others
import { getCustomerToken } from "../../utils"

const ChangePasswordForm = props => {
    // variables
    const [formData, setFormData] = useState({
        currentPassword: "",
        newPassword: "",
    })
    const {
        changeCustomerPassword, changeCustomerPasswordReset, changeCustomerPasswordState
    } = props

    // callbacks
    useEffect(() => {
        if (changeCustomerPasswordState.apiState === "success") {
            notification["success"]({
                message: 'Password changed successfully.',
                placement: "bottomRight"
            });
            props.closeModal()
            changeCustomerPasswordReset()
        }

        if (changeCustomerPasswordState.apiState === "error") {
            notification["error"]({
                message: changeCustomerPasswordState.message,
                placement: "bottomRight"
            });
            changeCustomerPasswordReset()
        }
    }, [changeCustomerPasswordState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        changeCustomerPassword({
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
            customerToken: getCustomerToken(),
        })
    }

    return (
        <Form
            layout="vertical"
            onFinish={handleSubmit}
        >
            <Form.Item
                name="currentPassword"
                rules={[
                    { required: true, message: 'Required' },
                ]}
            >
                <Input.Password name="currentPassword" placeholder="Current Password" onChange={handleOnChange} />
            </Form.Item>

            <Form.Item
                name="newPassword"
                rules={[
                    { required: true, message: 'Required' },
                ]}
            >
                <Input.Password name="newPassword" placeholder="New Password" onChange={handleOnChange} />
            </Form.Item>

            <Form.Item name="confirm_password"
                rules={[
                    {
                        required: true,
                        message: 'Confirm your password',
                    },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject('Your username and/or password do not match.');
                        },
                    }),
                ]}
            >
                <Input.Password name="confirm_password" placeholder="Confirm password" />
            </Form.Item>

            <Button type="primary"
                htmlType="submit"
                loading={changeCustomerPasswordState.apiState === "loading" ? true : false} >Change Password</Button>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    changeCustomerPasswordState: state.changeCustomerPassword,
});

const mapDispatchToProps = (dispatch) => ({
    changeCustomerPassword: (params) => dispatch(changeCustomerPassword(params)),
    changeCustomerPasswordReset: () => dispatch(changeCustomerPasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);