export const GET_CUSTOMER_CART_ID = 'GET_CUSTOMER_CART_ID'
export const GET_CUSTOMER_CART_ID_SUCCESS = 'GET_CUSTOMER_CART_ID_SUCCESS'
export const GET_CUSTOMER_CART_ID_FAIL = 'GET_CUSTOMER_CART_ID_FAIL'

export const getCustomerCartId = (params) => {
    return { type: GET_CUSTOMER_CART_ID, params }
}

export const getCustomerCartIdSuccess = (response) => {
    return { type: GET_CUSTOMER_CART_ID_SUCCESS, response }
}

export const getCustomerCartIdFail = (response) => {
    return { type: GET_CUSTOMER_CART_ID_FAIL, response }
}
