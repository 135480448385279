import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    SET_RZP_PAYMENT_DETAILS_ON_CART,
    setRzpPaymentDetailsOnCartSuccess,
    setRzpPaymentDetailsOnCartFail,
} from '../actions/setRzpPaymentDetailsOnCartAction'

import setRzpPaymentDetailsOnCartApi from '../apis/setRzpPaymentDetailsOnCartApi';


export function* setRzpPaymentDetailsOnCartSaga(action) {

    try {
        const response = yield call(() => setRzpPaymentDetailsOnCartApi(action.params))
        if (response.errors) {
            yield put(setRzpPaymentDetailsOnCartFail(response, action))
        } else {
            yield put(setRzpPaymentDetailsOnCartSuccess(response, action))
        }
    } catch (e) {
        yield put(setRzpPaymentDetailsOnCartFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SET_RZP_PAYMENT_DETAILS_ON_CART, setRzpPaymentDetailsOnCartSaga);
}