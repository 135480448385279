import { sumBy } from "lodash"
import styled from "styled-components"

export const Container = styled.div`
     display: flex;
   align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px;
`

export const Box = styled.div`
    /* width: 25%; */
    max-width: 270px;
    /* margin: 0 auto; */
    

    /* @media ${props => props.theme.device.laptop} {
        width: 33.33%;
    }

    @media ${props => props.theme.device.tablet} {
        width: 50%;
    }

    @media ${props => props.theme.device.mobileL} {
        width: 100%;
    } */
`

export const SortContainer = styled.section`
  display: table-row;
  background: black;
  color: white;
  position: fixed;
  bottom: 10px;
  padding: 0;
  left: 50%;
  
  transform: translateX(-50%);
  border-radius: 12px;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 11%);
  display: flex;
  align-items: center;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
    z-index: 999;
`;