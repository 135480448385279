import React, { useEffect, useState, useCallback } from "react";
import { Space, Menu, Badge, Tabs, Input, Button, Spin } from "antd";
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import { Link, Redirect, withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash"
import debounce from 'lodash/debounce'
import Authentication from '../../components/Authentication/Authentication'

import {
    Container,
    LeftSection,
    RightSection,
    SearchInput,
    MenuContainer,
    SearchButton,
    TabsComponent,
    AddToCart,
    ItemContent,
    HamburgerContainer,
    HideSearch,
    DropdownContainer,
    SearchBtn,
    InputSearch,
    Arrow,
    SearchIconBtn,
    AutoCompleteDropDown,
    SearchBlock,
    SearchAutocompleteInput,
    MainContainer,
    LogoContainerLink,
    DrawerContainer,
} from "./HeaderStyle";
import { SearchIcon } from "../../icons";
import { toggleCategory } from "../../actions/categoryActions";
import { fetchCartData } from "../../actions/fetchCartDataAction";
import { updateSearchText, updateSearchTextReset } from "../../actions/updateSearchTextAction";
import { useHistory } from "react-router-dom";
import { getCustomerToken } from '../../utils';

// custom components
import { CrossIcon, MenuIcon } from '../CustomIcons'

// actions
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import { getTopProduct, getTopProductReset } from '../../actions/getTopProductAction'
import { getCartData, getCartDataReset } from '../../actions/getCartDataAction'
import { loginRequest } from '../../actions/loginAction'
import { fetchSearchDataReset } from "../../actions/fetchSearchAction";
import { resetPasswordReset } from '../../actions/resetPasswordAction'
import { fetchAddressRequest } from '../../actions/fetchAddressAction'
import { updateCartProduct, updateCartProductReset } from '../../actions/updateCartProductAction'


// others
import { getCartId } from "../../utils"
import NewsBannerHeader from "../NewsBannerHeader/NewsBannerHeader";
import CartModal from "../CartModal/cart";

const FrontHeader = (props) => {

    // variables
    const {
        updateSearchText, updateSearchTextReset,
        getTopProduct, getTopProductReset, getTopProductState,
        getCartCount, getCartCountState, getCartCountReset,
        createCustomerCartState,
        resetPasswordState, resetPasswordReset,
        productState,
        searchTextData, getQuantity,
        getFavouriteCount, fetchCartData, fetchAddressRequest, updateCartProduct,
        reOrderState
    } = props;
    const isMobile = window.innerWidth < 768;
    const { TabPane } = TabsComponent;
    const [activeTab, setActiveTab] = useState("1");
    const [cartQuantity, setCartQuantity] = useState(getQuantity);
    const [searchKey, setSearchKey] = useState(null);
    const [showSearchBar, setSearchBar] = useState(false);
    const [hamburger, setHamburger] = useState(false);
    const [logo, setLogo] = useState(false);
    const [hideRightSectionSearch, setHideRightSectionSearch] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'))
    const historyPath = useHistory();
    const location = useLocation();
    const { Search } = SearchInput;
    const [redirect, setRedirect] = useState([false, ""])

    const setDebouncedSearch = useCallback(
        debounce(value => handleSearch(value), 400), []
    )
    const [visiable, setVisiable] = useState(false)
    const [refresh, setRefresh] = useState(0)
    const [keyVisiable, setKeyVisiable] = useState(false)
    const [newsBanner, setNewsBanner] = useState(localStorage.getItem('newsbanner'))
    const [visibleCart, setVisibleCart] = useState(false);



    const currencyMenu = (
        <Menu>
            <Menu.Item>INR</Menu.Item>
            <Menu.Item>USD</Menu.Item>
        </Menu>
    );

    /* ================== */
    // callbacks
    /* ================== */

    const handleAutoCompleteSelect = (value, option) => {
        if (option.is_brand === 1) {
            setRedirect([true, `/brand/${option.value}`])
        }
        else {
            setRedirect([true, `/product-detail/${value}`])
        }
    }

    const handleOpenDrawer = () => {
        setVisiable(true)
    }
    const handleCloseDrawer = () => {
        setVisiable(false)
    }

    useEffect(() => {
        return () => {
            resetPasswordReset()
            getTopProductReset()
            // updateSearchTextReset()
        }
    }, [])
    useEffect(() => {
        const cart_id = getCartId()
        const token = getCustomerToken()
        getCartCount({
            cart_id: cart_id,
            token: token
        })
    }, [])

    useEffect(() => {
        if (reOrderState.apiState === "success") {
            showDrawer()
        }
    }, [reOrderState])

    // useEffect(() => {
    //     let handleTagSearchData = productState.productTagData1;
    //     setSearchKey(productState.productTagData)
    //     productState.productTagData = ""
    // }, [productState])

    useEffect(() => {
        if (window.location.pathname === "/categories") {
            setActiveTab("shop");
            setLogo(true)
            if (isMobile) {
                setHamburger(true);
            }
        } else if (window.location.pathname === "/") {
            setActiveTab("");
            setLogo(true)
            if (isMobile) {
                setHamburger(false);
            }
        }
        // if (window.location.pathname === "/search") {
        //     if (isMobile) {
        //         setLogo(false)
        //     }
        //     else {
        //         setLogo(false)
        //     }
        //     setSearchBar(true);
        // } else {
        //     setLogo(true)
        //     setSearchBar(false);
        // }
        setLogo(false)
        setSearchBar(false);
    }, []);

    useEffect(() => {
        if (historyPath.location.pathname === "/search") {
            setSearchBar(true);
            setSearchKey(searchTextData)
            if (isMobile) {
                setLogo(false)
                setHideRightSectionSearch(true);
            }
            else {
                setLogo(true)
            }
        } else {
            setLogo(true)
            setSearchBar(false);
            setHideRightSectionSearch(false);
        }

        if (historyPath.location.pathname === "/categories") {
            setActiveTab("shop");
            if (isMobile) {
                setHamburger(true);
            }
        } else {
            setActiveTab("");
            if (isMobile) {
                setHamburger(false);
            }
        }
    }, [historyPath.location.pathname]);

    useEffect(() => {
        if (getCartCountState.apiState === "success") {
            if (_.hasIn(getCartCountState.data, 'cart.items')) {
                setCartQuantity(getCartCountState.data.cart.items.length)
            } else {
                setCartQuantity(0)
            }
        }
        else {
            setCartQuantity(0)
        }
        if (productState.apiState === "success" && productState.productTagData !== "") {
            setSearchKey(productState.productTagData)
            updateSearchText(productState.productTagData);
            productState.productTagData = ""
            // updateSearchTextReset()
            getTopProductReset()
            setKeyVisiable(true)
        }
        else {
            // setSearchKey("")
        }
        setRefresh(refresh + 1)
    }, [getCartCountState])

    useEffect(() => {
        setCartQuantity(getQuantity)
    }, [getQuantity])



    useEffect(() => {
        if (createCustomerCartState.refreshItemCount) {
            setRefresh(refresh + 1)
            createCustomerCartState.refreshItemCount = false
        }
    }, [createCustomerCartState])

    useEffect(() => {
        if (resetPasswordState.apiState === "success") {
            setVisiable(true)
            // resetPasswordReset()
        }
    }, [resetPasswordState])
    // useEffect(() => {
    //     return (() => {
    //         getCartCountReset()
    //     })
    // })
    /* ================== */
    // function
    /* ================== */
    const tabChange = (key) => {
        setActiveTab(key);
        if (location.pathname === "/categories") {
            return props.history.push("/");
        }
        return props.history.push("/categories");
    };
    const onCategoryPage = () => {
        props.history.push("/categories");
    };

    const handleCart = () => {
        props.history.push("/cart");
        setKeyVisiable(false)
    };

    const handleWishList = () => {
        if (!getCustomerToken()) {
            handleOpenDrawer()
        }
        else {
            props.history.push('/wishlist')
        }
        setKeyVisiable(false)

    }

    const handleSearchData = () => {
        if (searchKey) {
            updateSearchText(searchKey);
            getTopProductReset()
            setKeyVisiable(true)
            props.history.push("/search");
        }

    };
    const onSearchClick = () => {
        // props.history.push("/search");
        setSearchBar(true);
        setKeyVisiable(false)
        if (isMobile) {
            setLogo(false)
            setHideRightSectionSearch(true);
        }

    };

    const onCloseSearchClick = () => {
        // props.history.push("/search");
        setSearchBar(false);
        setKeyVisiable(false)
        setLogo(true)
        setHideRightSectionSearch(false);


    };

    const handleHamburger = () => {
        props.history.push("/categories");
        setHamburger(true);
        setKeyVisiable(false)
    };

    const handleCrossHam = () => {
        props.history.push("/");
        setHamburger(false);
    };

    const onProfileClick = () => {
        setKeyVisiable(false)
        const customerToken = getCustomerToken()
        if (customerToken) {
            props.history.push("/orders");
        }

    };
    const onProfileMobileClick = (url) => {
        props.history.push(url);
    };

    const handleCheckout = () => {
        props.history.push("/checkout");
    };

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <a onClick={() => onProfileMobileClick("/orders")}>My Orders</a>
            </Menu.Item>
            {/* <Menu.Item key="1">
                <a onClick={() => onProfileMobileClick("/manage-address")}>Manage Address</a>
            </Menu.Item> */}
            <Menu.Item key="2">
                <a onClick={() => onProfileMobileClick("/my-profile")}>My Profile</a>
            </Menu.Item>
            <Menu.Item key="3">
                <a onClick={() => handleLogout()}>Logout</a>
            </Menu.Item>
        </Menu>
    );

    const handleSearch = (value) => {
        getTopProduct({
            serachterm: value
        })
    }
    const handleLogout = () => {
        getCartCountReset()
        localStorage.removeItem("customerToken");
        localStorage.removeItem("cartId");
        props.history.push("/");
    }

    const handleCallbackClick = () => {
    }


    const handleCallbackBanner = () => {
        setNewsBanner(localStorage.getItem('newsbanner'))
        props.handleCallbackBannerAppJs()
    }

    const showDrawer = () => {
        setVisibleCart(true);
        fetchCartData({
            cart_id: getCartId()
        })
    };
    const onClose = () => {
        setVisibleCart(false);

    };

    const handleClose = () => {
        setVisibleCart(false)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            {newsBanner !== "true" &&
                <NewsBannerHeader handleCallbackBanner={handleCallbackBanner} />
            }
            <Container height={newsBanner ? '0px' : '44px'}>
                <LeftSection>
                    <Space size="large">
                        {isMobile && !hamburger ? (
                            <HamburgerContainer hide={hideRightSectionSearch}>
                                <Link onClick={handleHamburger}>
                                    <MenuIcon size="18" />
                                </Link>
                            </HamburgerContainer>
                        ) : isMobile ? (
                            !showSearchBar &&
                            <Link onClick={handleCrossHam}>
                                <CrossIcon size="18" />
                            </Link>
                        ) : null}
                        {logo &&
                            <LogoContainerLink to="/">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/Logo.svg`}
                                    alt="ladu-india"
                                    width="80"
                                    style={{ marginTop: 20 }}
                                />
                            </LogoContainerLink>
                        }
                    </Space>
                </LeftSection>
                <SearchBlock >
                    {
                        window.location.pathname === "/search" ?
                            <Arrow>
                                <a href="/">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                                        alt="ladu-india"
                                        width="36"
                                    />
                                </a>
                            </Arrow>
                            :
                            <>
                                {showSearchBar && isMobile &&
                                    <Arrow>
                                        <Button type="link" onClick={onCloseSearchClick} icon={<CloseOutlined style={{ color: '#000', fontSize: 20 }} />} />
                                    </Arrow>
                                }
                            </>
                    }
                    <InputSearch >
                        {(showSearchBar || !isMobile) && (
                            <>
                                <div style={{ display: "flex" }} >
                                    <AutoCompleteDropDown
                                        dropdownMatchSelectWidth={252}
                                        style={{ width: "100%" }}
                                        options={getTopProductState.items.map(item => {
                                            return ({
                                                value: item.url_key,
                                                is_brand: item.is_brand,
                                                brand_name: item.brand_name,
                                                label: <div style={{ display: "flex", alignItems: 'center' }} >
                                                    <img src={item.thumbnail?.url} alt="" style={{ height: 50, width: 50 }} />
                                                    <span style={{ marginLeft: '10px' }} >{item.name}</span>
                                                </div>
                                            })
                                        })}
                                        onSelect={(value, option) => handleAutoCompleteSelect(value, option)}
                                        value={searchKey}
                                        onSearch={(value) =>
                                            [getTopProductReset(),
                                            setDebouncedSearch(value)
                                            ]
                                        }
                                        notFoundContent={getTopProductState.apiState === "loading" ? <Spin /> : getTopProductState.apiState === "success" ? 'No Product Found.' : null}
                                    >
                                        <SearchAutocompleteInput
                                            size="large"
                                            placeholder="#Organic #Natural #Handmade #Local"
                                            onChange={(e) => {
                                                setSearchKey(e.target.value);
                                                setKeyVisiable(true)
                                            }}
                                            onPressEnter={handleSearchData}
                                            prefix={!isMobile ? (<SearchOutlined style={{ fontSize: 14, marginRight: 8 }} />) : null}
                                            suffix={!isMobile ? (< SearchBtn type="primary" shape="round" onClick={handleSearchData} >Search</SearchBtn>) : (<SearchIconBtn type="primary" shape="circle" size="14" onClick={handleSearchData}  ><SearchOutlined style={{ fontSize: '14px' }} /></SearchIconBtn>)}
                                        />
                                    </AutoCompleteDropDown>
                                </div>
                            </>
                        )}
                    </InputSearch>
                </SearchBlock>
                <RightSection>
                    {!isMobile && (
                        <MenuContainer>
                            <TabsComponent activeKey={activeTab} onChange={tabChange}>
                                <TabPane style={{ textTransform: 'capitalize' }} tab="Shop" key="shop" />
                            </TabsComponent>
                        </MenuContainer>
                    )}
                    <AddToCart>
                        {(isMobile && !showSearchBar) && (
                            <ItemContent onClick={onSearchClick}>
                                <a>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/images/ladu-search.svg`}
                                        alt="ladu-india"
                                        width="15"
                                    />
                                </a>
                            </ItemContent>
                        )}
                        <HideSearch hide={hideRightSectionSearch}>
                            <ItemContent>
                                {isMobile ? (
                                    <>
                                        {getCustomerToken() &&
                                            <DropdownContainer overlay={menu} trigger={["click"]}>
                                                <a
                                                    className="ant-dropdown-link"
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <img
                                                        src={`${process.env.PUBLIC_URL}/images/ladu-people.svg`}
                                                        alt="ladu-india"
                                                        width="20"
                                                    />
                                                </a>
                                            </DropdownContainer>
                                        }

                                        {!getCustomerToken() &&
                                            <a onClick={handleOpenDrawer} >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/ladu-people.svg`}
                                                    alt="ladu-india"
                                                    width="20"
                                                />
                                            </a>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            getCustomerToken() &&
                                            <a onClick={onProfileClick}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/ladu-people.svg`}
                                                    alt="ladu-india"
                                                    width="20"
                                                />
                                            </a>
                                        }
                                        {
                                            !getCustomerToken() &&
                                            <a onClick={handleOpenDrawer}>
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/images/ladu-people.svg`}
                                                    alt="ladu-india"
                                                    width="20"
                                                />
                                            </a>
                                        }
                                    </>

                                )}
                            </ItemContent>
                            <ItemContent>
                                <a onClick={handleWishList}>
                                    <Badge
                                        count={getFavouriteCount}
                                        size="small"
                                        style={{ backgroundColor: "#ffe867", color: "#000" }}
                                    >
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/ladu-heart.svg`}
                                            alt="ladu-india"
                                            width="20"
                                        />
                                    </Badge>
                                </a>
                            </ItemContent>
                            {location.pathname !== "/payment" && location.pathname !== "/guest-payment" &&
                                <ItemContent>
                                    <a onClick={showDrawer}>
                                        <Badge count={cartQuantity} size="small">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/ladu-cart.svg`}
                                                alt="ladu-india"
                                                width="15"
                                            />
                                        </Badge>
                                    </a>
                                </ItemContent>
                            }
                        </HideSearch>
                    </AddToCart>
                </RightSection>

            </Container>
            <Authentication
                drawerState={visiable}
                handleCloseDrawer={handleCloseDrawer}
                handleCallbackClick={handleCallbackClick}
            />
            <DrawerContainer
                title="Cart"
                placement="right"
                onClose={onClose}
                visible={visibleCart}
                width={isMobile ? "100% !important" : "50% !important"}
                height={"100%"}
            >
                <CartModal handleCheckoutHeader={handleCheckout} handleClose={handleClose} />
            </DrawerContainer>

        </>
    );
};

const mapStateToProps = (state) => ({
    categoryState: state.category,
    getQuantity: state.detail.getQuantity,
    getFavouriteCount: state.productList.getFavouriteCount,
    cartDataItems: state.cartData.cartDataItems,
    getCartCountState: state.getCartCount,
    getTopProductState: state.getTopProduct,
    getCartDataState: state.getCartData,
    createCustomerCartState: state.createCustomerCart,
    resetPasswordState: state.resetPassword,
    productState: state.product,
    searchTextData: state.searchText.searchTextData,
    reOrderState: state.reOrder,

});

const mapDispatchToProps = (dispatch) => ({
    toggleCategory: () => dispatch(toggleCategory()),
    fetchCartData: (params) => dispatch(fetchCartData(params)),
    updateSearchText: (params) => dispatch(updateSearchText(params)),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
    getTopProduct: (params) => dispatch(getTopProduct(params)),
    getTopProductReset: () => dispatch(getTopProductReset()),
    getCartData: (params) => dispatch(getCartData(params)),
    getCartDataReset: () => dispatch(getCartDataReset()),
    loginRequest: (params) => dispatch(loginRequest(params)),
    resetPasswordReset: () => dispatch(resetPasswordReset()),
    fetchSearchDataReset: () => dispatch(fetchSearchDataReset()),
    updateSearchTextReset: () => dispatch(updateSearchTextReset()),
    fetchAddressRequest: (params) => dispatch(fetchAddressRequest(params)),
    updateCartProduct: (params) => dispatch(updateCartProduct(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FrontHeader));
