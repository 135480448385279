export const ADD_WISHLIST_REQUEST = 'ADD_WISHLIST_REQUEST'
export const ADD_WISHLIST_SUCCESS = 'ADD_WISHLIST_SUCCESS'
export const ADD_WISHLIST_FAIL = 'ADD_WISHLIST_FAIL'

export const addWishListRequest = (params) => {
    return { type: ADD_WISHLIST_REQUEST, params }
}

export const addWishListSuccess = (response) => {
    return { type: ADD_WISHLIST_SUCCESS, response }
}

export const addWishListFail = (response) => {
    return { type: ADD_WISHLIST_FAIL, response }
}