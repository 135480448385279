import axios from "axios";
import { BASE_URL, isAuthorized } from "../utils";

const removeWishListApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        applyCouponToCart(
          input: {
            cart_id: "${data.params.item.cartId}",
            coupon_code: "${data.params.item.coupon}"
          }
        ) {
          cart {
            prices{
              subtotal_including_tax{
                value
              }
              grand_total{
                value
              }
              discounts{
                amount{
                  value
                }
                label
              }

            }
            applied_coupons {
              code
            }
         
            items {
              id
              product {
                sku
                stock_status
                url_key
                name
                price_range{
                  minimum_price{
                    final_price{
                      currency
                      value
                    }
                  }
                }
                media_gallery{
                  position
                  url
                }
                short_description{
                  html
              }
              description{
                  html
              }
              }
              quantity
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default removeWishListApi;
