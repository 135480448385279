import axios from "axios";
import { BASE_URL, isAuthorized } from "../utils";

const guestBillingAddress = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setBillingAddressOnCart(
          input: {
            cart_id: "${params.cartId}"
            billing_address: {
                address: {
                  firstname: "${params.firstname}"
                  lastname: "${params.lastname}"
                  street: ["${params.address}"]
                  city: "${params.city}"
                  region: "${params.state_id}"
                  postcode:"${params.pincode}"
                  country_code: "IN"
                  telephone: "${params.mobile}"
                  save_in_address_book: false
                }
              }
          }
        ) {
          cart {
            billing_address {
              firstname
              lastname
              company
              street
              city
              region {
                code
                label
                region_id
              }
              postcode
              telephone
              country {
                code
                label
              }
            }
          }
        }
      }`,
    },
    // headers: isAuthorized(),
  });

  return response.data;
};

export default guestBillingAddress;
