export const SET_GUEST_EMAIL_CART = "SET_GUEST_EMAIL_CART"
export const SET_GUEST_EMAIL_CART_SUCCESS = "SET_GUEST_EMAIL_CART_SUCCESS"
export const SET_GUEST_EMAIL_CART_FAIL = "SET_GUEST_EMAIL_CART_FAIL"
export const SET_GUEST_EMAIL_CART_RESET = "SET_GUEST_EMAIL_CART_RESET"


export const setGuestEmailCart = (params) => {
    return { type: SET_GUEST_EMAIL_CART, params }
}
export const setGuestEmailCartSuccess = (response) => {
    return { type: SET_GUEST_EMAIL_CART_SUCCESS, response }
}
export const setGuestEmailCartFail = (response) => {
    return { type: SET_GUEST_EMAIL_CART_FAIL, response }
}
export const setGuestEmailCartReset = () => {
    return { type: SET_GUEST_EMAIL_CART_RESET }
}