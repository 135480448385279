import axios from "axios";
import { BASE_URL } from "../utils";

const homePageBannerMobileApi = async () => {
    let cancelToken
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        cancelToken: cancelToken.token,
        data: {
            query: `
                {
                    products(
                        filter: {home_page_banner_mobile: {eq: "1"}},
                        currentPage:1, 
                        pageSize: 20, 
                        sort :{showcase_on_home_page_sort_ord : ASC}
                    ) {
                        total_count
                        items {
                            is_brand
                            brand_name
                            showcase_on_home_page_sort_ord
                            url_key
                            small_image  {
                                url
                            }
                                  media_gallery{
                                    ... on ProductVideo{
                                        video_content{
                                            video_description
                                            video_description
                                            video_metadata
                                            video_provider
                                            video_title
                                            video_url
                                        }
                                        }
                                    }
                        }
                    }
                }
            `,
        }
    });
    return response.data;
};

export default homePageBannerMobileApi;
