import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    HOME_PAGE_BANNER_MOBILE,
    homePageBannerMobileSuccess,
    homePageBannerMobileFail,
} from '../actions/homePageBannerMobileAction'

// api
import homePageBannerMobileApi from '../apis/homePageBannerMobileApi'


export function* homePageBannerSaga(action) {
    try {
        const response = yield call(() => homePageBannerMobileApi(action.params))
        if (response) {
            yield put(homePageBannerMobileSuccess(response, action))
        }
    } catch (e) {
        yield put(homePageBannerMobileFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(HOME_PAGE_BANNER_MOBILE, homePageBannerSaga);
}