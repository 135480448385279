import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const mergeCartsApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                mutation {
                    mergeCarts(source_cart_id: "${params.source_cart_id}", destination_cart_id: "${params.destination_cart_id}") {
                        items {
                        id
                        }
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default mergeCartsApi;
