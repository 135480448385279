import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    SALES_PLACE_ORDER,
    salesPlaceOrderSuccess,
    salesPlaceOrderFail
} from '../actions/salesPlaceOrderAction'

// api
import salesPlaceOrderApi from '../apis/salesPlaceOrderApi'


export function* salesPlaceOrderSaga(action) {
    try {
        const response = yield call(() => salesPlaceOrderApi(action.params))
        if (response.errors) {
            yield put(salesPlaceOrderFail(response, action))
        }
        else {
            yield put(salesPlaceOrderSuccess(response, action))
        }
    }
    catch (e) {
        yield put(salesPlaceOrderFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SALES_PLACE_ORDER, salesPlaceOrderSaga)
}