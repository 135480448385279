import React, { useEffect, useState } from "react"
import Lottie from "react-lottie"
import { connect } from "react-redux";
import { Button, Form, Input, Alert, notification } from "antd"
import { Redirect, Link } from "react-router-dom";

// custom component
import { Container, Box } from "./SignUpPageStyle"
import Authentication from '../../components/Authentication/Authentication'

// others
import { getCartId, setCartId, setCustomerToken, SITE_URL } from '../../utils'
import manShoppingAnimation from "../../lottie/man-shopping.json"

// actions
import { createCustomer, createCustomerReset } from '../../actions/createCustomerAction'

const SignUpPage = props => {
    // variables
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: ""
    })
    const [redirect, setRedirect] = useState([false, ""])
    const manShoppingOptions = {
        loop: true,
        autoplay: true,
        animationData: manShoppingAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const {
        createCustomer, createCustomerReset, createCustomerState
    } = props
    // callbacks
    useEffect(() => {
        return () => {
            createCustomerReset()
        }
    }, [])

    useEffect(() => {
        if (createCustomerState.apiState === "success") {
            notification["success"]({
                message: 'Welcome to Ladu! Your account has been successfully created.',
                placement: "bottomRight"
            });
            // setRedirect([true, "/login"])
            props.handleSignup()
        }
    }, [createCustomerState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        createCustomer(formData)
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <Container>
                <Box>
                    <div style={{ display: "flex", justifyContent: "center" }} >
                        {/* <Lottie
                            options={manShoppingOptions}
                            height={200}
                            width={200}
                            style={{ margin: "none" }}
                        /> */}
                        <img src={`${SITE_URL()}/pub/media/wysiwyg/Screenshot_2021-01-09_at_2.28.59_PM_3x.jpg`} style={{ width: '200px', marginTop: -16 }} />
                    </div>
                    {createCustomerState.apiState === "error" &&
                        <Alert message={createCustomerState.message} type="error" style={{ marginBottom: 16 }} />
                    }
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            name="first_name"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="first_name" placeholder="First Name" onChange={handleOnChange} />
                        </Form.Item>

                        <Form.Item
                            name="last_name"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input name="last_name" placeholder="Last Name" onChange={handleOnChange} />
                        </Form.Item>

                        <Form.Item
                            name="email"
                            rules={[
                                { required: true, message: 'Required' },
                                { type: 'email', message: 'Email is not valid' },
                            ]}
                        >
                            <Input name="email" placeholder="Email" onChange={handleOnChange} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input.Password name="password" placeholder="Password" onChange={handleOnChange} />
                        </Form.Item>

                        <Form.Item name="confirm_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirm your password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Your username and/or password do not match.');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password name="confirm_password" placeholder="Confirm password" />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" block loading={createCustomerState.apiState === "loading" ? true : false}  >Signup</Button>
                        {/* <p style={{ marginTop: 16 }} >Already have an account? <Link to="/login" ><b>Login</b></Link></p> */}
                    </Form>
                </Box>
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    createCustomerState: state.createCustomer,
});

const mapDispatchToProps = (dispatch) => ({
    createCustomer: (params) => dispatch(createCustomer(params)),
    createCustomerReset: () => dispatch(createCustomerReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);