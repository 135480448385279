import React, { useState } from "react";
import {
  Container,
  ContactHeading,
} from "./style";
import MobileTabs from "../../components/MobileTabs/mobileTabs";

const Gift = (props) => {
  const isMobile = window.innerWidth < 768;

  return (
    <>
      {isMobile && <MobileTabs tabsKey="gift" history={props.history} />}
      <Container>
        <ContactHeading>Pantry</ContactHeading>
      </Container>
    </>
  );
};

export default Gift;
