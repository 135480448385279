import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, ContactHeading, ContactWrapper } from "./style";
import { fetchAboutRequest } from "../../actions/fetchAboutAction";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom"

import BackButton from '../../components/BackButton/BackButton'

const ContactUs = (props) => {
    const { fetchAboutRequest, fetchAboutData, history } = props;

    useEffect(() => {
        fetchAboutRequest({
            errorAction: (data) => { },
            successAction: (data) => {
            },
        });
    }, []);

    return (
        <>
            <Container>
                <ContactHeading>
                    <Link to="/backHome">
                        <BackButton />
                    </Link>
                    <ContactWrapper>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: (fetchAboutData && fetchAboutData.content) || null,
                            }}
                        />
                    </ContactWrapper>
                </ContactHeading>
            </Container>
            <Footer isBackHeader history={history} />

        </>
    );
};

const mapStateToProps = (state) => ({
    fetchAboutData: state.aboutCms.fetchAboutData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchAboutRequest: () => dispatch(fetchAboutRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
