import {
    GUEST_APPLY_COUPON_TO_CART,
    GUEST_APPLY_COUPON_TO_CART_SUCCESS,
    GUEST_APPLY_COUPON_TO_CART_FAIL,
    GUEST_APPLY_COUPON_TO_CART_RESET
} from '../actions/guestApplyCouponToCartAction'

const initialState = {
    apiState: "",
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GUEST_APPLY_COUPON_TO_CART:
            return {
                ...state,
                apiState: "loading"
            }
        case GUEST_APPLY_COUPON_TO_CART_SUCCESS:
            return {
                ...state,
                apiState: "success"
            }
        case GUEST_APPLY_COUPON_TO_CART_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case GUEST_APPLY_COUPON_TO_CART_RESET:
            return initialState

        default:
            return state
    }
}