import React from "react";
import Footer from '../../components/Footer/Footer'


const Brands = (props) => {
    const {history} = props;

  return (<>
  <div>Brands</div>
  <Footer isShop history={history}/>
</>
    );
};

export default Brands;
