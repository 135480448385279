export const FETCH_SHIPPING_AMOUNT_REQUEST = 'FETCH_SHIPPING_AMOUNT_REQUEST'
export const FETCH_SHIPPING_AMOUNT_SUCCESS = 'FETCH_SHIPPING_AMOUNT_SUCCESS'
export const FETCH_SHIPPING_AMOUNT_FAIL = 'FETCH_SHIPPING_AMOUNT_FAIL'

export const fetchShippingAmountRequest = (params) => {
    return { type: FETCH_SHIPPING_AMOUNT_REQUEST, params }
}

export const fetchShippingAmountSuccess = (response) => {
    return { type: FETCH_SHIPPING_AMOUNT_SUCCESS, response }
}

export const fetchShippingAmountFail = (response) => {
    return { type: FETCH_SHIPPING_AMOUNT_FAIL, response }
}

