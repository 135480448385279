import { call, put, takeLatest } from 'redux-saga/effects'

// Action
import { ADD_PRODUCT_TO_WISH_LIST, addProductToWishListSuccess, addProductToWishListFail } from '../actions/addProductToWishListAction';

// Api
import addProductToWishListApi from '../apis/addProductToWishListApi'


export function* addProductToWishListSaga(action) {
    try {
        const response = yield call(() => addProductToWishListApi(action.params))
        yield put(addProductToWishListSuccess(response, action))
    }
    catch (e) {
        yield put(addProductToWishListFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(ADD_PRODUCT_TO_WISH_LIST, addProductToWishListSaga)
}