import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    REQUEST_PASSWORD_RESET_EMAIL,
    requestPasswordResetEmailSuccess,
    requestPasswordResetEmailFail,
} from '../actions/requestPasswordResetEmailAction'

import requestPasswordResetEmailApi from '../apis/requestPasswordResetEmailApi'


export function* requestPasswordResetEmailSaga(action) {
    try {
        const response = yield call(() => requestPasswordResetEmailApi(action.params))

        if (response.errors) {
            yield put(requestPasswordResetEmailFail(response, action))
        } else {
            yield put(requestPasswordResetEmailSuccess(response, action))
        }
    } catch (e) {
        yield put(requestPasswordResetEmailFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(REQUEST_PASSWORD_RESET_EMAIL, requestPasswordResetEmailSaga);
}