export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD"
export const SET_PAYMENT_METHOD_SUCCESS = "SET_PAYMENT_METHOD_SUCCESS"
export const SET_PAYMENT_METHOD_FAIL = "SET_PAYMENT_METHOD_FAIL"
export const SET_PAYMENT_METHOD_RESET = "SET_PAYMENT_METHOD_RESET"


export const setPaymentMethod = (params) => {
    return { type: SET_PAYMENT_METHOD, params }
}
export const setPaymentMethodSuccess = (response) => {
    return { type: SET_PAYMENT_METHOD_SUCCESS, response }
}
export const setPaymentMethodFail = (response) => {
    return { type: SET_PAYMENT_METHOD_FAIL, response }
}
export const setPaymentMethodReset = () => {
    return { type: SET_PAYMENT_METHOD_RESET }
}

