import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';
import {
    GET_CUSTOMER_CART_ID,
    getCustomerCartIdSuccess,
    getCustomerCartIdFail,
} from '../actions/customerCartIdAction'

import getCustomerCartIdApi from '../apis/customerCartIdApi'


function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* getCustomerIdSaga(action) {

    try {
        const response = yield call(() => getCustomerCartIdApi(action))
        if (response.errors) {
            yield put(getCustomerCartIdFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(getCustomerCartIdSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(getCustomerCartIdFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_CUSTOMER_CART_ID, getCustomerIdSaga);
}