import axios from "axios";
import { BASE_URL } from "../utils";


const salesPlaceOrderApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
                placeOrder(input: {cart_id: "${params.cart_id}"}) {
                  order {
                    order_number
                  }
                }
              }`,
    },
    headers: {
      Authorization: `Bearer ${params.customerToken}`
    }
  });
  return response;
};

export default salesPlaceOrderApi;
