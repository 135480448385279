import axios from 'axios'
import { isAuthorized,BASE_URL } from '../utils'

const addProductToWishListApi = async (params) => {

    const response = await axios({
        method: 'post',
        url: `${BASE_URL()}`,
        data: {
            query: `mutation {
            addProductsToWishlist(
                wishlistId: 0
                wishlistItems: [
                {
                    sku: "${params.sku}"
                    quantity: 1
                }
                ]
            ) {

                user_errors {
                code
                message
                }
            }
            }`
        },
        headers: isAuthorized(),
    });

    return response.data;
}
export default addProductToWishListApi