import styled from 'styled-components'

export const HelpNumberContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    margin-top: 8px;

    @media ${(props) => props.theme.device.tablet}{
        text-align: center;
    }
`