import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    GUEST_APPLY_COUPON_TO_CART,
    guestApplyCouponToCartSuccess,
    guestApplyCouponToCartFail
} from '../actions/guestApplyCouponToCartAction'

// api
import guestApplyCouponToCartApi from '../apis/guestApplyCouponToCartApi'

export function* guestApplyCouponToCartSaga(action) {
    try {
        const response = yield call(() => guestApplyCouponToCartApi(action.params))
        if (response.data.errors) {
            yield put(guestApplyCouponToCartFail(response, action))
        } else {
            yield put(guestApplyCouponToCartSuccess(response, action))
        }
    } catch (e) {
        yield put(guestApplyCouponToCartFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GUEST_APPLY_COUPON_TO_CART, guestApplyCouponToCartSaga)
}