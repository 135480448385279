import {
  FETCH_SHIPPING_AMOUNT_REQUEST,
  FETCH_SHIPPING_AMOUNT_SUCCESS,
  FETCH_SHIPPING_AMOUNT_FAIL,
} from "../actions/fetchShippingAmountAction";
import get from "lodash/get";


const initialState = {
  shippingAmountData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SHIPPING_AMOUNT_REQUEST:
      return {
        ...state,
      };

    case FETCH_SHIPPING_AMOUNT_FAIL:
      return {
        ...state,
      };

    case FETCH_SHIPPING_AMOUNT_SUCCESS:
      return {
        ...state,
        //  shippingAmountData
      };

    default:
      return state;
  }
}
