import axios from "axios";
import { isAuthorized, BASE_URL } from '../utils';


const fetchPaymentMethodApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setPaymentMethodOnCart(input: {
            cart_id: "${data.params.cartId}"
            payment_method: {
                code: "checkmo"
            }
        }) {
          cart {
            selected_payment_method {
              code
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchPaymentMethodApi;
