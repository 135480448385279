export const CHECK_DELIVERY = "CHECK_DELIVERY"
export const CHECK_DELIVERY_SUCCESS = "CHECK_DELIVERY_SUCCESS"
export const CHECK_DELIVERY_FAIL = "CHECK_DELIVERY_FAIL"
export const CHECK_DELIVERY_RESET = "CHECK_DELIVERY_RESET"

export const checkDelivery = (params) => {
    return { type: CHECK_DELIVERY, params }
}

export const checkDeliverySuccess = (response) => {
    return { type: CHECK_DELIVERY_SUCCESS, response }
}

export const checkDeliveryFail = (response) => {
    return { type: CHECK_DELIVERY_FAIL, response }
}

export const checkDeliveryReset = () => {
    return { type: CHECK_DELIVERY_RESET }
}