import React, { useEffect, useState } from "react"
import { Button, Col, Form, Input, Row, Select, notification } from "antd"
import { connect } from "react-redux"

// actions
import { updateCustomerInfo, updateCustomerInfoReset } from "../../actions/updateCustomerInfoAction"

// others
import { getCustomerToken } from "../../utils"

const ProfileForm = props => {
    // variables
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        gender: ""
    })
    const {
        getCustomerInfoState,
        updateCustomerInfo, updateCustomerInfoReset, updateCustomerInfoState
    } = props
    const [form] = Form.useForm()

    // callbacks
    useEffect(() => {
        if (getCustomerInfoState.apiState === "success") {
            setFormData({
                ...formData,
                ["first_name"]: getCustomerInfoState.data.customer.firstname,
                ["last_name"]: getCustomerInfoState.data.customer.lastname,
                ["email"]: getCustomerInfoState.data.customer.email,
                ["gender"]: getCustomerInfoState.data.customer.gender,
            })
            form.setFieldsValue({
                first_name: getCustomerInfoState.data.customer.firstname,
                last_name: getCustomerInfoState.data.customer.lastname,
                email: getCustomerInfoState.data.customer.email,
                gender: getCustomerInfoState.data.customer.gender,
            })
        }
    }, [getCustomerInfoState])

    useEffect(() => {
        if (updateCustomerInfoState.apiState === "success") {
            notification["success"]({
                message: 'Profile updated successfully.',
                placement: "bottomRight"
            });
            props.closeModal()
            updateCustomerInfoReset()
        }

        if (updateCustomerInfoState.apiState === "error") {
            notification["error"]({
                message: updateCustomerInfoState.message,
                placement: "bottomRight"
            });
            updateCustomerInfoReset()
        }
    }, [updateCustomerInfoState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        updateCustomerInfo({
            first_name: formData.first_name,
            last_name: formData.last_name,
            email: formData.email,
            gender: formData.gender,
            customerToken: getCustomerToken(),
        })
    }

    const handleSelect = (v) => {
        setFormData({
            ...formData,
            ["gender"]: v
        })
    }

    return (
        <Form
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
        >
            <Row gutter="16" >
                <Col span="12" >
                    <Form.Item
                        name="first_name"
                        rules={[
                            { required: true, message: 'Required' },
                        ]}
                    >
                        <Input name="first_name" placeholder="First Name" onChange={handleOnChange} />
                    </Form.Item>
                </Col>

                <Col span="12" >
                    <Form.Item
                        name="last_name"
                        rules={[
                            { required: true, message: 'Required' },
                        ]}
                    >
                        <Input name="last_name" placeholder="Last Name" onChange={handleOnChange} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                name="email"
                rules={[
                    { required: true, message: 'Required' },
                    { type: 'email', message: 'Email is not valid' },
                ]}
            >
                <Input name="email" placeholder="Email" onChange={handleOnChange} />
            </Form.Item>

            <Form.Item
                name="gender"
                rules={[
                    { required: true, message: 'Required' },
                ]}
            >
                <Select name="gender" placeholder="Gender" onSelect={handleSelect} >
                    <Select.Option key={1} value={1} >Male</Select.Option>
                    <Select.Option key={2} value={2} >Female</Select.Option>
                </Select>
            </Form.Item>

            <Button type="primary"
                htmlType="submit"
                loading={updateCustomerInfoState.apiState === "loading" ? true : false} >Update Profile</Button>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    getCustomerInfoState: state.getCustomerInfo,
    updateCustomerInfoState: state.updateCustomerInfo,
});

const mapDispatchToProps = (dispatch) => ({
    updateCustomerInfo: (params) => dispatch(updateCustomerInfo(params)),
    updateCustomerInfoReset: () => dispatch(updateCustomerInfoReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);