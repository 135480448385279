export const GET_SHIPPIGO_TOKEN = "GET_SHIPPIGO_TOKEN"
export const GET_SHIPPIGO_TOKEN_SUCCESS = "GET_SHIPPIGO_TOKEN_SUCCESS"
export const GET_SHIPPIGO_TOKEN_FAIL = "GET_SHIPPIGO_TOKEN_FAIL"
export const GET_SHIPPIGO_TOKEN_RESET = "GET_SHIPPIGO_TOKEN_RESET"

export const getShippigoToken = (params) => {
    return { type: GET_SHIPPIGO_TOKEN, params }
}
export const getShippigoTokenSuccess = (response) => {
    return { type: GET_SHIPPIGO_TOKEN_SUCCESS, response }
}
export const getShippigoTokenFail = (response) => {
    return { type: GET_SHIPPIGO_TOKEN_FAIL, response }
}
export const getShippigoTokenReset = () => {
    return { type: GET_SHIPPIGO_TOKEN_RESET }
}