import {
    ADD_PRODUCT_TO_WISH_LIST,
    ADD_PRODUCT_TO_WISH_LIST_SUCCESS,
    ADD_PRODUCT_TO_WISH_LIST_FAIL,
    ADD_PRODUCT_TO_WISH_LIST_RESET,
} from '../actions/addProductToWishListAction'

const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT_TO_WISH_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case ADD_PRODUCT_TO_WISH_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }
        case ADD_PRODUCT_TO_WISH_LIST_FAIL:
            return {
                ...state,
                apiState: "error"
            }

        case ADD_PRODUCT_TO_WISH_LIST_RESET:
            return initialState

        default:
            return state;
    }
}

