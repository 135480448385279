import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';


import {
    LOGIN_REQUEST,
    loginSuccess,
    loginFail,
} from '../actions/loginAction'

import loginApi from '../apis/loginApi';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}


export function* loginSaga(action) {

    try {
        const response = yield call(() => loginApi(action.params))
        if (response.errors) {
            yield put(loginFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(loginSuccess(response, action))
            yield common(action, true, response);
        }
    } catch (e) {
        yield put(loginFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(LOGIN_REQUEST, loginSaga);
}

