import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

// Action
import { CHECK_DELIVERY, checkDeliverySuccess, checkDeliveryFail, checkDeliveryReset } from '../actions/checkDeliveryAction'

// Api
import checkDeliveryApi from '../apis/checkDeliveryApi'


export function* checkDeliverySaga(action) {
    try {
        const response = yield call(() => checkDeliveryApi(action.params))
        if (response.errors) {
            yield put(checkDeliveryFail(response, action))
        }
        else {
            yield put(checkDeliverySuccess(response, action))
        }
    }
    catch (e) {
        yield put(checkDeliveryFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(CHECK_DELIVERY, checkDeliverySaga);
}