import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GUEST_SET_SHIPPING_ADDRESS,
    guestSetShippingAddressSuccess,
    guestSetShippingAddressFail,
} from '../actions/guestSetShippingAddressAction'


import guestSetShippingAddressApi from '../apis/guestSetShippingAddressApi'
import get from 'lodash/get';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}

export function* guestSetShippingAddressSaga(action) {
    try {
        const response = yield call(() => guestSetShippingAddressApi(action.params))
        if (response.errors) {
            yield put(guestSetShippingAddressFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(guestSetShippingAddressSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(guestSetShippingAddressFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GUEST_SET_SHIPPING_ADDRESS, guestSetShippingAddressSaga);
}