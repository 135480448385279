export const CREATE_CUSTOMER_CART = 'CREATE_CUSTOMER_CART'
export const CREATE_CUSTOMER_CART_SUCCESS = 'CREATE_CUSTOMER_CART_SUCCESS'
export const CREATE_CUSTOMER_CART_FAIL = 'CREATE_CUSTOMER_CART_FAIL'
export const CREATE_CUSTOMER_CART_RESET = 'CREATE_CUSTOMER_CART_RESET'

export const createCustomerCart = (params) => {
    return { type: CREATE_CUSTOMER_CART, params }
}

export const createCustomerCartSuccess = (response) => {
    return { type: CREATE_CUSTOMER_CART_SUCCESS, response }
}

export const createCustomerCartFail = (response) => {
    return { type: CREATE_CUSTOMER_CART_FAIL, response }
}

export const createCustomerCartReset = () => {
    return { type: CREATE_CUSTOMER_CART_RESET }
}