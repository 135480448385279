import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_CART_ID,
    getCartIdSuccess,
    getCartIdFail,
} from '../actions/createCartAction'

import getCartIdApi from '../apis/getCartIdApi'


export function* getCartIdSaga(action) {

    try {
        const response = yield call(() => getCartIdApi(action))
        if (response.errors) {
            yield put(getCartIdFail(response, action))
        } else {
            yield put(getCartIdSuccess(response, action))
        }
    } catch (e) {
        yield put(getCartIdFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_CART_ID, getCartIdSaga);
}