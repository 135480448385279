export const GET_BRAND_LIST = 'GET_BRAND_LIST'
export const GET_BRAND_LIST_SUCCESS = 'GET_BRAND_LIST_SUCCESS'
export const GET_BRAND_LIST_FAIL = 'GET_BRAND_LIST_FAIL'
export const GET_BRAND_LIST_RESET = 'GET_BRAND_LIST_RESET'

export const getBrandList = (params) => {
    return { type: GET_BRAND_LIST, params }
}

export const getBrandListSuccess = (response) => {
    return { type: GET_BRAND_LIST_SUCCESS, response }
}

export const getBrandListFail = (response) => {
    return { type: GET_BRAND_LIST_FAIL, response }
}

export const getBrandListReset = () => {
    return { type: GET_BRAND_LIST_RESET }
}