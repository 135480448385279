import axios from "axios";
import { BASE_URL } from "../utils";

const getBrandInfoApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                    customAttributeMetadata(
                        attributes: [
                        {
                            attribute_code: "brand_name"
                            entity_type: "catalog_product"
                        }
                        ]
                    ) {
                        items {
                        attribute_code
                        attribute_type
                        entity_type
                        input_type
                        attribute_options {
                        value
                        label
                        }
                        }
                    }
                }
            `,
        }
    });
    return response.data;
};

export default getBrandInfoApi;
