import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, HelpHeading } from "./style";
import { fetchHelpCmsRequest } from "../../actions/fetchHelpCmsAction";
import Footer from "../../components/Footer/Footer";


const Help = (props) => {
  const { fetchHelpCmsRequest, fetchHelpData, history } = props;

  useEffect(() => {
    fetchHelpCmsRequest({
      errorAction: (data) => { },
      successAction: (data) => {
      },
    });
  }, []);

  return (
    <>
      <Container>
        <HelpHeading>
          <div
            dangerouslySetInnerHTML={{
              __html: (fetchHelpData && fetchHelpData.content) || null,
            }}
          />
        </HelpHeading>
      </Container>
      <Footer isBackHeader history={history} />

    </>
  );
};

const mapStateToProps = (state) => ({
  fetchHelpData: state.helpCms.fetchHelpData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchHelpCmsRequest: () => dispatch(fetchHelpCmsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
