import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  FETCH_ABOUT_REQUEST,
  fetchAboutSuccess,
  fetchAboutFail,
} from "../actions/fetchAboutAction";
import get from 'lodash/get';

import fetchAboutApi from "../apis/fetchAboutApi";



function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(...otherParams);
  }
}

export function* fetchAboutSaga(action) {

  try {

    const response = yield call(() => fetchAboutApi(action));

    if (response.errors) {
      yield put(fetchAboutFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(fetchAboutSuccess(response, action));
      yield common(action, true, response);


    }
  } catch (e) {
    yield put(fetchAboutFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(FETCH_ABOUT_REQUEST, fetchAboutSaga);
}
