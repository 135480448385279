import styled from 'styled-components'

export const FrontBannerWrapper = styled.div`
.ant-carousel .slick-dots{
    margin-left: 0px !important;
    margin-right: 0px !important;
}
.slick-dots li button:before{
    content:""
}
.ant-carousel .slick-dots li.slick-active button{
    background: #f78c3e !important;
}
`

export const Row = styled.div`
    width: 100%;
    height: 100%;
`
export const Video = styled.video`
    width: 100%;
    height: 481px !important;
    object-fit: cover;
    border-radius: 18px;

    @media screen and (max-width: 581px){
          width: 100%;
          height: 407px !important;
    }

`

export const RowBannerDesktop = styled.div`
width:100%;
margin-top: 12px;
height: auto;
padding: 4px 10px;
&:hover {
      img {
          transform: scale(1.02);
          transition: transform 0.3s ease-in-out 0s;
      }
  }

 img{
   transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
    width: 100%;
    height: 100%;
    border-radius: 18px;
  }

display: block;
  @media screen and (max-width: 570px){
   display: none;
  }
`
export const RowBannerMobile = styled.div`
width:100%;
height:auto;
margin-top: 12px;
padding: 4px 10px;
 img{
    width: 100%;
    height: 100%;
    border-radius: 18px;
  }
  display: none;
  @media screen and (max-width: 570px){
   display: block;
  }

`