import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
  @media ${(props) => props.theme.device.tablet} {
      margin-top: 150px;
  }
`
export const PolicyHeading = styled.div`
color:${(props) => props.theme.color.black}
`

