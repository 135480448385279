import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { notification, Popconfirm, message } from 'antd'

/* ============= */
// custom compnents
/* ============= */
// import { Container, ArrowContainer, CartContainer } from '../Cart/style'
import {
    Container, Arrowwrapper,
    WishListCard, ProductInfo, ProductPrice, ArrowBlock, WishListFormWrapper, WishListFormBlock, WishListCardContainer, CardContainer,
} from './WishListStyle'
import WishListSkeleton from '../../components/WishListSkeleton/WishListSkeleton'
import NoDataContianer from '../../components/NoDataContainer/NoData'
/* ============= */
// actions
/* ============= */

import { getWishList } from '../../actions/getWishListAction'
import { deleteWishList, deleteWishListReset } from '../../actions/deleteWishListAction'


/* ============= */
// others
/* ============= */

import { getCustomerToken, getCartId } from '../../utils'


import { Button } from 'antd'

const WishList = (props) => {
    /* ============= */
    // variables
    /* ============= */

    const [renderList, setRenderList] = useState("")
    const [visiable, setVisiable] = useState(false)

    const { getWishList, getWishListState, deleteWishList, deleteWishListState, deleteWishListReset } = props;

    const previousPath = useHistory();
    const [refresh, setRefresh] = useState(0)

    const goToPreviousPath = () => {
        previousPath.goBack();
    };
    const isMobile = window.innerWidth < 768;
    const [productName, setProductName] = useState(null)

    // callbacks

    useEffect(() => {
        return (() => {
            deleteWishListReset()
        })
    }, [])

    useEffect(() => {
        if (getWishListState.apiState === "success") {
            setRenderList("success")
        } else if (getWishListState.apiState === "loading") {
            setRenderList("loading")
        } else if (getWishListState.apiState === "error") {
            setRenderList("error")
        }
    }, [getWishListState])


    useEffect(() => {
        getWishList({ customerToken: getCustomerToken() })
    }, [])

    useEffect(() => {
        if (deleteWishListState.apiState === "success") {
            setRenderList("success")
            notification["success"]({
                message: `${productName} removed to your wishlist.`,
                placement: "bottomRight"
            });
            deleteWishListReset()
            getWishList({ customerToken: getCustomerToken() })

        } else if (deleteWishListState.apiState === "loading") {
            setRenderList("loading")
        } else if (deleteWishListState.apiState === "error") {
            notification["error"]({
                message: 'An error occurred during processing. Please try again.',
                placement: "bottomRight"
            });
            deleteWishListReset()
        }
    }, [deleteWishListState])

    const handleRemoveWishList = (id, name) => {
        setProductName(name)
        deleteWishList({ id: id });
    }

    return (
        <Container>
            <Arrowwrapper >
                <ArrowBlock onClick={goToPreviousPath}>
                    <img
                        src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                        alt="ladu-india"
                        width="36"
                    />
                </ArrowBlock>
                <span style={{
                    fontSize: '16px',
                    fontWeight: "600",
                    marginLeft: '8px',
                    textTransform: 'uppercase'
                }}>
                    Your Wishlist
                </span>
            </Arrowwrapper>
            {
                renderList === "loading" &&
                <>
                    <WishListSkeleton />
                </>
            }

            {
                (getWishListState.apiState === "success" && getWishListState.data.wishlist ? getWishListState.data.wishlist.items_count === 0 : null) &&
                <>
                    <NoDataContianer
                        showText="No Items available in WishList."
                        animationType={"cartAnimation"}
                    />
                    <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                </>
            }
            {
                renderList === "success" &&
                <>
                    {
                        getWishListState.data.wishlist ?
                            <WishListCardContainer>
                                {getWishListState.data.wishlist.items.map((wish) => {
                                    let img = ""
                                    if (wish.product.media_gallery[0]) {
                                        img = wish.product.media_gallery[0].url
                                    }
                                    else {
                                        img = wish.product.thumbnail.url
                                    }
                                    return (
                                        <>
                                            <CardContainer key={wish.id} >
                                                <ProductInfo>
                                                    <Link to={`/product-detail/${wish.product?.url_key}`} target={isMobile ? '_self' : '_blank'}><img style={{ width: '150px', height: '150px' }} src={img} alt={wish.product.name} /></Link>
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 16 }}>
                                                        <Link to={`/product-detail/${wish.product?.url_key}`} target={isMobile ? '_self' : '_blank'}><h3 style={{ fontWeight: 900 }}>{wish.product.name}</h3></Link>
                                                        <ProductPrice>₹{wish.product.price_range.minimum_price.regular_price.value}</ProductPrice>
                                                        <Popconfirm
                                                            title="Are you sure you want to remove this item?"
                                                            onConfirm={() => handleRemoveWishList(wish.id, wish.product.name)}
                                                            okText="Remove"
                                                            cancelText="Cancel"
                                                        >
                                                            <Button size="middle" style={{ width: 100, marginTop: 10 }}>Remove</Button>
                                                        </Popconfirm>
                                                    </div>
                                                </ProductInfo>
                                                {/* <Button >Add To Card</Button> */}
                                            </CardContainer>
                                        </>
                                    )
                                })}
                            </WishListCardContainer>
                            :
                            <WishListFormWrapper>
                                <WishListFormBlock>
                                    <h2>PLEASE LOG IN</h2>
                                    <p>Login to view items in your wishlist.</p>
                                    {/* <img src="https://image.shutterstock.com/image-vector/wish-list-icon-simple-outline-260nw-526566010.jpg" /> */}
                                    <Link to="/login" ><Button type="primary" style={{ width: '100%' }} >Login</Button></Link>
                                </WishListFormBlock>
                            </WishListFormWrapper>
                    }
                </>
            }
        </Container >
    )
}

const mapStateToProps = (state) => ({
    getWishListState: state.getWishList,
    deleteWishListState: state.deleteWishList,
})
const mapDispatchToProps = (dispatch) => ({
    getWishList: (params) => dispatch(getWishList(params)),
    deleteWishList: (params) => dispatch(deleteWishList(params)),
    deleteWishListReset: () => dispatch(deleteWishListReset()),
})


export default connect(mapStateToProps, mapDispatchToProps)(WishList)
