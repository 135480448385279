import axios from "axios";
import { isAuthorized,BASE_URL} from '../utils';

const createGuestCartApi = async () => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                mutation {
                    createEmptyCart
                }
            `,
        },
        headers: isAuthorized(),
    });
    return response.data;
};

export default createGuestCartApi;
