import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    MERGE_CARTS,
    mergeCartsSuccess,
    mergeCartsFail,
} from '../actions/mergeCartsActions'

import mergeCartsApi from '../apis/mergeCartsApi'


export function* mergeCartsSaga(action) {
    try {
        const response = yield call(() => mergeCartsApi(action.params))

        if (response.errors) {
            yield put(mergeCartsFail(response, action))
        } else {
            yield put(mergeCartsSuccess(response, action))
        }
    } catch (e) {
        yield put(mergeCartsFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(MERGE_CARTS, mergeCartsSaga);
}