import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Collapse, Menu, Dropdown, Modal, List } from "antd";
import {
    CaretRightOutlined,
    DownOutlined,
    CaretDownOutlined,
} from "@ant-design/icons";
import { Helmet } from 'react-helmet'
import _ from "lodash"
import { getCategoryList, toggleCategory } from "../../actions/categoryActions";
import { fetchListCategoriesRequest } from "../../actions/productListAction";
import { Xgrid, XgridCard } from "../../components/Xcomponent";
import RowListSkeleton from "../../components/RowListSkeleton/RowListSkeleton";
import {
    Container,
    Body,
    // Footer,
    CategoryName,
    CategoryImg,
    SubCategoryContainer,
    SubCategoryName,
    CollapseContainer,
    PanelImg,
    PanelContainer,
    CategoryContainer,
    CategoryCard,
    CategoryCardItem,
    DropdownContainer,
    ListItem,
    ListHeader,
    CategorySubCard,
    SkeletonCategoryContainer,
    MenuContent,
    SingleCategoryName,
    BackHeaderLink
} from "./CategoryStyle";
import Footer from "../../components/Footer/Footer";
import MobileTabs from "../../components/MobileTabs/mobileTabs";

const CategoryMenu = (props) => {
    const {
        getCategoryList,
        categoryState,
        toggleCategory,
        fetchListCategoriesRequest,
        history,
        isLoading,
        // visibleCategoryData,
    } = props;
    const [visible, setVisible] = useState(false);
    const { Panel } = Collapse;
    const isMobile = window.innerWidth < 1024;
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [display, setDisplay] = useState(false)

    const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

    useEffect(() => {
        setVisible(categoryState.visible);

        if (categoryState.visible) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "auto";
    }, [categoryState.visible]);

    useEffect(() => {
        if (categoryState.list.length === 0) {
            getCategoryList({ id: 2 })
        }
        setShowModal(true);
    }, [])

    const onListCategoryClick = (item) => {
        history.push({
            pathname: `/category/${item.url_key}/${item.id}`,
            state: {
                url_key: item.url_key,
                id: item.id,
            },
        });
        //  fetchListCategoriesRequest({id:item.id})
        fetchListCategoriesRequest({ id: item.id });
    };

    const handleMenu = () => {
        setDisplay(true)
    }
    const menu = (item) => {
        return (
            <Menu>
                {item.children.map((rootItem) => (
                    <Menu.Item key={rootItem.key}>
                        <MenuContent onClick={() => onListCategoryClick(rootItem)}>
                            {rootItem.name}
                        </MenuContent>
                    </Menu.Item>
                ))}
            </Menu>
        );
    };

    const handleModal = (data) => {
        setShowModal(true);
        setModalData(data);
    };

    const handleOk = () => {
        setShowModal(false);
    };


    return (
        // <Container>
        //   <Body>
        <>
            {isLoading && (
                <SkeletonCategoryContainer>
                    <RowListSkeleton rows={16} />
                </SkeletonCategoryContainer>
            )}
            {!isMobile && !isLoading
                ? [
                    <>
                        <CategoryContainer>
                            {(categoryState.list.children || []).map((item) => (
                                <CategoryCard>
                                    <CategoryName onClick={() => onListCategoryClick(item)}>
                                        {item.name}
                                    </CategoryName>
                                    {item.children.length > 0 ? (
                                        <SubCategoryContainer>
                                            {_.sortBy(item.children, ['position']).map((subCat) => (
                                                <>
                                                    {subCat.children.length > 0 ? (
                                                        <DropdownContainer
                                                            overlay={menu(subCat)}
                                                            trigger={["click"]}
                                                            overlayStyle={
                                                                {
                                                                    //  minWidth: "50px",
                                                                    //   paddingTop: "20px",
                                                                }
                                                            }
                                                        // arrow
                                                        >
                                                            <SubCategoryName
                                                                className="ant-dropdown-link"
                                                                onClick={(e) => e.preventDefault()}
                                                                onClick={() => handleModal(subCat)}
                                                            >
                                                                {subCat.name} <CaretDownOutlined />
                                                            </SubCategoryName>
                                                        </DropdownContainer>
                                                    ) : (
                                                        <SubCategoryName
                                                            onClick={() => onListCategoryClick(subCat)}
                                                        >
                                                            {subCat.name}
                                                        </SubCategoryName>
                                                    )}
                                                </>
                                            ))}
                                        </SubCategoryContainer>
                                    ) : (
                                        <></>
                                    )}
                                    {/* </CategorySubCard> */}
                                </CategoryCard>
                            ))}
                            <CategoryCard>
                                <CategoryName>
                                    <BackHeaderLink to="/about?r=about-ladu"  >
                                        About Us
                                    </BackHeaderLink>
                                </CategoryName>
                            </CategoryCard>
                        </CategoryContainer>
                        <Footer isShop history={history} display={display} />
                    </>,
                ]
                : null}
            {isMobile && !isLoading && (
                <>
                    {/* <MobileTabs tabsKey="shop" history={history} /> */}
                    <Xgrid>
                        <CollapseContainer
                            bordered={false}
                            expandIcon={({ isActive }) => (
                                <CaretRightOutlined rotate={isActive ? 90 : 0} />
                            )}
                            className="site-collapse-custom-collapse"
                            accordion
                        >
                            {(categoryState.list.children || []).map((item, key) => (
                                <>
                                    {item.children.length > 0 ? (
                                        <Panel
                                            header={item.name}
                                            key={key}
                                            className="site-collapse-custom-panel"
                                        >
                                            {item.children.length > 0 ? (
                                                <SubCategoryContainer>
                                                    {item.children.map((subCat) => (
                                                        <>
                                                            {subCat.children.length > 0 ? (
                                                                <DropdownContainer
                                                                    overlay={menu(subCat)}
                                                                    trigger={["click"]}
                                                                    overlayStyle={{ minWidth: "150px" }}
                                                                >
                                                                    <SubCategoryName
                                                                        onClick={(e) => e.preventDefault()}
                                                                    >
                                                                        {subCat.name} <CaretDownOutlined />
                                                                    </SubCategoryName>
                                                                </DropdownContainer>
                                                            ) : (
                                                                <SubCategoryName
                                                                    onClick={() => onListCategoryClick(subCat)}
                                                                >
                                                                    {subCat.name}
                                                                </SubCategoryName>
                                                            )}
                                                        </>
                                                    ))}
                                                </SubCategoryContainer>
                                            ) : (
                                                "no data"
                                            )}
                                        </Panel>
                                    ) : (
                                        <SingleCategoryName
                                            onClick={() => onListCategoryClick(item)}
                                        >
                                            {item.name}
                                        </SingleCategoryName>
                                        // <Panel
                                        //     header={item.name}
                                        //     key={key}
                                        //     showArrow={false}

                                        //     onClick={() => onListCategoryClick(item)}
                                        // ></Panel>
                                    )}
                                </>
                            ))}
                            <SingleCategoryName>
                                <BackHeaderLink to="/about?r=about-ladu"  >
                                    About Us
                                </BackHeaderLink>
                            </SingleCategoryName>
                        </CollapseContainer>
                        <Helmet>
                            <meta property="og:locale" content="en_US" />
                            <meta property="og:type" content="article" />
                            <meta property="og:site_name" content="Ladu India" />
                        </Helmet>
                    </Xgrid>
                    <Footer isShop history={history} display={display} />
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    categoryState: state.category,
    isLoading: state.category.loading,
});

const mapDispatchToProps = (dispatch) => ({
    getCategoryList: (params) => dispatch(getCategoryList(params)),
    toggleCategory: () => dispatch(toggleCategory()),
    fetchListCategoriesRequest: (params) =>
        dispatch(fetchListCategoriesRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CategoryMenu);
