import axios from 'axios'
import { BASE_URL } from '../utils';
const addConfigrableProductApi = async (params) => {

    const response = await axios({
         url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
            mutation {
                    addConfigurableProductsToCart(
                        input: {
                        cart_id: "4JQaNVJokOpFxrykGVvYrjhiNv9qt31C"
                        cart_items: [
                            {
                            parent_sku: "${params}"
                            data: {
                                quantity: ${params}
                                sku: "${params}"
                            }
                            }
                        ]
                        }
                    ) {
                        cart {
                        items {
                            uid
                            quantity
                            product {
                            name
                            sku
                            }
                            ... on ConfigurableCartItem {
                            configurable_options {
                                option_label
                            }
                            }
                        }
                        }
                    }
                    }
            `
        }
    })
    return response.data
}

export default addConfigrableProductApi