import styled from "styled-components";
import { Checkbox, Button, InputNumber, } from "antd";


export const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const CardItem = styled.div`
background-color: #f8f8f8;
padding:15px;
display: flex;
justify-content: space-between;
align-items: center;
margin:20px;
border-radius:5px;
 @media screen and (max-width: 545px){
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const DeleteItem = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
cursor: pointer;
margin-right: 8px;
`;

export const ImageContainer = styled.div`
height: 100px;
width: 100px;
margin-left: 8px;
`;
export const JaipurDeliveryMessage = styled.div`
display: block;
width: 100%;
`

export const CardContent = styled.div`
margin-left: 8px;
margin-right: 8px;
`;

export const Content = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
`;
export const CustomizeableContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
`;
export const SubContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
`;
export const GiftContainer = styled.div`

`;
export const CheckBox = styled(Checkbox)`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
`;
export const CheckBoxContent = styled.div`

`;
export const CardPriceWrapper = styled.div`
  display: flex;
  align-items: center;
 
`

export const CardPrice = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
margin-right: 12px;
color: ${(props) => props.theme.color.black};
`;

export const QuantityContainer = styled.div`
  display: flex;
  margin-right: 10px;
  //  flex: 0.5;
`;

export const IncreamentButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 0;
  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #d9d9d9;
  }
`;

export const DecreamentButton = styled(Button)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right: 0;
  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #d9d9d9;
  }
`;
export const InputQuantity = styled(InputNumber)`
  border-radius: 0;
  max-width: 40px;
  border-left: 0;
  border-right: 0;

  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-number-input{
      font-size: 14px;
      font-weight: bold;
       color: ${(props) => props.theme.color.black};
  text-align: center;

  }
  
  &&.ant-input-number-disabled{
    background-color:#ffffff !important;
    color:#000;
    
  }

  && .ant-input:focus,
  .ant-input-focused {
    outline: 0;
  }

  &.ant-input-number:hover {
    border-color: #d9d9d9;
    border-right-width: 0;
  }
  &.ant-input-number:focus,
  .ant-input-number-focused {
    outline: 0;
    box-shadow: unset;
  }
`;


export const MobileCartItem = styled.div`
background-color: #f8f8f8;
padding:15px;
display: flex;
justify-content: space-between;
margin:20px;
flex-direction: column;
border-radius:5px;
/* align-items: center; */
/* justify-content: flex-start; */


`

export const MobileDeleteItem = styled.div`
text-align: left;
margin-right: 10px;
`

export const MobileImageContainer = styled.div`
height: 100px;
width: 100px;
margin-right: 10px;
`

export const MobileImgContentContainer = styled.div`
display: flex;
align-items: center;
`

export const MobileCardContent = styled.div`
padding: 0 10px;
`

export const MobileContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
padding-bottom:8px;

`

export const MobileSubContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
padding-bottom:5px;
`

export const MobileGiftContainer = styled.div``

export const MobileCheckBox = styled(Checkbox)`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
padding-bottom:5px;
`

export const MobileCardPrice = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
padding-bottom:5px;
margin-right: 10px;
`

export const MobileQuantityContainer = styled.div``

export const MobileDecreamentButton = styled(Button)`
border-bottom-right-radius: 0;
border-top-right-radius: 0;
border-bottom-left-radius: 10px;
border-top-left-radius: 10px;
border-right: 0;
&.ant-btn:hover,
&.ant-btn:focus {
  border-color: #d9d9d9;
}
`

export const MobileInputQuantity = styled(InputNumber)`
border-radius: 0;
max-width: 40px;
border-left: 0;
border-right: 0;
.ant-input-number-handler-wrap {
  display: none;
}

&& .ant-input:focus,
.ant-input-focused {
  outline: 0;
}

  
&&.ant-input-number-disabled{
  background-color:#ffffff !important;
  color:#000;
}

&.ant-input-number:hover {
  border-color: #d9d9d9;
  border-right-width: 0;
}
&.ant-input-number:focus,
.ant-input-number-focused {
  outline: 0;
  box-shadow: unset;
}
`

export const MobileIncreamentButton = styled(Button)`
border-bottom-left-radius: 0;
border-top-left-radius: 0;
border-bottom-right-radius: 10px;
border-top-right-radius: 10px;
border-left: 0;
&.ant-btn:hover,
&.ant-btn:focus {
  border-color: #d9d9d9;
}
`




