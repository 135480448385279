import styled from "styled-components";
import { Card } from "antd";
export const CardContainer = styled.div``;

export const HeartContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right:10px;
  z-index: 9;
  cursor: pointer;
 // display:none;
  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
`;

export const ProductContainer = styled.div`
  display: flex;
  align-items: strech;
  flex-wrap: wrap;
  height: ${(props) =>
    (props.noData === 0 && "unset")};
  justify-content: ${(props) => (props.noData == 0 ? "center" : "flex-start")};
  max-width: 1000px;
  margin: auto;
  padding-bottom: 82px;

  // &:hover ${HeartContainer} {
  //   display: block;
  // }
  @media ${(props) => props.theme.device.tablet} {
    flex-direction: column;
    margin-bottom: 72px;

  }
`;

export const ProductCard = styled.div`
  margin: 5px;
  position: relative;
  @media ${(props) => props.theme.device.tablet} {
    width: unset;
    margin: 3px 10px;
  }
`;
export const ProductCardItem = styled.div`
  display: inline-block;
  text-align: left;
  position: relative;
`;

export const ProductListName = styled.div`
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  //margin: 20px;
  @media ${(props) => props.theme.device.tablet} {
 //   margin: 10px;
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;

export const CardItem = styled(Card)`
&&.ant-card.ant-card-bordered.ant-card-hoverable{
    height: 100%;
    width:240px;
    border: 1px solid #fff;
    background-color: #f8f8f8;
    @media ${(props) => props.theme.device.tablet} {
        width:100%;
        display: flex;
      }
}
&& .ant-card-body{
    display: flex;
    justify-content: center;  
    flex-direction: column;
    padding:0px;
    max-width: 240px;
    @media ${(props) => props.theme.device.tablet} {
      padding-left: 10px;
    }
   
}
&& .ant-card-meta{

}
&& .ant-card-meta-title{
  padding:10px 10px 0 10px;
  font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
}
&&.ant-card-bordered .ant-card-cover img{
     height:240px;
     border-radius: 10px 0px
    border-radius: 0;
    object-fit: cover;
    @media ${(props) => props.theme.device.tablet} {
      height: auto;
      width:110px;
    }

}

`;

export const CardName = styled.div`
  padding: 5px 10px 10px 10px;
`;

export const CardPrice = styled.div`
padding: 0 10px 5px;
  font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;

export const BrandName = styled.div`
padding: 5px 10px 0 10px;
  font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;

export const NoDataContianer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoDataContent = styled.div``;

export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin:20px 0px;
   @media ${(props) => props.theme.device.tablet} {
  //  justify-content: space-around;
    align-items: center;
    margin-top:0px;
  }
`;
export const ProductCount = styled.div`
 // margin: 20px;
  font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  font-weight: ${(props) => props.theme.fontWeight};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-right: 12px;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
 //   margin: 10px;
  }
`;

export const ArrowNameContainer = styled.div`
display:flex;
justify-content: center;
    align-items: center;
`


export const ArrowContainer = styled.div`
display: flex;
align-items: center;
cursor:pointer;
margin-right:20px;
& img{
    border-radius: 50%;
    &:hover{
      background-color: #e2e2e2;
    }
    padding: 10px;

    // @media ${(props) => props.theme.device.tablet} {
    //   padding-bottom: 5px;
    // }
}

`
