export const GET_CART_DATA = 'GET_CART_DATA'
export const GET_CART_DATA_SUCCESS = 'GET_CART_DATA_SUCCESS'
export const GET_CART_DATA_FAIL = 'GET_CART_DATA_FAIL'
export const GET_CART_DATA_RESET = 'GET_CART_DATA_RESET'

export const getCartData = (params) => {
    return { type: GET_CART_DATA, params }
}

export const getCartDataSuccess = (response) => {
    return { type: GET_CART_DATA_SUCCESS, response }
}

export const getCartDataFail = (response) => {
    return { type: GET_CART_DATA_FAIL, response }
}

export const getCartDataReset = () => {
    return { type: GET_CART_DATA_RESET }
}
