import { call, put, takeLatest } from 'redux-saga/effects'


import { GET_CART_DATA, getCartDataSuccess, getCartDataFail } from '../actions/getCartDataAction'

import getCartDataApi from '../apis/getCartDataApi'


export function* getCartDataSaga(action) {
    try {
        const response = yield call(() => getCartDataApi(action.params))
        if (response.errors) {
            yield put(getCartDataFail(response, action))
        } else {
            yield put(getCartDataSuccess(response, action))
        }
    } catch (e) {
        yield put(getCartDataFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_CART_DATA, getCartDataSaga);
}