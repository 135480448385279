export const HOME_PAGE_BANNER_MOBILE = 'HOME_PAGE_BANNER_MOBILE'
export const HOME_PAGE_BANNER_MOBILE_SUCCESS = 'HOME_PAGE_BANNER_MOBILE_SUCCESS'
export const HOME_PAGE_BANNER_MOBILE_FAIL = 'HOME_PAGE_BANNER_MOBILE_FAIL'
export const HOME_PAGE_BANNER_MOBILE_RESET = 'HOME_PAGE_BANNER_MOBILE_RESET'

export const homePageBannerMobile = (params) => {
    return { type: HOME_PAGE_BANNER_MOBILE, params }
}

export const homePageBannerMobileSuccess = (response) => {
    return { type: HOME_PAGE_BANNER_MOBILE_SUCCESS, response }
}

export const homePageBannerMobileFail = (response) => {
    return { type: HOME_PAGE_BANNER_MOBILE_FAIL, response }
}

export const homePageBannerMobileReset = () => {
    return { type: HOME_PAGE_BANNER_MOBILE_RESET }
}