export const CARD_CONFIG = [
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 0,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 1,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 2,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 3,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 4,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 5,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 6,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 7,
      isHeartFilled:false,
    },
    {
      img: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png",
      description: "www.instagram.com",
      title: "Europe Street beat",
      id: 8,
      isHeartFilled:false,
    },
  ];
  