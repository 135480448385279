import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    HOME_PAGE_BANNER,
    homePageBannerSuccess,
    homePageBannerFail,
} from '../actions/homePageBannerAction'

// api
import homePageBannerApi from '../apis/homePageBannerApi'


export function* homePageBannerSaga(action) {
    try {
        const response = yield call(() => homePageBannerApi(action.params))
        if (response) {
            yield put(homePageBannerSuccess(response, action))
        }
    } catch (e) {
        yield put(homePageBannerFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(HOME_PAGE_BANNER, homePageBannerSaga);
}