import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { RightOutlined } from "@ant-design/icons";
//Components
import Footer from '../../components/Footer/Footer'
import Loader from '../../components/Loader/Loader'
import HomePageFooter from "../../components/HomePageFooter/HomePageFooter";
import FrontBanner from '../../components/FrontBanner/FrontBanner'
import HomePageCarosuelSkeleton from '../../components/HomePageCarosuelSkeleton/HomePageCarosuelSkeleton'
import { Link } from "react-router-dom";
//Style
import {
    Container, FigureCard, HomeWrapper, Row, ProductName, HLine, CarouselDiv, TitleLink,
    Title, LifestyleContainer, TitleContainer, Image, ProductLink, HorizontalLineBlock, FlexRow, FlexBlock,
} from "./HomeStyle"
// Actions
import { getFeaturedImageList, getFeaturedImageListReset } from "../../actions/getFeaturedImageListAction";
import { showOnHomePageBurniSection, showOnHomePageBurniSectionReset } from '../../actions/showOnHomePageBurniSectionAction'
import { showOnHomePageBrandSection, showOnHomePageBrandSectionReset } from '../../actions/showOnHomePageBrandSectionAction'
import { wrap } from "lodash";

const Home = props => {
    const { history } = props;
    // variables
    const {
        getFeaturedImageList, getFeaturedImageListState, getFeaturedImageListReset,
        showOnHomePageBurniSection, showOnHomePageBurniSectionState, showOnHomePageBurniSectionReset,
        showOnHomePageBrandSection, showOnHomePageBrandSectionState, showOnHomePageBrandSectionReset
    } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [featuredImageData, setFeaturedImageData] = useState([])
    const [count, setCount] = useState(0)
    const [renderList, setRenderList] = useState("") // loading, success, error
    const prevCurrentPageRef = useRef();
    const [isMobile, setIsMobile] = useState(false)

    const handleResize = () => {
        setIsMobile(window.innerWidth < 768)
    }
    window.addEventListener('resize', handleResize)

    useEffect(() => {
        setIsMobile(window.innerWidth < 768)
        prevCurrentPageRef.current = currentPage;
    }, [])
    const prevPage = prevCurrentPageRef.current;
    // Callback
    useEffect(() => {
        window.scrollTo(0, 0)
        return (() => {
            getFeaturedImageListReset()
            showOnHomePageBurniSectionReset()
            showOnHomePageBrandSectionReset()
        })
    }, [])
    useEffect(() => {
        getFeaturedImageList({ attributeType: 'showcase_on_home_page', currentPage: currentPage })
    }, [currentPage])

    useEffect(() => {
        showOnHomePageBurniSection()
        showOnHomePageBrandSection()
    }, [])


    useEffect(() => {
        if (featuredImageData.length === 0) {
            setRenderList("loading")
        }
        if (getFeaturedImageListState.apiState === "success") {
            const imageListTotalCount = getFeaturedImageListState.data.products.total_count
            if (currentPage !== prevPage) {
                let data = [...featuredImageData]
                getFeaturedImageListState.data.products.items.map((item) => {
                    data.push(item)
                })
                setFeaturedImageData(data)
                handleCount(imageListTotalCount, data.length)
            }
            else {
                setFeaturedImageData(getFeaturedImageListState.data.products.items)
                handleCount(imageListTotalCount, getFeaturedImageListState.data.products.items.length)
            }
        }
    }, [getFeaturedImageListState])

    const handleCount = (imageListTotalCount, dataLength) => {
        if (imageListTotalCount > dataLength) {
            setHasMore(true)
        }
        else {
            setHasMore(false)
        }
        setCount(count + 1)
        getFeaturedImageListReset()
    }

    useEffect(() => {
        if (count > 0) {
            setRenderList("success")
        }
    }, [count])
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    return (
        <>
            <HomeWrapper>
                <FrontBanner />
                <Row>
                    <TitleContainer>

                        {/* <Title>Shop By Shop</Title> */}
                    </TitleContainer>
                    <FlexRow>
                        <FlexBlock>
                            <Link to={`/category/food/3`} >
                                <CarouselDiv>
                                    <Image src={`${process.env.PUBLIC_URL}/images/home/Food.jpg`} />
                                    <ProductName>FOOD</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                        <FlexBlock>
                            <Link to={`/category/gifting/135`} >
                                <CarouselDiv>

                                    <Image src={`${process.env.PUBLIC_URL}/images/home/gifting.jpg`} />
                                    <ProductName >GIFTING</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                        {/* <Link to={`/product-detail/wmc-kumaon-turmeric-powder-225g`} >
                                <CarouselDiv>
                                    <Image src={`${process.env.PUBLIC_URL}/images/home/Jwellery.jpg`} />
                                    <ProductName>JEWELLERY</ProductName>
                                </CarouselDiv>
                            </Link> */}
                        <FlexBlock>
                            <Link to={`/category/kitchen-and-dining/5`} >
                                <CarouselDiv>
                                    <Image src={`${process.env.PUBLIC_URL}/images/home/kitchen-mug.jpg`} />
                                    <ProductName>KITCHEN & DINING </ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                        <FlexBlock>
                            <Link to={`/category/living-spaces/68`} >
                                <CarouselDiv>

                                    <Image src={`${process.env.PUBLIC_URL}/images/home/Living-Nimmits.jpg`} />
                                    <ProductName>LIVING SPACES</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                    </FlexRow>
                    <FlexRow>
                        <FlexBlock>
                            <Link to={`/category/personal-care/9`} >
                                <CarouselDiv>

                                    <Image src={`${process.env.PUBLIC_URL}/images/home/personal-care-perfume.jpg`} />
                                    <ProductName>PERSONAL CARE</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                        <FlexBlock>
                            <Link to={`/category/stationery/8`} >
                                <CarouselDiv>

                                    <Image src={`${process.env.PUBLIC_URL}/images/home/stationary.jpg`} />
                                    <ProductName>STATIONERY</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                        <FlexBlock>
                            <Link to={`/category/prayer-and-wellness/81`} >
                                <CarouselDiv>
                                    <Image src={`${process.env.PUBLIC_URL}/images/home/Wellness.jpg`} />
                                    <ProductName>PRAYER & WELLNESS</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                        <FlexBlock>
                            <Link to={`/category/wardrobe/4`} >
                                <CarouselDiv>

                                    <Image src={`${process.env.PUBLIC_URL}/images/home/wardrobe.jpg`} />
                                    <ProductName>WARDROBE</ProductName>
                                </CarouselDiv>
                            </Link>
                        </FlexBlock>
                    </FlexRow>

                </Row>
                <Row>
                    <TitleContainer>
                        <HorizontalLineBlock>
                            <HLine></HLine>
                            <Title>FEATURED COLLECTIONS</Title>
                            <HLine></HLine>
                        </HorizontalLineBlock>
                    </TitleContainer>

                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlay
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className="caro"
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                            desktop: {
                                breakpoint: {
                                    max: 3000,
                                    min: 1024,
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                    max: 464,
                                    min: 0
                                },
                                items: 1,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                    max: 1024,
                                    min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            }
                        }}
                        style={{ padding: "0px 10px", }}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={isMobile ? 1 : 3}
                        swipeable
                    >

                        <ProductLink to={`category/books/102`} >
                            <CarouselDiv>
                                <Image src={`${process.env.PUBLIC_URL}/images/home/books.jpg`} />
                                <ProductName>BOOKS</ProductName>
                            </CarouselDiv>
                        </ProductLink>
                        <ProductLink to={`/category/gardening/70`} >
                            <CarouselDiv>
                                <Image src={`${process.env.PUBLIC_URL}/images/home/Plant.jpg`} />
                                <ProductName>PLANTS</ProductName>
                            </CarouselDiv>
                        </ProductLink>
                        <ProductLink to={`/category/personal-care/9`} >
                            <CarouselDiv>
                                <Image src={`${process.env.PUBLIC_URL}/images/home/Soap.jpg`} />
                                <ProductName>SOAP</ProductName>
                            </CarouselDiv>
                        </ProductLink>
                        <ProductLink to={`/brand-products/milk-and-meadows-1/98`} >
                            <CarouselDiv>
                                <Image src={`${process.env.PUBLIC_URL}/images/home/Spices.jpg`} />
                                <ProductName>SPICES</ProductName>
                            </CarouselDiv>
                        </ProductLink>
                        <ProductLink to={`/category/tea-accessories/24`} >
                            <CarouselDiv>
                                <Image src={`${process.env.PUBLIC_URL}/images/home/tea-accessory.jpg`} />
                                <ProductName>TEA ACCESSORIES</ProductName>
                            </CarouselDiv>
                        </ProductLink>
                        <ProductLink to={`/category/wallets/98`} >
                            <CarouselDiv>
                                <Image src={`${process.env.PUBLIC_URL}/images/home/wallets.jpg`} />
                                <ProductName>WALLETS</ProductName>
                            </CarouselDiv>
                        </ProductLink>
                    </Carousel>
                </Row>
                {
                    showOnHomePageBrandSectionState.apiState === "loading" &&
                    <>
                        {
                            <HomePageCarosuelSkeleton />
                        }
                    </>
                }
                {(showOnHomePageBrandSectionState.apiState === "success" && showOnHomePageBrandSectionState.data.products.items.length > 0) &&
                    <Row>
                        <TitleContainer>
                            {/* <HorizontalLineBlock>
                                <HLine></HLine>
                                <Title>BRANDS AT LADU</Title>
                                <HLine></HLine>
                            </HorizontalLineBlock> */}
                            <Title>BRANDS AT LADU</Title>
                            <TitleLink to={`/brands`}>VIEW ALL <RightOutlined /></TitleLink>
                        </TitleContainer>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className="caro"
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            style={{ padding: "0px 10px", }}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={isMobile ? 1 : 3}
                            swipeable
                        >
                            {showOnHomePageBrandSectionState.data.products.items.map((items) => (
                                <>
                                    {
                                        items.is_brand === 1 ?
                                            <ProductLink to={`/brand/${items.url_key}`} >
                                                <CarouselDiv>
                                                    <Image src={`${items.small_image.url}`} />
                                                    <ProductName>{items.name}</ProductName>
                                                </CarouselDiv>
                                            </ProductLink>
                                            :
                                            <ProductLink to={`/product-detail/${items.url_key}`} >
                                                <CarouselDiv>
                                                    <Image src={`${items.small_image.url}`} />
                                                    <ProductName>{items.name}</ProductName>
                                                </CarouselDiv>
                                            </ProductLink>
                                    }
                                </>
                            ))
                            }
                        </Carousel>
                    </Row>
                }
                {
                    (showOnHomePageBurniSectionState.apiState === "loading") &&
                    <>
                        {
                            <HomePageCarosuelSkeleton />
                        }
                    </>
                }
                {(showOnHomePageBurniSectionState.apiState === "success" && showOnHomePageBurniSectionState.data.products.items.length > 0) &&
                    <Row>
                        {/* <TitleContainer>
                            <HorizontalLineBlock>
                                <HLine></HLine>
                                <Title>BARNI - JAIPUR LOCAL</Title>
                                <HLine></HLine>
                            </HorizontalLineBlock>
                        </TitleContainer> */}
                        <TitleContainer>
                            <Title>BARNI - JAIPUR LOCAL</Title>
                            <TitleLink to={`/category/barni/156`}>VIEW ALL <RightOutlined /></TitleLink>
                        </TitleContainer>
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className="caro"
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                                desktop: {
                                    breakpoint: {
                                        max: 3000,
                                        min: 1024
                                    },
                                    items: 3,
                                    partialVisibilityGutter: 40
                                },
                                mobile: {
                                    breakpoint: {
                                        max: 464,
                                        min: 0
                                    },
                                    items: 1,
                                    partialVisibilityGutter: 30
                                },
                                tablet: {
                                    breakpoint: {
                                        max: 1024,
                                        min: 464
                                    },
                                    items: 2,
                                    partialVisibilityGutter: 30
                                }
                            }}
                            style={{ padding: "0px 10px", height: "100%" }}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={isMobile ? 1 : 3}
                            swipeable
                        >
                            {showOnHomePageBurniSectionState.data.products.items.map((items) => (
                                <>
                                    {
                                        items.is_brand === 1 ?
                                            <ProductLink to={`/brand/${items.url_key}`} >
                                                <CarouselDiv>
                                                    <Image src={`${items.small_image.url}`} />
                                                    <ProductName>{items.name}</ProductName>
                                                </CarouselDiv>
                                            </ProductLink>
                                            :
                                            <ProductLink to={`/product-detail/${items.url_key}`} >
                                                <CarouselDiv>
                                                    <Image src={`${items.small_image.url}`} />
                                                    <ProductName>{items.name}</ProductName>
                                                </CarouselDiv>
                                            </ProductLink>
                                    }
                                </>
                            ))
                            }
                        </Carousel>
                    </Row>
                }

                {(renderList === "loading") &&
                    <Loader height={60} width={80} />
                }
                {
                    (renderList === "success" &&
                        <>
                            {featuredImageData.length > 0 &&
                                <>
                                    <LifestyleContainer>
                                        <TitleContainer>
                                            <HorizontalLineBlock>
                                                <HLine></HLine>
                                                <Title>LIFESTYLE</Title>
                                                <HLine></HLine>
                                            </HorizontalLineBlock>
                                        </TitleContainer>
                                        <InfiniteScroll
                                            dataLength={featuredImageData.length}
                                            scrollThreshold={0.7}
                                            next={() => {
                                                setCurrentPage(currentPage + 1)
                                            }
                                            }
                                            hasMore={hasMore}
                                        >
                                            {(renderList === "success" && featuredImageData.length > 0) &&
                                                <Container >
                                                    <div id="columns">
                                                        {featuredImageData.map((item) => {
                                                            return (
                                                                <>
                                                                    <FigureCard>
                                                                        {item.is_brand === 1 ?
                                                                            <ProductLink
                                                                                to={`/brand/${item.url_key}`}>
                                                                                <LazyLoadImage
                                                                                    src={item.small_image.url}
                                                                                    effect="blur"
                                                                                />
                                                                            </ProductLink>
                                                                            :
                                                                            <ProductLink to={`/product-detail/${item.url_key}`}>
                                                                                <LazyLoadImage
                                                                                    src={item.small_image.url}
                                                                                    effect="blur"
                                                                                />
                                                                            </ProductLink>
                                                                        }
                                                                    </FigureCard>

                                                                </>
                                                            )
                                                        })}
                                                    </div>

                                                </Container>
                                            }
                                        </InfiniteScroll>
                                        <div style={{ paddingTop: 10, display: "flex", justifyContent: "center" }} >
                                            {getFeaturedImageListState.apiState === "loading" &&
                                                <Loader />
                                            }
                                        </div>
                                    </LifestyleContainer>
                                </>
                            }
                        </>
                    )}
                <div style={{ padding: "42px 22px 16px 22px" }} >
                    <HomePageFooter />
                </div>
            </HomeWrapper>
            <Footer isShop history={history} />
        </>
    )
}

const mapStateToProps = (state) => ({
    searchTextState: state.searchText,
    getFeaturedImageListState: state.getFeaturedImageList,
    showOnHomePageBurniSectionState: state.showOnHomePageBurniSection,
    showOnHomePageBrandSectionState: state.showOnHomePageBrandSection,
});

const mapDispatchToProps = (dispatch) => ({
    getFeaturedImageList: (params) => dispatch(getFeaturedImageList(params)),
    getFeaturedImageListReset: () => dispatch(getFeaturedImageListReset()),
    showOnHomePageBurniSection: (params) => dispatch(showOnHomePageBurniSection(params)),
    showOnHomePageBurniSectionReset: () => dispatch(showOnHomePageBurniSectionReset()),
    showOnHomePageBrandSection: (params) => dispatch(showOnHomePageBrandSection(params)),
    showOnHomePageBrandSectionReset: () => dispatch(showOnHomePageBrandSectionReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);
