export const REMOVE_ITEM_CART = 'REMOVE_ITEM_CART'
export const REMOVE_ITEM_CART_SUCCESS = 'REMOVE_ITEM_CART_SUCCESS'
export const REMOVE_ITEM_CART_FAIL = 'REMOVE_ITEM_CART_FAIL'
export const REMOVE_ITEM_CART_RESET = 'REMOVE_ITEM_CART_RESET'

export const removeItemCart = (params) => {
    return { type: REMOVE_ITEM_CART, params }
}

export const removeItemCartSuccess = (response) => {
    return { type: REMOVE_ITEM_CART_SUCCESS, response }
}

export const removeItemCartFail = (response) => {
    return { type: REMOVE_ITEM_CART_FAIL, response }
}

export const removeItemCartReset = () => {
    return { type: REMOVE_ITEM_CART_RESET }
}
