import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    CREATE_CUSTOMER,
    createCustomerSuccess,
    createCustomerFail,
} from '../actions/createCustomerAction'

import createCustomerApi from '../apis/createCustomerApi'


export function* createCustomerSaga(action) {
    try {
        const response = yield call(() => createCustomerApi(action.params))

        if (response.errors) {
            yield put(createCustomerFail(response, action))
        } else {
            yield put(createCustomerSuccess(response, action))
        }
    } catch (e) {
        yield put(createCustomerFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(CREATE_CUSTOMER, createCustomerSaga);
}