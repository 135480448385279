import axios from "axios";
import { isAuthorized,BASE_URL } from "../utils";

const fetchAddressApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
        customer {
          addresses {
            id
            firstname
            lastname
            street
            city
            region {
              region_code
              region
              region_id
            }
            postcode
            country_code
            telephone
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchAddressApi;
