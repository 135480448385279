export const FETCH_SEARCH_DATA = 'FETCH_SEARCH_DATA'
export const FETCH_SEARCH_DATA_SUCCESS = 'FETCH_SEARCH_DATA_SUCCESS'
export const FETCH_SEARCH_DATA_FAIL = 'FETCH_SEARCH_DATA_FAIL'
export const FETCH_SEARCH_DATA_RESET = 'FETCH_SEARCH_DATA_RESET'

export const fetchSearchData = (params) => {
    return { type: FETCH_SEARCH_DATA, params }
}

export const fetchSearchDataSuccess = (response) => {
    return { type: FETCH_SEARCH_DATA_SUCCESS, response }
}

export const fetchSearchDataFail = (response) => {
    return { type: FETCH_SEARCH_DATA_FAIL, response }
}
export const fetchSearchDataReset = () => {
    return { type: FETCH_SEARCH_DATA_RESET }
}

