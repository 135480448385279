export const GUEST_APPLY_COUPON_TO_CART = "GUEST_APPLY_COUPON_TO_CART"
export const GUEST_APPLY_COUPON_TO_CART_SUCCESS = "GUEST_APPLY_COUPON_TO_CART_SUCCESS"
export const GUEST_APPLY_COUPON_TO_CART_FAIL = "GUEST_APPLY_COUPON_TO_CART_FAIL"
export const GUEST_APPLY_COUPON_TO_CART_RESET = "GUEST_APPLY_COUPON_TO_CART_RESET"

export const guestApplyCouponToCart = (params) => {
    return { type: GUEST_APPLY_COUPON_TO_CART, params }
}
export const guestApplyCouponToCartSuccess = (response) => {
    return { type: GUEST_APPLY_COUPON_TO_CART_SUCCESS, response }
}
export const guestApplyCouponToCartFail = (response) => {
    return { type: GUEST_APPLY_COUPON_TO_CART_FAIL, response }
}
export const guestApplyCouponToCartReset = () => {
    return { type: GUEST_APPLY_COUPON_TO_CART_RESET }
}