import React, { useEffect, useState } from "react"
import { Avatar, Button, Skeleton, Space, Modal, Popconfirm, notification, Tag } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { connect } from "react-redux"
import { EditOutlined } from "@ant-design/icons"

// custom components
import ProfileTabs from '../../components/ProfileTabs/ProfileTabs'
import { Container, Left, Right, AddressContent, AddressSkelaton, AddressContentSkelaton, EditWrapper, EditButton, DisplayText } from "./MyProfileStyle"
import ProfileForm from "../../components/ProfileForm/ProfileForm"
import ChangePasswordForm from "../../components/ChangePasswordForm/ChangePasswordForm"
import NewAddressForm from "../../components/NewAddressForm/NewAddressForm"
import UpdateCustomerAddress from '../../components/UpdateCustomerAddress/UpdateCustomerAddress'
import NoDataContianer from "../../components/NoDataContainer/NoData";
import { DynamicSkeleton } from '../../components/Xcomponent'

// actions
import { getCustomerInfo } from '../../actions/getCustomerInfoAction'
import { deleteCustomerAddress, deleteCustomerAddressReset } from '../../actions/deleteCustomerAddressAction'
import { updateCustomerAddress, updateCustomerAddressReset } from '../../actions/updateCustomerAddressAction'
// others
import { getCustomerToken, inr } from "../../utils"
import _ from "lodash"

const MyProfile = (props) => {


    // variables
    const {
        getCustomerInfo, getCustomerInfoState,
        deleteCustomerAddress, deleteCustomerAddressReset, deleteCustomerAddressState,
        updateCustomerAddressState, updateCustomerAddressReset
    } = props
    const [profileVisible, setProfileVisible] = useState(false)
    const [updateAddress, setUpdateAddress] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [addressVisible, setAddressVisible] = useState(false)
    const [refresh, setRefresh] = useState(0)
    const [editForm, setEditForm] = useState()

    // callbacks
    useEffect(() => {
        getCustomerInfo({
            customerToken: getCustomerToken(),
            currentPage: 1,
        })
    }, [refresh])
    useEffect(() => {
        if (updateCustomerAddressState.apiState === "success") {
            setUpdateAddress(false)
            setRefresh(refresh + 1)
        }
    }, [updateCustomerAddressState])

    useEffect(() => {
        if (deleteCustomerAddressState.apiState === "success") {
            notification["success"]({
                message: 'Address has been removed successfully!',
                placement: "bottomRight"
            });
            deleteCustomerAddressReset()
            setRefresh(refresh + 1)
        }

        if (deleteCustomerAddressState.apiState === "error") {
            notification["error"]({
                message: "Default address can't be removed.",
                placement: "bottomRight"
            });
            deleteCustomerAddressReset()
        }
    }, [deleteCustomerAddressState])
    useEffect(() => {


    }, [getCustomerInfoState])

    // Functions
    const setEditFormData = (id) => {
        let data = _.find(getCustomerInfoState.data.customer.addresses, { id: id })
        setEditForm(data)
        setUpdateAddress(true)
    }

    return (
        <Container>
            <Left>
                <ProfileTabs active="my-profile" />
            </Left>
            <Right>
                {getCustomerInfoState.apiState === "loading" &&
                    <>
                        <Skeleton active />
                        <Skeleton active />
                        <AddressContentSkelaton >
                            <AddressSkelaton active />
                            <AddressSkelaton active />
                            <AddressSkelaton active />
                        </AddressContentSkelaton>

                    </>
                }
                {getCustomerInfoState.apiState === "success" &&
                    <>
                        <div style={{
                            background: "#f5f5f5",
                            padding: 16,
                            borderRadius: 5
                        }} >
                            <div style={{ display: "flex", marginBottom: 5 }} >
                                <div>
                                    <Avatar size={64} icon={<UserOutlined />} />
                                </div>
                                <div style={{ marginLeft: 16 }} >
                                    <span style={{ fontWeight: 600 }} >{`${getCustomerInfoState.data.customer.firstname} ${getCustomerInfoState.data.customer.lastname}`}</span><br />
                                    <span>{getCustomerInfoState.data.customer.email}</span><br />
                                    <span>{getCustomerInfoState.data.customer.gender === 1 ? "Male" : getCustomerInfoState.data.customer.gender === 2 ? "Female" : ""}</span>
                                </div>
                            </div>
                            <EditWrapper>
                                {/* <Space > */}
                                <Button style={{ marginBottom: 5, textTransform: 'capitalize' }} type="dashed" onClick={() => setProfileVisible(true)} >edit profile</Button>
                                <Button style={{ marginBottom: 5, marginLeft: 10, textTransform: 'capitalize' }} type="dashed" onClick={() => setPasswordVisible(true)} >change password</Button>
                                {/* </Space> */}
                            </EditWrapper>
                        </div>
                        <Modal
                            title="Edit Profile"
                            visible={profileVisible}
                            centered
                            footer={null}
                            onCancel={() => setProfileVisible(false)}
                        >
                            <ProfileForm closeModal={() => {
                                setProfileVisible(false)
                                setRefresh(refresh + 1)
                            }} />
                        </Modal>
                        <Modal
                            title="Change Password"
                            visible={passwordVisible}
                            centered
                            footer={null}
                            onCancel={() => setPasswordVisible(false)}
                        >
                            <ChangePasswordForm closeModal={() => {
                                setPasswordVisible(false)
                                setRefresh(refresh + 1)
                            }} />
                        </Modal>

                        <div style={{
                            background: "#f5f5f5",
                            padding: 16,
                            borderRadius: 5,
                            marginTop: 16,
                            display: "flex",
                            flexFlow: "wrap",
                            flexDirection: "column"
                        }} >
                            <div style={{
                                display: 'flex',
                                justifyContent: "flex-end",

                            }} >
                                <Button type="dashed" onClick={() => setAddressVisible(true)} >Add New Address</Button>
                                <Modal
                                    title="Add New Address"
                                    visible={addressVisible}
                                    centered
                                    footer={null}
                                    onCancel={() => setAddressVisible(false)}
                                >
                                    <NewAddressForm closeModal={() => {
                                        setAddressVisible(false)
                                        setRefresh(refresh + 1)
                                    }} />
                                </Modal>
                            </div>
                            <div style={{
                                display: "flex",
                                flexFlow: "wrap",
                                justifyContent: 'flex-start'
                            }} >
                                {getCustomerInfoState.data.customer.addresses.map(item => {
                                    return (
                                        // <DynamicSkeleton active h="300px" >
                                        <AddressContent>
                                            <EditButton type="dashed" size="small" onClick={() =>
                                                [
                                                    setEditFormData(item.id),
                                                    // setRefresh(refresh + 1)
                                                ]
                                            } ><EditOutlined /></EditButton>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }} >

                                            </div>
                                            <DisplayText style={{ fontWeight: 600, marginTop: 10, textTransform: "capitalize" }} >{`${item.firstname} ${item.lastname}`}</DisplayText>

                                            <DisplayText> {item.street[0]}, {item.city}, {item.region.region} </DisplayText>
                                            <DisplayText>Pincode : {item.postcode}</DisplayText>
                                            <DisplayText>Mobile : +91-{item.telephone}</DisplayText>
                                            {/* <span style={{ width: '100%', float: 'left', marginBottom: '5px' }} >
                                                {item.default_billing &&
                                                    <Tag color="gold" style={{ marginBottom: '2px' }} >Billing</Tag>
                                                }

                                                {item.default_shipping &&
                                                    <Tag color="gold" style={{ marginBottom: '2px' }} >Shipping</Tag>
                                                }</span> */}

                                            <Popconfirm
                                                title="Are you sure you want to remove this address?"
                                                okText="Remove"
                                                cancelText="Cancel"
                                                onConfirm={() => {
                                                    deleteCustomerAddress({
                                                        id: item.id,
                                                        customerToken: getCustomerToken(),
                                                    })
                                                }}
                                            >
                                                <Button type="dashed" style={{ marginBottom: 10 }} size="small">Remove</Button>
                                            </Popconfirm>
                                        </AddressContent>
                                        // </DynamicSkeleton>

                                    )
                                })}
                                <Modal
                                    title="Update Address"
                                    visible={updateAddress}
                                    centered
                                    footer={null}
                                    onCancel={() => setUpdateAddress(false)}
                                >
                                    <UpdateCustomerAddress refresh={refresh} editForm={editForm} closeModal={() => {
                                        setUpdateAddress(false)
                                        setRefresh(refresh + 1)
                                    }} />
                                </Modal>
                            </div>
                        </div>
                    </>
                }
            </Right >
        </Container >
    );
};

const mapStateToProps = (state) => ({
    getCustomerInfoState: state.getCustomerInfo,
    deleteCustomerAddressState: state.deleteCustomerAddress,
    updateCustomerAddressState: state.updateCustomerAddress
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerInfo: (params) => dispatch(getCustomerInfo(params)),
    deleteCustomerAddress: (params) => dispatch(deleteCustomerAddress(params)),
    deleteCustomerAddressReset: () => dispatch(deleteCustomerAddressReset()),
    updateCustomerAddress: (params) => dispatch(updateCustomerAddress(params)),
    updateCustomerAddressReset: () => dispatch(updateCustomerAddressReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);