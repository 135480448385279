import {
    UPDATE_CUSTOMER_INFO,
    UPDATE_CUSTOMER_INFO_SUCCESS,
    UPDATE_CUSTOMER_INFO_FAIL,
    UPDATE_CUSTOMER_INFO_RESET,
} from '../actions/updateCustomerInfoAction'


const initialState = {
    apiState: "", // loading, success, error
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CUSTOMER_INFO:
            return {
                ...state,
                apiState: "loading",
            }

        case UPDATE_CUSTOMER_INFO_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case UPDATE_CUSTOMER_INFO_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.errors[0] && action.response.errors[0].message || "Somthing is not right"
            }

        case UPDATE_CUSTOMER_INFO_RESET:
            return initialState


        default:
            return state
    }
}
