import axios from "axios";
import { BASE_URL } from "../utils";

const getBrandDetailsApi = async (params) => {
    let cancelToken
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        cancelToken: cancelToken.token,
        data: {
            query: `
                {
                    products(filter: {url_key: {eq: "${params.brand_url_key}"}, is_brand: {eq: "1"}}) {
                        total_count
                        items {
                        name
                        brand_name
                        description {
                            html
                        }
                        media_gallery {
                            disabled
                            position
                            url
                        }
                        }
                    }
                }
            `,
        }
    });
    return response.data;
};

export default getBrandDetailsApi;
