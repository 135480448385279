import {
    GET_FEATURED_IMAGE_LIST,
    GET_FEATURED_IMAGE_LIST_SUCCESS,
    GET_FEATURED_IMAGE_LIST_FAIL,
    GET_FEATURED_IMAGE_LIST_RESET
} from '../actions/getFeaturedImageListAction'


const initialState = {
    apiState: "", // loading, success, error
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_FEATURED_IMAGE_LIST:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_FEATURED_IMAGE_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }

        case GET_FEATURED_IMAGE_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_FEATURED_IMAGE_LIST_RESET:
            return initialState


        default:
            return state
    }
}
