export const SET_BILLING_ADDRESSS_REQUEST = 'SET_BILLING_ADDRESSS_REQUEST'
export const SET_BILLING_ADDRESSS_SUCCESS = 'SET_BILLING_ADDRESSS_SUCCESS'
export const SET_BILLING_ADDRESSS_FAIL = 'SET_BILLING_ADDRESSS_FAIL'

export const setBillingAddressRequest = (params) => {
    return { type: SET_BILLING_ADDRESSS_REQUEST, params }
}

export const setBillingAddressSuccess = (response) => {
    return { type: SET_BILLING_ADDRESSS_SUCCESS, response }
}

export const setBillingAddressFail = (response) => {
    return { type: SET_BILLING_ADDRESSS_FAIL, response }
}

