import axios from 'axios'

import { isAuthorized,BASE_URL } from '../utils'

const deleteWishListApi = async (params) => {
    const response = await axios({
        method: 'post',
        url: `${BASE_URL()}`,
        data: {
            query: `mutation {
            removeProductsFromWishlist(
            wishlistId: 0
            wishlistItemsIds: [
                ${params.id}
            ]){
                wishlist {
                id
                items_count
                }
                user_errors {
                code
                message
                }
            }
            }
            `

        },
        headers: isAuthorized(),
    })
    return response.data
}

export default deleteWishListApi;