import axios from 'axios';
import { BASE_URL } from '../utils';

const setGuestEmailCartApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
            mutation {
                setGuestEmailOnCart(
                    input: {
                    cart_id: "${params.cartId}"
                    email: "${params.email}"
                    }
                ) {
                    cart {
                    email
                    }
                }
                }`,
        },
    });
    return response.data;
}

export default setGuestEmailCartApi
