export const PLACE_RAZORPAY_ORDER = 'PLACE_RAZORPAY_ORDER'
export const PLACE_RAZORPAY_ORDER_SUCCESS = 'PLACE_RAZORPAY_ORDER_SUCCESS'
export const PLACE_RAZORPAY_ORDER_FAIL = 'PLACE_RAZORPAY_ORDER_FAIL'
export const PLACE_RAZORPAY_ORDER_RESET = 'PLACE_RAZORPAY_ORDER_RESET'

export const placeRazorpayOrder = (params) => {
    return { type: PLACE_RAZORPAY_ORDER, params }
}

export const placeRazorpayOrderSuccess = (response) => {
    return { type: PLACE_RAZORPAY_ORDER_SUCCESS, response }
}

export const placeRazorpayOrderFail = (response) => {
    return { type: PLACE_RAZORPAY_ORDER_FAIL, response }
}
export const placeRazorpayOrderReset = () => {
    return { type: PLACE_RAZORPAY_ORDER_RESET }
}