import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_ADDRESS_REQUEST,
  addAddressSuccess,
  addAddressFail,
} from "../actions/addAddressAction";
import { fetchAddressRequest } from '../actions/fetchAddressAction';
import addAddressApi from "../apis/addAddressApi";
import get from 'lodash/get';


function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(otherParams);
  }
}

export function* addAddressSaga(action) {

  try {
    const response = yield call(() => addAddressApi(action));
    if (response.errors) {
      yield put(addAddressFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(addAddressSuccess(response, action));
      yield common(action, true, response);
      yield put(fetchAddressRequest());

    }
  } catch (e) {
    yield put(addAddressFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(ADD_ADDRESS_REQUEST, addAddressSaga);
}
