import axios from 'axios'
import { BASE_URL } from '../utils';

const guestApplyCouponToCartApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        applyCouponToCart(input: {cart_id: "${params.cart_id}", coupon_code: "${params.coupon}"}) {
                            cart {
                                applied_coupons {
                                    code
                                }
                            }
                        }
                    }`
        }
    });
    return response
}

export default guestApplyCouponToCartApi
