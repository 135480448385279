import axios from "axios";
import { isAuthorized,BASE_URL } from "../utils";

const fetchHelpCmsApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
        cmsPage(identifier: "help") {
          identifier
          url_key
          title
          content
          content_heading
          page_layout
          meta_title
          meta_description
          meta_keywords
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchHelpCmsApi;
