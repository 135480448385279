import {
    ADD_COUPON_REQUEST,
    ADD_COUPON_SUCCESS,
    ADD_COUPON_FAIL,
  } from "../actions/addCouponAction";
  import get from "lodash/get";


  const initialState = {
    addCouponData: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case ADD_COUPON_REQUEST:
        return {
          ...state,
        };
  
      case ADD_COUPON_FAIL:
        return {
          ...state,
        };
  
      case ADD_COUPON_SUCCESS:
        return {
          ...state,
        //  addCouponData: get(action, "response.data.country.available_regions"),
        };
  
      default:
        return state;
    }
  }
  