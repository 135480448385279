import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, Link, useHistory } from 'react-router-dom'
import { notification, Popconfirm, Button, Modal } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

// custom component
import {
    Container, CardWrapper, CardBlock, CartSummery, ImgBlock,
    ProductPrice, ProductName, CartSummeryBlock,
    QuantityContainer, SubTotal,
    Shipping, Total, OrderSummary, CheckoutButton, HorizontalLine, ArrowContainer,
    PinCodeInput, PinCodeButton, DisCount, ProductInfo, CardImgWrapper, MobileProductInfo,
    MobileCardBlock, MobileCardImgWrapper, MobileImgBlock, MobileProductName, MobileProductPrice,
    MobileQuantityContainer, DeleteItem, CustomizeableContent, MobileCustomizeableContent
} from './GuestPaymentStyle'
import NoDataContianer from '../../components/NoDataContainer/NoData'
import GuestPaymentSkeleton from '../../components/GuestPaymentSkeleton/GuestPaymentSkeleton'

// others
import { getCartId, RAZORPAY_API_KEY } from '../../utils'

// action
import { fetchGuestCartData, fetchGuestCartDataReset } from '../../actions/fetchGuestCartDataAction'
import { guestRemoveCartProduct, guestRemoveCartProductReset } from '../../actions/guestRemoveCartProductAction'
import { guestApplyCouponToCart, guestApplyCouponToCartReset } from '../../actions/guestApplyCouponToCartAction'
import { guestRemoveCouponToCart, guestRemoveCouponToCartReset } from '../../actions/guestRemoveCouponToCartAction'
import { guestSetPaymentMethod, guestSetPaymentMethodReset } from '../../actions/guestSetPaymentMehtodAction'
import { guestSalesPlaceOrder, guestSalesPlaceOrderReset } from '../../actions/guestSalesPlaceOrderAction'
import { placeRazorpayOrder, placeRazorpayOrderReset } from '../../actions/placeRazorpayOrderAction';
import { setRzpPaymentDetailsOnCart, setRzpPaymentDetailsOnCartReset } from '../../actions/setRzpPaymentDetailsOnCartAction';


const GuestPayment = (props) => {
    // variables
    const {
        fetchGuestCartData, fetchGuestCartDataState, fetchGuestCartDataReset,
        guestRemoveCartProduct, guestRemoveCartProductState, guestRemoveCartProductReset,
        guestApplyCouponToCart, guestApplyCouponToCartState, guestApplyCouponToCartReset,
        guestRemoveCouponToCart, guestRemoveCouponToCartState, guestRemoveCouponToCartReset,
        guestSetPaymentMethod, guestSetPaymentMethodState, guestSetPaymentMethodReset,
        guestSalesPlaceOrder, guestSalesPlaceOrderState, guestSalesPlaceOrderReset,
        placeRazorpayOrder, placeRazorpayOrderState, placeRazorpayOrderReset,
        setRzpPaymentDetailsOnCart, setRzpPaymentDetailsOnCartState, setRzpPaymentDetailsOnCartReset
    } = props

    const cart_id = getCartId()
    const [refresh, setRefresh] = useState(0)
    const [redirect, setRedirect] = useState([false, ""])
    const isMobile = window.innerWidth < 768
    const [btnLoading, setBtnLoading] = useState(false)
    const [coupon, setCoupon] = useState(null);
    const [displayScreenLock, setDisplayScreenLock] = useState(false)
    const [contactAdmin, setContactAdmin] = useState(false)
    const [productName, setProductName] = useState(null)

    // callbacks


    useEffect(() => {
        if (cart_id) {
            fetchGuestCartData({
                cart_id: cart_id
            })
        }
        else {
            setRedirect([true, "/"])
        }
    }, [refresh])
    useEffect(() => {
        return (() => {
            fetchGuestCartDataReset()
            guestRemoveCartProductReset()
            guestApplyCouponToCartReset()
            guestRemoveCouponToCartReset()
            guestSetPaymentMethodReset()
            guestSalesPlaceOrderReset()
            placeRazorpayOrderReset()
            setRzpPaymentDetailsOnCartReset()
        })
    }, [])


    useEffect(() => {
        if (guestRemoveCartProductState.apiState === "success") {
            notification["success"]({
                message: `${productName} was removed from your cart`,
                placement: "topRight"
            })
            setRefresh(refresh + 1)
            guestRemoveCartProductReset()
        }
        if (guestRemoveCartProductState.apiState === "error") {
            notification["error"]({
                message: 'An error occurred during processing. Please try again.',
            });
            guestApplyCouponToCartReset()
        }
    }, [guestRemoveCartProductState])
    useEffect(() => {
        if (guestApplyCouponToCartState.apiState === "success") {
            notification["success"]({
                message: 'Coupon applied ',
            });
            setRefresh(refresh + 1)
            guestApplyCouponToCartReset()
        }
        if (guestApplyCouponToCartState.apiState === "error") {
            notification["error"]({
                message: 'Sorry, this coupon is not valid.',
            });
            guestApplyCouponToCartReset()
        }
    }, [guestApplyCouponToCartState])

    useEffect(() => {
        if (guestRemoveCouponToCartState.apiState === "success") {
            notification["success"]({
                message: 'Coupon removed ',
            });
            setRefresh(refresh + 1)
            guestRemoveCouponToCartReset()
        }
    }, [guestRemoveCouponToCartState])
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    useEffect(() => {
        if (guestSetPaymentMethodState.apiState === "success") {
            // guestSalesPlaceOrder({
            //     cart_id: cart_id
            // })
            placeRazorpayOrder({
                cart_id: cart_id,
            })
            guestSetPaymentMethodReset()
        }
        if (guestSetPaymentMethodState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
            });
            guestSetPaymentMethodReset()
        }
    }, [guestSetPaymentMethodState])

    useEffect(() => {
        if (placeRazorpayOrderState.apiState === "success") {
            displayRazorpay(placeRazorpayOrderState.data.placeRazorpayOrder.rzp_order_id)
            setBtnLoading(false)
        }
        if (placeRazorpayOrderState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
            });
        }
    }, [placeRazorpayOrderState])

    useEffect(() => {
        if (guestSalesPlaceOrderState.apiState === "success") {
            if (guestSalesPlaceOrderState.data.data.placeOrder === null) {
                return ""
            }
            localStorage.removeItem("cartId")
            setRedirect([true, "/thanks"])
        }
        if (guestSalesPlaceOrderState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
            });
        }
    }, [guestSalesPlaceOrderState])

    useEffect(() => {
        if (setRzpPaymentDetailsOnCartState.apiState === "success") {
            guestSalesPlaceOrder({
                cart_id: cart_id,
            })
        }
        if (setRzpPaymentDetailsOnCartState.apiState === "error") {
            setContactAdmin(true)
        }


    }, [setRzpPaymentDetailsOnCartState])


    async function displayRazorpay(orderId) {

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // const result = await axios({
        //     method: 'post',
        //     url: `https://staging.laduindia.com/rest/default/V1/rzpay/first-api/`,
        //     data: {
        //         order_id: orderId
        //     },
        //     headers: {
        //         "Content-type": "application/json; charset=UTF-8",
        //         "authorization": `Bearer uchz0at3uos0eagb4ngmmnespkermn3g`
        //     }
        // })


        // if (!result) {
        //     alert("Server error. Are you online?");
        //     return;
        // }


        const rzpData = placeRazorpayOrderState.data.placeRazorpayOrder

        const options = {
            key: RAZORPAY_API_KEY(), // Enter the Key ID generated from the Dashboard
            amount: rzpData.amount.toString(),
            currency: "INR",
            name: "",
            description: "",
            image: `${process.env.PUBLIC_URL}/images/Logo.svg`,
            order_id: rzpData.rzp_order_id,
            handler: async function (response) {
                // setRedirect([true, "/thanks"])
                // localStorage.removeItem("cartId")

                setDisplayScreenLock(true)
                setRzpPaymentDetailsOnCart({
                    cart_id: cart_id,
                    rzp_payment_id: response.razorpay_payment_id,
                    rzp_order_id: response.razorpay_order_id,
                    rzp_signature: response.razorpay_signature,
                })
            },
            prefill: {
                name: `${fetchGuestCartDataState.data.billing_address.firstname} " " ${fetchGuestCartDataState.data.billing_address.lastname}`,
                email: fetchGuestCartDataState.data.email,
                contact: fetchGuestCartDataState.data.billing_address.telephone,
            },
            notes: {
                order_quote_id: rzpData.order_quote_id,
            },
            theme: {
                color: "#61dafb",
            },
            // modal: {
            //     ondismiss: function () {
            //         setRedirect([true, "/"])
            //     }
            // }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }


    // fucntion
    let prevPath = useHistory()

    const goToPreviousPath = () => {
        prevPath.goBack()
    }

    const handleRemoveProduct = (id, name) => {
        setProductName(name)
        guestRemoveCartProduct({
            cart_id: cart_id,
            cart_item_id: id,
        })
    }

    return (
        <div style={{ width: '100%' }} >
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <ArrowContainer onClick={goToPreviousPath}>
                <img
                    src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                    alt="ladu-india"
                    width="36"
                />
            </ArrowContainer>

            <Container>
                {
                    fetchGuestCartDataState.apiState === "loading" &&
                    <div style={{ width: '100%' }} >
                        <GuestPaymentSkeleton />
                    </div>
                }

                {
                    fetchGuestCartDataState.apiState === "success" &&
                    <>
                        <Modal title="Basic Modal" visible={displayScreenLock} centered footer={null} closable={false} title={null} >
                            <p>Do not close/refresh the window while we are updating your payment info and placing the order.</p>
                        </Modal>
                        {
                            fetchGuestCartDataState.data.items.length > 0 &&
                            <>
                                <CardWrapper >
                                    {
                                        fetchGuestCartDataState.data.items.map((item) => {
                                            let img = "";
                                            if (item.product.media_gallery[0]) {
                                                img = item.product.media_gallery[0].url
                                            } else {
                                                img = item.product.thumbnail.url
                                            }
                                            return (
                                                <>
                                                    {!isMobile ? (
                                                        <CardBlock>
                                                            <CardImgWrapper>
                                                                {/* <DeleteItem>
                                                                    <Popconfirm
                                                                        title="Are you sure you want to remove this item?"
                                                                        onConfirm={() => handleRemoveProduct(item.id, item.product.name)}
                                                                        okText="Remove"
                                                                        cancelText="Cancel"
                                                                    >
                                                                        <CloseOutlined />
                                                                    </Popconfirm>
                                                                </DeleteItem> */}
                                                                <ImgBlock>
                                                                    <img style={{ width: '100%' }} src={img} />
                                                                </ImgBlock>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                                                    <Link to={`/product-detail/${item.product.url_key}`}>
                                                                        <ProductName>{item.product.name}</ProductName>
                                                                    </Link>
                                                                    <CustomizeableContent>
                                                                        {
                                                                            item.customizable_options &&
                                                                            <>
                                                                                {
                                                                                    item.customizable_options.map((item) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <span>{item.label}</span>:
                                                                                                {item.values[0].label ?
                                                                                                    <span style={{ marginLeft: 5 }} >{item.values[0].label}</span>
                                                                                                    : <span style={{ marginLeft: 5 }} >{item.values[0].value}</span>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        }
                                                                    </CustomizeableContent>
                                                                </div>
                                                            </CardImgWrapper>
                                                            <ProductInfo>
                                                                <ProductPrice>₹ {item.prices.row_total_including_tax && item.prices.row_total_including_tax.value}</ProductPrice>
                                                                <QuantityContainer>
                                                                    <h5 style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0' }} >QTY: {item.quantity}</h5>

                                                                </QuantityContainer>
                                                            </ProductInfo>
                                                        </CardBlock>) : (
                                                        <MobileCardBlock>
                                                            <MobileCardImgWrapper>
                                                                {/* <Popconfirm
                                                                    title="Are you sure you want to remove this item?"
                                                                    onConfirm={() => handleRemoveProduct(item.id, item.product.name)}
                                                                    okText="Remove"
                                                                    cancelText="Cancel"
                                                                >
                                                                    <CloseOutlined />
                                                                </Popconfirm> */}
                                                                <MobileImgBlock>
                                                                    <img style={{ width: '100%' }} src={img} />
                                                                </MobileImgBlock>
                                                            </MobileCardImgWrapper>
                                                            <MobileProductInfo>
                                                                <div style={{ display: 'flex', flexDirection: 'column' }} >
                                                                    <Link to={`/product-detail/${item.product.url_key}`}>
                                                                        <MobileProductName>{item.product.name}</MobileProductName>
                                                                    </Link>
                                                                    <MobileCustomizeableContent>
                                                                        {
                                                                            item.customizable_options &&
                                                                            <>
                                                                                {
                                                                                    item.customizable_options.map((item) => {
                                                                                        return (
                                                                                            <div>
                                                                                                <span>{item.label}</span>:
                                                                                                {item.values[0].label ?
                                                                                                    <span style={{ marginLeft: 5 }} >{item.values[0].label}</span>
                                                                                                    : <span style={{ marginLeft: 5 }} >{item.values[0].value}</span>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        }
                                                                    </MobileCustomizeableContent>
                                                                </div>
                                                                <MobileProductPrice>₹ {item.product.price_range.minimum_price.final_price.value}</MobileProductPrice>
                                                                <MobileQuantityContainer>
                                                                    <h5 style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0' }} >QTY: {item.quantity}</h5>
                                                                </MobileQuantityContainer>
                                                            </MobileProductInfo>
                                                        </MobileCardBlock>
                                                    )
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </CardWrapper>
                                <CartSummery>
                                    <CartSummeryBlock>
                                        <OrderSummary>Order Summary</OrderSummary>
                                        <HorizontalLine></HorizontalLine>
                                        <SubTotal>Sub Total <span>₹ {fetchGuestCartDataState.data.prices.subtotal_including_tax.value}</span></SubTotal>
                                        {
                                            fetchGuestCartDataState.data.shipping_addresses.length > 0 &&
                                            <>
                                                {

                                                    fetchGuestCartDataState.data.shipping_addresses[0].available_shipping_methods.length > 0 &&
                                                    <Shipping>Shipping<span>₹ {fetchGuestCartDataState.data.shipping_addresses[0].available_shipping_methods[0].amount.value}</span></Shipping>
                                                }
                                            </>
                                        }
                                        {
                                            fetchGuestCartDataState.data.prices.discounts === null ? "" :
                                                <DisCount> Discount < span >₹ {fetchGuestCartDataState.data.prices.discounts[0].amount.value}</span></DisCount>
                                        }

                                        < Total > Total < span >₹ {fetchGuestCartDataState.data.prices.grand_total.value}</span></Total>
                                        <HorizontalLine></HorizontalLine>
                                        <CheckoutButton onClick={() => {
                                            setBtnLoading(true)
                                            guestSetPaymentMethod({
                                                cart_id: cart_id,
                                                // customerToken: getCustomerToken()
                                            })
                                        }} loading={btnLoading} >proceed to payment</CheckoutButton>
                                        <Modal
                                            title="Something wenrt wrong :("
                                            visible={contactAdmin}
                                            centered
                                            footer={null}
                                            onCancel={() => setContactAdmin(false)}
                                        >
                                            <p>Some meesage here.</p>
                                        </Modal>
                                        <div style={{ display: "flex", margin: "10px 0px 20px 0px" }}>
                                            {
                                                fetchGuestCartDataState.data.applied_coupons === null ?
                                                    <>
                                                        <PinCodeInput
                                                            placeholder="Coupon Code"
                                                            onChange={(e) => setCoupon(e.target.value)}
                                                        />
                                                        <PinCodeButton onClick={() => {
                                                            guestApplyCouponToCart({
                                                                cart_id: cart_id,
                                                                coupon: coupon,
                                                            })
                                                        }}>Apply</PinCodeButton>
                                                    </> :
                                                    <>
                                                        <PinCodeInput
                                                            disabled
                                                            placeholder="Coupon Code"
                                                            value={fetchGuestCartDataState.data.applied_coupons[0].code}
                                                            onChange={(e) => setCoupon(e.target.value)}
                                                        />
                                                        <PinCodeButton onClick={() => {
                                                            guestRemoveCouponToCart({
                                                                cart_id: cart_id,
                                                            })
                                                        }}>Remove</PinCodeButton>
                                                    </>
                                            }
                                        </div>
                                    </CartSummeryBlock>
                                </CartSummery>
                            </>
                        }

                        <>
                            {fetchGuestCartDataState.data.items.length == 0 &&
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
                                    <NoDataContianer
                                        showText="No Items available in cart."
                                        animationType={"cartAnimation"}
                                    />
                                    <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px', textTransform: 'capitalize' }} ><Button>explore category</Button></Link>
                                </div>
                            }
                        </>
                    </>
                }
            </Container>
        </div>

    )
}

const mapStateToProps = (state) => ({
    fetchGuestCartDataState: state.fetchGuestCartData,
    guestRemoveCartProductState: state.guestRemoveCartProduct,
    guestApplyCouponToCartState: state.guestApplyCouponToCart,
    guestRemoveCouponToCartState: state.guestRemoveCouponToCart,
    guestSetPaymentMethodState: state.guestSetPaymentMethod,
    guestSalesPlaceOrderState: state.guestSalesPlaceOrder,
    placeRazorpayOrderState: state.placeRazorpayOrder,
    setRzpPaymentDetailsOnCartState: state.setRzpPaymentDetailsOnCart,
})
const mapDispatchToProps = (dispatch) => ({
    fetchGuestCartData: (params) => dispatch(fetchGuestCartData(params)),
    fetchGuestCartDataReset: () => dispatch(fetchGuestCartDataReset()),
    guestRemoveCartProduct: (params) => dispatch(guestRemoveCartProduct(params)),
    guestRemoveCartProductReset: () => dispatch(guestRemoveCartProductReset()),
    guestApplyCouponToCart: (params) => dispatch(guestApplyCouponToCart(params)),
    guestApplyCouponToCartReset: () => dispatch(guestApplyCouponToCartReset()),
    guestRemoveCouponToCart: (params) => dispatch(guestRemoveCouponToCart(params)),
    guestRemoveCouponToCartReset: () => dispatch(guestRemoveCouponToCartReset()),
    guestSetPaymentMethod: (params) => dispatch(guestSetPaymentMethod(params)),
    guestSetPaymentMethodReset: () => dispatch(guestSetPaymentMethodReset()),
    guestSalesPlaceOrder: (params) => dispatch(guestSalesPlaceOrder(params)),
    guestSalesPlaceOrderReset: () => dispatch(guestSalesPlaceOrderReset()),
    placeRazorpayOrder: (params) => dispatch(placeRazorpayOrder(params)),
    placeRazorpayOrderReset: () => dispatch(placeRazorpayOrderReset()),
    setRzpPaymentDetailsOnCart: (params) => dispatch(setRzpPaymentDetailsOnCart(params)),
    setRzpPaymentDetailsOnCartReset: () => dispatch(setRzpPaymentDetailsOnCartReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(GuestPayment)
