import {
    GET_CART_COUNT,
    GET_CART_COUNT_SUCCESS,
    GET_CART_COUNT_FAIL,
    GET_CART_COUNT_RESET
} from '../actions/getCartCountAction'


const initialState = {
    apiState: "", // loading, success, error
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CART_COUNT:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_CART_COUNT_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case GET_CART_COUNT_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_CART_COUNT_RESET:
            return initialState


        default:
            return state
    }
}
