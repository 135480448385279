import React, { useEffect, useState } from "react"
import { Link, Redirect } from 'react-router-dom'
import { connect } from "react-redux"
import { useHistory } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { notification, Button, Popconfirm, Modal, message } from 'antd'
import axios from "axios"

// actions
import { getCartData, getCartDataReset } from '../../actions/getCartDataAction'
import { removeItemCart, removeItemCartReset } from '../../actions/removeItemCartAction'
import { applyCouponToCart, applyCouponToCartReset } from '../../actions/applyCouponToCartAction'
import { removeCouponToCart, removeCouponToCartReset } from '../../actions/removeCouponToCartAction'
import { salesPlaceOrder, salesPlaceOrderReset } from '../../actions/salesPlaceOrderAction'
import { setPaymentMethod, setPaymentMethodReset } from '../../actions/setPaymentMethodAction'
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import { placeRazorpayOrder, placeRazorpayOrderReset } from '../../actions/placeRazorpayOrderAction';
import { setRzpPaymentDetailsOnCart, setRzpPaymentDetailsOnCartReset } from '../../actions/setRzpPaymentDetailsOnCartAction';
import { updateProductCart, updateProductCartReset } from "../../actions/updateCartAction";


/* ==================== */
/* custom components */
/* ==================== */
import {
    Container, CardWrapper, CardBlock, CartSummery, ImgBlock,
    ProductPrice, ProductName, CartSummeryBlock,
    QuantityContainer, SubTotal,
    Shipping, Total, OrderSummary, CheckoutButton, HorizontalLine, ArrowContainer,
    PinCodeInput, PinCodeButton, DisCount, ProductInfo, CardImgWrapper, MobileProductInfo,
    MobileCardBlock, MobileCardImgWrapper, MobileImgBlock, MobileProductName, MobileProductPrice,
    MobileQuantityContainer, DeleteItem, CustomizeableContent, MobileCustomizeableContent
} from "./PaymentStyle";
import PaymentSkeleton from '../../components/PaymentSkeleton/PaymentSkeleton'
import NoDataContianer from "../../components/NoDataContainer/NoData";


// others
import { getCartId, getCustomerToken, RAZORPAY_API_KEY } from '../../utils'

const Payment = props => {
    /* ============================ */
    // Variables
    /* ============================ */
    const cartId = getCartId();
    const {
        getCartData, getCartDataState, getCartDataReset,
        getCartCount, getCartCountReset,
        removeItemCart, removeItemCartState, removeItemCartReset,
        applyCouponToCart, applyCouponToCartState, applyCouponToCartReset,
        removeCouponToCart, removeCouponToCartState, removeCouponToCartReset,
        salesPlaceOrder, salesPlaceOrderState, salesPlaceOrderReset,
        setPaymentMethod, setPaymentMethodState, setPaymentMethodReset,
        placeRazorpayOrder, placeRazorpayOrderState, placeRazorpayOrderReset,
        updateProductCart,
        setRzpPaymentDetailsOnCart, setRzpPaymentDetailsOnCartState, setRzpPaymentDetailsOnCartReset
    } = props

    const [refresh, setRefresh] = useState(0);
    const [coupon, setCoupon] = useState(null);
    let previousPath = useHistory()
    const goToPreviousPath = () => {
        previousPath.goBack();
    }
    const [redirect, setRedirect] = useState([false, ""])
    const [btnLoading, setBtnLoading] = useState(false)
    const isMobile = window.innerWidth < 768;
    const [displayScreenLock, setDisplayScreenLock] = useState(false)
    const [contactAdmin, setContactAdmin] = useState(false)
    const [productName, setProductName] = useState(null)
    /* ============================ */
    // Callbacks
    /* ============================ */
    useEffect(() => {
        return (() => {
            setPaymentMethodReset()
            getCartDataReset()
            getCartCountReset()
            salesPlaceOrderReset()
            applyCouponToCartReset()
            placeRazorpayOrderReset()
            setRzpPaymentDetailsOnCartReset()
        })
    }, [])

    useEffect(() => {
        if (cartId) {
            getCartData({ cartId: cartId, })
        } else {
            setRedirect([true, "/"])
        }
        getCartCount({
            cart_id: cartId,
            customerToken: getCustomerToken()
        })
    }, [refresh])

    useEffect(() => {
        if (getCartDataState.apiState === "success") {
            if (getCartDataState.data.cart.shipping_addresses.length === 0) {
                setRedirect([true, "/"])
            }
        }
        if (getCartDataState.apiState === "error") {
            getCartDataState.data.map((item) => {
                if (item !== null) {
                    if (item.product.stock_status === "OUT_OF_STOCK" || item.product.only_x_left_in_stock === null) {
                        let itemId = item.id
                        removeItemCart({
                            cart_id: cartId,
                            cart_item_id: itemId,
                        })
                    }
                    else {
                        if (item.quantity > item.product.only_x_left_in_stock) {
                            let decreseQuantity = item.quantity - item.product.only_x_left_in_stock
                            if (decreseQuantity) {
                                const updatedData = {
                                    cart_id: cartId,
                                    cart_item_id: item.id,
                                    quantity: item.product.only_x_left_in_stock
                                }
                                updateProductCart({
                                    updatedData,
                                    successAction: (data) => {
                                        getCartData({ cartId: cartId })
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }
    }, [getCartDataState])
    useEffect(() => {
        if (removeItemCartState.apiState === "success") {
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken()
            })
            getCartData({ cartId: cartId, })
            setRefresh(refresh + 1)
            notification["success"]({
                message: `${productName} was removed from your cart`,
                placement: "bottomRight"
            })
            removeItemCartReset()
        }
        if (removeItemCartState.apiState === "error") {
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken()
            })
            getCartData({ cartId: cartId, })
            setRefresh(refresh + 1)
            notification["error"]({
                message: `An error occurred during processing. Please try again.`,
                placement: "bottomRight"
            })
            removeItemCartReset()
        }
    }, [removeItemCartState])


    useEffect(() => {
        if (setPaymentMethodState.apiState === "success") {
            placeRazorpayOrder({
                cart_id: cartId,
                customerToken: getCustomerToken()
            })
        }
        if (setPaymentMethodState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
            });
        }
    }, [setPaymentMethodState])

    useEffect(() => {
        if (placeRazorpayOrderState.apiState === "success") {
            displayRazorpay(placeRazorpayOrderState.data.placeRazorpayOrder.rzp_order_id)
            setBtnLoading(false)
        }
        if (placeRazorpayOrderState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
            });
        }
    }, [placeRazorpayOrderState])

    useEffect(() => {
        if (salesPlaceOrderState.apiState === "success") {
            localStorage.removeItem("cartId")
            setRedirect([true, "/thanks"])
        }
        if (salesPlaceOrderState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
            });
        }
    }, [salesPlaceOrderState])

    useEffect(() => {
        if (applyCouponToCartState.apiState === "success") {
            notification["success"]({
                message: 'Coupon applied',
            });
            setRefresh(refresh + 1)
            applyCouponToCartReset()
        }
        if (applyCouponToCartState.apiState === "error") {
            notification["error"]({
                message: 'Sorry, this coupon is not valid.',
            });
            applyCouponToCartReset()
        }
    }, [applyCouponToCartState])

    useEffect(() => {
        if (removeCouponToCartState.apiState === "success") {
            notification["success"]({
                message: 'Coupon removed',
            });
            setRefresh(refresh + 1)
            removeCouponToCartReset()
        }
    }, [removeCouponToCartState])

    useEffect(() => {
        if (setRzpPaymentDetailsOnCartState.apiState === "success") {
            salesPlaceOrder({
                cart_id: cartId,
                customerToken: getCustomerToken()
            })
        }
        if (setRzpPaymentDetailsOnCartState.apiState === "error") {
            setContactAdmin(true)
        }


    }, [setRzpPaymentDetailsOnCartState])

    // funtion
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(orderId) {


        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        // const result = await axios({
        //     method: 'post',
        //     url: `https://staging.laduindia.com/rest/default/V1/rzpay/first-api/`,
        //     data: {
        //         order_id: orderId
        //     },
        //     headers: {
        //         "Content-type": "application/json; charset=UTF-8",
        //         "authorization": `Bearer uchz0at3uos0eagb4ngmmnespkermn3g`
        //     }
        // })


        // if (!result) {
        //     alert("Server error. Are you online?");
        //     return;
        // }
        const rzpData = placeRazorpayOrderState.data.placeRazorpayOrder

        const options = {
            key: RAZORPAY_API_KEY(), // Enter the Key ID generated from the Dashboard
            amount: rzpData.amount.toString(),
            currency: "INR",
            name: "",
            description: "",
            image: `${process.env.PUBLIC_URL}/images/Logo.svg`,
            order_id: rzpData.rzp_order_id,
            handler: async function (response) {
                // setRedirect([true, "/thanks"])
                // localStorage.removeItem("cartId")
                // salesPlaceOrder({
                //     cart_id: cartId,
                //     customerToken: getCustomerToken()
                // })
                setDisplayScreenLock(true)
                setRzpPaymentDetailsOnCart({
                    cart_id: cartId,
                    rzp_payment_id: response.razorpay_payment_id,
                    rzp_order_id: response.razorpay_order_id,
                    rzp_signature: response.razorpay_signature,
                    customerToken: getCustomerToken()
                })
            },
            prefill: {
                name: `${getCartDataState.data.customer.firstname} " " ${getCartDataState.data.customer.lastname}`,
                email: getCartDataState.data.customer.email,
                contact: getCartDataState.data.cart.billing_address.telephone,
            },
            notes: {
                order_quote_id: rzpData.order_quote_id,
            },
            theme: {
                color: "#61dafb",
            },
            // modal: {
            //     ondismiss: function () {
            //         setRedirect([true, "/orders"])
            //     }
            // }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const handleRemoveProduct = (id, name) => {
        setProductName(name)
        removeItemCart({
            cart_id: cartId,
            cart_item_id: id,
        })
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <div style={{ width: "100%" }}>
                <ArrowContainer onClick={goToPreviousPath}>
                    <img

                        src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                        alt="ladu-india"
                        width="36"
                    />
                </ArrowContainer>
                <Container>
                    {
                        getCartDataState.apiState === "loading" &&
                        <div style={{ width: '100%' }} >
                            <PaymentSkeleton />
                        </div>
                    }
                    {
                        getCartDataState.apiState === "success" &&
                        <>
                            <Modal title="Basic Modal" visible={displayScreenLock} centered footer={null} closable={false} title={null} >
                                <p>Do not close/refresh the window while we are updating your payment info and placing the order.</p>
                            </Modal>
                            {getCartDataState.data.cart.items.length > 0 &&
                                <>

                                    <CardWrapper >
                                        {
                                            getCartDataState.data.cart.items.map((item) => {
                                                let img = "";
                                                if (item.product.media_gallery[0]) {
                                                    img = item.product.media_gallery[0].url
                                                } else {
                                                    img = item.product.thumbnail.url
                                                }
                                                return (
                                                    <>
                                                        {!isMobile ? (
                                                            <CardBlock>
                                                                <CardImgWrapper>
                                                                    {/* <DeleteItem>
                                                                        <Popconfirm
                                                                            title="Are you sure you want to remove this item?"
                                                                            // onConfirm={() => {

                                                                            //     removeItemCart({
                                                                            //         cart_id: cartId,
                                                                            //         cart_item_id: item.id,
                                                                            //     })
                                                                            // }}
                                                                            onConfirm={() => handleRemoveProduct(item.id, item.product.name)}
                                                                            okText="Remove"
                                                                            cancelText="Cancel"
                                                                        >
                                                                            <CloseOutlined />
                                                                        </Popconfirm>
                                                                    </DeleteItem> */}
                                                                    <ImgBlock>
                                                                        <img style={{ width: '100%' }} src={img} />
                                                                    </ImgBlock>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                                                                        <Link to={`/product-detail/${item.product.url_key}`}>
                                                                            <ProductName>{item.product.name}</ProductName>
                                                                        </Link>
                                                                        <CustomizeableContent>
                                                                            {
                                                                                item.customizable_options &&
                                                                                <>
                                                                                    {
                                                                                        item.customizable_options.map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <span>{item.label}</span>:
                                                                                                    {item.values[0].label ?
                                                                                                        <span style={{ marginLeft: 5 }} >{item.values[0].label}</span>
                                                                                                        : <span style={{ marginLeft: 5 }} >{item.values[0].value}</span>
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </CustomizeableContent>
                                                                    </div>
                                                                </CardImgWrapper>
                                                                <ProductInfo>
                                                                    <ProductPrice>₹ {item.prices.row_total_including_tax && item.prices.row_total_including_tax.value}</ProductPrice>
                                                                    <QuantityContainer>
                                                                        <h5 style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0' }} >QTY: {item.quantity}</h5>

                                                                    </QuantityContainer>
                                                                </ProductInfo>
                                                            </CardBlock>) : (
                                                            <MobileCardBlock>
                                                                <MobileCardImgWrapper>
                                                                    {/* <Popconfirm
                                                                        title="Are you sure you want to remove this item?"
                                                                        onConfirm={() => handleRemoveProduct(item.id, item.product.name)}
                                                                        okText="Remove"
                                                                        cancelText="Cancel"
                                                                    >
                                                                        <CloseOutlined />
                                                                    </Popconfirm> */}
                                                                    <MobileImgBlock>
                                                                        <img style={{ width: '100%' }} src={img} />
                                                                    </MobileImgBlock>
                                                                </MobileCardImgWrapper>
                                                                <MobileProductInfo>
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }} >
                                                                        <Link to={`/product-detail/${item.product.url_key}`}>
                                                                            <MobileProductName>{item.product.name}</MobileProductName>
                                                                        </Link>
                                                                        <MobileCustomizeableContent>
                                                                            {
                                                                                item.customizable_options &&
                                                                                <>
                                                                                    {
                                                                                        item.customizable_options.map((item) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <span>{item.label}</span>:
                                                                                                    {item.values[0].label ?
                                                                                                        <span style={{ marginLeft: 5 }} >{item.values[0].label}</span>
                                                                                                        : <span style={{ marginLeft: 5 }} >{item.values[0].value}</span>
                                                                                                    }
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </MobileCustomizeableContent>
                                                                    </div>
                                                                    <MobileProductPrice>₹   {item.prices.row_total_including_tax &&
                                                                        item.prices.row_total_including_tax.value
                                                                    }</MobileProductPrice>
                                                                    <MobileQuantityContainer>
                                                                        <h5 style={{ fontWeight: 'bold', fontSize: '15px', marginBottom: '0' }} >QTY: {item.quantity}</h5>
                                                                    </MobileQuantityContainer>
                                                                </MobileProductInfo>
                                                            </MobileCardBlock>
                                                        )
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </CardWrapper>
                                    <CartSummery>
                                        <CartSummeryBlock>
                                            <OrderSummary>Order Summary</OrderSummary>
                                            <HorizontalLine></HorizontalLine>
                                            <SubTotal>Sub Total <span>₹ {getCartDataState.data.cart.prices.subtotal_including_tax.value}</span></SubTotal>
                                            {
                                                getCartDataState.data.cart.shipping_addresses.length > 0 &&
                                                <>
                                                    {

                                                        getCartDataState.data.cart.shipping_addresses[0].available_shipping_methods.length > 0 &&
                                                        <Shipping>Shipping<span>₹ {getCartDataState.data.cart.shipping_addresses[0].available_shipping_methods[0].amount.value}</span></Shipping>
                                                    }
                                                </>
                                            }



                                            {
                                                getCartDataState.data.cart.prices.discounts === null ? "" :
                                                    <DisCount> Discount < span >₹ {getCartDataState.data.cart.prices.discounts[0].amount.value}</span></DisCount>
                                            }

                                            < Total > Total < span >₹ {getCartDataState.data.cart.prices.grand_total.value}</span></Total>
                                            <HorizontalLine></HorizontalLine>
                                            <CheckoutButton onClick={() => {
                                                setBtnLoading(true)
                                                setPaymentMethod({
                                                    cart_id: cartId,
                                                    customerToken: getCustomerToken()
                                                })
                                            }} loading={btnLoading} >proceed to payment</CheckoutButton>
                                            <Modal
                                                title="Something wenrt wrong :("
                                                visible={contactAdmin}
                                                centered
                                                footer={null}
                                                onCancel={() => setContactAdmin(false)}
                                            >
                                                <p>Some meesage here.</p>
                                            </Modal>
                                            <div style={{ display: "flex", margin: "10px 0px 20px 0px" }}>
                                                {
                                                    getCartDataState.data.cart.applied_coupons === null ?
                                                        <>
                                                            <PinCodeInput
                                                                placeholder="Coupon Code"
                                                                onChange={(e) => setCoupon(e.target.value)}
                                                            />
                                                            <PinCodeButton onClick={() => {
                                                                applyCouponToCart({
                                                                    cart_id: cartId,
                                                                    coupon: coupon,
                                                                    customerToken: getCustomerToken()
                                                                })
                                                            }}>Apply</PinCodeButton>
                                                        </> :
                                                        <>
                                                            <PinCodeInput
                                                                disabled
                                                                placeholder="Coupon Code"
                                                                value={getCartDataState.data.cart.applied_coupons[0].code}
                                                                onChange={(e) => setCoupon(e.target.value)}
                                                            />
                                                            <PinCodeButton onClick={() => {
                                                                removeCouponToCart({
                                                                    cart_id: cartId,
                                                                    customerToken: getCustomerToken()
                                                                })
                                                            }}>Remove</PinCodeButton>
                                                        </>
                                                }
                                            </div>
                                        </CartSummeryBlock>
                                    </CartSummery>
                                </>
                            }
                            {getCartDataState.data.cart.items.length == 0 &&
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
                                    <NoDataContianer
                                        showText="No Items available in cart."
                                        animationType={"cartAnimation"}
                                    />
                                    <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                                </div>
                            }
                        </>
                    }
                </Container >
            </div >
        </>
    )
}

const mapStateToProps = (state) => ({
    getCartDataState: state.getCartData,
    removeItemCartState: state.removeItemCart,
    applyCouponToCartState: state.applyCouponToCart,
    removeCouponToCartState: state.removeCouponToCart,
    salesPlaceOrderState: state.salesPlaceOrder,
    setPaymentMethodState: state.setPaymentMethod,
    placeRazorpayOrderState: state.placeRazorpayOrder,
    setRzpPaymentDetailsOnCartState: state.setRzpPaymentDetailsOnCart,
});

const mapDispatchToProps = (dispatch) => ({
    getCartData: (params) => dispatch(getCartData(params)),
    getCartDataReset: () => dispatch(getCartDataReset()),
    removeItemCart: (params) => dispatch(removeItemCart(params)),
    applyCouponToCart: (params) => dispatch(applyCouponToCart(params)),
    applyCouponToCartReset: () => dispatch(applyCouponToCartReset()),
    removeCouponToCart: (params) => dispatch(removeCouponToCart(params)),
    removeCouponToCartReset: () => dispatch(removeCouponToCartReset()),
    salesPlaceOrder: (params) => dispatch(salesPlaceOrder(params)),
    setPaymentMethod: (params) => dispatch(setPaymentMethod(params)),
    setPaymentMethodReset: () => dispatch(setPaymentMethodReset()),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
    salesPlaceOrderReset: () => dispatch(salesPlaceOrderReset()),
    removeItemCartReset: () => dispatch(removeItemCartReset()),
    placeRazorpayOrder: (params) => dispatch(placeRazorpayOrder(params)),
    placeRazorpayOrderReset: () => dispatch(placeRazorpayOrderReset()),
    setRzpPaymentDetailsOnCart: (params) => dispatch(setRzpPaymentDetailsOnCart(params)),
    updateProductCart: (params) => dispatch(updateProductCart(params)),
    updateProductCartReset: () => dispatch(updateProductCartReset()),
    setRzpPaymentDetailsOnCartReset: () => dispatch(setRzpPaymentDetailsOnCartReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
