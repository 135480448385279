import axios from "axios";
import { BASE_URL } from "../utils";

const loginApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        generateCustomerToken(email: "${params.email}", password: "${params.password}") {
          token
        }
      }`,
    },
    headers: {},
  });
  return response.data;
};

export default loginApi;

