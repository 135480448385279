import styled, { css } from "styled-components";
import { Button, InputNumber, Select, Input, Tag, Table, Drawer, Radio } from "antd";
import { Link } from "react-router-dom";
const { Search } = Input

export const DetailBackground = styled.div`
//background-color: #f8f8f8;
padding-bottom : 80px;
`

const styles = css`
 .product-detail{
background-color: #f5f1ed !important;
 }
`

export const Arrow = styled.div`
  margin-top: 16px;
  margin-left: 16px;

@media ${(props) => props.theme.device.tablet}{
  margin-left: 6px;
}

`
export const Required = styled.div`
  /* display: block; */
  margin-left: 5px;
  font-size: 18px;
`
export const BreadcrumbText = styled.div`
    font-size: 12px;
    display: contents;
    margin-bottom: 3px;
`

export const Container = styled.div`
  padding: 1rem;
  margin-top: 0px;
  display: flex;
  @media ${(props) => props.theme.device.tablet} {
    flex-direction: column;
    height: calc(100% - 110px);
    /* overflow: scroll; */
    padding-top: 18px;        

  }
`;
export const SmallImageBlock = styled.div`
  display: flex;
  /* border: 1px solid #f5f5f5; */
  justify-content: center;
  align-items: center;
  margin-top: 8;

`;

export const OutofStockContent = styled.div`
    color: red;
    font-weight: normal;
    margin-bottom: 5px;
    font-style: italic;
    font-size: 12px;
`
export const CheckDeliveryLabel = styled.div`
display: flex;
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    align-items: center;
`

//style
export const LeftSection = styled.div`
  margin: 10px;
  width: 40%;


  @media ${(props) => props.theme.device.tablet} {
    width: 100%;
     margin: 0px;
  }
  span{
    padding: 2px;
  }
span:hover{

  border: 3px solid #f78c3e !important;
}
span.active{
  cursor: pointer;
  border: 3px solid #f78c3e !important;
}
  .product-images:hover div:first-child > img {
    opacity: 0;
  }
`;


export const ProductContainer = styled.div`
  margin: 10px;
  margin-bottom: 82px;
  margin-left:70px;
  
  width: 60%;

  @media ${(props) => props.theme.device.tablet} {
  width: 100%;
  margin-left:0px;

  }

`;
export const ProductTitle = styled.div`
     font-size: 14px;
    text-align: left;
    font-weight: 600;
    margin: 10px 0px;

`



export const Name = styled.h1`
  font-size: 20px;
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  margin: 0;
  margin-bottom: 5px;
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  }
`;

export const Brand = styled.span`
  font-size: 14px;
  /* font-weight: bold; */
  margin-bottom: 1px;
  color: #595454 ;
  &:hover{
  color: #e49045;
  }
  text-decoration: underline;
  /* font-style: oblique; */
`;
export const BrandItem = styled.span`
 font-size: 12px;
    /* font-weight: bold; */
    margin-bottom: 1px;
    color: #000;
    /* font-style: italic; */
`;
export const SearchButton = styled(Button)`
border-top-right-radius: '10px';
  border-bottom-right-radius: '10px';
    border: none !important;
    span {
        font-weight: bold;
    }
   
`

export const FreeShipingText = styled.span`
font-size: 12px;
font-weight: bold;
margin-bottom: 5px;
color: #e49045;
`;

export const Price = styled.span`
  font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  font-weight: bold;
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  }
`;
export const SortDescriptionTitle = styled.h6`
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-top: 1.2rem;
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;
export const HorizontalLine = styled.span`
  width: 100%;
  height: 1px;
  float: left;
  background-color: #b2b2b2;
`

export const SortDescription = styled.div` 
  font-size: 14px;
  text-align: justify;
@media ${(props) => props.theme.device.tablet} {
  font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
}
p{
      margin-top: 10px;
}
`;

export const AddToCartContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 0px 0 20px 0;
  @media ${(props) => props.theme.device.tablet} {
    flex-direction: column;
  }
   .ant-form-item{
    margin-bottom: 0px !important;
  }
`;

export const QuantityContainer = styled.div`
  display: flex;
  margin-right: 10px;
  //  flex: 0.5;
  
`;

export const IncreamentButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 0;
  font-size: 14px;
  font-weight: bold;
  width: 60px;
   @media ${(props) => props.theme.device.mobileL} {
      width: 40px;
  }
  color: #000000;
    &&.ant-btn[disabled], &&.ant-btn[disabled]:hover, &&.ant-btn[disabled]:focus, &&.ant-btn[disabled]:active{
    background: #fff;
    color: #000;
  }
  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #d9d9d9;
     color: #000000;
  }
`;

export const DecreamentButton = styled(Button)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right: 0;
  width: 60px;
   @media ${(props) => props.theme.device.mobileL} {
      width: 40px;
  }

  &&.ant-btn[disabled], &&.ant-btn[disabled]:hover, &&.ant-btn[disabled]:focus, &&.ant-btn[disabled]:active{
    background: #fff;
    color: #000;
  }

  &&.ant-btn:hover{
  font-size: 14px;
  font-weight: bold;
  color: #000000;
  }
  &&.ant-btn:focus {
    border-color: #d9d9d9;
     color: #000000;
  }
`;
export const InputQuantity = styled(InputNumber)`
  border-radius: 0;
  min-width: 60px;
  max-width: 60px;
   @media ${(props) => props.theme.device.mobileL} {
      min-width: 40px;
       max-width: 40px;
  }
  
  border-left: 0;
  border-right: 0;
  text-align:center;
  pointer-events:none;
  .ant-input-number-handler-wrap {
    display: none;
  }
    .ant-input-number-input{
      font-size: 14px;
      font-weight: bold;
       color: ${(props) => props.theme.color.black};
  }
  &&.ant-input-number-disabled{
    background-color:#f2f2f2 !important;
    color:#000;
  }

  && .ant-input:focus,
  .ant-input-focused {
    outline: 0;
  }

  &.ant-input-number:hover {
    border-color: #d9d9d9;
    border-right-width: 0;
  }
  &.ant-input-number:focus,
  .ant-input-number-focused {
    outline: 0;
    box-shadow: unset;
  }
  && .ant-input-number-input{
    text-align:center;
  }
`;

export const CustomizableButton = styled(Button)`

  border-radius: ${(props) => props.theme.border.borderRadius};

`;

export const ConfigureButton = styled(Radio.Button)`
     border-radius: 10px !important;
    margin-right: 5px !important;
    height: 35px !important;
    padding: 5px 22px !important;
    display: flex;
    align-items: center;
    border: 1px dashed #d9d9d9;
    &.ant-radio-button-wrapper:not(:first-child)::before{
      width: 0px !important;
    }
    &.ant-radio-button-wrapper-disabled{
      opacity: 0.8;
    }
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      background-color: #808080 !important;
      color: #fff !important;
    }
    
`;
export const ConfigureButtonSwatch = styled(Radio.Button)`
     border-radius: 10px !important;
    height: 35px !important;
    padding: 5px 22px !important;
    display: flex;
    align-items: center;
    border: 1px dashed #d9d9d9;
    &.ant-radio-button-wrapper:not(:first-child)::before{
      width: 0px !important;
    }
    &.ant-radio-button-wrapper-disabled{
      opacity: 0.2;
    /* background: linear-gradient(to left top, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 42%, #fff 43%, #fff 46%, #ff5216 47%, #ff5216 53%, #fff 54%, #fff 57%, rgba(255,255,255,0) 58%, rgba(255,255,255,0) 100%) !important;
        padding: 10px 0px; */
    }
    
   
    
`;
export const ActiveButton = styled.span`
    padding: 2px;
    border: 1px solid transparent;
    margin-right: 5px !important;
    border-radius: 10px;
    &.selected{
    border: 1px solid #e49045;
    }
`;


export const AddToCartButton = styled(Button)`
  flex: 1;
  max-width: 174px;
  border-radius: ${(props) => props.theme.border.borderRadius};
  background-color: #e49045;
  color: white;
  border-color: #e49045;
  padding: 0px 48px;
  &:hover,
  &:focus,
  &:active {
    background-color: #e49045;
    color: white;
    border-color: #e49045;
  }
  @media ${(props) => props.theme.device.mobileL} {
    margin: 10px 0;
    min-width: 130px;
    max-width: 130px;
    padding: 0px 30px;
  }
 
`;

export const SelectContainer = styled(Select)`
  display: block;
  border-radius: 500px;
  margin-top: 20px;
`;
export const DeliveryContainer = styled.div`
  display: flex;
  width: 300px;
  margin-top: 5px;
  /* margin: 10px 0 20px 0; */
  .ant-form-item-explain{
    margin-bottom: 22px;
  }
`;
export const PinCodeInput = styled(Search)`
  /* font-size: 12px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
 border-top-right-radius: '10px';
  border-bottom-right-radius: '10px';
  letter-spacing: normal;
  text-align: left;
  width: 100%;
  color: #000000;
    .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:active {
    background: #fff !important;
  }
    &.ant-input{
       height: 30px;
  }
  & .ant-input-group .ant-input{
/* border-radius: ${(props) => props.theme.border.borderRadius}; */
border-top-left-radius: ${(props) => props.theme.border.borderRadius};
border-bottom-left-radius: ${(props) => props.theme.border.borderRadius};
 height: 32px;
 padding: 4px 15px;
 border: none;
  }

&.ant-input-search-enter-button + .ant-input-group-addon .ant-input-search-button, &.ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
   border-top-right-radius: ${(props) => props.theme.border.borderRadius};
    border-bottom-right-radius: ${(props) => props.theme.border.borderRadius};
}
  & .ant-input:focus,
  .ant-input-focused {
    outline: 0;
    border-color: #d9d9d9;
    box-shadow: unset;
    /* border-right-width: 0; */
  }
   & .ant-input-search-enter-button input {
    border-right: 1;
}
  & .ant-input-search .ant-input:focus, .ant-input-search .ant-input:hover {
  box-shadow: unset;
    border-color: #d9d9d9;
}


  &.ant-input-number:hover {
    border-color: #d9d9d9;
    border-right-width: 0;
  }
  &.ant-input-number:focus,
  .ant-input-number-focused {
    outline: 0;
     border-color: #d9d9d9;
    box-shadow: unset;
  }
    & ::placeholder{
    font-size: 15px;
    font-weight: 400;
  }
 
`;
export const CheckIcon = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 5px;
    background: #23c5a0;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
`;
export const VisiablePinCodeInputValue = styled.div`
    border-radius: 10px;
    height: 32px;
    padding: 4px 15px;
    width: 300px;
    max-width: 258px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    cursor: pointer;
  

`;

export const PinCodeButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  text-transform: capitalize;
  border-radius: ${(props) => props.theme.border.borderRadius};
  background-color: #bfbfbf;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  border: 1px solid #bfbfbf;
  &:hover,
  &:focus,
  &:active {
    background-color: #bfbfbf;
    color: #000;
    border: 1px solid #bfbfbf;
  }
`;
export const TagContainer = styled.div`
  margin: 10px 0;
`;
export const ProductTag = styled.div`
    display: flex;
    max-width: 500px;
    width: 100%;
    flex-wrap:wrap;
`;
export const ProductBlock = styled.div`
border-radius: ${(props) => props.theme.border.borderRadius};
     /* padding: 3px 10px; */
    background: none;
    /* border: #c7c7c7 1px solid; */
    color: #595454 ;
  &:hover{
  color: #e49045;
  }
    cursor: pointer;
    /* text-transform: capitalize; */
    width: auto;
    margin: 0px;
    margin-right: 8px;
    

`;

export const MoreItemButton = styled(Link)`
    flex: 1;
    margin-top: -10px;
    margin-bottom: 8px;
    text-transform: capitalize;
    max-width: 150px;
    /* border-radius: ${(props) => props.theme.border.borderRadius}; */
    /* background-color: #bfbfbf; */
    /* color: ${(props) => props.theme.color.black}; */
        color: #595454 ;

    text-decoration: underline;
    /* border: solid 1px #bfbfbf; */
    border: none;
    &:hover,
    &:focus,
    &:active {
       color: #e49045;
    text-decoration: underline;
       /* background-color: #bfbfbf; */
           /* border: 1px solid #bfbfbf; */
    /* color: ${(props) => props.theme.color.black}; */
    }
    @media ${(props) => props.theme.device.tablet} {
      /* margin: 10px 0; */
      /* max-width: 130px; */
    }
`;

export const ArrowContainer = styled.div`
 margin-top: 16px;
  margin-left: 16px;
cursor:pointer;
& img{
  border-radius: 50%;
  &:hover{
    background-color: #e2e2e2;
  }
  padding: 10px;

  @media ${(props) => props.theme.device.tablet} {
    padding-top:5px;
  }
}
@media ${(props) => props.theme.device.tablet} {
  margin: 10px;
}
`;
export const ShippingMessage = styled.div`
  display: flex;
  justify-content: flex-start;
  /* margin-top: -22px; */
`
export const DeliverySuccessMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  text-transform: capitalize;
  font-size: 14px;
  color: #73b255;
  font-weight: 600;
  transition: 0.8s ease-in-out;
  /* margin-top: -22px; */
`
export const DeliveryErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 250px;
  text-transform: capitalize;
  font-size: 14px;
  color: #ff4d4f;
  font-weight: 600;
  transition: 0.8s ease-in-out;
  /* margin-top: -20px; */
`


export const CartContent = styled.span`
text-transform: capitalize;
`



export const TagComponent = styled(Tag)`
&.ant-tag{
  padding: 4px 10px 5px;
  border-radius: 10px;
  border: solid 1px #707070;
  background-color: #ffffff;
  color:black;
  cursor:pointer;
  &:hover,
  &:focus,
  &:active {
    background-color: #939393;
    color:#ffffff;

  }
}
`

export const ManufactureContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
padding-top: 0px;
`

export const TableContainer = styled(Table)`
.ant-table-thead > tr > th{
  background-color:white;
}
.ant-table-thead{
  display:none;
}
.ant-table-cell:first-child{
  font-weight: bold;
}
`

export const DrawerContainer = styled(Drawer)`
/* .ant-drawer-header{
  background-color: #f5f1ed;
} */
.ant-drawer-body{
  background-color: #f3f3f3 !important;

}
.ant-drawer-body{
    padding: 0px !important;
}
/* .ant-table{
  background-color: #f3f3f3 !important;
} */
.ant-table-tbody > tr:hover > td {
   background: #f3f3f394;
}
.ant-table-tbody > tr > td {
   background: #f3f3f3;
   border-bottom: 1px solid #eceaea;
}
.ant-drawer-content-wrapper{


  width:54% !important;
  float: right;
  position: relative;
  @media ${(props) => props.theme.device.tablet} {
    width:100% !important;

  }
}
`


export const SizeChartLink = styled(Link)`
    flex: 1;
    margin-top: -10px;
    margin-bottom: 8px;
    text-transform: capitalize;
    max-width: 150px;
    /* border-radius: ${(props) => props.theme.border.borderRadius}; */
    /* background-color: #bfbfbf; */
    /* color: ${(props) => props.theme.color.black}; */
        color: #595454 ;

    text-decoration: none;
    /* border: solid 1px #bfbfbf; */
    border: none;
     color: #e49045;
    &:hover,
    &:focus,
    &:active {
       color: #e49045;
    text-decoration: underline;
       /* background-color: #bfbfbf; */
           /* border: 1px solid #bfbfbf; */
    }
    @media ${(props) => props.theme.device.tablet} {
      /* margin: 10px 0; */
      /* max-width: 130px; */
    }
`;