import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';

const createCustomerApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        createCustomerV2(
                            input: {
                                firstname: "${params.first_name}"
                                lastname: "${params.last_name}"
                                email: "${params.email}"
                                password: "${params.password}"
                                is_subscribed: true
                            }
                        ) {
                            customer {
                                firstname
                            }
                        }
                    }
                    `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data;
};

export default createCustomerApi;
