import styled from "styled-components"

import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
 /* height: 100%; */
     padding-top: 0px;

  
    #columns {
      column-width: 300px;
      column-gap: 0px;
      width: 100%;
      /* max-width: 1100px; */
      margin: auto;
    }

    div#columns figure {
      background: #fefefe;
      margin: 0px;
      padding: 8px;
      /* padding-bottom: 5px; */
      transition: opacity .4s ease-in-out;
      display: inline-block;
      column-break-inside: avoid;
    }

  div#columns figure img:hover {
      
          transform: scale(1.02);
          transition: transform 0.3s ease-in-out 0s;
  }
    div#columns figure img {
      width: 100%; height: auto;
      margin-bottom: 0px;
      border-radius: 8px;
       transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
    }

    @media screen and (max-width: 612px){
       div#columns figure {
         width:50%;
       }
    div#columns figure img {
      width: 100%; 
      height: 200px;
    }
    @media screen and (max-width: 480px){
       div#columns figure {
         width:50%;
       }
    div#columns figure img {
      width: 100%; 
      height: 170px;
    }
  }

    
 @media ${(props) => props.theme.device.tablet} {
    #columns {
        /* column-gap: 0px;  */
         column-width: 280px;
      /* column-gap: 8px; */
      width: 98%;
      max-width: 1100px;
      margin: auto;

      }
      #columns figure { width: 100%;}
       div#columns figure {
      margin: 0px;
    }
  }


    /* @media screen and (max-width: 750px) { 
      #columns { 
         column-width: 150px;
      column-gap: 10px;
      width: 90%;
      max-width: 1100px;
      margin: 14px auto;
     
      }
      #columns figure { width: 100%;}
       div#columns figure {
      margin: 0 2px 5px;
    }
    } */
`
export const PolicyHeading = styled.div`
color:${(props) => props.theme.color.black};
`

export const MainContainer = styled.div`
margin-top: 20px;
@media ${props => props.theme.device.tablet} {
        margin-top: 0px;
    }
`

export const FigureCard = styled.figure`
/* &:hover{
box-shadow: 0 6px 16px -8px rgb(0 0 0 / 8%), 0 9px 28px 0 rgb(0 0 0 / 5%), 0 12px 48px 16px rgb(0 0 0 / 3%)

} */
`
export const HomeWrapper = styled.div`
/* width:100%; */
max-width: 1440px;
height:100%;
 margin-right: auto;
 margin-left: auto;
  padding-left: 2%;
 padding-right: 2%;
`
export const RowBanner = styled.div`
width:100%;
height:auto;
margin-top: 12px;
padding: 4px 10px;

 img{
    width: 100%;
    height: 100%;
  }
`

export const RowBannerDesktop = styled.div`
width:100%;
height:auto;
margin-top: 12px;
padding: 4px 10px;
&:hover {
      img {
          transform: scale(1.02);
          transition: transform 0.3s ease-in-out 0s;
      }
  }

 img{
   transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
    width: 100%;
    height: 100%;
    border-radius: 18px;
  }

display: block;
  @media screen and (max-width: 570px){
   display: none;
  }
`
export const RowBannerMobile = styled.div`
width:100%;
height:auto;
margin-top: 12px;
padding: 4px 10px;
 img{
    width: 100%;
    height: 100%;
    border-radius: 18px;
  }
display: none;
  @media screen and (max-width: 570px){
   display: block;
  }

`

export const Row = styled.div`
width:100%;
height:auto;
margin-top: 12px;
 img{
    width: 100%;
    height: 100%;
    /* padding: 0px 10px; */
  }
.react-multi-carousel-item{
   /* padding: 0px 10px; */
       padding: 8px !important;
}
.react-multiple-carousel__arrow{
  z-index: 97 !important;
}
 
`

export const MainBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 20px;
 @media screen and (max-width: 570px){
   flex-direction: column;
  }
`
export const LeftBlock = styled.div`
  width: 50%;
  height: 100%;
  margin: 0px 10px 0px 0px;

   img{
    width: 100%;
    height: 100%;
  }

@media screen and (max-width: 570px){
    width: 100%;
    margin: 0px;
  }
`
export const RightBlock = styled.div`
   width: 50%;
  height: 100%;
  margin: 0px 0px 0px 10px;
   img{
    width: 100%;
    height: 100%;
  }
   @media screen and (max-width: 570px){
    width: 100%;
    margin: 0px;
  }
`
export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
   flex-direction: row;
   img{
    width: 100%;
    height: 100%;
  }
  /* column-gap: 14px; */
  @media screen and (max-width: 570px){
    flex-direction: row;
    flex-wrap: wrap;
    /* column-gap: 0px; */
    /* row-gap: 22px; */
    /* padding-top: 10px; */
    /* padding: 0 10px; */
         /* padding: 10px; */
  }
`
export const FlexBlock = styled.div`
    width: 25% !important;
    padding: 8px;
    img{
        border-radius: 18px;
      }
    @media screen and (max-width: 570px){
    width: 50% !important;
    margin: 0px;
    padding:10px 10px;
  }
`

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`
export const Card = styled.div` 
  width: 25% !important;
  padding: 8px !important;
  @media ${(props) => props.theme.device.tablet}{
      flex-direction: column;
      width: 50% !important;
  }
  @media ${(props) => props.theme.device.mobileL}{
      flex-direction: column;
      width: 100% !important;
  }
 &:hover {
      img {
          transform: scale(1.02);
          transition: transform 0.3s ease-in-out 0s;
      }
     
  }

 img{
   transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
    border-radius: 18px;
  }
  
`
export const ProductName = styled.div`
 width: 100%;
    padding: 10px 0px;
    color: #000;
    /* display: none; */
    transition: 0.8s ease-in-out !important;
    text-align: center;
    position: absolute;
    bottom: 0px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    transition: 0.3s ease-in-out;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255,255,255,0.72);
    z-index: 999;

    @media ${(props) => props.theme.device.tablet}{
       display: block;

    }

`
export const HorizontalLineBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`
export const HorizontalLine = styled.div`
    width: 100%auto;
     height: 1;
      float: right;
      background: #000;
`

export const Image = styled.img`
  border-radius: 18px;
  width: 100%;
  height: 100%;
`
export const ProductLink = styled(Link)`
 &:hover ${ProductName} {
  display: block;
 }


`
export const CarouselDiv = styled.div`
  position: relative;
  border: 1px solid #00000014;
  height: 100%;
  border-radius: 18px;
  transform: scale(1.0);
  transition: transform 0.3s ease-in-out 0s;
  
 &:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease-in-out 0s;
  }
`


export const TitleContainer = styled.div`
    padding: 42px 22px 16px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const Title = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    margin: 0px 10px;
    max-width: max-content;
    white-space: nowrap;

     @media ${(props) => props.theme.device.tablet}{
       font-size: 15px;
    }

`
export const HLine = styled.span`
  width: 100%;
  height: 1px;
  float: left;
  background: #0003;
`

export const TitleLink = styled(Link)`
    font-size: 18px;
    font-weight: bold;
    color: #f78c3e;
    /* width: 25%; */
    /* margin-left: 10px; */
    &&:hover{
      color: #f78c3e;
    }

     @media ${(props) => props.theme.device.tablet}{
       font-size: 13px;
    }
`

export const LifestyleContainer = styled.div`
 margin-top: 22px;
    
`