import axios from 'axios'

const getShippigoTokenApi = async () => {
    const response = await axios({
        method: "post",
        url: `https://v1thirdpartyapi.shpgodev.xyz/authToken`,
        data: {
            "username": "SGMU201024705",
            "password": "3C1437F3"
        },
        headers: {
            "platform": "shippigo"
        }
    })
    return response
}

export default getShippigoTokenApi