import React, { useState, useEffect } from "react";
import { NoDataContianer, NoDataContent } from "./style";
import Lottie from "react-lottie";
import searchAnimation from "./lotties/13525-empty.json";
import cartAnimation from "../../lottie/nodata.json";
import defaultSearchAnimation from './lotties/16258-searching-women.json';

const NoData = (props) => {
  const { showText, animationType } = props;
  const [animation, setAnimation] = useState(null);

  useEffect(() => {
    if (animationType) {
      if (animationType == "searchAnimation") {
        setAnimation(searchAnimation);
      }
      if (animationType == "cartAnimation") {
        setAnimation(cartAnimation);
      }
      if (animationType === 'defaultSearchAnimation') {
        setAnimation(defaultSearchAnimation);
      }
    }
  }, [animationType]);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    // rendererSettings: {
    //   preserveAspectRatio: 'xMidYMid slice'
    // }
  };

  return (
    <NoDataContianer>
      <Lottie
        options={defaultOptions}
        height={300}
        width={300}
        isStopped={false}
      />
      {showText && <NoDataContent>{showText}</NoDataContent>}
    </NoDataContianer>
  );
};

export default NoData;
