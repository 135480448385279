import {
  UPDATE_PRODUCT_CART,
  UPDATE_PRODUCT_CART_SUCCESS,
  UPDATE_PRODUCT_CART_FAIL,
  UPDATE_PRODUCT_CART_RESET
} from "../actions/updateCartAction";
import get from 'lodash/get';

const initialState = {
  updateListItems: null,
  updateListItemsPrices: null,
  isLoading: null,
  apiState: ""
};

export default function (state = initialState, action) {

  switch (action.type) {
    case UPDATE_PRODUCT_CART:
      return {
        apiState: "loading",
        ...state,
        isLoading: true
      };

    case UPDATE_PRODUCT_CART_FAIL:
      return {
        apiState: "error",
        ...state,
        isLoading: false

      };

    case UPDATE_PRODUCT_CART_SUCCESS:
      return {
        apiState: "success",
        ...state,
        updateListItems: get(action, "response.data.updateCartItems.cart.items"),
        updateListItemsPrices: get(action, "response.data.updateCartItems.cart.prices"),
        isLoading: false,

      };
    case UPDATE_PRODUCT_CART_RESET:
      return initialState

    default:
      return state;
  }
}
