export const FETCH_PARTNER_CMS_REQUEST = 'FETCH_PARTNER_CMS_REQUEST'
export const FETCH_PARTNER_CMS_SUCCESS = 'FETCH_PARTNER_CMS_SUCCESS'
export const FETCH_PARTNER_CMS_FAIL = 'FETCH_PARTNER_CMS_FAIL'

export const fetchPartnerCmsRequest = (params) => {
    return { type: FETCH_PARTNER_CMS_REQUEST, params }
}

export const fetchPartnerCmsSuccess = (response) => {
    return { type: FETCH_PARTNER_CMS_SUCCESS, response }
}

export const fetchPartnerCmsFail = (response) => {
    return { type: FETCH_PARTNER_CMS_FAIL, response }
}