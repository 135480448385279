export const GET_CART_ID = 'GET_CART_ID'
export const GET_CART_ID_SUCCESS = 'GET_CART_ID_SUCCESS'
export const GET_CART_ID_FAIL = 'GET_CART_ID_FAIL'

export const getCartId = () => {
    return { type: GET_CART_ID }
}

export const getCartIdSuccess = (response) => {
    return { type: GET_CART_ID_SUCCESS, response }
}

export const getCartIdFail = (response) => {
    return { type: GET_CART_ID_FAIL, response }
}
