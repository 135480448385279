export const CREATE_GUEST_CART = 'CREATE_GUEST_CART'
export const CREATE_GUEST_CART_SUCCESS = 'CREATE_GUEST_CART_SUCCESS'
export const CREATE_GUEST_CART_FAIL = 'CREATE_GUEST_CART_FAIL'
export const CREATE_GUEST_CART_RESET = 'CREATE_GUEST_CART_RESET'

export const createGuestCart = () => {
    return { type: CREATE_GUEST_CART }
}

export const createGuestCartSuccess = (response) => {
    return { type: CREATE_GUEST_CART_SUCCESS, response }
}

export const createGuestCartFail = (response) => {
    return { type: CREATE_GUEST_CART_FAIL, response }
}

export const createGuestCartReset = () => {
    return { type: CREATE_GUEST_CART_RESET }
}
