import { call, put, take, takeLatest } from 'redux-saga/effects'

// action
import {
    SHOW_ON_HOME_PAGE_BURNI_SECTION,
    showOnHomePageBurniSectionFail,
    showOnHomePageBurniSectionSuccess
} from '../actions/showOnHomePageBurniSectionAction'

// api
import showOnHomePageBurniSectionApi from '../apis/showOnHomePageBurniSectionApi'

export function* showOnHomePageBurniSectionSaga(action) {
    try {
        const response = yield call(() => showOnHomePageBurniSectionApi(action.params))
        if (response) {
            yield put(showOnHomePageBurniSectionSuccess(response, action))
        }
    }
    catch (e) {
        yield put(showOnHomePageBurniSectionFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(SHOW_ON_HOME_PAGE_BURNI_SECTION, showOnHomePageBurniSectionSaga)
}