import React, { useState, useEffect } from 'react'
import { DrawerContainer, Conatiner, AuthenticationWrapper, GuestCheckoutBtn, HorizontalLine, GuestCheckoutWrapper } from './AuthenticationStyle'
import Login from '../../pages/LoginPage/LoginPage'
import Signup from '../../pages/SignUpPage/SignUpPage'
import ForgotPassword from '../../pages/ForgotPassword/ForgotPassword'
import { Tabs, Button } from 'antd'
import { Link } from 'react-router-dom'

const Authentication = (props) => {
    const { drawerState, handleCloseDrawer, handleCallbackClick, refreshData, withoutLogin } = props

    // variables
    const [toggleState, setToggleState] = useState("1")
    const [forgetVisiable, setForgetVisiable] = useState(false)
    const { TabPane } = Tabs;

    const handleChange = (key) => {
        setToggleState(key)
    }

    const handleRefreshClick = () => {
        handleCallbackClick()
        handleCloseDrawer()

    }

    const handleForgetPassword = () => {
        setForgetVisiable(true)
    }

    const handleLogin = () => {
        setForgetVisiable(false)
    }



    return (
        <Conatiner>
            <DrawerContainer
                placement="bottom"
                onClose={handleCloseDrawer}
                visible={drawerState}
                width={"100% !important"}
                height={"100%"}
            >
                {
                    forgetVisiable ?
                        <ForgotPassword handleLogin={handleLogin} /> :
                        <>
                            <AuthenticationWrapper>
                                <Tabs style={{ display: 'flex', justifyContent: 'center', marginTop: '2%' }} activeKey={toggleState} onChange={handleChange}>
                                    <TabPane tab="Login" key="1"   >
                                        <Login handleCloseDrawer={handleCloseDrawer} handleRefreshClick={handleRefreshClick} handleForgetPassword={handleForgetPassword} />
                                    </TabPane>
                                    <TabPane tab="Signup" key="2"  >
                                        <Signup handleSignup={() => handleChange("1")} />
                                    </TabPane>
                                </Tabs>
                            </AuthenticationWrapper>
                            {withoutLogin &&
                                < GuestCheckoutWrapper >
                                    <div style={{ display: 'flex', alignItems: ' center', justifyContent: 'center', width: '250px', marginTop: 20, marginBottom: 10 }}>
                                        <HorizontalLine></HorizontalLine>
                                        <span style={{ margin: "0px 10px" }}>OR</span>
                                        <HorizontalLine></HorizontalLine>
                                    </div>

                                    <Link to="/guest-checkout" style={{ display: 'flex', alignItem: "center", justifyContent: 'center', width: "100%" }} onClick={handleCloseDrawer} >
                                        <GuestCheckoutBtn>Continue Without Login</GuestCheckoutBtn>
                                    </Link>
                                </GuestCheckoutWrapper>
                            }



                        </>
                }

            </DrawerContainer>

        </Conatiner >
    )
}

export default Authentication
