import {
    UPDATE_CART_PRODUCT,
    UPDATE_CART_PRODUCT_SUCCESS,
    UPDATE_CART_PRODUCT_FAIL,
    UPDATE_CART_PRODUCT_RESET,
} from '../actions/updateCartProductAction'

const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CART_PRODUCT:
            return {
                ...state,
                apiState: "loading"
            }
        case UPDATE_CART_PRODUCT_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.data && action.data || {}
            }
        case UPDATE_CART_PRODUCT_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case UPDATE_CART_PRODUCT_RESET:
            return initialState

        default:
            return state
    }
}