import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_FEATURED_IMAGE_LIST,
    getFeaturedImageListSuccess,
    getFeaturedImageListFail,
} from '../actions/getFeaturedImageListAction'

import getFeaturedImageListApi from '../apis/getFeaturedImageListApi'


export function* getFeaturedImageListSaga(action) {
    try {
        const response = yield call(() => getFeaturedImageListApi(action.params))

        if (response.errors) {
            yield put(getFeaturedImageListFail(response, action))
        } else {
            yield put(getFeaturedImageListSuccess(response, action))
        }
    } catch (e) {
        yield put(getFeaturedImageListFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_FEATURED_IMAGE_LIST, getFeaturedImageListSaga);
}