import axios from 'axios'

const checkDeliveryApi = async (params) => {
    // const response = await axios({
    //     url: `https://v1thirdpartyapi.shpgodev.xyz​/serviceAndRates`,
    //     method: "post",
    //     data: {
    //         query: `
    //                 "source": 110006,
    //                 "destination": 2478007,
    //                 "paymentType": "ppd"
    //             `
    //     }
    // })

    return {
        message: "delivery available"
    }
}

export default checkDeliveryApi;