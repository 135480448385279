import styled from "styled-components";


export const Container = styled.div`
height: 100%;
//display: flex;
//justify-content: center;
`
export const ArrowContainer = styled.div`
margin: 20px 0 0 20px;
cursor:pointer;
& img{
  border-radius: 50%;
  &:hover{
    background-color: #e2e2e2;
  }
  padding: 10px;

  @media ${(props) => props.theme.device.tablet} {
    padding-top:5px;
  }
}
@media ${(props) => props.theme.device.tablet} {
  margin: 10px;
}
`;
export const CartContainer = styled.div`
display: flex;
justify-content: center;
@media ${(props) => props.theme.device.laptopL} {
  flex-direction: column;
}
`


export const AddCardContainer = styled.div`
flex:3;
`

export const SummaryContainer = styled.div`
flex:1.5;
`
export const InfoContainer = styled.div`
background-color: #2f2f2f;
padding: 15px;
margin: 20px;
border-radius:5px;
display: flex;
`

export const InfoContent = styled.span`

font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.white};
/* padding-left:20px; */

`
export const SpinContainer = styled.div`
display: flex;
justify-content: center;

`