import {
    GET_GUEST_SHIPPIGO_TOKEN,
    GET_GUEST_SHIPPIGO_TOKEN_SUCCESS,
    GET_GUEST_SHIPPIGO_TOKEN_FAIL,
    GET_GUEST_SHIPPIGO_TOKEN_RESET
} from '../actions/getGuestShippigoTokenAction'

const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GUEST_SHIPPIGO_TOKEN:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_GUEST_SHIPPIGO_TOKEN_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data || []
            }
        case GET_GUEST_SHIPPIGO_TOKEN_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case GET_GUEST_SHIPPIGO_TOKEN_RESET:
            return initialState

        default:
            return state
    }
}