import React from "react"
import { CreditCardFilled } from "@ant-design/icons"
import { HelpNumberContent } from './HelpNumberStyle'

const HelpNumber = props => {
    return (
        <div style={{ padding: "20px" }} >
            <div style={{
                background: "#F5F5F5",
                padding: "20px",
                borderRadius: 8
            }} >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                    <CreditCardFilled style={{ color: "green", fontSize: 24 }} />
                    <span style={{
                        marginLeft: 16,
                        color: "#6b6b6b"
                    }} >Safe and Secure Payment. 100% Authentic products.</span>
                </div>
                <HelpNumberContent  >
                    <p style={{ margin: 0 }} >NEED HELP? CALL US AT <a href="tel:+91 7414003336" style={{ color: "#000" }} ><b style={{ textDecoration: 'underline' }} >+917414003336</b></a></p>
                </HelpNumberContent>
            </div>
        </div>
    )
}

export default HelpNumber