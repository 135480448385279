import styled, { css } from "styled-components";
import { Skeleton } from "antd";
export const Container = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  @media ${(props) => props.theme.device.tablet} {
    flex-direction: row;
    display: flex;
    //    padding: 5px;
    flex-flow: initial;
    align-items: center;
    justify-content: center;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  @media ${(props) => props.theme.device.tablet} {
    margin: 10px;
  }
`;

export const RightSection = styled.div`
  width: 100%;
  @media ${(props) => props.theme.device.tablet} {
    width: unset;
    margin: 10px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 60px;
  height: 60px;
`;

export const Title = styled.p`
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  @media ${(props) => props.theme.device.tablet} {
    display: ${(props) => (props.hideMobile ? "none" : "flex")};
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const SubTitle = styled.span`
  display: flex;
  margin-bottom: 10px;
  @media ${(props) => props.theme.device.tablet} {
    display: ${(props) => (props.hideMobile ? "none" : "flex")};
    margin-bottom: ${(props) => (props.hideMobile ? "0px" : "10px")};
  }
`;

export const Xskeleton = styled(Skeleton.Button)`
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};

  ${(props) =>
    props.type === "box" &&
    css`
      width: 100%;
      height: 100%;

      .ant-skeleton-button {
        width: ${(props) => (props.w ? props.w : "100%")};
        height: ${(props) => (props.h ? props.h : "100%")};
        border-radius: ${(props) => (props.br ? props.br : "0")};
      }
    `}

  ${(props) =>
    props.type === "text" &&
    css`
      width: ${(props) => (props.w ? props.w : "100%")};
      height: ${(props) => (props.h ? props.h : "100%")};

      .ant-skeleton-button {
        width: 100%;
        height: 100%;
      }
    `}
`;

export const ImageSkeleton = styled(Skeleton.Image)`
&&.ant-skeleton-element .ant-skeleton-image{
    height: 240px;
    width: 240px;
    @media ${(props) => props.theme.device.tablet} {
      height: 80px;
    width: 80px;
    }
   
}

}
`
