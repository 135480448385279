import {
  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_PRODUCT_LIST_FAILED,
  FETCH_FAVOURITE_COUNT_REQUEST,
} from "../actions/productListAction";
import get from 'lodash/get';

const initialState = {
  getFavouriteCount: null,
  productListData: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FAVOURITE_COUNT_REQUEST:
      return {
        ...state,
        getFavouriteCount: action.payload,
      };

    case FETCH_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        apiState: "loading",
        loading: true,

      };

    case FETCH_PRODUCT_LIST_SUCCESS:
      const updatedData = get(action, 'response.data.products.items');

      const data = updatedData.map((item) => {
        return {
          ...item,
          isHeartFilled: false,
        }
      })
      return {
        ...state,
        apiState: "success",
        loading: false,
        productListData: data,
      };

    case FETCH_PRODUCT_LIST_FAILED:
      return {
        ...state,
        apiState: "error",
      };

    default:
      return state;
  }
}
