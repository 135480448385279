export const SHOW_ON_HOME_PAGE_BURNI_SECTION = "SHOW_ON_HOME_PAGE_BURNI_SECTION"
export const SHOW_ON_HOME_PAGE_BURNI_SECTION_FAIL = "SHOW_ON_HOME_PAGE_BURNI_SECTION_FAIL"
export const SHOW_ON_HOME_PAGE_BURNI_SECTION_SUCCESS = "SHOW_ON_HOME_PAGE_BURNI_SECTION_SUCCESS"
export const SHOW_ON_HOME_PAGE_BURNI_SECTION_RESET = "SHOW_ON_HOME_PAGE_BURNI_SECTION_RESET"


export const showOnHomePageBurniSection = (params) => {
    return { type: SHOW_ON_HOME_PAGE_BURNI_SECTION, params }
}
export const showOnHomePageBurniSectionFail = (response) => {
    return { type: SHOW_ON_HOME_PAGE_BURNI_SECTION_FAIL, response }
}
export const showOnHomePageBurniSectionSuccess = (response) => {
    return { type: SHOW_ON_HOME_PAGE_BURNI_SECTION_SUCCESS, response }
}
export const showOnHomePageBurniSectionReset = () => {
    return { type: SHOW_ON_HOME_PAGE_BURNI_SECTION_RESET }
}