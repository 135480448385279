import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_PRODUCT_LIST,
    getProductListSuccess,
    getProductListFail,
} from '../actions/getProductListAction'

import getProductListApi from '../apis/getProductListApi'


export function* getProductListSaga(action) {
    try {
        const response = yield call(() => getProductListApi(action.params))

        if (response.errors) {
            yield put(getProductListFail(response, action))
        } else {
            yield put(getProductListSuccess(response, action))
        }
    } catch (e) {
        yield put(getProductListFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_PRODUCT_LIST, getProductListSaga);
}