import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_WISHLIST_REQUEST,
  addWishListSuccess,
  addWishListFail,
} from "../actions/addWishListAction";
import addWishListApi from "../apis/addWishListApi";
import get from 'lodash/get';


function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(otherParams);
  }
}

export function* addWishListSaga(action) {

  try {
    const response = yield call(() => addWishListApi(action));
    if (response.errors) {
      yield put(addWishListFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(addWishListSuccess(response, action));
      yield common(action, true, response);

    }
  } catch (e) {
    yield put(addWishListFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(ADD_WISHLIST_REQUEST, addWishListSaga);
}
