import { createAction } from "redux-action";

export const FETCH_FAVOURITE_COUNT_REQUEST = "FETCH_FAVOURITE_COUNT_REQUEST";
export const FETCH_PRODUCT_LIST_REQUEST = "FETCH_PRODUCT_LIST_REQUEST";
export const FETCH_PRODUCT_LIST_SUCCESS = "FETCH_PRODUCT_LIST_SUCCESS";
export const FETCH_PRODUCT_LIST_FAILED = "FETCH_PRODUCT_LIST_FAILED";

export const fetchFavouriteCount = (state) => {
  return { type: FETCH_FAVOURITE_COUNT_REQUEST, payload: state };
};

export const fetchListCategoriesRequest = (state) => {
  return { type: FETCH_PRODUCT_LIST_REQUEST, params: state };
};

export const fetchListCategoriesSuccess = (response) => {
  return { type: FETCH_PRODUCT_LIST_SUCCESS, response };
};

//export const fetchListCategoriesRequest = createAction(FETCH_PRODUCT_LIST_REQUEST)
//export const fetchListCategoriesSuccess = createAction(FETCH_PRODUCT_LIST_SUCCESS)
export const fetchListCategoriesFailure = createAction(
  FETCH_PRODUCT_LIST_FAILED
);
