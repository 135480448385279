
import styled from 'styled-components'
import { Drawer, Button } from "antd";


export const DrawerContainer = styled(Drawer)`
.ant-drawer-content-wrapper{
  width:100% !important;
  position: relative;
}
.ant-tabs-nav-wrap{
    display: flex;
    justify-content: flex-start;
}
.ant-drawer-body{
    padding: 0px;
}
/* .ant-tabs-nav{
      margin-bottom: 0px;
} */
`
export const Conatiner = styled.span`
   width: 100%;
  height: 100%;
`;
export const AuthenticationWrapper = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .ant-tabs-nav-list{
        width: 100%;
        /* margin-bottom: 50px; */
    }
    .ant-tabs-tab{
        width: 50%;
        /* background: #808080; */
        margin-right: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-tabs-tab-btn{
        color: ${(props) => props.theme.color.black} ;
    }
`
export const GuestCheckoutBtn = styled.div`
    color: #fff;
    background: ${(props) => props.theme.color.orange} ;
    border-color: #808080;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    margin-top: 10px;
    width: 250px;
    padding:5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    text-transform: capitalize;
    
`
export const HorizontalLine = styled.div`
width:100%;
 border: solid 1px #8e8e8e;
 margin: 12px 0;
 opacity: 0.5
 `
export const GuestCheckoutWrapper = styled.div`

display: flex;
align-items: center;
justify-content: center;
    /* max-width: 250px; */
    width: 100%;
    flex-direction: column;
`