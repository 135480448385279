import styled from 'styled-components'
import { Input } from 'antd'
import { Link } from 'react-router-dom'
export const HomePageFooterWrapper = styled.div`
    width: 100%;
    height: 100%;
    /* background: linear-gradient(172deg, #c8e6f8,#fad4fa, #ffffe9); */
    /* border-radius: 15px; */
    padding: 20px;
    border-top: 1px solid #808080;
margin-bottom: 20px;
    
`
export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
   
`
export const EmailBlock = styled.div`
    width: 100%;
    height: 100%;
    text-align: center;
    &.ant-form-item{
        margin-bottom: 0px !important;
    }

`
export const EmailInput = styled(Input)`
    width: 100%;
    height: 100%;
    border-radius: 32px;
    /* padding: 0px 0px 0px 0px !important; */
      padding: 3px 3px !important;
    min-width: 500px;
    .ant-input{
        border-radius: 30px !important;
        padding-left: 20px !important;
        background-color: #fff !important;
    }
    .ant-input:active{
        background-color: #fff !important;
    }
    .ant-btn-round{
            height: 35px !important;
    padding: 2px 16px !important;
    }
   @media ${(props) => props.theme.device.tablet}{
        min-width: 100%;
   }
`
export const FooterBlock = styled.div`
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 640px){
    width: 50%;
    margin-bottom: 20px;
}
    @media screen and (max-width: 356px){
    width: auto;
    }
`
export const FooterLinks = styled(Link)`
    color: #000;
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
    &:hover{
        color: #000;
    }
`
export const FooterHead = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    
`
export const IconBlock = styled.div`
    width: 30px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #1d1d1d; */
    border-radius: 30px;
    
`
export const FooterIconLink = styled(Link)`
    color: #000;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    &:hover{
        color: #000;
    }
`
export const Address = styled.div`
    width: 100%;
    height: 100%;
    font-weight: 500;
`
export const ErrorMessage = styled.div`
    color: red;
    font-weight: 400;
    transition: 0.3 ease-in-out;
`
export const SuccessMessage = styled.div`
    color: green;
    font-weight: 400;
    transition: 0.3 ease-in-out;
`
export const FooterImage = styled.div`
    width: 250px;
    height: auto;
 @media screen and (max-width: 540px){
     width: 100%;
 }
`
export const PaymentCardImage = styled.div`
    width: 200px;
`
export const Image = styled.img`
    width: 100%;
`