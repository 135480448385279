import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const setRzpPaymentDetailsOnCartApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                mutation {
                    setRzpPaymentDetailsOnCart (
                        input: {
                            cart_id: "${params.cart_id}"
                            rzp_payment_id: "${params.rzp_payment_id}"
                            rzp_order_id: "${params.rzp_order_id}"
                            rzp_signature: "${params.rzp_signature}"
                        }
                    ){
                        cart{
                            id
                        }
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default setRzpPaymentDetailsOnCartApi;
