export const APPLY_COUPON_TO_CART = "APPLY_COUPON_TO_CART"
export const APPLY_COUPON_TO_CART_SUCCESS = "APPLY_COUPON_TO_CART_SUCCESS"
export const APPLY_COUPON_TO_CART_FAIL = "APPLY_COUPON_TO_CART_FAIL"
export const APPLY_COUPON_TO_CART_RESET = "APPLY_COUPON_TO_CART_RESET"

export const applyCouponToCart = (params) => {
    return { type: APPLY_COUPON_TO_CART, params }
}
export const applyCouponToCartSuccess = (response) => {
    return { type: APPLY_COUPON_TO_CART_SUCCESS, response }
}
export const applyCouponToCartFail = (response) => {
    return { type: APPLY_COUPON_TO_CART_FAIL, response }
}
export const applyCouponToCartReset = () => {
    return { type: APPLY_COUPON_TO_CART_RESET }
}