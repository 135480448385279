import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';


import {
    FETCH_ORDERS_REQUEST,
    fetchOrdersSuccess,
    fetchOrdersFail,
} from '../actions/fetchOrdersAction'

import fetchOrderApi from '../apis/fetchOrdersApi';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* fetchOrderSaga(action) {

    try {
        const response = yield call(() => fetchOrderApi(action))
        if (response.errors) {
            yield put(fetchOrdersFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(fetchOrdersSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(fetchOrdersFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_ORDERS_REQUEST, fetchOrderSaga);
}