import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const resetPasswordApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        resetPassword(
                            email: "${params.email}",
                            resetPasswordToken: "${params.resetPasswordToken}",
                            newPassword: "${params.password}"
                        )
                    }
                `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data;
};

export default resetPasswordApi;
