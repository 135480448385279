import React from 'react'
import {
    Container, SubContainer
} from "./HomePageBannerSkeletonStyle"
import { DynamicSkeleton } from '../Xcomponent'
const HomePageBannerSkeleton = () => {

    return (
        <Container>
            <div style={{ paddingTop: 12 }} >
                <SubContainer>
                    <DynamicSkeleton active h="500px" />
                </SubContainer>
            </div>
            <div style={{ paddingTop: 12 }} >
                <SubContainer>
                    <DynamicSkeleton active h="500px" />
                </SubContainer>
            </div>
            <div style={{ paddingTop: 12 }} >
                <SubContainer>
                    <DynamicSkeleton active h="500px" />
                </SubContainer>
            </div>

        </Container>
    )
}

export default HomePageBannerSkeleton
