import axios from "axios";
import { BASE_URL, isAuthorized } from "../utils";

const updateAddressApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        updateCustomerAddress(id:${data.params.data.id}, input: {
            region: {
                region: "${data.params.data.stateName}"
                region_id:${data.params.data.region_id}
              }
              country_code: IN
              street: ["${data.params.data.address}"]
              telephone: ${data.params.data.phone}
              postcode: "${data.params.data.pinCode}"
              city: "${data.params.data.city}"
              firstname: "${data.params.data.firstName}"
              lastname: "${data.params.data.lastName}"
          }) {
            id
            firstname
            lastname
            street
            city
            region {
              region_code
              region
            }
            postcode
            country_code
            telephone
          }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default updateAddressApi;
