import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_PRODUCT_CART_LIST,
    getProductCartListSuccess,
    getProductCartListFail,
} from '../actions/addProductCartAction'
import get from 'lodash/get';
import getProductCartApi from '../apis/getProductCart';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}

export function* getProductCartSaga(action) {

    try {
        const response = yield call(() => getProductCartApi(action))
        if (response.errors) {
            yield put(getProductCartListFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(getProductCartListSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(getProductCartListFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_PRODUCT_CART_LIST, getProductCartSaga);
}