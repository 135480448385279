import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_BRAND_DETAILS,
    getBrandDetailsSuccess,
    getBrandDetailsFail,
} from '../actions/getBrandDetailsAction'

import getBrandDetailsApi from '../apis/getBrandDetailsApi'


export function* getBrandDetailsSaga(action) {
    try {
        const response = yield call(() => getBrandDetailsApi(action.params))

        if (response.errors) {
            yield put(getBrandDetailsFail(response, action))
        } else {
            yield put(getBrandDetailsSuccess(response, action))
        }
    } catch (e) {
        yield put(getBrandDetailsFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_BRAND_DETAILS, getBrandDetailsSaga);
}