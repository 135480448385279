export const DELETE_WISH_LIST = "DELETE_WISH_LIST"
export const DELETE_WISH_LIST_SUCCESS = "DELETE_WISH_LIST_SUCCESS"
export const DELETE_WISH_LIST_FAIL = "DELETE_WISH_LIST_FAIL"
export const DELETE_WISH_LIST_RESET = "DELETE_WISH_LIST_RESET"

export const deleteWishList = (params) => {
    return { type: DELETE_WISH_LIST, params }
}
export const deleteWishListSuccess = (response) => {
    return { type: DELETE_WISH_LIST_SUCCESS, response }
}
export const deleteWishListFail = (response) => {
    return { type: DELETE_WISH_LIST_FAIL, response }
}
export const deleteWishListReset = () => {
    return { type: DELETE_WISH_LIST_RESET }
}