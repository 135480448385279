import axios from "axios";
import { isAuthorized,BASE_URL } from "../utils";

const getSearchApi = async (params) => {
  let cancelToken
  //Save the cancel token for the current request
  cancelToken = axios.CancelToken.source()
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    cancelToken: cancelToken.token,
    data: {
      query: `{
                products(search: "${params.data}", currentPage:${params.currentPage}, pageSize: 20) {
                    total_count
                           items {
                             ... on CustomizableProductInterface {
                                __typename

                                options {
                                ... on CustomizableFieldOption {
                                    title
                                }
                                ... on CustomizableRadioOption{
                                    title

                                }
                                }
                            }
                             __typename
                                 sku
                                  id
                                  name
                                  is_brand
                                  brand_name
                                  options_container
                                  url_key
                                  product_tag
                                  thumbnail {
                                    disabled
                                    label
                                    position
                                    url
                                  }
                                  short_description {
                                    html
                                  }
                                  description {
                                    html
                                  }
                                   special_price
                                  price_range {
                                    minimum_price {
                                      regular_price {
                                        value
                                      }
                                       discount {
                                          percent_off
                                        }
                                    }
                                  }
                                  categories {
                                    name
                                    image
                                    breadcrumbs {
                                      category_id
                                      category_level
                                      category_name
                                      category_url_key
                                      category_url_path
                                    }
                                  }
                                  image {
                                    url
                                    }
                                  media_gallery {
                                          position
                                          url
                                  }
                                  }
                                  page_info {
                                    page_size
                                    current_page
                                  }
                                }
                              }`,
      variables: {
        category_id: params.id,
      },
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default getSearchApi;
