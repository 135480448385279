import {
  UPDATE_SEARCH_TEXT,
  UPDATE_SEARCH_TEXT_RESET
} from "../actions/updateSearchTextAction";

const initialState = {
  searchTextData: "",
  productTag: ""
};


export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SEARCH_TEXT:
      return {
        ...state,
        searchTextData: action.payload,
        productTag: action.payload,
      };
    case UPDATE_SEARCH_TEXT_RESET:
      return initialState
    default:
      return state;
  }
}
