import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const reOrderApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                mutation{
                    reorderItems(orderNumber: "${params.orderNumber}"){
                        cart {
                        id
                        items {
                            product {
                            sku
                            name
                            }
                            quantity
                            prices {
                            price {
                                value
                            }
                            }
                        }
                        }
                        userInputErrors{
                        code
                        message
                        path
                        }
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default reOrderApi;
