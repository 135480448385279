import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_SHIPPING_AMOUNT_REQUEST,
    fetchShippingAmountSuccess,
    fetchShippingAmountFail,
} from '../actions/fetchShippingAmountAction';


import fetchShippingAmountApi from '../apis/fetchShippingAmountApi';
import get from 'lodash/get';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}

export function* fetchShippingAmountSaga(action) {
    try {
        const response = yield call(() => fetchShippingAmountApi(action.params))
        if (response.errors) {
            yield put(fetchShippingAmountFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(fetchShippingAmountSuccess(response, action))
            yield common(action, true, response);
        }
    } catch (e) {
        yield put(fetchShippingAmountFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_SHIPPING_AMOUNT_REQUEST, fetchShippingAmountSaga);
}