import { call, put, takeLatest } from 'redux-saga/effects'


// action
import {
    BILLING_ADDRESS,
    billingAddressSuccess,
    billingAddressFail
} from '../actions/billingAddressAction'

// api

import billingAddressApi from '../apis/billingAddressApi'

export function* billingAddressSaga(action) {
    try {
        const response = yield call(() => billingAddressApi(action.params))

        if (response.errors) {
            yield put(billingAddressFail(response, action))
        }
        else {
            yield put(billingAddressSuccess(response, action))
        }
    }
    catch (e) {
        yield put(billingAddressFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(BILLING_ADDRESS, billingAddressSaga)
}