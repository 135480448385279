import axios from 'axios'
import { BASE_URL, isAuthorized } from '../utils';


const getCategoryListApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: 'post',
        data: {
            query: `
                query navigationMenu($id: Int!) {
                    category(id: $id) {
                        id
                        name
                        position
                        image
                        children {
                            children_count
                            id
                            name
                            position
                            image
                            url_key
                            children {
                                name
                                position
                                id
                                children_count
                                url_key
                                children {
                                    name
                                    position
                                    id
                                    children_count
                                    url_key
                                }
                            }
                        }
                    }
                }
            `,
            variables: {
                id: params.id,
            }
        },
        headers: isAuthorized(),
    })

    return response.data
}

export default getCategoryListApi