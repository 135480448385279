import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';

const addProductToCartApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        addProductsToCart(
                            cartId: "${params.cart_id}",
                             cartItems: [
                                 {
                                     quantity: ${params.quantity}
                                     parent_sku:"${params.parent_sku}"
                                      sku: "${params.sku}"
                                      selected_options : ["${params.uid}","${params.productUid}"]
                                       entered_options: [{
                                        uid: "${params.textUid}"
                                        value: "${params.instruction}"
                                        }]
                                    }
                                ]
                            ) {
                            cart {
                            items {
                                id
                                product {
                                sku
                                stock_status
                                url_key
                                name
                                price_range {
                                    minimum_price {
                                    final_price {
                                        currency
                                        value
                                    }
                                    }
                                }
                                media_gallery {
                                    position
                                    url
                                }
                                short_description {
                                    html
                                }
                                description {
                                    html
                                }
                                }
                                quantity
                            }
                            prices {
                                subtotal_including_tax {
                                value
                                }
                                grand_total {
                                value
                                }
                                discounts {
                                amount {
                                    value
                                }
                                label
                                }
                            }
                            }
                        }
                    }`,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data;



};

export default addProductToCartApi;
