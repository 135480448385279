export const FETCH_POLICY_CMS_REQUEST = 'FETCH_POLICY_CMS_REQUEST'
export const FETCH_POLICY_CMS_SUCCESS = 'FETCH_POLICY_CMS_SUCCESS'
export const FETCH_POLICY_CMS_FAIL = 'FETCH_POLICY_CMS_FAIL'

export const fetchPolicyCmsRequest = (params) => {
    return { type: FETCH_POLICY_CMS_REQUEST, params }
}

export const fetchPolicyCmsSuccess = (response) => {
    return { type: FETCH_POLICY_CMS_SUCCESS, response }
}

export const fetchPolicyCmsFail = (response) => {
    return { type: FETCH_POLICY_CMS_FAIL, response }
}