import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const getProductCartApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation{
        addSimpleProductsToCart(
          input: {
            cart_id: "${data.params.data.cartId}"
            cart_items: [
              {
                data: {
                  quantity: ${data.params.data.quantity}
                  sku: "${data.params.data.sku}"
                }
              }
            ]
          }
        ) {
          cart {
            prices{
              subtotal_including_tax{
                value
              }
              grand_total{
                value
              }
              discounts{
                amount{
                  value
                }
                label
              }
            }
            items {
              id
              product {
                sku
                stock_status
                url_key
                name
                price_range{
                  minimum_price{
                    final_price{
                      currency
                      value
                    }
                  }
                }
                media_gallery{
                  position
                  url
                }
                short_description{
                  html
              }
              description{
                  html
              }
              }
              quantity
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default getProductCartApi;
