import axios from "axios";
import { isAuthorized, BASE_URL } from '../utils';


const fetchPlaceOrderApi = async (params) => {

  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        placeOrder(input: {cart_id: "${params.data.cartId}"}) {
          order {
            order_number
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response;
};

export default fetchPlaceOrderApi;
