import { call, put, takeLatest } from 'redux-saga/effects'

// action
import { GET_GUEST_SHIPPIGO_TOKEN, getGuestShippigoTokenSuccess, getGuestShippigoTokenFail } from '../actions/getGuestShippigoTokenAction'

// api
import getGuestShippigoTokenApi from '../apis/getGuestShippigoTokenApi'

export function* getGuestShippigoTokenSaga(action) {
    try {
        const response = yield call(() => getGuestShippigoTokenApi(action.params))
        if (response.data.status === "FAILED") {
            yield put(getGuestShippigoTokenFail(response, action))
        }
        else {
            yield put(getGuestShippigoTokenSuccess(response, action))
        }
    }
    catch (e) {
        yield put(getGuestShippigoTokenFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_GUEST_SHIPPIGO_TOKEN, getGuestShippigoTokenSaga)
}