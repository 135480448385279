import axios from 'axios'
import { BASE_URL } from '../utils'

const updateCustomerAddressApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `
             mutation {
                    updateCustomerAddress(id:${params.formId}, input: {
                        firstname:"${params.first_name}",
                        lastname:"${params.last_name}",
                        street:["${params.address}"],
                      	postcode:"${params.pincode}",
                        city:"${params.city}",
                      region:{
                        region:"${params.state}",
                        region_id: ${params.state_id},
                        region_code:"${params.state_code}"
                      },
                        telephone:"${params.mobile}",
                    }) {
                        id
                        firstname
                        lastname
                        street
                        postcode
                        city
                        region {
                          region
                          region_id
                          region_code
                        }
                        telephone
                    }
                    }
            `
    },
    headers: {
      Authorization: `Bearer ${params.customerToken}`
    }
  })

  return response.data
}

export default updateCustomerAddressApi
