import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_CART_COUNT,
    getCartCountSuccess,
    getCartCountFail,
} from '../actions/getCartCountAction'

import getCartCountApi from '../apis/getCartCountApi'


export function* getCartCountSaga(action) {
    try {
        const response = yield call(() => getCartCountApi(action.params))
        if (response.errors) {
            yield put(getCartCountFail(response, action))
        } else {
            yield put(getCartCountSuccess(response, action))
        }
    } catch (e) {
        yield put(getCartCountFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_CART_COUNT, getCartCountSaga);
}