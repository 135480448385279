import { CHECK_DELIVERY, CHECK_DELIVERY_SUCCESS, CHECK_DELIVERY_FAIL, CHECK_DELIVERY_RESET } from '../actions/checkDeliveryAction';


const initialState = {
    apiState: "", // loading, success, error, reset 
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_DELIVERY:
            return {
                ...state,
                apiState: "loading"
            }
        case CHECK_DELIVERY_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.message
            }
        case CHECK_DELIVERY_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case CHECK_DELIVERY_RESET:
            return initialState

        default:
            return state
    }
}