import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    PLACE_RAZORPAY_ORDER,
    placeRazorpayOrderSuccess,
    placeRazorpayOrderFail,
} from '../actions/placeRazorpayOrderAction'

import placeRazorpayOrderApi from '../apis/placeRazorpayOrderApi';


export function* placeRazorpayOrderSaga(action) {

    try {
        const response = yield call(() => placeRazorpayOrderApi(action.params))
        if (response.errors) {
            yield put(placeRazorpayOrderFail(response, action))
        } else {
            yield put(placeRazorpayOrderSuccess(response, action))
        }
    } catch (e) {
        yield put(placeRazorpayOrderFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(PLACE_RAZORPAY_ORDER, placeRazorpayOrderSaga);
}