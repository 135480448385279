import { call, put, takeLatest } from 'redux-saga/effects'

// Action
import {
    SET_SHIPPING_METHOD,
    setShippingMethodSuccess,
    setShippingMethodFail
} from '../actions/setShippingMethodAction'

// api

import setShippingMethodApi from '../apis/setShippingMethodApi'


export function* setShippingMethodSaga(action) {
    try {
        const response = yield call(() => setShippingMethodApi(action.params))
        yield put(setShippingMethodSuccess(response, action))
    }
    catch (e) {
        yield put(setShippingMethodFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SET_SHIPPING_METHOD, setShippingMethodSaga)
}