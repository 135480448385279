export const REQUEST_PASSWORD_RESET_EMAIL = 'REQUEST_PASSWORD_RESET_EMAIL'
export const REQUEST_PASSWORD_RESET_EMAIL_SUCCESS = 'REQUEST_PASSWORD_RESET_EMAIL_SUCCESS'
export const REQUEST_PASSWORD_RESET_EMAIL_FAIL = 'REQUEST_PASSWORD_RESET_EMAIL_FAIL'
export const REQUEST_PASSWORD_RESET_EMAIL_RESET = 'REQUEST_PASSWORD_RESET_EMAIL_RESET'

export const requestPasswordResetEmail = (params) => {
    return { type: REQUEST_PASSWORD_RESET_EMAIL, params }
}

export const requestPasswordResetEmailSuccess = (response) => {
    return { type: REQUEST_PASSWORD_RESET_EMAIL_SUCCESS, response }
}

export const requestPasswordResetEmailFail = (response) => {
    return { type: REQUEST_PASSWORD_RESET_EMAIL_FAIL, response }
}

export const requestPasswordResetEmailReset = () => {
    return { type: REQUEST_PASSWORD_RESET_EMAIL_RESET }
}