export const GUEST_SET_SHIPPING_ADDRESS = 'GUEST_SET_SHIPPING_ADDRESS'
export const GUEST_SET_SHIPPING_ADDRESS_SUCCESS = 'GUEST_SET_SHIPPING_ADDRESS_SUCCESS'
export const GUEST_SET_SHIPPING_ADDRESS_FAIL = 'GUEST_SET_SHIPPING_ADDRESS_FAIL'
export const GUEST_SET_SHIPPING_ADDRESS_RESET = 'GUEST_SET_SHIPPING_ADDRESS_RESET'

export const guestSetShippingAddress = (params) => {
    return { type: GUEST_SET_SHIPPING_ADDRESS, params }
}

export const guestSetShippingAddressSuccess = (response) => {
    return { type: GUEST_SET_SHIPPING_ADDRESS_SUCCESS, response }
}

export const guestSetShippingAddressFail = (response) => {
    return { type: GUEST_SET_SHIPPING_ADDRESS_FAIL, response }
}

export const guestSetShippingAddressReset = () => {
    return { type: GUEST_SET_SHIPPING_ADDRESS_RESET }
}
