import styled, { css } from "styled-components";
import { Skeleton } from "antd";
export const Container = styled.div`
  display: flex;
  padding: 5px;
  align-items: flex-start;
  border-radius: 10px;
  justify-content: flex-start;
  width: 100%;
  margin-top: 20px;
  
  
    @media screen and (max-width: 768px){
      display: flex;
      flex-direction: column;

    }
`;


export const LeftSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media ${(props) => props.theme.device.tablet} {
   margin: 10px 10px 10px 0px;
    overflow: hidden;
      
  }
  .ant-skeleton-button{
    width: 100% !important;
    height: 40px;
  }
  @media screen and (max-width: 768px){
     .ant-skeleton-button{
    width: 100% !important;
    height: 40px;
  }
  }
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media screen and (max-width: 420px){
    width: 100%;
    margin-right: 8px;
  }
`

export const RightSection = styled.div`
  width: 50%;
  text-align: center;
  /* margin-left: 20px; */
  margin-top: 20px;

  @media ${(props) => props.theme.device.tablet} {
   margin: 10px 10px 10px 0px;
    width: 100%;
  }
  .ant-skeleton-button{
    height: 200px !important;
  }
`;

export const ImageSkeletonLeft = styled(Skeleton.Button)`
margin: 5px;
width: 100%;
`;
export const LeftBlock = styled.div`
display: flex;
align-items: center;
margin-bottom: 10px;
width: 100% !important;
@media screen and (max-width: 768px){
  width: 100% !important;
}
`
export const ImageSkeletonLeftRow = styled(Skeleton.Button)`
    /* width: 758px !important; */
   margin: 5px;
@media screen and (max-width:768px){
  overflow: hidden;
  width: 100% !important;
  /* max-width: 576px !important; */
}
@media screen and (max-width:420px){
  overflow: hidden;
  width: 100% !important;
  max-width: 350px !important;
}
`

export const ImageSkeletonRight = styled(Skeleton.Button)`


`


