import styled, { css } from "styled-components";
import { Skeleton } from "antd";
export const Container = styled.div`
  /* padding: 1rem; */
  display: flex;
  margin-top: 40px;

  @media ${(props) => props.theme.device.tablet} {
    flex-direction: column;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
   @media only screen and (max-width: 768px){
      padding: 0px 20px !important;
      margin: 30px 0px;
  }
`;

export const LeftSection = styled.div`
width: 70%;
margin: 20px;

@media ${(props) => props.theme.device.tablet} {
  width: 100%;
  margin: 0px;
}
 
`;

export const RightSection = styled.div`
  margin: 20px;
  width: 30%;
  text-align: right;

  @media ${(props) => props.theme.device.tablet} {
    width: 100%;
    margin: 0px; 
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 60px;
  height: 60px;
`;

export const Title = styled.p`
  margin: 0;
  margin-top: 20px;
  margin-bottom: 10px;
  @media ${(props) => props.theme.device.tablet} {
    display: ${(props) => (props.hideMobile ? "none" : "flex")};
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const SubTitle = styled.span`
  display: flex;
  margin: 25px 0;
  @media ${(props) => props.theme.device.tablet} {
    display: ${(props) => (props.hideMobile ? "none" : "flex")};
    margin-bottom: ${(props) => (props.hideMobile ? "0px" : "10px")};
  }
`;

export const Xskeleton = styled(Skeleton.Button)`
  margin-bottom: ${(props) => (props.mb ? props.mb : 0)};
  margin-right: ${(props) => (props.mr ? props.mr : 0)};
 // margin:10px;
  ${(props) =>
    props.type === "box" &&
    css`
      width: 100%;
      height: 100%;

      .ant-skeleton-button {
        width: ${(props) => (props.w ? props.w : "100%")};
        height: ${(props) => (props.h ? props.h : "100%")};
        border-radius: ${(props) => (props.br ? props.br : "0")};
      }
    `}
    @media only screen and(min-width: '768px'){
        height: '100px';
    }

  ${(props) =>
    props.type === "text" &&
    css`
      width: ${(props) => (props.w ? props.w : "100%")};
      height: ${(props) => (props.h ? props.h : "100%")};

      .ant-skeleton-button {
        width: 100%;
        height: 100%;
        
      }
    `}
`;

export const ImageSkeleton = styled(Skeleton.Image)`
&&.ant-skeleton-element .ant-skeleton-image{
  height: 130px;
  width: 200px;
  margin-bottom:5px;
}
`;

export const QuantityContainer = styled.div`
  display: flex;
  justify-content: start;
  margin: 20px 0;
`;
export const QuantityContent = styled.div`
margin-right: 30px;
`;


export const CardContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;

`

export const ImageSkeletonRight = styled(Skeleton.Image)`
&&.ant-skeleton-element .ant-skeleton-image{
  height: 300px;
  width: 300px;
  margin:10px;
}`
