import React, { useState } from "react";
import {
  Container,
  ContactHeading,
  MenuContainer,
  TabsComponent,
} from "./style";
import MobileTabs from "../../components/MobileTabs/mobileTabs";

const Local = (props) => {
  const isMobile = window.innerWidth < 768;

  {
    return (
      <>
        {isMobile && <MobileTabs tabsKey="local" history={props.history} />}
        <Container>
          <ContactHeading>Local</ContactHeading>
        </Container>
      </>
    );
  }
};

export default Local;
