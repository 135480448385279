import React, { useEffect, useState } from "react"
import { Button, Form, Input, Alert, notification } from "antd"
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Lottie from "react-lottie"

// components
import { Container, Box } from "./ForgotPasswordStyle"

// actions
import { requestPasswordResetEmail, requestPasswordResetEmailReset } from '../../actions/requestPasswordResetEmailAction'

// others
import { getCartId, setCartId, setCustomerToken } from '../../utils'
import forgotAnimation from "../../lottie/forget-password.json"

const ForgotPassword = props => {
    // variables
    const [redirect, setRedirect] = useState([false, ""])
    const guestCartId = getCartId()
    const [formData, setFormData] = useState({
        email: "",
    })
    const forgotOptions = {
        loop: true,
        autoplay: true,
        animationData: forgotAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const {
        requestPasswordResetEmail, requestPasswordResetEmailReset, requestPasswordResetEmailState, handleLogin
    } = props

    // callbacks
    useEffect(() => {
        return () => {
            requestPasswordResetEmailReset()
        }
    }, [])

    useEffect(() => {
        if (requestPasswordResetEmailState.apiState === "error") {
            notification["error"]({
                message: 'Something is not right.',
                placement: "bottomRight"
            });
        }
    }, [requestPasswordResetEmailState])



    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        requestPasswordResetEmail({
            email: formData.email
        })
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <Container>
                {requestPasswordResetEmailState.apiState !== "success" &&
                    <Box>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                            <Lottie
                                options={forgotOptions}
                                height={150}
                                width={150}
                                style={{ margin: "none" }}
                            />
                            <h1 style={{ marginTop: 16 }} >Forgot password?</h1>
                        </div>
                        <Form
                            layout="vertical"
                            onFinish={handleSubmit}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    { required: true, message: 'Required' },
                                    { type: 'email', message: 'Email is not valid' },
                                ]}
                            >
                                <Input name="email" placeholder="Email" onChange={handleOnChange} />
                            </Form.Item>
                            <Button type="primary" htmlType="submit" block loading={requestPasswordResetEmailState.apiState === "loading" ? true : false}  >Send Email</Button>
                            <p style={{ marginTop: 8 }}>Login from <Link onClick={handleLogin} ><b>here</b></Link></p>
                        </Form>
                    </Box>
                }
                {requestPasswordResetEmailState.apiState === "success" &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                        <Alert message={"We have sent you an email with a link to reset your password. Check your inbox."} type="success" style={{ marginBottom: 16 }} />
                    </div>
                }
            </Container>
        </>
    )
}

const mapStateToProps = (state) => ({
    requestPasswordResetEmailState: state.requestPasswordResetEmail,
});

const mapDispatchToProps = (dispatch) => ({
    requestPasswordResetEmail: (params) => dispatch(requestPasswordResetEmail(params)),
    requestPasswordResetEmailReset: () => dispatch(requestPasswordResetEmailReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);