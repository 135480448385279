import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_STATE_REQUEST,
    fetchStateSuccess,
    fetchStateFail,
} from '../actions/fetchStateAction'
import { globalLoaderRequest, globalLoaderSuccess, globalLoaderFail } from '../actions/showGlobalLoaderAction';

import fetchStateApi from '../apis/fetchStateApi';


export function* fetchStateSaga(action) {

    try {
        // yield put(globalLoaderRequest(true))
        const response = yield call(() => fetchStateApi(action))
        // yield put(globalLoaderRequest(false))

        if (response.errors) {
            yield put(fetchStateFail(response, action))
        } else {
            yield put(fetchStateSuccess(response, action))
            // yield put(globalLoaderSuccess())
        }
    } catch (e) {
        yield put(fetchStateFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_STATE_REQUEST, fetchStateSaga);
}