import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    RE_ORDER,
    reOrderSuccess,
    reOrderFail,
} from '../actions/reOrderActions'

import reOrderApi from '../apis/reOrderApi'


export function* reOrderSaga(action) {

    try {
        const response = yield call(() => reOrderApi(action.params))
        if (response.errors) {
            yield put(reOrderFail(response, action))
        } else {
            yield put(reOrderSuccess(response, action))
        }
    } catch (e) {
        yield put(reOrderFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(RE_ORDER, reOrderSaga);
}