import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const fetchStateApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: ` {
        country(id: "IN") {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
            available_regions {
                id
                code
                name
            }
        }
    }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchStateApi;
