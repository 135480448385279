import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_MERGE_CART_ID,
    getMergeCartIdSuccess,
    getMergeCartIdFail,
} from '../actions/mergeCartIdAction'

import getMergeCartIdApi from '../apis/mergeCartIdApi'


export function* getMergeIdSaga(action) {

    try {
        const response = yield call(() => getMergeCartIdApi(action))
        if (response.errors) {
            yield put(getMergeCartIdFail(response, action))
        } else {
            yield put(getMergeCartIdSuccess(response, action))
        }
    } catch (e) {
        yield put(getMergeCartIdFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_MERGE_CART_ID, getMergeIdSaga);
}