export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS'
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL'

export const signUpRequest = (params) => {
    return { type: SIGN_UP_REQUEST, params }
}

export const signUpSuccess = (response) => {
    return { type: SIGN_UP_SUCCESS, response }
}

export const signUpFail = (response) => {
    return { type: SIGN_UP_FAIL, response }
}

