export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST'
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS'
export const FETCH_ORDERS_FAIL = 'FETCH_ORDERS_FAIL'

export const fetchOrdersRequest = (params) => {
    return { type: FETCH_ORDERS_REQUEST, params }
}

export const fetchOrdersSuccess = (response) => {
    return { type: FETCH_ORDERS_SUCCESS, response }
}

export const fetchOrdersFail = (response) => {
    return { type: FETCH_ORDERS_FAIL, response }
}