import React, { useState, useEffect } from 'react'
import { Collapse, Menu, Dropdown, Modal, List } from "antd";
import { MenuContainer, TabsComponent } from './style'


const MobileTabs = (props) => {
  const [activeTab, setActiveTab] = useState("shop");

  const { TabPane } = TabsComponent;
  useEffect(() => {
    setActiveTab(props.tabsKey);
  }, [props.tabsKey])


  const tabChange = (key) => {
    setActiveTab(key);
    switch (key) {
      case "shop":
        return props.history.push("/categories");
        break;
      case "local":
        return props.history.push("/local");
        break;
      case "gift":
        return props.history.push("/pantry");
        break;
      default:
        return props.history.push("/");
    }
  };

  return (
    <div>
      <MenuContainer>
        <TabsComponent onChange={tabChange}>
          {/* <TabPane tab="SHOP" key="shop" /> */}
          {/* <TabPane tab="LOCAL" key="local" />
            <TabPane tab="GIFT" key="gift" /> */}
        </TabsComponent>
      </MenuContainer>
    </div>
  )
}

export default MobileTabs