import {
    ADD_PRODUCT_TO_CART,
    ADD_PRODUCT_TO_CART_SUCCESS,
    ADD_PRODUCT_TO_CART_FAIL,
    ADD_PRODUCT_TO_CART_RESET,
} from '../actions/addProductToCartAction'


const initialState = {
    apiState: "", // loading, success, error
    data: [],
    errorType: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_PRODUCT_TO_CART:
            return {
                ...state,
                apiState: "loading",
            }

        case ADD_PRODUCT_TO_CART_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case ADD_PRODUCT_TO_CART_FAIL:
            return {
                ...state,
                apiState: "error",
                errorType: action.response.errors[0] && action.response.errors[0].extensions && action.response.errors[0].extensions.category || "system"
            }

        case ADD_PRODUCT_TO_CART_RESET:
            return initialState


        default:
            return state
    }
}
