import {
    GET_CUSTOMER_INFO,
    GET_CUSTOMER_INFO_SUCCESS,
    GET_CUSTOMER_INFO_FAIL,
    GET_CUSTOMER_INFO_RESET
} from '../actions/getCustomerInfoAction'
import _ from "lodash"

const initialState = {
    apiState: "", // loading, success, error
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CUSTOMER_INFO:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_CUSTOMER_INFO_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case GET_CUSTOMER_INFO_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_CUSTOMER_INFO_RESET:
            return initialState


        default:
            return state
    }
}
