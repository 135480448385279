import styled from "styled-components";
import { Input, Button } from "antd";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const LoginWrapper = styled.div`
  width: 500px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px #707070;
  padding: 20px;
`;

export const InputContainer = styled(Input)`
  height: 45px;
  margin-top: 20px;
  max-width: 300px;
  border-color: #8c8484;
  
`;

export const Heading = styled.div`
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: uppercase;
  margin-top: 20px;
`;

export const LoginButton = styled(Button)`
  margin-top: 20px;
  width: 150px;
  height: 45px;
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.white};
  background-color: #585858;
  &:hover,
  &:focus,
  &:active {
    background-color: #585858;
    color: white;
    border-color: white;
  }
`;

export const SignUpContainer = styled.div`
  margin-top: 20px;
  font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  font-weight: ${(props) => props.theme.fontWeight};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;

  & span {
    font-weight: ${(props) => props.theme.fontWeightBold};
    cursor: pointer;
  }
`;
