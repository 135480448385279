export const BILLING_ADDRESS = "BILLING_ADDRESS"
export const BILLING_ADDRESS_SUCCESS = "BILLING_ADDRESS_SUCCESS"
export const BILLING_ADDRESS_FAIL = "BILLING_ADDRESS_FAIL"
export const BILLING_ADDRESS_RESET = "BILLING_ADDRESS_RESET"

export const billingAddress = (params) => {
    return { type: BILLING_ADDRESS, params }
}
export const billingAddressSuccess = (response) => {
    return { type: BILLING_ADDRESS_SUCCESS, response }
}
export const billingAddressFail = (response) => {
    return { type: BILLING_ADDRESS_FAIL, response }
}
export const billingAddressReset = () => {
    return { type: BILLING_ADDRESS_RESET }
}