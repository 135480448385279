export const FETCH_BRAND_BY_ID = 'FETCH_BRAND_BY_ID'
export const FETCH_BRAND_BY_ID_SUCCESS = 'FETCH_BRAND_BY_ID_SUCCESS'
export const FETCH_BRAND_BY_ID_FAIL = 'FETCH_BRAND_BY_ID_FAIL'
export const FETCH_BRAND_BY_ID_RESET = 'FETCH_BRAND_BY_ID_RESET'

export const fetchBrandById = (params) => {
    return { type: FETCH_BRAND_BY_ID, params }
}

export const fetchBrandByIdSuccess = (response) => {
    return { type: FETCH_BRAND_BY_ID_SUCCESS, response }
}

export const fetchBrandByIdFail = (response) => {
    return { type: FETCH_BRAND_BY_ID_FAIL, response }
}

export const fetchBrandByIdReset = () => {
    return { type: FETCH_BRAND_BY_ID_RESET }
}