
import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';


import {
    FETCH_GUEST_CART_DATA,
    fetchGuestCartDataSuccess,
    fetchGuestCartDataFail,
} from '../actions/fetchGuestCartDataAction'

import fetchGuestCartDataApi from '../apis/fetchGuestCartDataApi'

function* common(action = { params: {} }, success, otherParams) {

    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* fetchGuestCartDataSaga(action) {

    try {
        const response = yield call(() => fetchGuestCartDataApi(action.params))
        if (response.errors) {

            yield put(fetchGuestCartDataFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(fetchGuestCartDataSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(fetchGuestCartDataFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_GUEST_CART_DATA, fetchGuestCartDataSaga);
}