export const GET_GUEST_SHIPPIGO_TOKEN = "GET_GUEST_SHIPPIGO_TOKEN"
export const GET_GUEST_SHIPPIGO_TOKEN_SUCCESS = "GET_GUEST_SHIPPIGO_TOKEN_SUCCESS"
export const GET_GUEST_SHIPPIGO_TOKEN_FAIL = "GET_GUEST_SHIPPIGO_TOKEN_FAIL"
export const GET_GUEST_SHIPPIGO_TOKEN_RESET = "GET_GUEST_SHIPPIGO_TOKEN_RESET"

export const getGuestShippigoToken = (params) => {
    return { type: GET_GUEST_SHIPPIGO_TOKEN, params }
}
export const getGuestShippigoTokenSuccess = (response) => {
    return { type: GET_GUEST_SHIPPIGO_TOKEN_SUCCESS, response }
}
export const getGuestShippigoTokenFail = (response) => {
    return { type: GET_GUEST_SHIPPIGO_TOKEN_FAIL, response }
}
export const getGuestShippigoTokenReset = () => {
    return { type: GET_GUEST_SHIPPIGO_TOKEN_RESET }
}