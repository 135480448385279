export const GET_BRAND_INFO = 'GET_BRAND_INFO'
export const GET_BRAND_INFO_SUCCESS = 'GET_BRAND_INFO_SUCCESS'
export const GET_BRAND_INFO_FAIL = 'GET_BRAND_INFO_FAIL'
export const GET_BRAND_INFO_RESET = 'GET_BRAND_INFO_RESET'

export const getBrandInfo = (params) => {
    return { type: GET_BRAND_INFO, params }
}

export const getBrandInfoSuccess = (response) => {
    return { type: GET_BRAND_INFO_SUCCESS, response }
}

export const getBrandInfoFail = (response) => {
    return { type: GET_BRAND_INFO_FAIL, response }
}

export const getBrandInfoReset = () => {
    return { type: GET_BRAND_INFO_RESET }
}