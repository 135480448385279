import { call, put, takeLatest } from 'redux-saga/effects'

import {
    RESET_PASSWORD,
    resetPasswordSuccess,
    resetPasswordFail,
} from '../actions/resetPasswordAction'

import resetPasswordApi from '../apis/resetPasswordApi'


export function* resetPasswordSaga(action) {
    try {
        const response = yield call(() => resetPasswordApi(action.params))

        if (response.errors) {
            yield put(resetPasswordFail(response, action))
        } else {
            yield put(resetPasswordSuccess(response, action))
        }
    } catch (e) {
        yield put(resetPasswordFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(RESET_PASSWORD, resetPasswordSaga);
}