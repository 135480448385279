import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    CHANGE_CUSTOMER_PASSWORD,
    changeCustomerPasswordSuccess,
    changeCustomerPasswordFail,
} from '../actions/changeCustomerPasswordAction'

import changeCustomerPasswordApi from '../apis/changeCustomerPasswordApi'


export function* changeCustomerPasswordSaga(action) {
    try {
        const response = yield call(() => changeCustomerPasswordApi(action.params))

        if (response.errors) {
            yield put(changeCustomerPasswordFail(response, action))
        } else {
            yield put(changeCustomerPasswordSuccess(response, action))
        }
    } catch (e) {
        yield put(changeCustomerPasswordFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(CHANGE_CUSTOMER_PASSWORD, changeCustomerPasswordSaga);
}