export const SHOW_ON_HOME_PAGE_BRAND_SECTION = "SHOW_ON_HOME_PAGE_BRAND_SECTION"
export const SHOW_ON_HOME_PAGE_BRAND_SECTION_FAIL = "SHOW_ON_HOME_PAGE_BRAND_SECTION_FAIL"
export const SHOW_ON_HOME_PAGE_BRAND_SECTION_SUCCESS = "SHOW_ON_HOME_PAGE_BRAND_SECTION_SUCCESS"
export const SHOW_ON_HOME_PAGE_BRAND_SECTION_RESET = "SHOW_ON_HOME_PAGE_BRAND_SECTION_RESET"


export const showOnHomePageBrandSection = (params) => {
    return { type: SHOW_ON_HOME_PAGE_BRAND_SECTION, params }
}
export const showOnHomePageBrandSectionFail = (response) => {
    return { type: SHOW_ON_HOME_PAGE_BRAND_SECTION_FAIL, response }
}
export const showOnHomePageBrandSectionSuccess = (response) => {
    return { type: SHOW_ON_HOME_PAGE_BRAND_SECTION_SUCCESS, response }
}
export const showOnHomePageBrandSectionReset = () => {
    return { type: SHOW_ON_HOME_PAGE_BRAND_SECTION_RESET }
}