import { call, put, takeLatest } from 'redux-saga/effects'


// action
import {
    UPDATE_CART_PRODUCT,
    updateCartProductSuccess,
    updateCartProductFail
} from '../actions/updateCartProductAction'

// api
import updateCartProductApi from '../apis/updateCartProductApi'

export function* updateCartProductSaga(action) {
    try {
        const response = yield call(() => updateCartProductApi(action.params))
        yield put(updateCartProductSuccess(response, action))
    }
    catch (e) {
        yield put(updateCartProductFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(UPDATE_CART_PRODUCT, updateCartProductSaga)
}