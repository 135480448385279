export const UPDATE_CART_PRODUCT = "UPDATE_CART_PRODUCT"
export const UPDATE_CART_PRODUCT_SUCCESS = "UPDATE_CART_PRODUCT_SUCCESS"
export const UPDATE_CART_PRODUCT_FAIL = "UPDATE_CART_PRODUCT_FAIL"
export const UPDATE_CART_PRODUCT_RESET = "UPDATE_CART_PRODUCT_RESET"


export const updateCartProduct = (params) => {
    return { type: UPDATE_CART_PRODUCT, params }
}
export const updateCartProductSuccess = (params) => {
    return { type: UPDATE_CART_PRODUCT_SUCCESS, params }
}
export const updateCartProductFail = (params) => {
    return { type: UPDATE_CART_PRODUCT_FAIL, params }
}
export const updateCartProductReset = (params) => {
    return { type: UPDATE_CART_PRODUCT_RESET, params }
}