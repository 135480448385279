import { GET_CART_ID_SUCCESS } from "../actions/createCartAction";
import get from 'lodash/get';

const initialState = {
    getCartIdData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
     
    case GET_CART_ID_SUCCESS:
      return {
        ...state,
        getCartIdData:action.response && action.response.data.createEmptyCart
      };
    default:
      return state;
  }
}
