import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_CATEGORY_LIST,
    getCategoryListSuccess,
    getCategoryListFail,
} from '../actions/categoryActions'

import getCategoryListApi from '../apis/getCategoryListApi'


export function* getCategoryListSaga(action) {
    try {
        const response = yield call(() => getCategoryListApi(action.params))

        if (response.errors) {
            yield put(getCategoryListFail(response, action))
        } else {
            yield put(getCategoryListSuccess(response, action))
        }
    } catch (e) {
        yield put(getCategoryListFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_CATEGORY_LIST, getCategoryListSaga);
}