import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    UPDATE_CUSTOMER_ADDRESS,
    updateCustomerAddressSuccess,
    updateCustomerAddressFail
} from '../actions/updateCustomerAddressAction'

// api
import updateCustomerAddressApi from '../apis/updateCustomerAddressApi'


export function* updateCustomerAddressSaga(action) {
    try {
        const response = yield call(() => updateCustomerAddressApi(action.params))
        if (response.errors) {
            yield put(updateCustomerAddressFail(response, action))
        }
        else {
            yield put(updateCustomerAddressSuccess(response, action))
        }
    }
    catch (e) {
        yield put(updateCustomerAddressFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(UPDATE_CUSTOMER_ADDRESS, updateCustomerAddressSaga)
}