// action
import {
    CHECK_GUEST_SERVICE_AVAILABILITY,
    CHECK_GUEST_SERVICE_AVAILABILITY_SUCCESS,
    CHECK_GUEST_SERVICE_AVAILABILITY_FAIL,
    CHECK_GUEST_SERVICE_AVAILABILITY_RESET
} from '../actions/checkGuestServiceAvailabilityAction'


const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CHECK_GUEST_SERVICE_AVAILABILITY:
            return {
                ...state,
                apiState: "loading"
            }
        case CHECK_GUEST_SERVICE_AVAILABILITY_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response) ? action.response : []
            }
        case CHECK_GUEST_SERVICE_AVAILABILITY_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case CHECK_GUEST_SERVICE_AVAILABILITY_RESET:
            return initialState

        default:
            return state
    }
}