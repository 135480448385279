export const FETCH_HELP_CMS_REQUEST = 'FETCH_HELP_CMS_REQUEST'
export const FETCH_HELP_CMS_SUCCESS = 'FETCH_HELP_CMS_SUCCESS'
export const FETCH_HELP_CMS_FAIL = 'FETCH_HELP_CMS_FAIL'

export const fetchHelpCmsRequest = (params) => {
    return { type: FETCH_HELP_CMS_REQUEST, params }
}

export const fetchHelpCmsSuccess = (response) => {
    return { type: FETCH_HELP_CMS_SUCCESS, response }
}

export const fetchHelpCmsFail = (response) => {
    return { type: FETCH_HELP_CMS_FAIL, response }
}