import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Spin } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import axios from "axios"
import HelpNumber from "../../components/HelpNumber/HelpNumber"

/* ==================== */
/* custom components */
/* ==================== */
import {
    Container, AddCardContainer, SummaryContainer, ArrowContainer, CartContainer,
    InfoContainer, InfoContent, SpinContainer,
} from "./style";
import OrderSummary from "../../components/OrderSummary/orderSummary";
import Cards from "./Cards/cards";
import CartSkeleton from "../../components/CartSkeleton/cartSkeleton";
import NoDataContianer from "../../components/NoDataContainer/NoData";

/* ===================== */
/* others */
/* ===================== */
import get from "lodash/get";
import _ from "lodash"
import { openSuccessNotification, openErrorNotification, SITE_URL } from '../../utils';
import { getCartId } from "../../utils"

/* ===================== */
/* actions */
/* ===================== */
import { removeItemCart } from "../../actions/removeItemCartAction";
import { updateProductCart } from "../../actions/updateCartAction";
import { getProductCartList } from "../../actions/addProductCartAction";
import { fetchCartData, fetchCartDataReset } from "../../actions/fetchCartDataAction";
import { fetchQuantity } from "../../actions/detailActions";
import { addCouponRequest } from "../../actions/addCouponAction";
import { removeCouponRequest } from "../../actions/removeCouponAction";
import { fetchPaymentMethodRequest } from "../../actions/fetchPaymentMethodAction";
import { fetchPlaceOrderRequest } from "../../actions/fetchPlaceOrderAction";
import { toggleCouponRequest } from '../../actions/toggleCouponAction';
import { getCartCountReset } from "../../actions/getCartCountAction";
import { fetchShippingAmountRequest } from '../../actions/fetchShippingAmountAction'

const PlaceOrder = (props) => {
    /* ============================ */
    // Variables
    /* ============================ */
    const {
        removeItemCart,
        updateProductCart,
        productCartListItems,
        productCartListPrices,
        updateListItemsPrices,
        updateListItems,
        removeListItem,
        removeListPrices,
        fetchCartData, fetchCartDataReset,
        cartDataItems,
        cartDataPrices,
        fetchQuantity,
        updateLoader,
        addCouponRequest,
        removeCouponRequest,
        cartLoader,
        toggleCouponRequest,
        fetchPaymentMethodRequest,
        paymentMethodData,
        fetchPlaceOrderRequest,
        placeOrderState,
        fetchShippingAmountRequest,
    } = props;
    const previousPath = useHistory();
    const [cardData, setCardData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [couponCheck, setCouponCheck] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('token'))
    const [payButton, showPayButton] = useState(null);
    const [redirect, setRedirect] = useState([false, ""])
    const [shippingData, setShippingData] = useState([])

    /* ============================ */
    // Callbacks
    /* ============================ */
    useEffect(() => {
        if (productCartListItems) {
            setCardData([...productCartListItems]);
            setPriceData(productCartListPrices);
        }
        return () => {
            setCardData([]);
        };
    }, [productCartListItems]);



    useEffect(() => {
        if (removeListItem) {
            setCardData([...removeListItem]);
            setPriceData(removeListPrices);
            fetchQuantity(removeListItem.length);
        }
        return () => {
            setCardData([]);
        };
    }, [removeListItem]);

    useEffect(() => {
        if (updateListItems) {
            setCardData([...updateListItems]);
            setPriceData(updateListItemsPrices);
            fetchQuantity(updateListItems.length);
        }
    }, [updateListItems]);

    useEffect(() => {
        const cart_id = getCartId();
        fetchCartData({
            cart_id,
            successAction: (data) => {
                setShippingData(data.data.cart.shipping_addresses && data.data.cart.shipping_addresses[0].available_shipping_methods || null)


                const coupon = get(data, "data.cart.applied_coupons[0].code");
                if (coupon) {
                    setAppliedCoupon(coupon);
                    setCouponCheck(true);
                }
            },
        });
    }, []);

    useEffect(() => {
        if (cartDataItems) {
            setCardData([...cartDataItems]);
            setPriceData(cartDataPrices);
            fetchQuantity(cartDataItems.length);
        }

        return () => {
            setCardData([]);
        };
    }, [cartDataItems]);

    useEffect(() => {
        if (placeOrderState.apiState === "success") {
            localStorage.removeItem("guestCartId");
        }
    }, [placeOrderState])

    useEffect(() => {
        if (paymentMethodData) {
            const cartId = getCartId();
            const data = {
                cartId: cartId,
            };
            fetchPlaceOrderRequest({
                data,
                successAction: (data) => {
                    const orderId = get(data, "data.placeOrder.order.order_number")
                    localStorage.removeItem("cartId")
                    getCartCountReset()

                    displayRazorpay(orderId)
                },
            })
        }
    }, [paymentMethodData])

    /* ============================ */
    // Functions
    /* ============================ */
    const goToPreviousPath = () => {
        previousPath.goBack();
    };

    const removeItem = (id) => {
        const cartId = getCartId();
        const data = {
            cart_id: cartId,
            cart_item_id: id,
        };
        removeItemCart(data);
    };

    const updateProductList = (data) => {
        const cartId = getCartId();
        const updatedData = {
            cartId: cartId,
            cart_item_id: parseInt(data.cart_item_id),
            quantity: data.quantity,
        };
        updateProductCart({
            updatedData,
            errorAction: (data) => {
                openErrorNotification('Requested quantity is not available.')
                handleCartData();
            },
            successAction: (data) => {
            },
        });
    };

    const handleCheckout = () => {
        if (token) {
            props.history.push("/checkout");
        } else {
            props.history.push("/login");
        }
    };


    const addCouponCode = (item) => {
        addCouponRequest({
            item,
            errorAction: (data) => {

                openErrorNotification(data.errors[0].message)
            },
            successAction: (data) => {
                const couponCode = get(data,
                    "data.applyCouponToCart.cart.applied_coupons[0].code")
                setAppliedCoupon(couponCode);
                setCouponCheck(true);
                const couponDataItem = get(
                    data,
                    "data.applyCouponToCart.cart.items"
                );
                const couponDataPrice = get(
                    data,
                    "data.applyCouponToCart.cart.prices"
                );
                toggleCouponRequest(true)
                openSuccessNotification('coupon applied successfully');
                setCardData([...couponDataItem]);
                setPriceData({ ...couponDataPrice, discountApplied: true });
            },
        });
    };

    const removeCouponCode = (item) => {
        removeCouponRequest({
            item,
            errorAction: () => {
                openErrorNotification('Failed to remove code.')
            },
            successAction: (data) => {
                openSuccessNotification('coupon code removed');
                setCouponCheck(false);
                setAppliedCoupon(null);
                const couponDataItem = get(
                    data,
                    "data.removeCouponFromCart.cart.items"
                );

                const couponDataPrice = get(
                    data,
                    "data.removeCouponFromCart.cart.prices"
                );
                toggleCouponRequest(false)
                setCardData([...couponDataItem]);
                setPriceData({ ...couponDataPrice, discountApplied: false });
            },
        });
    };

    const handleCartData = () => {
        const cartId = getCartId();
        fetchCartData({
            cartId,
            successAction: (data) => {
            },
        });

    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(orderId) {

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const result = await axios({
            method: 'post',
            url: `${SITE_URL()}/rest/default/V1/rzpay/first-api/`,
            data: {
                order_id: orderId
            },
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "authorization": `Bearer uchz0at3uos0eagb4ngmmnespkermn3g`
            }
        })


        if (!result) {
            alert("Server error. Are you online?");
            return;
        }

        const rzpData = JSON.parse(result.data)

        const options = {
            key: "rzp_live_CQNGe9SqJUIEOz", // Enter the Key ID generated from the Dashboard
            amount: rzpData.rzp_amount.toString(),
            currency: "INR",
            name: "",
            description: "",
            image: "hello",
            order_id: rzpData.rzp_order_id,
            handler: async function (response) {
                setRedirect([true, "/thanks"])
                localStorage.removeItem("cartId")
            },
            prefill: {
                name: "",
                email: "",
                contact: "",
            },
            notes: {
                order_quote_id: rzpData.order_quote_id,
            },
            theme: {
                color: "#61dafb",
            },
            modal: {
                ondismiss: function () {
                    setRedirect([true, "/orders"])
                }
            }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <Container>
                {(cartLoader || updateLoader || !cardData) ? <CartSkeleton /> : cardData && cardData.length > 0 ? (
                    <>
                        <ArrowContainer onClick={goToPreviousPath}>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                                alt="ladu-india"
                                width="36"
                            />
                        </ArrowContainer>
                        <CartContainer>
                            <AddCardContainer>
                                <InfoContainer>
                                    <InfoCircleOutlined
                                        style={{ fontSize: "16px", color: "#fff" }}
                                    />
                                    <InfoContent>
                                        Add product worth INR 3,000 to get free shipping.
                                    </InfoContent>
                                </InfoContainer>
                                {updateLoader ? (
                                    <SpinContainer>
                                        <Spin />
                                    </SpinContainer>
                                ) : (
                                    <Cards
                                        data={cardData}
                                        removeItem={removeItem}
                                        //data = {productCartList.items}
                                        updateProductList={updateProductList}
                                    />
                                )}
                            </AddCardContainer>
                            <SummaryContainer>
                                <OrderSummary
                                    enableButton
                                    summaryPrices={priceData}
                                    //  handleCheckout={handleCheckout}
                                    addCouponCode={addCouponCode}
                                    removeCouponCode={removeCouponCode}
                                    appliedCoupon={appliedCoupon}
                                    couponCheck={couponCheck}
                                    payButton={payButton}
                                    // handlePay={handlePay}
                                    shippingData={shippingData}
                                    isPlaceOrder

                                />
                            </SummaryContainer>
                        </CartContainer>

                        <HelpNumber />
                    </>
                ) : (
                    <NoDataContianer
                        showText="No Items available in cart."
                        animationType={"cartAnimation"}
                    />
                )}
            </Container>
        </>
    );
};

const mapStateToProps = (state) => ({
    productCartListItems: state.productCart.productCartListItems,
    productCartListPrices: state.productCart.productCartListPrices,
    isLoading: state.productCart.isLoading,
    removeItemList: state.removeItem.removeItemList,
    updateListItems: state.updateItem.updateListItems,
    updateListItemsPrices: state.updateItem.updateListItemsPrices,
    removeListPrices: state.removeItem.removeListPrices,
    removeListItem: state.removeItem.removeListItem,
    cartDataItems: state.cartData.cartDataItems,
    cartDataPrices: state.cartData.cartDataPrices,
    cartLoader: state.cartData.cartLoader,
    isLoading: state.loader.isLoading,
    updateLoader: state.updateItem.isLoading,
    toggleCouponBool: state.toggleCoupon.toggleCouponBool,
    paymentMethodData: state.paymentMethod.paymentMethodData,
    placeOrderState: state.placeOrder,
    fetchShippingAmountRequestState: state.fetchShippingAmountRequest,

});

const mapDispatchToProps = (dispatch) => ({
    removeItemCart: (params) => dispatch(removeItemCart(params)),
    updateProductCart: (params) => dispatch(updateProductCart(params)),
    getProductCartList: (params) => dispatch(getProductCartList(params)),
    fetchCartData: (params) => dispatch(fetchCartData(params)),
    fetchQuantity: (params) => dispatch(fetchQuantity(params)),
    addCouponRequest: (params) => dispatch(addCouponRequest(params)),
    removeCouponRequest: (params) => dispatch(removeCouponRequest(params)),
    toggleCouponRequest: (params) => dispatch(toggleCouponRequest(params)),
    fetchPaymentMethodRequest: (params) => dispatch(fetchPaymentMethodRequest(params)),
    fetchPlaceOrderRequest: (params) => dispatch(fetchPlaceOrderRequest(params)),
    fetchShippingAmountRequest: (params) => dispatch(fetchShippingAmountRequest(params)),
    fetchCartDataReset: () => dispatch(fetchCartDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOrder);
