import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    CREATE_CUSTOMER_ADDRESS,
    createCustomerAddressSuccess,
    createCustomerAddressFail,
} from '../actions/createCustomerAddressAction'

import createCustomerAddressApi from '../apis/createCustomerAddressApi'


export function* createCustomerAddressSaga(action) {
    try {
        const response = yield call(() => createCustomerAddressApi(action.params))

        if (response.errors) {
            yield put(createCustomerAddressFail(response, action))
        } else {
            yield put(createCustomerAddressSuccess(response, action))
        }
    } catch (e) {
        yield put(createCustomerAddressFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(CREATE_CUSTOMER_ADDRESS, createCustomerAddressSaga);
}