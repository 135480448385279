import styled, { css, keyframes } from "styled-components"
import { Button, Skeleton, Pagination } from "antd"
import { Link } from "react-router-dom"

export const HideOnMobileL = styled.span`
    @media ${props => props.theme.device.mobileL} {
        display: none !important;
    }
`

export const Xgrid = styled.div`
    display: flex;
	flex-flow: wrap;
`

export const XgridCard = styled.div`
    width: ${props => props.column === 3 ? "33.33%" : "25%"};
    margin-bottom: 1rem;


    @media ${props => props.theme.device.tablet} {
        width: 50%;
    }

    @media ${props => props.theme.device.mobileL} { 
        width: 100%;
    }
`

export const DynamicSkeleton = styled(Skeleton.Input)`
    width: ${props => props.w ? props.w : "100% !important"};
    height: 100%;

    .ant-skeleton-input {
        height: ${props => props.h ? props.h : "15px"};
    }
`
export const Xpagination = styled(Pagination)`
    display: flex;
    justify-content: center;
    margin:1rem 0;
`

export const Xlink = styled(Link)`
    font-weight: bold;
    margin: ${props => props.margin ? props.margin : "none"};
    margin-left: ${props => props.marginLeft ? props.marginLeft : "none"};
    white-space: ${props => props.noWrap ? "nowrap" : "initial"};
    color: ${props => props.black ? "rgb(0 0 0 / 0.75)" : "rgb(0 111 195 / 0.75)"};
    
    &:hover {
        color: ${props => props.black ? "rgb(0 0 0 / 1)" : "rgb(0 111 195 / 1)"};
    }
`