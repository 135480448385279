import { defaultsDeep } from "lodash"
import React from "react"
import { Link } from "react-router-dom"

// custom components
import {
    Container, SubContainer, DetailContainer, Name, Brand,
    Price, SpecialPrice, Off, ImgContainer, HeartContainer,
} from "./ProductCardSkeletonStyle"
import { HeartIcon } from '../CustomIcons'
import { DynamicSkeleton } from '../Xcomponent'

const ProductCard = props => {
    return (
        <Container>
            <SubContainer>
                <ImgContainer>
                    <DynamicSkeleton active h="300px" />
                </ImgContainer>
                <DetailContainer>
                    <DynamicSkeleton active h="50px" />
                </DetailContainer>
            </SubContainer>
        </Container>
    )
}

export default ProductCard