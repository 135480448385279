import axios from "axios";
import { BASE_URL } from "../utils";

const getBrandListApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                    products(filter: {is_brand: {eq: "1"}},currentPage:${params.currentPage}, pageSize: 20) {
                        total_count
                        items {
                        name    
                        brand_name
                        url_key
                        short_description {
                            html
                        }
                        thumbnail {
                            url
                        }
                        }
                    }
                }
            `,
        }
    });
    return response.data;
};

export default getBrandListApi;
