import React from "react"

import { Container } from "./BackButtonStyle"

const BackButton = props => {
    return (
        <Container>
            <img
                src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                alt="ladu-india"
                width="20"
                height="20"
            />
        </Container>
    )
}

export default BackButton