import { call, put, takeLatest } from 'redux-saga/effects'

// action
import { GUEST_REMOVE_CART_PRODUCT, guestRemoveCartProductSuccess, guestRemoveCartProductFail } from '../actions/guestRemoveCartProductAction'

// api
import guestRemoveCartProductApi from '../apis/guestRemoveCartProductApi'


export function* guestRemoveCartProductSaga(action) {
    try {
        const response = yield call(() => (guestRemoveCartProductApi(action.params)))
        if (response.errors) {
            yield put(guestRemoveCartProductFail(response, action))
        } else {
            yield put(guestRemoveCartProductSuccess(response, action))
        }
    }
    catch (e) {
        yield put(() => guestRemoveCartProductFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GUEST_REMOVE_CART_PRODUCT, guestRemoveCartProductSaga)
}