export const FETCH_PAYMENT_METHOD_REQUEST = 'FETCH_PAYMENT_METHOD_REQUEST'
export const FETCH_PAYMENT_METHOD_SUCCESS = 'FETCH_PAYMENT_METHOD_SUCCESS'
export const FETCH_PAYMENT_METHOD_FAIL = 'FETCH_PAYMENT_METHOD_FAIL'
export const FETCH_PAYMENT_METHOD_RESET = 'FETCH_PAYMENT_METHOD_RESET'

export const fetchPaymentMethodRequest = (params) => {
    return { type: FETCH_PAYMENT_METHOD_REQUEST, params }
}

export const fetchPaymentMethodSuccess = (response) => {
    return { type: FETCH_PAYMENT_METHOD_SUCCESS, response }
}

export const fetchPaymentMethodFail = (response) => {
    return { type: FETCH_PAYMENT_METHOD_FAIL, response }
}
export const fetchPaymentMethodReset = () => {
    return { type: FETCH_PAYMENT_METHOD_RESET }
}

