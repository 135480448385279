// action
import {
    HOME_PAGE_BANNER_MOBILE,
    HOME_PAGE_BANNER_MOBILE_SUCCESS,
    HOME_PAGE_BANNER_MOBILE_FAIL,
    HOME_PAGE_BANNER_MOBILE_RESET,
} from '../actions/homePageBannerMobileAction'

// initialState
const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case HOME_PAGE_BANNER_MOBILE:
            return {
                ...state,
                apiState: "loading"
            }
        case HOME_PAGE_BANNER_MOBILE_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case HOME_PAGE_BANNER_MOBILE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.products
            }

        case HOME_PAGE_BANNER_MOBILE_RESET:
            return initialState
        default:
            return state
    }
}