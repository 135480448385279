import { Skeleton, Button } from "antd"
import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    padding: 24px;

    @media ${props => props.theme.device.tablet} {
       flex-direction: column;
    }
`

export const Left = styled.div`
    width: 20%;

    @media ${props => props.theme.device.tablet} {
       width: 100%;
       margin-bottom: 24px;
    }
`
export const EditButton = styled(Button)`
float: right;
border: 1px solid dashed;
border-radius: 50px;
padding: 10px 8px;
display: flex;
align-items: center;
`

export const Right = styled.div`
    width: 80%;
    padding-left: 24px;

    @media ${props => props.theme.device.tablet} {
       width: 100%;
       padding-left: 0px;
    }
`
export const AddressContent = styled.div`
    width: 30%;
    height: auto;
    border: dashed 1px #ccc;
    border-radius: 8px;
    padding: 8px;
    margin: 8px 15px 0px 0px;
    background: #ffffff82;
    max-height: 200px;

    @media ${(props) => props.theme.device.tablet}{
        width: 100%;
        margin-bottom: 10px;
    }

`
export const AddressContentSkelaton = styled.div`
    display: flex;
    justify-content: space-between;

    @media ${(props) => props.theme.device.tablet}{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }
`
export const AddressSkelaton = styled(Skeleton.Button)`
    width: 30%;
    
      @media ${(props) => props.theme.device.tablet}{
        width: 100%;
        margin-bottom: 10px;
    }
    .ant-skeleton-button{
         width: 100%;
        height: 150px;
    }
`

export const EditWrapper = styled.div`
    display: flex;
    align-items:flex-start;
    justify-content: flex-start;

    @media ${(props) => props.theme.device.tablet}{
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
    }
`

export const DisplayText = styled.div`
    text-transform: capitalize;
    margin-bottom: 1px;
`