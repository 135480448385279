// action
import {
    HOME_PAGE_BANNER,
    HOME_PAGE_BANNER_SUCCESS,
    HOME_PAGE_BANNER_FAIL,
    HOME_PAGE_BANNER_RESET,
} from '../actions/homePageBannerAction'

// initialState
const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case HOME_PAGE_BANNER:
            return {
                ...state,
                apiState: "loading"
            }
        case HOME_PAGE_BANNER_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case HOME_PAGE_BANNER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data.products
            }

        case HOME_PAGE_BANNER_RESET:
            return initialState
        default:
            return state
    }
}