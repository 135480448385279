export const GUEST_SET_PAYMENT_METHOD = "GUEST_SET_PAYMENT_METHOD"
export const GUEST_SET_PAYMENT_METHOD_SUCCESS = "GUEST_SET_PAYMENT_METHOD_SUCCESS"
export const GUEST_SET_PAYMENT_METHOD_FAIL = "GUEST_SET_PAYMENT_METHOD_FAIL"
export const GUEST_SET_PAYMENT_METHOD_RESET = "GUEST_SET_PAYMENT_METHOD_RESET"


export const guestSetPaymentMethod = (params) => {
    return { type: GUEST_SET_PAYMENT_METHOD, params }
}
export const guestSetPaymentMethodSuccess = (response) => {
    return { type: GUEST_SET_PAYMENT_METHOD_SUCCESS, response }
}
export const guestSetPaymentMethodFail = (response) => {
    return { type: GUEST_SET_PAYMENT_METHOD_FAIL, response }
}
export const guestSetPaymentMethodReset = () => {
    return { type: GUEST_SET_PAYMENT_METHOD_RESET }
}

