export const CHANGE_CUSTOMER_PASSWORD = 'CHANGE_CUSTOMER_PASSWORD'
export const CHANGE_CUSTOMER_PASSWORD_SUCCESS = 'CHANGE_CUSTOMER_PASSWORD_SUCCESS'
export const CHANGE_CUSTOMER_PASSWORD_FAIL = 'CHANGE_CUSTOMER_PASSWORD_FAIL'
export const CHANGE_CUSTOMER_PASSWORD_RESET = 'CHANGE_CUSTOMER_PASSWORD_RESET'

export const changeCustomerPassword = (params) => {
    return { type: CHANGE_CUSTOMER_PASSWORD, params }
}

export const changeCustomerPasswordSuccess = (response) => {
    return { type: CHANGE_CUSTOMER_PASSWORD_SUCCESS, response }
}

export const changeCustomerPasswordFail = (response) => {
    return { type: CHANGE_CUSTOMER_PASSWORD_FAIL, response }
}

export const changeCustomerPasswordReset = () => {
    return { type: CHANGE_CUSTOMER_PASSWORD_RESET }
}