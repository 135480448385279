import {
    REMOVE_WISHLIST_REQUEST,
    REMOVE_WISHLIST_SUCCESS,
    REMOVE_WISHLIST_FAIL,
  } from "../actions/removeWishListAction";
  import get from "lodash/get";


  const initialState = {
    removeWishListData: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case REMOVE_WISHLIST_REQUEST:
        return {
          ...state,
        };
  
      case REMOVE_WISHLIST_FAIL:
        return {
          ...state,
        };
  
      case REMOVE_WISHLIST_SUCCESS:
        return {
          ...state,
        //  removeWishListData: get(action, "response.data.country.available_regions"),
        };
  
      default:
        return state;
    }
  }
  