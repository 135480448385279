import React, { useEffect, useState, useRef } from 'react'
import { Space, Button, Form } from 'antd'
import { InstagramOutlined, FacebookOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// custom component
import {
    HomePageFooterWrapper, Row, EmailBlock, EmailInput, FooterBlock,
    FooterLinks, FooterHead, IconBlock, Address, ErrorMessage, SuccessMessage,
    FooterImage, Image, PaymentCardImage, FooterIconLink
} from './HomePageFooterStyle'

// action
import { subscribeEmail, subscribeEmailReset } from '../../actions/subscribeEmailAction'

const HomePageFooter = (props) => {
    // variables
    const { subscribeEmail, subscribeEmailReset, subscribeEmailState } = props
    const [formData, setFormData] = useState({
        email: ""
    })
    const [form] = Form.useForm()

    // callbacks

    useEffect(() => {
        return () => {
            subscribeEmailReset()
        }
    }, [])

    useEffect(() => {
        if (subscribeEmailState.apiState === "success") {
            setTimeout(() => {
                subscribeEmailReset()
                form.resetFields()
            }, 5000)
        }
        if (subscribeEmailState.apiState === "error") {
            setTimeout(() => {
                subscribeEmailReset()
                form.resetFields()
            }, 5000)
        }
    }, [subscribeEmailState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }

    const handleSubmit = () => {
        subscribeEmail(formData)
    }

    return (
        <HomePageFooterWrapper>
            <EmailBlock>
                <h2 style={{ fontWeight: 600 }} >Subscribe Our News Letter</h2>
                <Form
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Space direction="vertical" style={{ marginBottom: 0, height: 50 }}>
                        <Form.Item
                            name="email"
                            style={{ marginBottom: 0 }}
                            rules={[
                                { required: true, message: 'Required' },
                                { type: 'email', message: 'Email is not valid' },
                            ]}
                            onChange={handleOnChange}
                        >
                            <EmailInput autoComplete="off" onChange={handleOnChange} placeholder="Enter Your Email ID" name="email" suffix={<Button loading={subscribeEmailState.apiState === "loading"} type="primary" htmlType="submit" fontSize={14} shape="round">Subscribe</Button>} />
                        </Form.Item>
                        <span>
                            {
                                subscribeEmailState.apiState === "error" ?
                                    <>
                                        {
                                            subscribeEmailState.apiState === "error" &&
                                            <ErrorMessage>{subscribeEmailState.error.errors[0].message}</ErrorMessage>
                                        }
                                    </>
                                    :
                                    <>
                                        {
                                            subscribeEmailState.apiState === "success" &&
                                            <SuccessMessage>Email subscribe successfully</SuccessMessage>
                                        }
                                    </>

                            }
                        </span>
                    </Space>
                </Form>
            </EmailBlock>
            <Row>
                <FooterBlock>
                    <FooterHead>Quick Links</FooterHead>
                    <FooterLinks to="/about?r=about-ladu" >About</FooterLinks>
                    <FooterLinks to="/brands" >Brands</FooterLinks>
                    <FooterLinks to="/categories" >Shop</FooterLinks>
                    {/* <FooterLinks to="/category/barni/156" >Barni (Jaipur Local)</FooterLinks> */}
                    <FooterLinks to="/gifting/135" >Gifting</FooterLinks>
                    {/* <FooterLinks to="/category/tapri-meals/167" >Tapri</FooterLinks> */}
                    <FooterLinks to="/about?r=contact-us" >Contact Us</FooterLinks>
                </FooterBlock>
                <FooterBlock>
                    <FooterLinks to="/become-a-vendor/p" >Become A Vendor</FooterLinks>
                    <FooterLinks to="/advertise/p" >Bulk Purchase</FooterLinks>
                    <FooterLinks to="/" >Track Order</FooterLinks>
                    <FooterLinks to="/" >Shipping & Returns</FooterLinks>
                    <FooterLinks to="/privacies-privacy-policy/p" >Privacy & Policy</FooterLinks>
                    <FooterLinks to="/terms-of-use/p" >Terms & Condition</FooterLinks>
                    <FooterLinks to="/faq/p" >FAQS</FooterLinks>
                </FooterBlock>
                <FooterBlock>
                    <FooterHead>Find Us On</FooterHead>
                    <FooterIconLink to={{ pathname: "https://www.instagram.com/laduindia/?hl=en" }} target="_blank" >
                        <IconBlock>
                            <Image src={`${process.env.PUBLIC_URL}/images/instagram_svg.svg`} />
                        </IconBlock>
                        <span style={{ marginLeft: 10, margiLeft: 10, fontWeight: 500, fontSize: 16 }} >@laduindia</span>
                    </FooterIconLink>
                    <FooterIconLink to={{ pathname: "https://facebook.com/laduindia/" }} target="_blank" >

                        <IconBlock>
                            <Image src={`${process.env.PUBLIC_URL}/images/facebook_svg.svg`} />
                        </IconBlock>
                        <span style={{ marginLeft: 10, margiLeft: 10, fontWeight: 500, fontSize: 16 }} >@laduindia</span>
                    </FooterIconLink>

                    <div style={{ marginTop: "22px" }} >
                        <FooterHead>Visit Our Store: </FooterHead>
                        <Address>
                            <span>LADU</span><br />
                            <span>2nd Floor, B4-E, Surana Jewellers Building,</span><br />
                            <span> Prithviraj Road, C-Scheme</span><br />
                            <span> Jaipur-302001</span><br />
                            <Link style={{ color: "#f78c3e", display: "flex", alignItems: "center" }} to={{ pathname: "https://www.google.com/maps/place/LADU+(Life+Around+Daily+Utilities)+Retail+Store+by+Tapri/@26.9056058,75.8089933,17z/data=!3m1!4b1!4m5!3m4!1s0x396db5b590e78b3b:0x6a7e748a328e7f37!8m2!3d26.9055301!4d75.8111461" }} target="_blank"  ><img src={`${process.env.PUBLIC_URL}/images/pin.png`} alt="map" style={{ width: 15, height: "100%" }} /> location</Link>
                        </Address>
                    </div>
                </FooterBlock>
                <FooterBlock>
                    <FooterImage>
                        <img src={`${process.env.PUBLIC_URL}/images/Bauji.jpg`} style={{ width: "100%", height: "100%" }} />
                    </FooterImage>
                </FooterBlock>
            </Row>
            <div>
                <FooterHead>Payment Methods</FooterHead>
                <div style={{ display: "flex", flexWrap: "wrap" }} >
                    <PaymentCardImage><Image src={`${process.env.PUBLIC_URL}/images/Payment_method.png`} /></PaymentCardImage>
                </div>
            </div>

        </HomePageFooterWrapper >
    )
}

const mapStateToProps = (state) => ({
    subscribeEmailState: state.subscribeEmail
})
const mapDispatchToProps = (dispatch) => ({
    subscribeEmail: (params) => dispatch(subscribeEmail(params)),
    subscribeEmailReset: () => dispatch(subscribeEmailReset())
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePageFooter)
