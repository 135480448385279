import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component";

// actions
import { getBrandList, getBrandListReset } from "../../actions/getBrandListAction"

// custom components
import BrandCard from "../../components/BrandCard/BrandCard"
import BrandCardSkeleton from "../../components/BrandCardSkeleton/BrandCardSkeleton"
import BackButton from '../../components/BackButton/BackButton'
import Loader from '../../components/Loader/Loader'
import { BrandListWrapper, BrandBlock } from './BrandListStyle'

const BrandList = props => {
    // variables
    const { getBrandList, getBrandListState, getBrandListReset } = props
    const [brandListData, setBrandListData] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [renderList, setRenderList] = useState("")
    const [count, setCount] = useState(0)
    const prevCurrentPageRef = useRef();

    const prevPage = prevCurrentPageRef.current;

    // callbacks
    useEffect(() => {
        getBrandList({
            currentPage: currentPage
        })
    }, [currentPage])
    useEffect(() => {
        prevCurrentPageRef.current = currentPage;
    }, [])

    useEffect(() => {
        if (brandListData.length === 0) {
            setRenderList("loading")
        }
        if (getBrandListState.apiState === "success") {
            const BrandImageListTotalCount = getBrandListState.data.products.total_count
            if (currentPage !== prevPage) {
                let data = [...brandListData]
                getBrandListState.data.products.items.map((item) => {
                    data.push(item)
                })
                setBrandListData(data)
                handleCount(BrandImageListTotalCount, data.length)
            }
            else {
                setBrandListData(getBrandListState.data.products.items)
                handleCount(BrandImageListTotalCount, getBrandListState.data.products.items.length)
            }

            setRenderList("success")
        }
    }, [getBrandListState])


    const handleCount = (BrandImageListTotalCount, dataLength) => {
        if (BrandImageListTotalCount > dataLength) {
            setHasMore(true)
        }
        else {
            setHasMore(false)
        }
        setCount(count + 1)
        getBrandListReset()
    }


    return (
        <>
            <div style={{ display: "flex", alignItems: "center", marginTop: 16 }} >
                <Link to="/categories" >
                    <BackButton />
                </Link>
                <span style={{ fontSize: 16, fontWeight: 600, marginLeft: 8, textTransform: "uppercase" }} >
                    BRAND LIST
                </span>
            </div>

            <BrandListWrapper style={{ display: "flex", flexFlow: 'wrap', justifyContent: 'center', alignItems: "center", marginTop: 16 }} >
                {renderList === "loading" &&
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(_ => <BrandCardSkeleton />)
                }

                {renderList === "success" &&
                    <>
                        {
                            brandListData.length > 0 &&
                            <InfiniteScroll
                                dataLength={brandListData.length}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    padding: '10px',
                                    flexWrap: 'wrap'
                                }}
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }
                                }
                                hasMore={hasMore}>
                                {
                                    brandListData.map((brand) => {
                                        return (
                                            <BrandBlock>
                                                <BrandCard
                                                    name={brand.name}
                                                    url_key={brand.url_key}
                                                    brand_id={brand.brand_name}
                                                    imgUrl={brand.thumbnail.url}
                                                />
                                            </BrandBlock>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        }
                        <div style={{ paddingBottom: 60, paddingTop: 10, display: "flex", justifyContent: "center" }} >
                            {getBrandListState.apiState === "loading" &&
                                <Loader />
                            }
                        </div>

                    </>
                }
            </BrandListWrapper>
        </>
    )
}

const mapStateToProps = (state) => ({
    getBrandListState: state.getBrandList,
});

const mapDispatchToProps = (dispatch) => ({
    getBrandList: (params) => dispatch(getBrandList(params)),
    getBrandListReset: () => dispatch(getBrandListReset())
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandList);