export const GLOBAL_LOADER_REQUEST = 'GLOBAL_LOADER_REQUEST'
export const GLOBAL_LOADER_SUCCESS = 'GLOBAL_LOADER_SUCCESS'
export const GLOBAL_LOADER_FAIL = 'GLOBAL_LOADER_FAIL'

export const globalLoaderRequest = (params) => {
    return { type: GLOBAL_LOADER_REQUEST, params }
}

export const globalLoaderSuccess = (response) => {
    return { type: GLOBAL_LOADER_SUCCESS, response }
}

export const globalLoaderFail = (response) => {
    return { type: GLOBAL_LOADER_FAIL, response }
}