import {
    GUEST_BILLING_ADDRESS,
    GUEST_BILLING_ADDRESS_SUCCESS,
    GUEST_BILLING_ADDRESS_FAIL,
    GUEST_BILLING_ADDRESS_RESET
} from '../actions/guestBillingAddressAction'

const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GUEST_BILLING_ADDRESS:
            return {
                ...state,
                apiState: "loading"
            }
        case GUEST_BILLING_ADDRESS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response
            }
        case GUEST_BILLING_ADDRESS_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case GUEST_BILLING_ADDRESS_RESET:
            return initialState

        default:
            return state
    }
}