import { DELETE_WISH_LIST, DELETE_WISH_LIST_SUCCESS, DELETE_WISH_LIST_FAIL, DELETE_WISH_LIST_RESET } from '../actions/deleteWishListAction'

const initialState = {
    apiState: "", // error loading success
}

export default function (state = initialState, action) {
    switch (action.type) {
        case DELETE_WISH_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case DELETE_WISH_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
            }
        case DELETE_WISH_LIST_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case DELETE_WISH_LIST_RESET:
            return initialState
        default:
            return state
    }
}