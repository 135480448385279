import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';


import {
    FETCH_PAYMENT_METHOD_REQUEST,
    fetchPaymentMethodSuccess,
    fetchPaymentMethodFail,
} from '../actions/fetchPaymentMethodAction'

import fetchPaymentMethodApi from '../apis/fetchPaymentMethodApi';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* fetchPaymentMethodSaga(action) {

    try {
        const response = yield call(() => fetchPaymentMethodApi(action))
        if (response.errors) {
            yield put(fetchPaymentMethodFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(fetchPaymentMethodSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(fetchPaymentMethodFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_PAYMENT_METHOD_REQUEST, fetchPaymentMethodSaga);
}