export const FETCH_PLACE_ORDER_REQUEST = 'FETCH_PLACE_ORDER_REQUEST'
export const FETCH_PLACE_ORDER_SUCCESS = 'FETCH_PLACE_ORDER_SUCCESS'
export const FETCH_PLACE_ORDER_FAIL = 'FETCH_PLACE_ORDER_FAIL'

export const fetchPlaceOrderRequest = (params) => {
    return { type: FETCH_PLACE_ORDER_REQUEST, params }
}

export const fetchPlaceOrderSuccess = (response) => {
    return { type: FETCH_PLACE_ORDER_SUCCESS, response }
}

export const fetchPlaceOrderFail = (response) => {
    return { type: FETCH_PLACE_ORDER_FAIL, response }
}

