import axios from "axios";
import { isAuthorized, BASE_URL } from '../utils';


const fetchCartDataApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
        cart(cart_id: "${params.cart_id}") {
          items {
            id
          product {
            id
                sku
                stock_status
                only_x_left_in_stock
                name
                url_key
                jaipur_only_delivery
                price_range{
                  minimum_price{
                    final_price{
                      currency
                      value
                    }
                  }
                }
                image {
                        url
                      }
                thumbnail{
                    position
                        url
                }
                  short_description{
                        html
                    }
                    description{
                        html
                    }     
              }
            quantity
             prices{
              row_total_including_tax{
                value
              }
            }
               ... on SimpleCartItem {
                  customizable_options{
              label
              id
            values{
              value
              label
          }
          }
            prices{
              row_total_including_tax{
                value
              }
            }

      }
          }
          shipping_addresses {
            customer_notes
            pickup_location_code
            postcode
            available_shipping_methods {
              error_message
              method_code
              method_title
              amount {
                currency
                value
              }
            }
          }
          applied_coupons {
            code
          }
            prices{
              subtotal_including_tax{
                value
              }
              grand_total{
                value
              }
              discounts{
                amount{
                  value
                }
                label
              }
            }
        }
      }
      `,
    },
    headers: isAuthorized(),
  });

  return response.data;
};

export default fetchCartDataApi;
