import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_BRAND_REQUEST,
    brandSuccess,
    brandFail,
} from '../actions/fetchBrandAction'
import get from 'lodash/get';
import brandApi from '../apis/brandApi'


function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* fetchBrandSaga(action) {

    try {
        const response = yield call(() => brandApi(action))
        if (response.errors) {
            yield put(brandFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(brandSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(brandFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_BRAND_REQUEST, fetchBrandSaga);
}