export const GUEST_REMOVE_COUPON_TO_CART = "GUEST_REMOVE_COUPON_TO_CART"
export const GUEST_REMOVE_COUPON_TO_CART_SUCCESS = "GUEST_REMOVE_COUPON_TO_CART_SUCCESS"
export const GUEST_REMOVE_COUPON_TO_CART_FAIL = "GUEST_REMOVE_COUPON_TO_CART_FAIL"
export const GUEST_REMOVE_COUPON_TO_CART_RESET = "GUEST_REMOVE_COUPON_TO_CART_RESET"

export const guestRemoveCouponToCart = (params) => {
    return { type: GUEST_REMOVE_COUPON_TO_CART, params }
}
export const guestRemoveCouponToCartSuccess = (response) => {
    return { type: GUEST_REMOVE_COUPON_TO_CART_SUCCESS, response }
}
export const guestRemoveCouponToCartFail = (response) => {
    return { type: GUEST_REMOVE_COUPON_TO_CART_FAIL, response }
}
export const guestRemoveCouponToCartReset = () => {
    return { type: GUEST_REMOVE_COUPON_TO_CART_RESET }
}