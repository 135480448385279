import {
    FETCH_CART_DATA,
    FETCH_CART_DATA_SUCCESS,
    FETCH_CART_DATA_FAIL,
    FETCH_CART_DATA_RESET
} from "../actions/fetchCartDataAction";
import get from 'lodash/get';

const initialState = {
    cartDataItems: null,
    cartDataPrices: null,
    cartLoader: null,
    apiState: ""
};

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_CART_DATA:
            return {
                ...state,
                apiState: "loading",
                cartLoader: true
            };

        case FETCH_CART_DATA_FAIL:
            return {
                ...state,
                apiState: "error",
                cartLoader: false
            };

        case FETCH_CART_DATA_SUCCESS:
            return {
                ...state,
                apiState: "success",
                cartDataItems: get(action, "response.data.cart.items"),
                cartDataPrices: get(action, "response.data.cart.prices"),
                cartLoader: false
            };

        case FETCH_CART_DATA_RESET:
            return initialState

        default:
            return state;
    }
}
