import {
  GET_PRODUCT_CART_LIST,
  GET_PRODUCT_CART_LIST_SUCCESS,
  GET_PRODUCT_CART_LIST_FAIL
} from "../actions/addProductCartAction";
import get from 'lodash/get';

const initialState = {
  productCartListItems: null,
  isLoading: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_CART_LIST:
      return {
        ...state,
        isLoading: true
      };
    case GET_PRODUCT_CART_LIST_SUCCESS:
      return {
        ...state,
        productCartListItems: get(action, "response.data.addSimpleProductsToCart.cart.items"),
        productCartListPrices: get(action, "response.data.addSimpleProductsToCart.cart.prices"),
        isLoading: false
      };
    case GET_PRODUCT_CART_LIST_FAIL:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
