export const CHECK_GUEST_SERVICE_AVAILABILITY = "CHECK_GUEST_SERVICE_AVAILABILITY"
export const CHECK_GUEST_SERVICE_AVAILABILITY_SUCCESS = "CHECK_GUEST_SERVICE_AVAILABILITY_SUCCESS"
export const CHECK_GUEST_SERVICE_AVAILABILITY_FAIL = "CHECK_GUEST_SERVICE_AVAILABILITY_FAIL"
export const CHECK_GUEST_SERVICE_AVAILABILITY_RESET = "CHECK_GUEST_SERVICE_AVAILABILITY_RESET"


export const checkGuestServiceAvailability = (params) => {
    return { type: CHECK_GUEST_SERVICE_AVAILABILITY, params }
}
export const checkGuestServiceAvailabilitySuccess = (response) => {
    return { type: CHECK_GUEST_SERVICE_AVAILABILITY_SUCCESS, response }
}
export const checkGuestServiceAvailabilityFail = (response) => {
    return { type: CHECK_GUEST_SERVICE_AVAILABILITY_FAIL, response }
}
export const checkGuestServiceAvailabilityReset = () => {
    return { type: CHECK_GUEST_SERVICE_AVAILABILITY_RESET }
}
