import {
    SUBSCRIBE_EMAIL,
    SUBSCRIBE_EMAIL_SUCCESS,
    SUBSCRIBE_EMAIL_FAIL,
    SUBSCRIBE_EMAIL_RESET,
} from "../actions/subscribeEmailAction";


const initialState = {
    apiState: "",
    data: {}
};

export default function (state = initialState, action) {

    switch (action.type) {
        case SUBSCRIBE_EMAIL:
            return {
                ...state,
                apiState: "loading"
            };

        case SUBSCRIBE_EMAIL_FAIL:
            return {
                ...state,
                apiState: "error",
                error: action.response
            };

        case SUBSCRIBE_EMAIL_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            };
        case SUBSCRIBE_EMAIL_RESET:
            return initialState

        default:
            return state;
    }
}
