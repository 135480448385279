import { notification } from "antd";

export const inr = (num) => {
  let n = parseFloat(num);
  let negativeNumber = false;
  if (n < 0) {
    negativeNumber = true;
    n = n * -1;
  }
  var x = n.toString();
  var afterPoint = "";
  if (x.indexOf(".") > 0) afterPoint = x.substring(x.indexOf("."), x.length);
  x = Math.floor(x);
  x = x.toString();
  var lastThree = x.substring(x.length - 3);
  var otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != "") lastThree = "," + lastThree;
  var res =
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;

  if (negativeNumber) {
    return "-" + res;
  } else {
    return res;
  }
};

// export const site_url = () => {
//   url: "http://laduindia.com/"
// }


export const isAuthorized = () => {
  const token = localStorage.getItem("customerToken");
  if (token) {
    return {
      Authorization: `Bearer ${token}`,
      //  Authorization:`Bearer uchz0at3uos0eagb4ngmmnespkermn3g`
      //  "Content-Type": "application/json",
      //  "Sec-Fetch-Mode": "cors",
    };
  }
  return {
    // "Content-Type": "application/json",
    //  "Access-Control-Allow-Origin": "*",
    // "Access-Control-Request-Headers": "random_header",
    // "Access-Control-Allow-Methods":"GET,HEAD,OPTIONS,POST,PUT",
    // "Access-Control-Allow-Credentials": "true",

  };
};

export const checkCartId = () => {
  const customerId = localStorage.getItem("getCustomerId");
  const guestId = localStorage.getItem("guestCartId");

  if (customerId) {
    return customerId;
  } else {
    return guestId;
  }
};

export const BASE_URL = () => {
  if (process.env.REACT_APP_ENV === "production") {
      return "https://staging.laduindia.com/graphql";
  }

  if (process.env.REACT_APP_ENV === "staging") {
      return "https://dev.laduindia.com/graphql";
  }
};

export const SITE_URL = () => {
  if (process.env.REACT_APP_ENV === "production") {
      return "https://staging.laduindia.com";
  }

  if (process.env.REACT_APP_ENV === "staging") {
      return "https://dev.laduindia.com";
  }
};

export const RAZORPAY_API_KEY = () => {
  if (process.env.REACT_APP_ENV === "production") {
      return "rzp_live_3PCQ4kcv5ErMXc";
  }

  if (process.env.REACT_APP_ENV === "staging") {
      return "rzp_test_9Ioxt23IjGN6M8";
  }
};

export const openSuccessNotification = (data) => {
  notification["success"]({
    message: data,
  });
};

export const openErrorNotification = (data) => {
  notification["error"]({
    message: data,
  });
};

export const getCartId = () => {
  return localStorage.getItem("cartId")
}

export const setCartId = (cartId) => {
  localStorage.setItem("cartId", cartId)
  return true
}

export const getCustomerToken = () => {
  return localStorage.getItem("customerToken")
}

export const setCustomerToken = (customerToken) => {
  localStorage.setItem("customerToken", customerToken)
  return true
}
export const jaipurPincodeData = [
  { 'pincode': 302001 },
  { 'pincode': 302002 },
  { 'pincode': 302003 },
  { 'pincode': 302004 },
  { 'pincode': 302005 },
  { 'pincode': 302006 },
  { 'pincode': 302007 },
  { 'pincode': 302009 },
  { 'pincode': 302010 },
  { 'pincode': 302011 },
  { 'pincode': 302012 },
  { 'pincode': 302013 },
  { 'pincode': 302014 },
  { 'pincode': 302015 },
  { 'pincode': 302016 },
  { 'pincode': 302017 },
  { 'pincode': 302018 },
  { 'pincode': 302019 },
  { 'pincode': 302020 },
  { 'pincode': 302021 },
  { 'pincode': 302022 },
  { 'pincode': 302023 },
  { 'pincode': 302024 },
  { 'pincode': 302025 },
  { 'pincode': 302026 },
  { 'pincode': 302027 },
  { 'pincode': 302028 },
  { 'pincode': 302029 },
  { 'pincode': 302030 },
  { 'pincode': 302031 },
  { 'pincode': 302032 },
  { 'pincode': 302033 },
  { 'pincode': 302034 },
  { 'pincode': 302036 },
  { 'pincode': 302037 },
  { 'pincode': 302038 },
  { 'pincode': 302039 },
  { 'pincode': 302040 },
  { 'pincode': 302041 },
  { 'pincode': 302042 },
  { 'pincode': 302043 },
  { 'pincode': 302055 },
]

export const shippingAmountText = 1000;
export const callOptionNumber = "+917414003336";


