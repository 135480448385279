import React, { useState, useEffect } from "react";
import { Popconfirm, Alert } from 'antd'
import {
    CardItem,
    DeleteItem,
    ImageContainer,
    CardContent,
    CardPrice,
    QuantityContainer,
    Content,
    GiftContainer,
    DecreamentButton,
    InputQuantity,
    IncreamentButton,
    MobileCartItem,
    MobileDeleteItem,
    MobileImageContainer,
    MobileCardContent,
    MobileContent,
    MobileGiftContainer,
    MobileCardPrice,
    MobileQuantityContainer,
    MobileDecreamentButton,
    MobileInputQuantity,
    MobileIncreamentButton,
    MobileImgContentContainer,
    CardPriceWrapper,
    JaipurDeliveryMessage,
    CustomizeableContent
} from "./style";
import { CloseOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"


const Cards = (props) => {
    const { data, removeItem, updateProductList } = props;
    const [cardData, setCardData] = useState(data);
    const isMobile = window.innerWidth < 768;



    const decreamentQuantity = (data) => {
        let arr = cardData.splice(0);
        let updateQuantity;
        arr.map((item) => {
            if (item.id === data.id) {
                item.quantity = item.quantity - 1;
                updateQuantity = {
                    quantity: item.quantity,
                    cart_item_id: item.id,
                };
            }
            return arr;
        });
        setCardData(arr);
        updateProductList(updateQuantity);
    };
    const increamentQuantity = (data) => {
        let arr = cardData.splice(0);
        let updateQuantity;
        arr.map((item) => {
            if (item.id === data.id) {
                item.quantity = item.quantity + 1;
                updateQuantity = {
                    quantity: item.quantity,
                    cart_item_id: item.id,
                };
            }
            return arr;
        });
        setCardData(arr);
        updateProductList(updateQuantity);
    };

    useEffect(() => {
        if (data) {
            setCardData(data);
        }
    }, [data]);
    const handleDeleteCard = (data) => {
        removeItem(data.id);
        let arr = [...cardData];
        arr = arr.filter((item) => item.id !== data.id);
        setCardData(arr);
    };

    return (
        <>
            {cardData &&
                cardData.map((item) => {
                    let img = "";
                    if (item.product.thumbnail) {
                        img = item.product.thumbnail.url
                    } else {
                        img = item.product.image.url
                    }

                    return (
                        <>
                            {!isMobile ? (
                                <div>
                                    <CardItem>
                                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }} >
                                            <DeleteItem >
                                                <Popconfirm
                                                    title="Are you sure you want to remove this item?"
                                                    onConfirm={() => handleDeleteCard(item)}
                                                    okText="Remove"
                                                    cancelText="Cancel"
                                                >
                                                    <CloseOutlined />
                                                </Popconfirm>
                                            </DeleteItem>
                                            <Link style={{ marginLeft: 8, marginRight: 8 }} to={`/product-detail/${item.product?.url_key}`} target="_blank">
                                                <ImageContainer>
                                                    {" "}
                                                    <img
                                                        alt="example"
                                                        height="100%"
                                                        src={img}
                                                    />
                                                </ImageContainer>
                                            </Link>
                                            <CardContent>
                                                <Link to={`/product-detail/${item.product?.url_key}`} target="_blank"><Content>{item.product?.name}</Content></Link>
                                                <JaipurDeliveryMessage>
                                                    {
                                                        item.product.jaipur_only_delivery ?
                                                            <Alert style={{ marginTop: 0 }} message="Delivery is only available in Jaipur, Rajasthan." banner /> : null
                                                    }
                                                </JaipurDeliveryMessage>
                                                <GiftContainer>
                                                </GiftContainer>
                                                <CustomizeableContent>
                                                    {
                                                        item.customizable_options &&
                                                        <>
                                                            {
                                                                item.customizable_options.map((item) => {
                                                                    return (
                                                                        <div>
                                                                            <span>{item.label}</span>:
                                                                            {item.values[0].label ?
                                                                                <span style={{ marginLeft: 5 }} >{item.values[0].label}</span>
                                                                                : <span style={{ marginLeft: 5 }} >{item.values[0].value}</span>
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    }
                                                </CustomizeableContent>
                                            </CardContent>
                                        </div>
                                        <CardPriceWrapper>
                                            <CardPrice>
                                                {item.prices.row_total_including_tax &&
                                                    item.prices.row_total_including_tax.value
                                                }
                                            </CardPrice>
                                            <QuantityContainer>
                                                <DecreamentButton
                                                    onClick={() => decreamentQuantity(item)}
                                                    disabled={item.quantity < 0}
                                                >-</DecreamentButton>
                                                <InputQuantity
                                                    value={item.quantity}
                                                    min={0}
                                                />
                                                <IncreamentButton onClick={() => increamentQuantity(item)}>+ </IncreamentButton>
                                            </QuantityContainer>

                                        </CardPriceWrapper>
                                    </CardItem>

                                </div>

                            ) : (
                                <MobileCartItem>
                                    <MobileImgContentContainer>
                                        <MobileDeleteItem >
                                            <Popconfirm
                                                title="Are you sure you want to remove this item?"
                                                onConfirm={() => handleDeleteCard(item)}
                                                okText="Remove"
                                                cancelText="Cancel"
                                            >
                                                <CloseOutlined />
                                            </Popconfirm>
                                        </MobileDeleteItem>
                                        <Link style={{ marginLeft: 10 }} to={`/product-detail/${item.product?.url_key}`}>
                                            <MobileImageContainer>
                                                <img
                                                    alt="example"
                                                    height="100%"
                                                    src={img}
                                                />

                                            </MobileImageContainer>
                                        </Link>
                                        <MobileCardContent>
                                            <Link to={`/product-detail/${item.product?.url_key}`}>
                                                <MobileContent>{item.product?.name}</MobileContent>
                                            </Link>
                                            <CustomizeableContent>
                                                {
                                                    item.customizable_options &&
                                                    <>
                                                        {
                                                            item.customizable_options.map((item) => {
                                                                return (
                                                                    <>
                                                                        <div>
                                                                            <span>{item.label}</span>:
                                                                            {item.values[0].label ?
                                                                                <span style={{ marginLeft: 5 }} >{item.values[0].label}</span>
                                                                                : <span style={{ marginLeft: 5 }} >{item.values[0].value}</span>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }

                                            </CustomizeableContent>
                                            <MobileGiftContainer>
                                            </MobileGiftContainer>
                                            <MobileCardPrice>

                                                ₹
                                                {item.prices.row_total_including_tax &&
                                                    item.prices.row_total_including_tax.value
                                                }

                                            </MobileCardPrice>
                                            <MobileQuantityContainer>
                                                <MobileDecreamentButton
                                                    onClick={() => decreamentQuantity(item)}
                                                    disabled={item.quantity < 0}
                                                >
                                                    -
                                                </MobileDecreamentButton>
                                                <MobileInputQuantity
                                                    value={item.quantity}
                                                    min={0}
                                                />
                                                <MobileIncreamentButton
                                                    onClick={() => increamentQuantity(item)}
                                                >
                                                    +
                                                </MobileIncreamentButton>
                                            </MobileQuantityContainer>
                                        </MobileCardContent>


                                    </MobileImgContentContainer>
                                    <JaipurDeliveryMessage>
                                        {
                                            item.product.jaipur_only_delivery ?
                                                <Alert style={{ marginTop: 8 }} message="Delivery is only available in Jaipur, Rajasthan." banner /> : null
                                        }
                                    </JaipurDeliveryMessage>

                                </MobileCartItem>
                            )}
                        </>
                    );
                })}
        </>
    );
};

export default Cards;
