import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const requestPasswordResetEmailApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        requestPasswordResetEmail(
                            email: "${params.email}"
                        )
                    }`,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data;
};

export default requestPasswordResetEmailApi;
