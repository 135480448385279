import {
    GET_GUEST_CART_DATA,
    GET_GUEST_CART_DATA_SUCCESS,
    GET_GUEST_CART_DATA_FAIL,
    GET_GUEST_CART_DATA_RESET
} from '../actions/getGuestCartDataAction'

const initialState = {
    apiState: "", // loading, success, error
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_GUEST_CART_DATA:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_GUEST_CART_DATA_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }

        case GET_GUEST_CART_DATA_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_GUEST_CART_DATA_RESET:
            return initialState

        default:
            return state
    }
}
