import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Spin, Button, Popconfirm } from "antd";
import get from "lodash/get";
import { InfoCircleOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types'

/* ============= */
// custom compnents
/* ============= */
import {
    Container, AddCardContainer, SummaryContainer, ArrowContainer, CartContainer,
    InfoContainer, InfoContent, SpinContainer,
} from "./style";
import OrderSummary from "../../components/OrderSummary/orderSummary";
import Cards from "./Cards/cards";
import CartSkeleton from "../../components/CartSkeleton/cartSkeleton";
import NoDataContianer from "../../components/NoDataContainer/NoData";
import HelpNumber from "../../components/HelpNumber/HelpNumber"
import Authentication from '../../components/Authentication/Authentication'

/* ============= */
// others
/* ============= */
import { openSuccessNotification, openErrorNotification } from '../../utils';
import { getCartId, getCustomerToken, shippingAmountText } from "../../utils"

/* ============= */
// actions
/* ============= */
import { removeItemCart, removeItemCartReset } from "../../actions/removeItemCartAction";
import { updateProductCart, updateProductCartReset } from "../../actions/updateCartAction";
import { getProductCartList } from "../../actions/addProductCartAction";
import { fetchCartData } from "../../actions/fetchCartDataAction";
import { fetchQuantity } from "../../actions/detailActions";
import { addCouponRequest } from "../../actions/addCouponAction";
import { removeCouponRequest } from "../../actions/removeCouponAction";
import { toggleCouponRequest } from '../../actions/toggleCouponAction';
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import { updateCartProduct, updateCartProductReset } from '../../actions/updateCartProductAction'

const Cart = (props) => {
    /* ============= */
    // variables
    /* ============= */
    const {
        removeItemCart, removeItemCartState, removeItemCartReset, updateProductCart, updateProductCartReset, productCartListItems, productCartListPrices,
        updateListItemsPrices, updateListItems, removeListItem, removeListPrices, fetchCartData, fetchCartDataState,
        cartDataItems, cartDataPrices, fetchQuantity, updateLoader, addCouponRequest, removeCouponRequest,
        cartLoader, toggleCouponRequest, removeItemState, updateCartProduct, updateCartProductState, updateCartProductReset,
        createCustomerCartState
    } = props;
    const previousPath = useHistory();
    const [cardData, setCardData] = useState(null);
    const [priceData, setPriceData] = useState(null);
    const [appliedCoupon, setAppliedCoupon] = useState(null);
    const [couponCheck, setCouponCheck] = useState(false);
    const [token, setToken] = useState(localStorage.getItem('customerToken'))
    const [refresh, setRefresh] = useState(0);


    /* ============= */
    // callbacks
    /* ============= */
    useEffect(() => {
        if (productCartListItems) {
            setCardData([...productCartListItems]);
            setPriceData(productCartListPrices);
        }
        return () => {
            setCardData([]);
        };
    }, [productCartListItems]);


    useEffect(() => {
        if (removeListItem) {
            getCartCount({
                cart_Id: getCartId(),
                customerToken: getCustomerToken()
            })
            setCardData([...removeListItem]);
            setPriceData(removeListPrices);
            fetchQuantity(removeListItem.length);
        }
        return () => {
            setCardData([]);
        };
    }, [removeListItem]);

    useEffect(() => {
        updateCartProduct({
            errorAction: (error) => {
            }
        })
    }, [])
    useEffect(() => {
        if (updateListItems) {
            setCardData([...updateListItems]);
            setPriceData(updateListItemsPrices);
            fetchCartData({
                cart_id: getCartId(),
            })
            fetchQuantity(updateListItems.length);
        }
    }, [updateListItems]);
    useEffect(() => {
        if (removeItemCartState.apiState === "success" || removeItemCartState.apiState === "error") {
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken()
            })
            fetchCartData({
                cart_id: getCartId(),
            })
            removeItemCartReset()
        }

    }, [removeItemCartState])


    useEffect(() => {
        const cartId = getCartId();
        fetchCartData({
            cart_id: cartId,
            successAction: (data) => {
                const coupon = get(data, "data.cart.applied_coupons[0].code");
                if (coupon) {
                    setAppliedCoupon(coupon);
                    setCouponCheck(true);
                }
            },
            errorAction: (error) => {
                if (error.data.cart.items) {
                    let errorData = error.data.cart.items
                    errorData.map((item) => {
                        if (item !== null) {
                            if (item.quantity > item.product.only_x_left_in_stock) {
                                let decreseQuantity = item.quantity - item.product.only_x_left_in_stock
                                if (decreseQuantity) {
                                    let itemId = item.id
                                    const updatedData = {
                                        cart_id: cartId,
                                        cart_item_id: itemId,
                                        quantity: item.product.only_x_left_in_stock
                                    }
                                    updateProductCart({
                                        updatedData
                                    })
                                }
                            }
                            if (item.product.stock_status === "OUT_OF_STOCK" || item.product.only_x_left_in_stock === null) {
                                let itemId = item.id
                                removeItemCart({
                                    cart_id: cartId,
                                    cart_item_id: itemId,
                                })
                            }
                        }
                    })
                }
            },
        });
    }, [refresh]);

    useEffect(() => {
        if (createCustomerCartState.refreshCart) {
            setRefresh(refresh + 1)
            createCustomerCartState.refreshCart = false
        }
    }, [createCustomerCartState])


    useEffect(() => {
        if (cartDataItems) {
            setCardData([...cartDataItems]);
            setPriceData(cartDataPrices);
            fetchQuantity(cartDataItems.length);
        }
        return () => {
            setCardData([]);
        };
    }, [cartDataItems]);


    /* ============= */
    // functions
    /* ============= */
    const goToPreviousPath = () => {
        previousPath.goBack();
    };

    const removeItem = (id) => {
        const cartId = getCartId();
        const data = {
            cart_id: cartId,
            cart_item_id: id,
        };
        removeItemCart(data);
    };

    const updateProductList = (data) => {
        const cartId = getCartId();
        const updatedData = {
            cart_id: cartId,
            cart_item_id: parseInt(data.cart_item_id),
            quantity: data.quantity,
        };
        updateProductCart({
            updatedData,
            errorAction: (data) => {
                openErrorNotification('Requested quantity is not available.')
                handleCartData();
                const cartId = getCartId();
                fetchCartData({
                    cart_id: cartId,
                    successAction: (data) => {
                        const coupon = get(data, "data.cart.applied_coupons[0].code");
                        if (coupon) {
                            setAppliedCoupon(coupon);
                            setCouponCheck(true);
                        }
                    },
                });
                updateProductCartReset()
            },
            successAction: (data) => {
            },
        });
    };

    const handleCheckout = () => {
        props.history.push("/checkout");
    };

    const addCouponCode = (item) => {
        addCouponRequest({
            item,
            errorAction: (data) => {
                openErrorNotification(data.errors[0].message)
            },
            successAction: (data) => {
                const couponCode = get(data,
                    "data.applyCouponToCart.cart.applied_coupons[0].code")
                setAppliedCoupon(couponCode);
                setCouponCheck(true);
                const couponDataItem = get(
                    data,
                    "data.applyCouponToCart.cart.items"
                );

                const couponDataPrice = get(
                    data,
                    "data.applyCouponToCart.cart.prices"
                );
                toggleCouponRequest(true)
                openSuccessNotification('coupon applied successfully');

                setCardData([...couponDataItem]);
                setPriceData({ ...couponDataPrice, discountApplied: true });
            },
        });
    };
    const removeCouponCode = (item) => {
        removeCouponRequest({
            item,
            errorAction: () => {
                openErrorNotification('Failed to remove code.')
            },
            successAction: (data) => {
                openSuccessNotification('coupon code removed');
                setCouponCheck(false);
                setAppliedCoupon(null);
                const couponDataItem = get(
                    data,
                    "data.removeCouponFromCart.cart.items"
                );
                const couponDataPrice = get(
                    data,
                    "data.removeCouponFromCart.cart.prices"
                );
                toggleCouponRequest(false)
                setCardData([...couponDataItem]);
                setPriceData({ ...couponDataPrice, discountApplied: false });
            },
        });
    };

    const handleCartData = () => {
        const cartId = getCartId();
        fetchCartData({
            cart_Id: cartId
        });
    }


    return (
        <>
            <Container>
                {getCartId() ?
                    (cartLoader || updateLoader) ? <CartSkeleton /> : cardData && cardData.length > 0 ? (
                        <>
                            <ArrowContainer onClick={goToPreviousPath}>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                                    alt="ladu-india"
                                    width="36"
                                />
                            </ArrowContainer>
                            <CartContainer>
                                <AddCardContainer>

                                    <InfoContainer>
                                        <InfoCircleOutlined
                                            style={{ fontSize: "14px", color: "#fff", marginRight: '10px', paddingTop: '5px' }}
                                        />
                                        <div style={{ width: '100%', height: '100%' }}>
                                            {/* {
                                                priceData && priceData.subtotal_including_tax && priceData.subtotal_including_tax.value < shippingAmountText ? <InfoContent>
                                                    Add products worth ₹{(shippingAmountText - priceData.subtotal_including_tax.value).toFixed(2)} more to get free shipping.
                                            </InfoContent> : <InfoContent>
                                                    Congratulations! Free Shipping applicable on this order.
                                                </InfoContent>
                                            } */}

                                            <InfoContent>
                                                Enjoy free shipping all over India.
                                            </InfoContent>
                                        </div>
                                    </InfoContainer>
                                    {updateLoader ? (
                                        <SpinContainer>
                                            <Spin />
                                        </SpinContainer>
                                    ) : (
                                        <Cards
                                            data={cardData}
                                            removeItem={removeItem}
                                            updateProductList={updateProductList}
                                        />
                                    )}
                                </AddCardContainer>
                                <SummaryContainer>
                                    <OrderSummary
                                        enableButton
                                        summaryPrices={priceData}
                                        handleCheckout={handleCheckout}
                                        addCouponCode={addCouponCode}
                                        removeCouponCode={removeCouponCode}
                                        appliedCoupon={appliedCoupon}
                                        couponCheck={couponCheck}
                                        handleRefreshCart={() => setRefresh(refresh + 1)}
                                        isCart
                                    />
                                </SummaryContainer>
                            </CartContainer>
                            <HelpNumber />
                        </>
                    ) :
                        <>
                            <NoDataContianer
                                showText="No Items available in cart."
                                animationType={"cartAnimation"}
                            />
                            <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                        </>
                    :
                    <>
                        <NoDataContianer
                            showText="No Items available in cart."
                            animationType={"cartAnimation"}
                        />
                        <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                    </>

                }
            </Container>
        </>
    );
};



const mapStateToProps = (state) => ({
    productCartListItems: state.productCart.productCartListItems,
    productCartListPrices: state.productCart.productCartListPrices,
    isLoading: state.productCart.isLoading,
    updateListItems: state.updateItem.updateListItems,
    updateListItemsPrices: state.updateItem.updateListItemsPrices,
    removeListPrices: state.removeItemCart.removeListPrices,
    removeListItem: state.removeItemCart.removeListItem,
    removeItemCartState: state.removeItemCart,
    cartDataItems: state.cartData.cartDataItems,
    cartDataPrices: state.cartData.cartDataPrices,
    cartLoader: state.cartData.cartLoader,
    isLoading: state.loader.isLoading,
    updateLoader: state.updateItem.isLoading,
    toggleCouponBool: state.toggleCoupon.toggleCouponBool,
    updateCartProductState: state.updateCartProduct,
    fetchCartDataState: state.fetchCartData,
    createCustomerCartState: state.createCustomerCart
});

const mapDispatchToProps = (dispatch) => ({
    removeItemCart: (params) => dispatch(removeItemCart(params)),
    removeItemCartReset: () => dispatch(removeItemCartReset()),
    updateProductCart: (params) => dispatch(updateProductCart(params)),
    updateProductCartReset: () => dispatch(updateProductCartReset()),
    getProductCartList: (params) => dispatch(getProductCartList(params)),
    fetchCartData: (params) => dispatch(fetchCartData(params)),
    fetchQuantity: (params) => dispatch(fetchQuantity(params)),
    addCouponRequest: (params) => dispatch(addCouponRequest(params)),
    removeCouponRequest: (params) => dispatch(removeCouponRequest(params)),
    toggleCouponRequest: (params) => dispatch(toggleCouponRequest(params)),
    getCartCount: (params) => dispatch(getCartCount(params)),
    updateCartProduct: (params) => dispatch(updateCartProduct(params)),
    updateCartProductReset: () => dispatch(updateCartProductReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
