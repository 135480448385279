import { call, put, takeLatest } from 'redux-saga/effects'


// action
import {
    GUEST_BILLING_ADDRESS,
    guestBillingAddressSuccess,
    guestBillingAddressFail
} from '../actions/guestBillingAddressAction'

// api

import guestBillingAddressApi from '../apis/guestBillingAddressApi'

export function* guestBillingAddressSaga(action) {
    try {
        const response = yield call(() => guestBillingAddressApi(action.params))

        if (response.errors) {
            yield put(guestBillingAddressFail(response, action))
        }
        else {
            yield put(guestBillingAddressSuccess(response, action))
        }
    }
    catch (e) {
        yield put(guestBillingAddressFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GUEST_BILLING_ADDRESS, guestBillingAddressSaga)
}