import { Button } from "antd";
import styled, { css } from "styled-components";


export const TopBannerInner = styled.div`
    flex: 0 1 auto;
    margin: 0 20px;
    font-size: 14px;
    line-height: 18px;
    padding: 6px 0;
`;
export const TopBannerButton = styled(Button)`
   display: block;
    border: none;
    background: none;
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 2;
    color: #000;
`;
export const Container = styled.section`
  position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 44px;
    background-color: #fcfcfc;
    font-weight: bold;
    /* color: #fff; */
    /* background-color: #304292; */
    font-size: 14px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;
