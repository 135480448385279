import axios from "axios";
import { BASE_URL } from '../utils';

const showOnHomePageBurniSectionApi = async () => {
    let cancelToken
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()
    const response = await axios({
       url: `${BASE_URL()}`,
        method: "post",
        cancelToken: cancelToken.token,
        data: {
            query: `
                {
                    products(
                        filter: {show_on_home_page_burni_sectio: {eq: "1"}},
                        currentPage:1, 
                        pageSize: 20, 
                        sort :{showcase_on_home_page_sort_ord : ASC}
                    ) {
                        total_count
                        items {
                            is_brand
                            brand_name
                            showcase_on_home_page_sort_ord
                            url_key
                            name
                            small_image  {
                                url
                            }
                        }
                    }
                }
            `,
        }
    });
    return response.data;
};

export default showOnHomePageBurniSectionApi;
