import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { RelatedProduct } from './style'

const DrawerComponent = (props) => {
  const { placement, visible, showData, DrawerTitle } = props;
  const [onVisible, setVisible] = useState(visible || null);
  const isMobile = window.innerWidth < 768;


  const onClose = () => {
    setVisible(false);
    props.resetCloseState()
  };

  useEffect(() => {
    setVisible(onVisible)
  }, [onVisible])

  return (
    <RelatedProduct>
      <Drawer
        title={DrawerTitle || "Basic Drawer"}
        placement={placement || "bottom"}
        closable
        onClose={onClose}
        visible={onVisible}
        width={isMobile ? "100%" : "80% !important"}
        height={'80%'}
        className="related_product"
      >
        {showData()}
      </Drawer>
    </RelatedProduct >
  );
};

export default DrawerComponent;
