import axios from "axios";
import { isAuthorized, BASE_URL } from "../utils";

const addAddressApi = async (data) => {

  const response = await axios({
     url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        createCustomerAddress(input: {
          region: {
            region: "${data.params.data.stateName.name}"
            region_code: "${data.params.data.stateName.code}"
            region_id:${data.params.data.stateName.id}
          }
          country_code: IN
          street: ["${data.params.data.address}"]
          telephone: "${data.params.data.phone}"
          postcode: "${data.params.data.pinCode}"
          city: "${data.params.data.city}"
          firstname: "${data.params.data.firstName}"
          lastname: "${data.params.data.lastName}"
          default_shipping: true
          default_billing: false
        }) {
          id
          region {
            region
            region_code
          }
          country_code
          street
          telephone
          postcode
          city
          default_shipping
          default_billing
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default addAddressApi;
