export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const ADD_PRODUCT_TO_CART_SUCCESS = 'ADD_PRODUCT_TO_CART_SUCCESS'
export const ADD_PRODUCT_TO_CART_FAIL = 'ADD_PRODUCT_TO_CART_FAIL'
export const ADD_PRODUCT_TO_CART_RESET = 'ADD_PRODUCT_TO_CART_RESET'

export const addProductToCart = (params) => {
    return { type: ADD_PRODUCT_TO_CART, params }
}

export const addProductToCartSuccess = (response) => {
    return { type: ADD_PRODUCT_TO_CART_SUCCESS, response }
}

export const addProductToCartFail = (response) => {
    return { type: ADD_PRODUCT_TO_CART_FAIL, response }
}

export const addProductToCartReset = () => {
    return { type: ADD_PRODUCT_TO_CART_RESET }
}