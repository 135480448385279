import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const getCartIdApi = async () => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: ` mutation
         {
                createEmptyCart
              }
  
    `,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default getCartIdApi;
