export const FETCH_RELATED_PRODUCTS_REQUEST = 'FETCH_RELATED_PRODUCTS_REQUEST'
export const FETCH_RELATED_PRODUCTS_SUCCESS = 'FETCH_RELATED_PRODUCTS_SUCCESS'

export const fetchRelatedProductRequest = (params) => {
    return { type: FETCH_RELATED_PRODUCTS_REQUEST, params }
}

export const fetchRelatedProductSuccess = (response) => {
    return { type: FETCH_RELATED_PRODUCTS_SUCCESS, response }
}


