import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  REMOVE_WISHLIST_REQUEST,
  removeWishListSuccess,
  removeWishListFail,
} from "../actions/removeWishListAction";
import removeWishListApi from "../apis/removeWishListApi";
import get from 'lodash/get';


function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(otherParams);
  }
}

export function* removeWishListSaga(action) {

  try {
    const response = yield call(() => removeWishListApi(action));
    if (response.errors) {
      yield put(removeWishListFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(removeWishListSuccess(response, action));
      yield common(action, true, response);

    }
  } catch (e) {
    yield put(removeWishListFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(REMOVE_WISHLIST_REQUEST, removeWishListSaga);
}
