import styled from "styled-components";
import { Collapse, Dropdown, List, Tabs } from "antd";
import { Link } from "react-router-dom";

export const Container = styled.div`
  height: 100%;

`;

export const Body = styled.div``;

export const Footer = styled.div`
 /* height: 16px;
 position: fixed;
 bottom: 0;
 display: flex;
 justify-content: center;
 left: 0;
 right: 0;
 padding-bottom: 20px;
 background: #355854;
 color: rgb(255 255 255 / 75%);
 @media ${(props) => props.theme.device.tablet} {
   display: none;
 } */
`;

export const BackHeaderLink = styled(Link)`
color: #000000;
&&:hover{
  color: #000000;
}
`
export const CategoryName = styled.p`
  margin: 0;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  margin-top: 0.5rem;
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
    font-weight: ${(props) => props.theme.fontWeightBold};
  }
`;

export const CategoryImg = styled.div`
  width: 74px;
  height: 74px;
  padding: 15px;
  border-radius: 37px;
  background: #68827f;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }
`;

export const SubCategoryContainer = styled.div`
  margin-top: 0.5rem;
`;


export const SubCategoryName = styled.p`
  margin: 0;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeight};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.81;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  padding-left:7px;
  &:hover{
    background: #f5f5f5;
  }
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
    margin: 0px 0px 20px 0px;
  }
`;

export const SingleCategoryName = styled.p`
  margin: 0;
  cursor: pointer;
    padding: 12px 5px;
    font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
    font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
`;

export const PanelContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: row;
`;

export const CollapseContainer = styled(Collapse)`
margin-bottom:72px;
  &.ant-collapse-borderless {
    background-color: unset;
    border: 0;
    width: 100%;
    padding:0 10px;
  }
  && .ant-collapse-item {
    border-bottom: unset;
  }
  && .ant-collapse-header {
  //  color: white;
  }
  &&.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 50px;
    left: unset;
    top: 24px;
}
  &&.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-left: 0;
    padding: 12px 5px;
    font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
    font-family: ${(props) => props.theme.fontFamily};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  }
  &&.ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    > .ant-collapse-extra {
    float: left;
    margin: 0 10px;
  }
`;

export const PanelImg = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 37px;
  background: #68827f;
  //display: flex;
  align-items: center;
  //justify-content: center;

  img {
    width: 100%;
  }
`;
export const CategoryContainer = styled.div`
  width: 100%;
  margin: auto;
  // display: flex;
  // flex-direction: row;
  // flex-flow: wrap;

 column-count: 4;
 column-gap:1rem;
 padding-bottom: 82px;
  @media ${(props) => props.theme.device.tablet} {
    padding: 0 10px;
  }
`;

export const SkeletonCategoryContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;


  @media ${(props) => props.theme.device.tablet} {
    padding: 0 10px;
  }
`;

export const CategoryCard = styled.span`
  // width: 25%;
 // flex: 1 0 auto;
 display:inline-block;
 margin: 0 0 1em;
 width:100%;

  @media ${(props) => props.theme.device.tablet} {
    /* width: 50%; */
  }
  padding-top: 20px;
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
`;

export const DropdownContainer = styled(Dropdown)`
  // &&
  //   .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  //   padding-top: 20px !important;
  // }
  // & .ant-dropdown ant-dropdown-placement-bottomLeft {

  // }
`;

export const ListItem = styled(List.Item)`
  &&.ant-list-item {
    padding: 10px 0;
    cursor: pointer;
  }
`;

export const ListHeader = styled.div`
  color: #f78c3e;
  padding: 10px 0;
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
`;

export const CategorySubCard = styled.div`
  display: inline-block;
`;

export const MenuContent = styled.a`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
padding: 6px !important;
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media ${(props) => props.theme.device.tablet} {
//    display: none;
  }
`;

export const TabsComponent = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0px;
  }
  &.ant-tabs {
    height: 64px;
  }
  &.ant-tabs > .ant-tabs-nav {
    height: 100%;
  }
  && .ant-tabs-tab {
    margin: 0 40px 0 0;
    font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
    font-stretch: ${(props) => props.theme.fontStretch};
    font-style: ${(props) => props.theme.fontStyle};
    font-family: ${(props) => props.theme.fontFamily};
    line-height: 1.5;
    letter-spacing: normal;
  }
  && .ant-tabs-tab-btn {
    // color: ${(props) => props.theme.color.white};
  }
  && .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${(props) => props.theme.color.black};
    font-weight: ${(props) => props.theme.fontWeightBold};

  }
  &&.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: unset;
    content: "";
  }
  && .ant-tabs-ink-bar {
    background: #f78c3e;
    height: 4px;
    border-radius: 50px;
  }
  @media ${(props) => props.theme.device.tablet} {
    // display: none;
  }
`;