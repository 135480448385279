import axios from "axios";
import { BASE_URL, isAuthorized } from "../utils";

const getProductDetailApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `
                query productDetail($urlKey: String!) {
                    products(
                        filter: {
                        url_key: {
                            eq: $urlKey
                        }
                        }
                    )             
                    {
                items {
                    product_tags
                    ... on SimpleProduct {
                      brand_name
                    }
                    meta_title
                    meta_keyword
                    meta_description
                    is_brand
                    url_key
                    categories {
                      id
                      name
                      url_key
                    breadcrumbs {
                          category_id
                          category_level
                          category_name
                          category_url_key
                          category_url_path
                        }
                    }

                    options_container
                    ... on ConfigurableProduct{
                      configurable_options{
                        __typename
                        product_id
                        label
                        id
                        attribute_code
                        attribute_id
                        values{
                          label
                          value_index
                          swatch_data {
                            value
                          }

                        }
                      }
                      variants {
                        product {
                          only_x_left_in_stock
                           media_gallery{
                            url
                          }
                          thumbnail{
                            url
                          }
                          stock_status
                          id
                          name
                          sku
                          attribute_set_id
                          ... on PhysicalProductInterface {
                            weight
                          }
                          price_range{
                            minimum_price{
                              regular_price{
                                value
                                currency
                              }
                            }
                          }
                        }
                        attributes {
                          uid
                          label
                          code
                          value_index
                        }
                      }
                    }
                    ... on CustomizableProductInterface {
                      __typename
                      options {
                        ... on CustomizableFieldOption {
                          title
                          __typename
                          required
                          text_value: value {
                            max_characters
                            price
                            price_type
                            sku
                              uid
                          }
                        }
                        ... on CustomizableRadioOption{
                          required
                          title
                          __typename
                          radio_value: value{
                            title
                            uid
                            price
                            price_type
                            sku
                          }

                        }
                        ... on CustomizableDropDownOption {
                          required
                          __typename
                          dropdown_value: value {
                            option_type_id
                            title
                            uid
                            price
                            price_type
                            sku
                            sort_order
                          }
                        }
                      }
                    }
                    sku
                    jaipur_only_delivery
                    thumbnail {
                      url
                    }
                    media_gallery {
                      url
                    }
                    image {
                      url
                    }
                    id
                    stock_status
                    only_x_left_in_stock
                    name
                    product_tag
                    special_price
                    price_range {
                      minimum_price {
                        regular_price {
                          value
                        }
                        discount {
                          percent_off
                        }
                      }
                    }
                    related_products {
                      id
                      name
                      sku
                      brand_name
                      thumbnail {
                        disabled
                        position
                        url
                      }
                      url_key
                      media_gallery {
                        disabled
                        position
                        url
                      }
                      short_description {
                        html
                      }
                      description {
                        html
                      }
                      price_range {
                        minimum_price {
                          regular_price {
                            value
                          }
                          discount {
                            percent_off
                          }
                          final_price {
                            value
                          }
                        }
                      }
                    }
                    short_description {
                      html
                    }
                    description {
                      html
                    }
                    price_range {
                      minimum_price {
                        final_price {
                          currency
                          value
                        }
                      }
                    }
                    categories {
                      url_key
                      name
                      image
                      breadcrumbs {
                        category_id
                        category_level
                        category_name
                        category_url_key
                        category_url_path
                      }
                    }
                    media_gallery {
                      disabled
                      position
                      url
                      label
                    }
                  }
                }
              }
            `,
      variables: {
        urlKey: params.urlKey,
      },
    },
    headers: isAuthorized(),
  });
  return response.data;

};

export default getProductDetailApi;
