export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST'
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS'
export const UPDATE_ADDRESS_FAIL = 'UPDATE_PRODUCT_CART_FAIL'

export const updateAddressRequest = (params) => {
    return { type: UPDATE_ADDRESS_REQUEST, params }
}

export const updateAddressSuccess = (response) => {
    return { type: UPDATE_ADDRESS_SUCCESS, response }
}

export const updateAddressFail = (response) => {
    return { type: UPDATE_ADDRESS_FAIL, response }
}

