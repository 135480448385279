
import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'


import {
    FETCH_BRAND_BY_ID,
    fetchBrandByIdSuccess,
    fetchBrandByIdFail,
} from '../actions/fetchBrandByIdAction'

import fetchBrandByIdApi from '../apis/fetchBrandByIdApi'


export function* fetchBrandByIdSaga(action) {
    try {
        const response = yield call(() => fetchBrandByIdApi(action.params))
        if (response.errors) {
            yield put(fetchBrandByIdFail(response, action))
        } else {
            yield put(fetchBrandByIdSuccess(response, action))
        }
    } catch (e) {
        yield put(fetchBrandByIdFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_BRAND_BY_ID, fetchBrandByIdSaga);
}