import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const updateProductCartApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation{
        updateCartItems(
          input: {
            cart_id: "${data.params.updatedData.cart_id}"
            cart_items: [
              {  
                  quantity: ${data.params.updatedData.quantity}
                  cart_item_id: ${data.params.updatedData.cart_item_id}
              }
            ]
          }
        ) {
        cart {
            prices{
              subtotal_including_tax{
                value
              }
              grand_total{
                value
              }
              discounts{
                amount{
                  value
                }
                label
              }
            }
            items {
              id
              product {
                sku
                stock_status
                url_key
                jaipur_only_delivery
                name
                price_range{
                  minimum_price{
                    final_price{
                      currency
                      value
                    }
                  }
                }
                 image {
                          url
                      }
                thumbnail{
                  url
                }
                media_gallery{
                  position
                  url
                }
                short_description{
                  html
              }
              description{
                  html
              }
              }
              quantity
               prices{
              row_total_including_tax{
                value
              }
              }
                ... on SimpleCartItem {
                    customizable_options{
              label
              id
            values{
              value
              label
          }
          }
             
            }

          }
            }
          }
        }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default updateProductCartApi;
