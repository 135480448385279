import {
  SET_SHIPPING_REQUEST,
  SET_SHIPPING_SUCCESS,
  SET_SHIPPING_FAIL,
  SET_SHIPPING_RESET
} from "../actions/setShippingAction";
import get from "lodash/get";

const initialState = {
  data: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SHIPPING_REQUEST:
      return {
        ...state,
      };

    case SET_SHIPPING_FAIL:
      return {
        ...state,
      };

    case SET_SHIPPING_SUCCESS:
      return {
        ...state,
        data: action.response.data
      };
    case SET_SHIPPING_RESET:
      return initialState;

    default:
      return state;
  }
}
