import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    SET_GUEST_EMAIL_CART,
    setGuestEmailCartSuccess,
    setGuestEmailCartFail
} from '../actions/setGuestEmailCartAction'

// api
import setGuestEmailCartApi from '../apis/setGuestEmailCartApi'

export function* setGuestEmailCartSaga(action) {
    try {
        const response = yield call(() => setGuestEmailCartApi(action.params))
        yield put(setGuestEmailCartSuccess(response, action))
    }
    catch (e) {
        yield put(setGuestEmailCartFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(SET_GUEST_EMAIL_CART, setGuestEmailCartSaga)
}