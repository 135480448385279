// action
import {
    UPDATE_CUSTOMER_ADDRESS,
    UPDATE_CUSTOMER_ADDRESS_SUCCESS,
    UPDATE_CUSTOMER_ADDRESS_FAIL,
    UPDATE_CUSTOMER_ADDRESS_RESET,
} from '../actions/updateCustomerAddressAction'

const initialState = {
    apiState: "",
    data: {}
}
export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_CUSTOMER_ADDRESS:
            return {
                ...state,
                apiState: "loading",
            }
        case UPDATE_CUSTOMER_ADDRESS_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case UPDATE_CUSTOMER_ADDRESS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response
            }
        case UPDATE_CUSTOMER_ADDRESS_RESET:
            return initialState

        default:
            return state
    }
}