import {
    SET_RZP_PAYMENT_DETAILS_ON_CART,
    SET_RZP_PAYMENT_DETAILS_ON_CART_SUCCESS,
    SET_RZP_PAYMENT_DETAILS_ON_CART_FAIL,
    SET_RZP_PAYMENT_DETAILS_ON_CART_RESET,
} from '../actions/setRzpPaymentDetailsOnCartAction'


const initialState = {
    apiState: "", // loading, success, error
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_RZP_PAYMENT_DETAILS_ON_CART:
            return {
                ...state,
                apiState: "loading",
            }

        case SET_RZP_PAYMENT_DETAILS_ON_CART_SUCCESS:
            return {
                ...state,
                apiState: "success",
            }

        case SET_RZP_PAYMENT_DETAILS_ON_CART_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case SET_RZP_PAYMENT_DETAILS_ON_CART_RESET:
            return initialState

        default:
            return state
    }
}
