import axios from "axios";
import { isAuthorized,BASE_URL } from "../utils";

const fetchBrandByIdApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
                products(filter: {brand_name: {eq: "${params.brand_name}"}, is_brand: {eq: "1"}}) {
                  items {
                    name
                    brand_name
                    url_key
                  }
                }
              }
            `,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchBrandByIdApi;
