export const FETCH_STATE_REQUEST = "FETCH_STATE_REQUEST";
export const FETCH_STATE_SUCCESS = "FETCH_STATE_SUCCESS";
export const FETCH_STATE_FAIL = "FETCH_STATE_FAIL";

export const fetchStateRequest = (params) => {
  return { type: FETCH_STATE_REQUEST, params };
};

export const fetchStateSuccess = (response) => {
  return { type: FETCH_STATE_SUCCESS, response };
};

export const fetchStateFail = (response) => {
  return { type: FETCH_STATE_FAIL, response };
};
