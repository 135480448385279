import React, { useEffect, useState } from "react";
import {
  Container,
  AddAddress,
  AddressCard,
  AddressPhone,
  AddressName,
  AddressContent,
  SelectButton,
  AddressPincode,
  EditButton
} from "./style";
import { ADDRESS_DATA } from "./constant";
import { connect } from 'react-redux'
import { notification, Button, Modal } from 'antd'
import { EditOutlined } from "@ant-design/icons"
import _ from 'lodash'

// custom component

// action
import { getShippigoToken, getShippigoTokenReset } from '../../../actions/getShippigoTokenAction'
import { checkServiceAvailability, checkServiceAvailabilityReset } from '../../../actions/checkServiceAvailabilityAction'
import { setShippingMethod } from '../../../actions/setShippingMethodAction'
import { jaipurPincodeData } from "../../../utils"
import { updateCustomerAddress, updateCustomerAddressReset } from '../../../actions/updateCustomerAddressAction'
import UpdateCustomerAddress from '../../../components/UpdateCustomerAddress/UpdateCustomerAddress'



const Address = (props) => {
  // variables
  const [postCode, setPostCode] = useState(null)
  const [item, setItem] = useState(null)
  const [btnLoading, setBtnLoading] = useState(false)
  const [updateAddress, setUpdateAddress] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [editForm, setEditForm] = useState("")
  const [loadingData, setLoadingData] = useState({})
  const [indexValue, setIndexValue] = useState()


  const {
    handleAddAddress,
    addressData,
    handleShippingRequest,
    getShippigoToken,
    getShippigoTokenReset,
    getShippigoTokenState,
    checkServiceAvailability,
    checkServiceAvailabilityState,
    checkServiceAvailabilityReset,
    setShippingMethodState,
    visiableData,
    updateCustomerAddress,
    updateCustomerAddressState,
    handleUpdateAddress
  } = props;

  useEffect(() => {
    if (updateCustomerAddressState.apiState === "success") {
      setUpdateAddress(false)
      // setRefresh(refresh + 1)
    }
    // updateCustomerAddressReset()
  }, [updateCustomerAddressState])


  useEffect(() => {
    if (getShippigoTokenState.apiState === "loading" || setShippingMethodState.apiState === "loading") {
      setBtnLoading(true)

      setLoadingData({ ...loadingData, [indexValue]: true })
    }
  }, [setShippingMethodState, getShippigoTokenState])
  // callbacks
  useEffect(() => {
    if (getShippigoTokenState.apiState === "success") {
      const token = getShippigoTokenState.data.token;
      checkServiceAvailability({
        token: token,
        postCode: postCode
      })
      getShippigoTokenReset()
    }
    if (getShippigoTokenState.apiState === "error") {
      notification["error"]({
        message: 'Something is not right.',
        placement: "topRight"
      });
      getShippigoTokenReset()
      setBtnLoading(false)
      setLoadingData({})
    }
  }, [getShippigoTokenState])


  useEffect(() => {
    if (checkServiceAvailabilityState.apiState === "success") {
      handleShippingRequest(item)
      checkServiceAvailabilityReset()
    }
    if (checkServiceAvailabilityState.apiState === "error") {
      notification["error"]({
        message: "Something is not right",
        placement: "topRight"
      })
      checkServiceAvailabilityReset()
      setBtnLoading(false)
      setLoadingData({})
    }
  }, [checkServiceAvailabilityState])

  const handleSelect = (item, index) => {

    const postCodeResult = item.postcode
    setLoadingData({})
    setIndexValue(index)
    if (visiableData && !(_.find(jaipurPincodeData, ['pincode', Number(postCodeResult)]))) {
      notification["error"]({
        message: "Some of the items in your cart can only be delivered to Jaipur. Please remove these from your cart or choose a delivery address in Jaipur to proceed with checkout.",
        placement: "bottomRight"
      })
    }
    else {
      getShippigoToken()
      setPostCode(item.postcode)
      setItem(item)
    }

  };


  const setEditData = (id) => {
    // let data = ._find(address)
    let data = _.find(addressData, { id: id })
    setEditForm(data)
    setUpdateAddress(true)

  }

  return (
    <Container>
      <AddAddress onClick={handleAddAddress}>
        <div>Add New</div>
      </AddAddress>
      {addressData &&
        addressData.map((item, index) => {
          return (
            <AddressCard>
              <EditButton onClick={() => [
                setEditData(item.id)

              ]} ><EditOutlined /></EditButton>
              <AddressName>
                {item.firstname} {item.lastname}
              </AddressName>
              <AddressContent>
                {item.street[0]}, {item.city}, {item.region.region}
              </AddressContent>
              <AddressPincode>Pincode : {item.postcode}</AddressPincode>
              <AddressPhone>Mobile : +91-{item.telephone === 'null' ? '' : item.telephone}</AddressPhone>
              <SelectButton onClick={() =>
                handleSelect(item, index)} loading={loadingData[index]} disabled={loadingData[index] ? false : btnLoading} >
                Deliver to this location
              </SelectButton>


            </AddressCard>
          );
        })}
      <Modal
        title="UPDATE ADDRESS"
        visible={updateAddress}
        centered
        footer={null}
        onCancel={() => setUpdateAddress(false)}
      >
        <UpdateCustomerAddress editForm={editForm} closeModal={() => {
          setUpdateAddress(false)
          setRefresh(refresh + 1)
        }} />
      </Modal>
    </Container >
  );
};

const mapStateToProps = (state) => ({
  getShippigoTokenState: state.getShippigoToken,
  checkServiceAvailabilityState: state.checkServiceAvailability,
  setShippingMethodState: state.setShippingMethod,
  updateCustomerAddressState: state.updateCustomerAddress
});

const mapDispatchToProps = (dispatch) => ({
  getShippigoToken: (params) => dispatch(getShippigoToken(params)),
  getShippigoTokenReset: () => dispatch(getShippigoTokenReset()),
  checkServiceAvailability: (params) => dispatch(checkServiceAvailability(params)),
  checkServiceAvailabilityReset: () => dispatch(checkServiceAvailabilityReset()),
  setShippingMethod: (params) => dispatch(setShippingMethod(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Address);