export const FETCH_CART_DATA = 'FETCH_CART_DATA'
export const FETCH_CART_DATA_SUCCESS = 'FETCH_CART_DATA_SUCCESS'
export const FETCH_CART_DATA_FAIL = 'FETCH_CART_DATA_FAIL'
export const FETCH_CART_DATA_RESET = 'FETCH_CART_DATA_RESET'

export const fetchCartData = (params) => {
    return { type: FETCH_CART_DATA, params }
}

export const fetchCartDataSuccess = (response) => {

    return { type: FETCH_CART_DATA_SUCCESS, response }
}

export const fetchCartDataFail = (response) => {
    return { type: FETCH_CART_DATA_FAIL, response }
}

export const fetchCartDataReset = () => {
    return { type: FETCH_CART_DATA_RESET }
}