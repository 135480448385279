import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Card, Row, Col, Tag, notification } from "antd";
import { connect } from "react-redux";
import debounce from 'lodash/debounce';
import { Xgrid, XgridCard } from "../../components/Xcomponent";
import ProductCard from '../../components/ProductCard/ProductCard'
import { Box } from '../ProductList/ProductListStyle'
import _ from 'lodash'
import { getCustomerToken } from '../../utils'
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from '../../components/Loader/Loader'

import {
  SmileTwoTone,
  HeartTwoTone,
  HeartFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { CARD_CONFIG } from "./constant";
import axios from "axios";
import Footer from "../../components/Footer/Footer";
import {
  CardContainer,
  HeartContainer,
  ProductContainer,
  // ProductCard,
  ProductListName,
  CardItem,
  CardName,
  CardPrice,
  ProductCardItem,
  ProductHeader,
  ProductCount,
  BrandName,
  ArrowNameContainer,
  ArrowContainer,
  SearchWrapper,
  ProductContainerSkeleton
} from "./style";


// action
import { fetchFavouriteCount } from "../../actions/productListAction";
import { getProductDetail } from "../../actions/productActions";
import CardListSkeleton from "../../components/CardListSkeleton/CardListSkeleton";
import ProductCardSkeleton from '../../components/ProductCardSkeleton/ProductCardSkeleton'
import NoDataContianer from "../../components/NoDataContainer/NoData";
import { fetchSearchData, fetchSearchDataReset } from "../../actions/fetchSearchAction";
import { getProductList } from '../../actions/getProductListAction'
import { getBrandInfo, getBrandInfoReset } from '../../actions/getBrandInfoAction'
import { getWishList, getWishListReset } from '../../actions/getWishListAction'
import { addProductToWishList, addProductToWishListReset } from '../../actions/addProductToWishListAction'
import { deleteWishList, deleteWishListReset } from '../../actions/deleteWishListAction'
import { updateSearchTextReset } from "../../actions/updateSearchTextAction";

// custom component
import BackHomePageComponent from '../BackHomePageComponent/backHomePageComponent'
import Authentication from "../../components/Authentication/Authentication";

const Search = (props) => {
  const { searchTextState } = props;
  const {
    fetchFavouriteCount,
    getProductDetail,
    history,
    isLoading,
    fetchSearchData,
    searchData,
    searchTextData,
    getProductList,
    getProductListState,
    getBrandInfo,
    getBrandInfoState,
    getBrandInfoReset,
    getWishList,
    getWishListState,
    getWishListReset,
    addProductToWishList,
    addProductToWishListState,
    addProductToWishListReset,
    deleteWishList,
    deleteWishListState,
    deleteWishListReset,
    searchCountData,
    fetchSearchDataReset,
    productState,
    updateSearchTextReset, fetchSearchDataState
    // searchTextState
  } = props;
  //const categoryKey = props.match.params.categoryKey;
  const [cardData, setCardData] = useState(CARD_CONFIG);
  const [favouritesCount, setFavouritesCount] = useState(null);
  const { Meta } = Card;
  const previousPath = useHistory();
  const [renderList, setRenderList] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)
  const [productListData, setProductListData] = useState([])
  const [count, setCount] = useState(0)
  const [visiableData, setVisiableData] = useState(false)
  const prevCurrentPageRef = useRef();
  const [visiable, setVisiable] = useState(false)
  const [productName, setProductName] = useState(null)

  const prevPage = prevCurrentPageRef.current;
  useEffect(() => {
    prevCurrentPageRef.current = currentPage;
    productState.productTagData1 = productState.productTagData
    productState.productTagData = ""
    return (() => {
      updateSearchTextReset()
      fetchSearchDataReset()
      getWishListReset()
      deleteWishListReset()
    })
  }, [])

  useEffect(() => {
    if (searchTextData) {
      fetchSearchData({
        data: searchTextData,
        currentPage: currentPage,
        errorAction: (data) => {
          //    openErrorNotification();
        },
        successAction: (data) => {
          //    openSuccessNotification();
        },
      });
      setVisiableData(true)
    }
    else {
      setVisiableData(false)
    }
  }, [searchTextData, currentPage]);

  useEffect(() => {
    getBrandInfo();
    getWishList({ customerToken: getCustomerToken() })
  }, [])

  let query = new URLSearchParams(useLocation().search)
  let type = query.get("type")
  useEffect(() => {
    if (addProductToWishListState.apiState === "success") {
      notification["success"]({
        message: 'Added to wishlist',
        placement: "bottomRight"
      });
      getProductList({
        product_tag: type ? type : null
      })
      getBrandInfo();
      getWishList({ customerToken: getCustomerToken() })
      addProductToWishListReset()
    }
    if (addProductToWishListState.apiState === "error") {
      addProductToWishListReset()
      notification["error"]({
        message: "An error occurred during processing. Please try again.",
        placement: "bottomRight"
      })
    }
  }, [addProductToWishListState])

  useEffect(() => {
    if (getBrandInfoState.apiState === "success" && getWishListState.apiState === "success") {
      setRenderList("success")
    }
    if (getBrandInfoState.apiState === "loading" || getWishListState.apiState === "loading") {
      setRenderList("loading")
    }
    if (getBrandInfoState.apiState === "error" || getWishListState.apiState === "error") {
      setRenderList("error")
      getWishListReset()
    }
  }, [getBrandInfoState, getWishListState])

  const productListTotalCount = searchCountData.total_count;

  useEffect(() => {
    if (searchData) {
      if (productListData.length === 0) {
        setRenderList("loading")
      }
      const productListTotalCount = searchCountData.total_count;
      if (currentPage !== prevPage) {
        let data = [...productListData]
        searchCountData.items.map((item) => {
          data.push(item)
        })
        // data.push(searchData)
        setProductListData(data)
        handleCount(productListTotalCount, data.length)
        setRenderList("success")
      }
      else {
        setProductListData(searchCountData.items)
        handleCount(productListTotalCount, searchCountData.items.length)
        setRenderList("success")
      }
    }
  }, [searchData])

  useEffect(() => {
    fetchFavouriteCount(favouritesCount);
  }, [favouritesCount]);

  const handleCloseDrawer = () => {
    setVisiable(false)
  }
  const handleCallbackClick = () => {

  }

  const handleWishListClick = (sku) => {
    if (getCustomerToken() === null) {
      // history.push("/login")
      setVisiable(true)
    }
    else {
      addProductToWishList({
        sku: sku,
        customerToken: getCustomerToken()
      });
    }
  }

  const handleCount = (productListTotalCount, dataLength) => {
    if (productListTotalCount > dataLength) {
      setHasMore(true)
    }
    else {
      setHasMore(false)
    }
    setCount(count + 1)
    // fetchSearchDataReset()
  }


  const goToPreviousPath = () => {
    previousPath.goBack();
  };

  const showHeaderText = () => {
    return <>Results for <b>{searchTextData}</b></>
  }

  const handleRemoveWishList = (id, name) => {
    setProductName(name)
    deleteWishList({ id: id });
  }
  useEffect(() => {
    if (deleteWishListState.apiState === "success") {
      // setRenderList("success")
      notification["success"]({
        message: 'Product removed to your wishlist.',
        placement: "bottomRight"
      });
      getWishList({ customerToken: getCustomerToken() })

    } else if (deleteWishListState.apiState === "loading") {
      setRenderList("loading")
    } else if (deleteWishListState.apiState === "error") {
      notification["error"]({
        message: 'An error occurred during processing. Please try again.',
        placement: "bottomRight"
      });
    }
  }, [deleteWishListState])
  let countData = searchCountData.total_count


  return (
    <>
      {
        searchTextData &&
        <ProductHeader>
          <ArrowNameContainer>
            <>
              <ArrowContainer onClick={goToPreviousPath}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                  alt="ladu-india"
                  width="36"
                />
              </ArrowContainer>
              <span style={{ fontSize: 16, fontWeight: 600, marginLeft: 8, textTransform: "uppercase" }} >
                {showHeaderText()}
              </span>
            </>
          </ArrowNameContainer>
          <ProductCount>
            {searchTextData && countData ?
              `Total  ${countData} Records`
              : null}
          </ProductCount>
        </ProductHeader>
      }
      {
        (isLoading && productListData.length === 0) &&
        <ProductContainerSkeleton>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4].map((item, i) => (
            <div key={i} >
              <ProductCardSkeleton />
            </div>
          ))}
        </ProductContainerSkeleton>
      }
      {
        (renderList === "success" && productListData.length > 0) &&
        <ProductContainer noData={searchData.length}>
          {productListData.length > 0 ? (
            <SearchWrapper>
              {/* {
                  productListData.length > 0 && */}
              <InfiniteScroll
                dataLength={productListData.length}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  padding: '10px',
                  flexWrap: 'wrap'
                }}
                next={() => {
                  setCurrentPage(currentPage + 1)
                }
                }
                hasMore={hasMore}>
                {
                  productListData.map((item) => {
                    let img = ""
                    if (item.thumbnail) {
                      img = item.thumbnail.url
                    } else {
                      img = item.image.url
                    }
                    let tags = []
                    let is_new = false
                    if (item.product_tag) {
                      tags = item.product_tag.split(",")
                      if (tags.includes("67")) {
                        is_new = true
                      }
                    }
                    return (
                      <>
                        <Box>
                          <ProductCard
                            url_key={item.url_key}
                            img={img}
                            name={item.name}
                            is_brand={item.is_brand}
                            brand_name={item.brand_name}
                            brand={item.brand_name !== null ? (_.find(getBrandInfoState.options, ["value", item.brand_name.toString()]).label) : ""}
                            special_price={item.special_price}
                            price={item.price_range.minimum_price.regular_price.value}
                            per_off={item.price_range.minimum_price.discount.percent_off}
                            is_new={is_new}
                            sku={item.sku}
                            handleWishListClick={handleWishListClick}
                            handleRemoveWishList={handleRemoveWishList}
                            is_wishlisted={getWishListState.data.wishlist ? (_.find(getWishListState.data.wishlist.items, ['product.sku', item.sku.toString()]) ? true : false) : false}
                            id={getWishListState.data.wishlist ? (_.find(getWishListState.data.wishlist.items, ['product.sku', item.sku.toString()]) ? _.find(getWishListState.data.wishlist.items, ['product.sku', item.sku.toString()])['id'] : "") : false}
                            productStatus={item.options}
                            productType={item.__typename}
                          />
                        </Box>
                      </>
                    );
                  })
                }
              </InfiniteScroll>
              {isLoading &&
                <div style={{ paddingBottom: 60, paddingTop: 10, display: "flex", justifyContent: "center" }} >
                  <Loader />
                </div>
              }
            </SearchWrapper>
          ) : visiableData && (
            <NoDataContianer
              showText={
                searchTextData && (
                  <>
                    No result for <b>{searchTextData}</b>
                  </>
                )
              }
              animationType={"searchAnimation"}
            />
          )}


          {
            visiable &&
            <Authentication
              drawerState={visiable}
              handleCloseDrawer={handleCloseDrawer}
              handleCallbackClick={handleCallbackClick}
            />
          }
        </ProductContainer>
      }
      {
        productListData.length === 0 &&
        <NoDataContianer
          showText={
            searchTextData && (
              <>
                No result for <b>{searchTextData}</b>
              </>
            )
          }
          animationType={"searchAnimation"}
        />
      }
      {/* <Footer isProductList /> */}
      {/* {
        (renderList === "loading" && !isLoading) ? null :
          visiableData === false &&
          <BackHomePageComponent />
      } */}
    </>
  );
};
const mapStateToProps = (state) => ({
  fetchSearchDataState: state.fetchSearchData,
  searchData: state.fetchSearch.searchData,
  searchCountData: state.fetchSearch.searchCountData,
  isLoading: state.fetchSearch.loading,
  searchTextData: state.searchText.searchTextData,
  getBrandInfoState: state.getBrandInfo,
  getWishListState: state.getWishList,
  addProductToWishListState: state.addProductToWishList,
  getProductListState: state.getProductList,
  deleteWishListState: state.deleteWishList,
  productState: state.product,

});

const mapDispatchToProps = (dispatch) => ({
  fetchFavouriteCount: (params) => dispatch(fetchFavouriteCount(params)),
  getProductDetail: (params) => dispatch(getProductDetail(params)),
  fetchSearchData: (params) => dispatch(fetchSearchData(params)),
  getBrandInfo: (params) => dispatch(getBrandInfo(params)),
  getBrandInfoReset: () => dispatch(getBrandInfoReset()),
  getWishList: (params) => dispatch(getWishList(params)),
  getWishListReset: () => dispatch(getWishListReset()),
  addProductToWishList: (params) => dispatch(addProductToWishList(params)),
  getProductList: (params) => dispatch(getProductList(params)),
  addProductToWishListReset: () => dispatch(addProductToWishListReset()),
  deleteWishList: (params) => dispatch(deleteWishList(params)),
  deleteWishListReset: () => dispatch(deleteWishListReset()),
  fetchSearchDataReset: () => dispatch(fetchSearchDataReset()),
  updateSearchTextReset: () => dispatch(updateSearchTextReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
