export const SET_SHIPPING_METHOD = "SET_SHIPPING_METHOD"
export const SET_SHIPPING_METHOD_SUCCESS = "SET_SHIPPING_METHOD_SUCCESS"
export const SET_SHIPPING_METHOD_FAIL = "SET_SHIPPING_METHOD_FAIL"
export const SET_SHIPPING_METHOD_RESET = "SET_SHIPPING_METHOD_RESET"



export const setShippingMethod = (params) => {
    return { type: SET_SHIPPING_METHOD, params }
}
export const setShippingMethodSuccess = (response) => {
    return { type: SET_SHIPPING_METHOD_SUCCESS, response }
}
export const setShippingMethodFail = (response) => {
    return { type: SET_SHIPPING_METHOD_FAIL, response }
}
export const setShippingMethodReset = () => {
    return { type: SET_SHIPPING_METHOD_RESET }
}