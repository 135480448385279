export const REMOVE_WISHLIST_REQUEST = 'REMOVE_WISHLIST_REQUEST'
export const REMOVE_WISHLIST_SUCCESS = 'REMOVE_WISHLIST_SUCCESS'
export const REMOVE_WISHLIST_FAIL = 'REMOVE_WISHLIST_FAIL'

export const removeWishListRequest = (params) => {
    return { type: REMOVE_WISHLIST_REQUEST, params }
}

export const removeWishListSuccess = (response) => {
    return { type: REMOVE_WISHLIST_SUCCESS, response }
}

export const removeWishListFail = (response) => {
    return { type: REMOVE_WISHLIST_FAIL, response }
}