import {
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL,
} from "../actions/updateAddressAction";
import get from 'lodash/get';


const initialState = {
  // updateListItems:null,
  // updateListItemsPrices:null,
  isLoading: null,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case UPDATE_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        isLoading: false

      };

    case UPDATE_ADDRESS_SUCCESS:
      return {
        ...state,
        //   updateListItems:get(action,"response.data.updateCartItems.cart.items"),
        //   updateListItemsPrices:get(action,"response.data.updateCartItems.cart.prices"),
        isLoading: false,

      };

    default:
      return state;
  }
}
