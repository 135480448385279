import React, { useState } from "react";
import {
  LoginContainer,
  InputContainer,
  Heading,
  LoginButton,
  SignUpContainer,
  LoginWrapper,
} from "./style";
import { connect } from "react-redux";
import { signUpRequest } from '../../actions/signUpAction';
import { openSuccessNotification, openErrorNotification } from '../../utils';



const SignUp = (props) => {
  const { signUpRequest } = props;
  const [fName, setFName] = useState(null);
  const [lName, setLName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSignUp = () => {

    const data = {
      firstname: fName,
      lastname: lName,
      email: email,
      password: password
    }
    signUpRequest({
      data,
      errorAction: (data) => {
        openErrorNotification(data.errors[0].message);
      },
      successAction: (data) => {
        openSuccessNotification('Registered Successfully.');
        //  previousPath.goBack();
        props.history.push("/");
      },
    })
  }

  const handleLogin = () => {
    props.history.push("/login");
  }

  return (
    <LoginContainer>
      <LoginWrapper>
        <Heading>Customer Sign Up</Heading>
        <InputContainer placeholder="First Name" onChange={(e) => setFName(e.target.value)} />
        <InputContainer placeholder="Last Name" onChange={(e) => setLName(e.target.value)} />
        <InputContainer placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
        <InputContainer placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)} type="password" />
        <InputContainer placeholder="Confirm Password" />
        <LoginButton onClick={handleSignUp}>Sign Up</LoginButton>
        <SignUpContainer>
          Already Register? <span onClick={handleLogin}>Login Now</span>
        </SignUpContainer>
      </LoginWrapper>
    </LoginContainer>
  );
};

const mapStateToProps = (state) => ({
  productState: state.product,

});

const mapDispatchToProps = (dispatch) => ({
  signUpRequest: (params) => dispatch(signUpRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
