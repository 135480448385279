import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const getCartCountApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                    cart(cart_id: "${params.cart_id}") {
                        items {
                        id
                        quantity
                        }
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default getCartCountApi;
