import axios from "axios";
import { BASE_URL, isAuthorized } from "../utils";

const subscribeEmailApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                subscribeEmailToNewsletter(
                    email: "${params.email}"
                ) {
                    status
                }
                }`,
        }
    });
    return response.data;
};

export default subscribeEmailApi;
