import {
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAIL,
} from "../actions/signUpAction";
import get from 'lodash/get';

const initialState = {
  signUpData: null,
};

export default function (state = initialState, action) {

  switch (action.type) {
    case SIGN_UP_REQUEST:
      return {
        ...state,
      };

    case SIGN_UP_FAIL:
      return {
        ...state,

      };

    case SIGN_UP_SUCCESS:
      return {
        ...state,
        //  signUpData
      };

    default:
      return state;
  }
}
