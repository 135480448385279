import {
    REMOVE_COUPON_REQUEST,
    REMOVE_COUPON_SUCCESS,
    REMOVE_COUPON_FAIL,
  } from "../actions/removeCouponAction";
  import get from "lodash/get";


  const initialState = {
    removeCouponData: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case REMOVE_COUPON_REQUEST:
        return {
          ...state,
        };
  
      case REMOVE_COUPON_FAIL:
        return {
          ...state,
        };
  
      case REMOVE_COUPON_SUCCESS:
        return {
          ...state,
        //  removeCouponData: get(action, "response.data.country.available_regions"),
        };
  
      default:
        return state;
    }
  }
  