import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';


const fetchShippingAmountApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setShippingMethodsOnCart(
          input: {
            cart_id: "${data.data.cartId}",
            shipping_methods: [
              {
                carrier_code: "${data.data.carrier_code}"
                method_code: "${data.data.method_code}"
              }
            ]
          }
        ) {
          cart {
            shipping_addresses {
              selected_shipping_method {
                carrier_code
                carrier_title
                method_code
                method_title
                amount {
                  value
                  currency
                }
              }
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchShippingAmountApi;

