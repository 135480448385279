import React, { useEffect, useState } from 'react'
import { Carousel } from 'antd'
import { Link } from "react-router-dom";
import { connect } from "react-redux";

// custom component
import { RowBannerDesktop, RowBannerMobile, Row, FrontBannerWrapper, Video } from './FrontBannerStyle'
import HomePageBannerSkeleton from '../HomePageBannerSkeleton/HomePageBannerSkeleton'

// action
import { homePageBanner, homePageBannerReset } from '../../actions/homePageBannerAction'


const FrontBanner = (props) => {

    // variables
    const { homePageBanner, homePageBannerState, homePageBannerReset } = props



    // callbacks
    useEffect(() => {
        homePageBanner()
    }, [])

    useEffect(() => {
        return (() => {
            homePageBannerReset()
        })
    }, [])

    return (
        <FrontBannerWrapper>
            {
                homePageBannerState.apiState === "success" &&
                <>
                    {
                        homePageBannerState.data.items.map((item) => {
                            return (
                                <Row>
                                    {item.media_gallery.length > 0 && item.media_gallery[0].video_content ?
                                        <>
                                            <iframe style={{ width: "100%", height: "100%", minHeight: "50vh" }} src={`${item.media_gallery[0].video_content.video_url}?modestbranding=1&showinfo=0&modestbranding=1&controls=0&rel=0&autoplay=1&origin=https://laduindia.com/&autohide=1&showinfo=0`} frameborder="0"  ></iframe>
                                        </>
                                        :
                                        <>
                                            <RowBannerDesktop >
                                                {/* <Link to={`/product-detail/${item.url_key}`} > */}
                                                <Link to={{ pathname: item.custom_link_on_home_page_banne }} target="_blank" >
                                                    <img style={{ height: "100%" }} src={`${item.image.url}`} />

                                                </Link>
                                            </RowBannerDesktop>
                                            <RowBannerMobile>
                                                <Link to={{ pathname: item.custom_link_on_home_page_banne }} target="_blank">
                                                    <div style={{ borderRadius: 18 }} >
                                                        <img style={{ height: "100%" }} src={`${item.small_image.url}`} />
                                                    </div>
                                                </Link>
                                            </RowBannerMobile>
                                        </>
                                    }
                                </Row>

                            )
                        })
                    }
                </>
            }
            {
                homePageBannerState.apiState === "loading" &&
                <>
                    <HomePageBannerSkeleton />
                </>
            }
        </FrontBannerWrapper >
    )
}

const mapStateToProps = (state) => ({
    homePageBannerState: state.homePageBanner,
});

const mapDispatchToProps = (dispatch) => ({
    homePageBanner: (params) => dispatch(homePageBanner(params)),
    homePageBannerReset: () => dispatch(homePageBannerReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FrontBanner);

