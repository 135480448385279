import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  ADD_COUPON_REQUEST,
  addCouponSuccess,
  addCouponFail,
} from "../actions/addCouponAction";
import addCouponApi from "../apis/addCouponApi";
import get from 'lodash/get';


function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(otherParams);
  }
}

export function* addCouponSaga(action) {

  try {
    const response = yield call(() => addCouponApi(action));
    if (response.errors) {
      yield put(addCouponFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(addCouponSuccess(response, action));
      yield common(action, true, response);

    }
  } catch (e) {
    yield put(addCouponFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(ADD_COUPON_REQUEST, addCouponSaga);
}
