import {
    FETCH_BRAND_BY_ID,
    FETCH_BRAND_BY_ID_SUCCESS,
    FETCH_BRAND_BY_ID_FAIL,
    FETCH_BRAND_BY_ID_RESET
} from "../actions/fetchBrandByIdAction";

const initialState = {
    apiState: "",
    data: {}
};

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_BRAND_BY_ID:
            return {
                ...state,
                apiState: "loading",
            };

        case FETCH_BRAND_BY_ID_FAIL:
            return {
                ...state,
                apiState: "error",
                data: action.response.data
            };

        case FETCH_BRAND_BY_ID_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data,
            };

        case FETCH_BRAND_BY_ID_RESET:
            return initialState

        default:
            return state;
    }
}
