import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    GUEST_SET_PAYMENT_METHOD,
    guestSetPaymentMethodSuccess,
    guestSetPaymentMethodFail
} from '../actions/guestSetPaymentMehtodAction'

// api
import guestSetPaymentMethodApi from '../apis/guestSetPaymentMethodApi'


export function* guestSetPaymentMethodSaga(action) {
    try {
        const response = yield call(() => guestSetPaymentMethodApi(action.params))
        if (response.errors) {
            yield put(guestSetPaymentMethodFail(response, action))
        }
        else {
            yield put(guestSetPaymentMethodSuccess(response, action))
        }
    }
    catch (e) {
        yield put(guestSetPaymentMethodFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GUEST_SET_PAYMENT_METHOD, guestSetPaymentMethodSaga)
}