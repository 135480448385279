import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    GUEST_SALES_PLACE_ORDER,
    guestSalesPlaceOrderSuccess,
    guestSalesPlaceOrderFail
} from '../actions/guestSalesPlaceOrderAction'

// api
import guestSalesPlaceOrderApi from '../apis/guestSalesPlaceOrderApi'


export function* guestSalesPlaceOrderSaga(action) {
    try {
        const response = yield call(() => guestSalesPlaceOrderApi(action.params))
        if (response.errors) {
            yield put(guestSalesPlaceOrderFail(response, action))
        }
        else {
            yield put(guestSalesPlaceOrderSuccess(response, action))
        }
    }
    catch (e) {
        yield put(guestSalesPlaceOrderFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GUEST_SALES_PLACE_ORDER, guestSalesPlaceOrderSaga)
}