import React, { useEffect } from "react"
import { Button, Space } from "antd"
import { Link } from "react-router-dom"
import Lottie from "react-lottie"
import { connect } from "react-redux";

// components
import { Container } from "./ThanksStyle"

// actions
import { fetchCartDataReset } from '../../actions/fetchCartDataAction'

// others
import cartThanksAnimation from "../../lottie/cart-thanks.json"
import { getCustomerToken } from '../../utils'

const Thanks = props => {
    // variables
    const cartThanksOptions = {
        loop: true,
        autoplay: true,
        animationData: cartThanksAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const visiableData = getCustomerToken();
    const { fetchCartDataReset } = props

    // callbacks
    useEffect(() => {
        fetchCartDataReset()
    }, [])

    return (
        <Container>
            <Lottie
                options={cartThanksOptions}
                height={300}
                width={300}
                style={{ margin: "none" }}
            />
            <h1 style={{ fontSize: 24, fontWeight: "bold", textAlign: "center" }} >Congratulations! </h1>
            <h1 style={{ fontSize: 16, textAlign: "center", marginBottom: 24 }} >Your order has been placed successfully. We'll share the shipping details soon.</h1>
            <Space>
                {
                    visiableData ?
                        <>
                            <Link to="/orders" >
                                <Button type="default">MY ORDERS</Button>
                            </Link>
                            <Link to="/categories" >
                                <Button type="default">EXPLORE STORE</Button>
                            </Link>
                        </> :
                        <>
                            <Link to="/categories" >
                                <Button type="default">EXPLORE STORE</Button>
                            </Link>
                        </>
                }

            </Space>
        </Container>
    )
}


const mapDispatchToProps = (dispatch) => ({
    fetchCartDataReset: () => dispatch(fetchCartDataReset()),

});
export default connect(null, mapDispatchToProps)(Thanks);
