import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "antd";
import {
  Container,
  LeftSection,
  RightSection,
  Xskeleton,
  ImageSkeleton,
  CardContainer,
  ImageSkeletonRight,
} from "./style";

const CartSkeleton = (props) => {
  return (
    <Container>
      <LeftSection >
        <Xskeleton type="text" w="100%" h="50px" mb="5px" active />
        <CardContainer>
          <ImageSkeleton size={10} />
          <Xskeleton type="text" w="100%" h="130px" mb="5px" active ></Xskeleton>
        </CardContainer>
        <CardContainer>
          <ImageSkeleton size={10} />
          <Xskeleton type="text" w="100%" h="130px" mb="5px" active />
        </CardContainer>
        <CardContainer>
          <ImageSkeleton size={10} />
          <Xskeleton type="text" w="100%" h="130px" mb="5px" active />
        </CardContainer>
      </LeftSection>
      <RightSection>
        <Xskeleton type="text" w="100%" h="180px" active />
      </RightSection>
    </Container>
  );
};

CartSkeleton.propTypes = {
  rows: PropTypes.number,
};

CartSkeleton.defaultProps = {
  rows: 4,
};

export default CartSkeleton;
