import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import {
    FETCH_PRODUCT_LIST_REQUEST,
    fetchListCategoriesSuccess,
    fetchListCategoriesFailure,
} from '../actions/productListAction'
import get from 'lodash/get';

import getProductListApi from '../apis/getProductListApi'

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* getProductListSaga(action) {
    try {
        const response = yield call(() => getProductListApi(action.params))
        if (response.data) {
            yield common(action, true, response);
            //     yield put(fetchListCategoriesFailure(response, action))
            yield put(fetchListCategoriesSuccess(response, action))

        } else {
            yield put(fetchListCategoriesSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(fetchListCategoriesFailure(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_PRODUCT_LIST_REQUEST, getProductListSaga);
}