import React, { useState, useEffect, useRef } from "react"
import { Modal, Button, notification, Alert, Checkbox } from "antd"
import { InfoCircleOutlined, EditOutlined } from "@ant-design/icons"
import { connect } from "react-redux"
import get from "lodash/get"
import _, { update } from "lodash"
import {
	Container,
	SummaryContainer,
	CheckoutContainer,
	AddressContainer,
	AddressContent,
	AddressFormWrapper,
	InputContainer,
	SelectContainer,
	InputAddressContainer,
	InfoContainer,
	AddAddress,
	AddressCard,
	AddressPhone,
	AddressName,
	SelectButton,
	AddressPincode,
	EditButton,
	AddressContainerList,
	FullAddressContent
} from "./style"
import { checkCartId } from "../../utils"
import OrderSummary from "../../components/OrderSummary/orderSummary"
import Address from "./Address/address"
// import { fetchStateRequest } from "../../actions/fetchStateAction"
import { addAddressRequest } from "../../actions/addAddressAction"
import { fetchAddressRequest } from "../../actions/fetchAddressAction"
import { fetchCartData } from "../../actions/fetchCartDataAction"
import { setShippingRequest } from "../../actions/setShippingAction";
import { fetchShippingAmountRequest } from "../../actions/fetchShippingAmountAction";
import CheckoutSkeleton from "../../components/checkoutSkeleton/checkoutSkeleton";
import { fetchPaymentMethodRequest } from "../../actions/fetchPaymentMethodAction";
import { fetchPlaceOrderRequest } from "../../actions/fetchPlaceOrderAction";
import { setBillingAddressRequest } from '../../actions/setBillingAddressAction';
import { getShippigoToken, getShippigoTokenReset } from '../../actions/getShippigoTokenAction'
import { checkServiceAvailability, checkServiceAvailabilityReset } from '../../actions/checkServiceAvailabilityAction'
import { billingAddress, billingAddressReset } from '../../actions/billingAddressAction'
import { setShippingAddress, setShippingAddressReset } from '../../actions/setShippingAddressAction'
import { setShippingMethod, setShippingMethodReset } from '../../actions/setShippingMethodAction'
import { updateCustomerAddress, updateCustomerAddressReset } from '../../actions/updateCustomerAddressAction'
import { mergeCarts, mergeCartsReset } from '../../actions/mergeCartsActions'
import { getCartCount } from "../../actions/getCartCountAction";
import UpdateCustomerAddress from '../../components/UpdateCustomerAddress/UpdateCustomerAddress'
import { removeItemCart, removeItemCartReset } from '../../actions/removeItemCartAction'
import NoDataContianer from "../../components/NoDataContainer/NoData";

// others
import { getCartId, getCustomerToken, jaipurPincodeData, openSuccessNotification, openErrorNotification } from "../../utils"
import NewAddressForm from '../../components/NewAddressForm/NewAddressForm'
import { Link } from "react-router-dom"
// import UpdateCustomAddress from '../../components/UpdateCustomerAddress/UpdateCustomerAddress'

const Checkout = (props) => {
	const {
		// fetchStateRequest,
		// stateData,
		addAddressRequest,
		fetchAddressData,
		fetchAddressRequest,
		fetchCartData,
		fetchCartDataState,
		cartDataPrices,
		isLoading,
		cartDataItems,
		setShippingRequest,
		fetchShippingAmountRequest,
		fetchPaymentMethodRequest,
		fetchPaymentMethodRequestState,
		fetchPlaceOrderRequest,
		paymentMethodData,
		billingAddress,
		billingAddressState,
		billingAddressReset,
		setShippingAddress,
		setShippingAddressState,
		setShippingAddressReset,
		setShippingMethod,
		setShippingMethodReset,
		setShippingMethodState,
		updateCustomerAddressState, mergeCartsState,
		getShippigoToken,
		getShippigoTokenReset,
		getShippigoTokenState,
		checkServiceAvailability, checkServiceAvailabilityState, checkServiceAvailabilityReset,
		removeItemCart, removeItemCartState, removeItemCartReset,
		getCartCount,
		updateListItems
	} = props


	const [isModalVisible, setIsModalVisible] = useState(false)
	const [shippingAmount, setShippingAmount] = useState(null)
	const { Option } = SelectContainer
	const optionLabel = useRef(null)
	const [shippingMethodData, setShippingMethodData] = useState(null)
	const [priceData, setPriceData] = useState(null)
	const [renderList, setRenderList] = useState(null)
	const [data, setData] = useState(false)
	const [visiableData, setVisiableData] = useState(false)
	const [updateAddressVisiable, setUpdateAddressVisiable] = useState(false)
	const [current, setCurrent] = useState(0)
	const [orderAddress, setOrderAddress] = useState({
		billingAddress: "",
		shippingAddress: "",
	})
	const [sameAsBilling, setSameAsBilling] = useState(true)

	const [postCode, setPostCode] = useState(null)
	const [btnLoading, setBtnLoading] = useState(false)
	const [updateAddress, setUpdateAddress] = useState(false)
	const [editForm, setEditForm] = useState("")
	const [shippingLoadingData, setShippingLoadingData] = useState({})
	const [billingLoadingData, setBillingLoadingData] = useState({})
	const [shippingIndexValue, setShippingIndexValue] = useState()
	const [billingIndexValue, setBillingIndexValue] = useState()
	const [shippingItem, setShippingItem] = useState(null)
	const [billingItem, setBillingItem] = useState(null)
	const [disabledRedirectToPayment, setDisabledRedirectToPayment] = useState(true)



	const handleAddAddress = () => {
		setIsModalVisible(true)
	}


	const [refresh, setRefresh] = useState(0)
	const cartId = getCartId()

	// Call back

	useEffect(() => {
		return (() => {
			billingAddressReset()
			setShippingAddressReset()
			setShippingMethodReset()
		})
	}, [])


	useEffect(() => {
		if (setShippingAddressState.apiState === "error" && billingAddressState.apiState === "error") {
			notification["error"]({
				message: "An error occurred during processing. Please try again.",
				placement: "topRight"
			})
			setShippingAddressReset()
			billingAddressReset()
		}
	}, [setShippingAddressState, billingAddressState])

	useEffect(() => {
		if (setShippingAddressState.apiState === "success") {
			const cartId = getCartId()
			// let shippingMethodData = setShippingAddressState.data.data.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods
			// setShippingMethodData(shippingMethodData)
			const carrier_code = setShippingAddressState.data.data.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods[0].carrier_code
			const method_code = setShippingAddressState.data.data.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods[0].method_code
			setShippingMethod({
				cartId: cartId,
				carrier_code: carrier_code,
				method_code: method_code
			})
			// setShippingAddressReset()
		}
		if (setShippingMethodState.apiState === "error") {
			setShippingAddressReset()
		}
	}, [setShippingAddressState])

	useEffect(() => {
		if (billingAddressState.apiState === "success") {
			openButton()
			billingAddressReset()
		}
		if (billingAddressState.apiState === "error") {
			billingAddressReset()
		}
	}, [billingAddressState])
	useEffect(() => {
		if (cartDataItems) {
			fetchAddressRequest()
		}
	}, [fetchCartDataState])

	useEffect(() => {
		if (setShippingMethodState.apiState === "success") {
			// show button
			setBtnLoading(false)
			setShippingLoadingData({ ...shippingLoadingData, [shippingIndexValue]: false })
			setShippingMethodReset()

		}
		if (setShippingMethodState.apiState === "error") {
			notification["error"]({
				message: "An error occurred during processing. Please try again.",
				placement: "topRight"
			})
			setShippingMethodReset()
		}
	}, [setShippingMethodState])

	useEffect(() => {
		if (mergeCartsState.apiState === "success") {
			const cartId = getCartId()
			fetchCartData({
				cart_id: cartId,
				successAction: (data) => {
					var cartData = data.data.cart.items
					if ((_.find(cartData, ['product.jaipur_only_delivery', 1]))) {
						setVisiableData(true)
					}
					else {
						setVisiableData(false)
					}
					setRenderList("success")
				},
			})
			fetchAddressRequest()
		}
	}, [mergeCartsState])

	useEffect(() => {
		getCartData()
	}, [])

	useEffect(() => {
		if (updateListItems) {
			getCartData()
		}
	}, [updateListItems])

	const getCartData = () => {
		const cartId = getCartId()
		getCartCount({
			cart_id: getCartId(),
			customerToken: getCustomerToken()
		})
		fetchCartData({
			cart_id: cartId,
			successAction: (data) => {
				var cartData = data.data.cart.items
				if ((_.find(cartData, ['product.jaipur_only_delivery', 1]))) {
					setVisiableData(true)
				}
				else {
					setVisiableData(false)
				}
				setRenderList("success")
			},
		})
		fetchAddressRequest()
		billingAddressReset()
		setShippingAddressReset()
		setShippingMethodReset()
		setShippingIndexValue()
		setBillingIndexValue()
	}

	useEffect(() => {
		if (removeItemCartState.apiState === "success" || removeItemCartState.apiState === "error") {
			const cartId = getCartId()
			getCartCount({
				cart_id: getCartId(),
				customerToken: getCustomerToken()
			})
			fetchCartData({
				cart_id: cartId,
				successAction: (data) => {
					var cartData = data.data.cart.items
					if ((_.find(cartData, ['product.jaipur_only_delivery', 1]))) {
						setVisiableData(true)
					}
					else {
						setVisiableData(false)
					}
					setRenderList("success")
				},
			})
			fetchAddressRequest()
			removeItemCartReset()
		}
	}, [removeItemCartState])

	useEffect(() => {
		if (refresh > 0) {
			fetchAddressRequest()
			setRefresh(0)
		}

	}, [refresh])
	useEffect(() => {
		if (updateCustomerAddressState.apiState === "success") {
			setUpdateAddress(false)
			setRefresh(refresh + 1)
		}
		// updateCustomerAddressReset()
	}, [updateCustomerAddressState])

	useEffect(() => {
		if (getShippigoTokenState.apiState === "loading" || setShippingMethodState.apiState === "loading") {
			setBtnLoading(true)

			setShippingLoadingData({ ...shippingLoadingData, [shippingIndexValue]: true })
		}
	}, [setShippingMethodState, getShippigoTokenState])

	useEffect(() => {
		if (getShippigoTokenState.apiState === "success") {
			const token = getShippigoTokenState.data.token
			checkServiceAvailability({
				token: token,
				postCode: postCode
			})
			getShippigoTokenReset()
		}
		if (getShippigoTokenState.apiState === "error") {
			notification["error"]({
				message: 'Something is not right.',
				placement: "topRight"
			})
			getShippigoTokenReset()
			setBtnLoading(false)
			setShippingLoadingData({})
		}
	}, [getShippigoTokenState])

	useEffect(() => {
		if (checkServiceAvailabilityState.apiState === "success") {
			handleShippingRequest(shippingItem)
			checkServiceAvailabilityReset()
		}
		if (checkServiceAvailabilityState.apiState === "error") {
			notification["error"]({
				message: "Something is not right",
				placement: "topRight"
			})
			checkServiceAvailabilityReset()
			setBtnLoading(false)
			setShippingLoadingData({})
		}
	}, [checkServiceAvailabilityState])

	useEffect(() => {
		openButton()
	}, [sameAsBilling, billingIndexValue])


	// Functions

	const handleShippingRequest = (item) => {
		const cartId = getCartId()
		const data = {
			firstname: item.firstname,
			lastname: item.lastname,
			//  company: "Company Name"
			street: item.street[0],
			city: item.city,
			region: item.region.region_code,
			//  region_id: 12,
			postcode: item.postcode,
			country_code: item.country_code,
			telephone: item.telephone,
			cartId: cartId,
		}
		setShippingAddress({
			data,
		})
		if (sameAsBilling) {
			setBillingAddress(item)
		}
		else {
			setBillingAddress(billingItem)
		}

	}

	const redirectToPayment = () => {
		// getShippigoToken()
		props.history.push("payment")
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const setEditData = (id) => {
		// let data = ._find(address)
		let data = _.find(fetchAddressData, { id: id })
		setEditForm(data)
		setUpdateAddress(true)
	}

	const handleBillingAddress = (item, index) => {
		setBillingIndexValue(index)
		setBillingLoadingData({})
		setBillingItem(item)
		setBillingAddress(item)
	}

	const setBillingAddress = (item) => {
		setBillingLoadingData({})
		const cartId = getCartId()
		billingAddress({
			cartId: cartId,
			item
		})
	}


	const handleSelect = (item, index) => {
		const postCodeResult = item.postcode
		setShippingLoadingData({})
		if (visiableData && !(_.find(jaipurPincodeData, ['pincode', Number(postCodeResult)]))) {
			notification["error"]({
				message: "Some of the items in your cart can only be delivered to Jaipur. Please remove these from your cart or choose a delivery address in Jaipur to proceed with checkout.",
				placement: "bottomRight"
			})
		}
		else {
			setPostCode(item.postcode)
			setShippingItem(item)
			setShippingIndexValue(index)
			getShippigoToken()
			// const cartId = getCartId()
			// const data = {
			// 	firstname: item.firstname,
			// 	lastname: item.lastname,
			// 	//  company: "Company Name"
			// 	street: item.street[0],
			// 	city: item.city,
			// 	region: item.region.region_code,
			// 	//  region_id: 12,
			// 	postcode: item.postcode,
			// 	country_code: item.country_code,
			// 	telephone: item.telephone,
			// 	cartId: cartId,
			// }
			// setShippingAddress({
			// 	data,
			// })

		}
	}

	const openButton = () => {
		if (sameAsBilling && shippingIndexValue > -1) {
			setDisabledRedirectToPayment(false)
		}
		else if (!sameAsBilling && shippingIndexValue > -1 && billingIndexValue > -1) {
			setDisabledRedirectToPayment(false)
		}
		else {
			setDisabledRedirectToPayment(true)
		}
	}

	return (

		<>
			{isLoading ? <CheckoutSkeleton /> :
				< Container >
					{fetchAddressData && (cartDataPrices.subtotal_including_tax ? cartDataPrices.subtotal_including_tax.value : 0) === 0 ?
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
							<NoDataContianer
								showText="No Items available in cart."
								animationType={"cartAnimation"}
							/>
							<Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
						</div>
						:
						<>
							<AddressContent>
								{fetchAddressData && fetchAddressData.length > 0
									? "Select Shipping Address"
									: "Add Address"}
							</AddressContent>
							<div style={{ display: 'flex', alignItems: 'center' }}></div>
							<CheckoutContainer>
								<AddressContainer>
									{
										visiableData &&
										<InfoContainer>
											<InfoCircleOutlined
												style={{ fontSize: "14px", color: "#fff", marginRight: '10px', paddingTop: '5px' }}
											/>
											<div style={{ width: '100%', height: '100%', }}>
												<Alert style={{ marginTop: 20 }} message="Delivery is only available in Jaipur, Rajasthan." banner />
											</div>
										</InfoContainer>
									}
									<AddressContainerList>
										<AddAddress onClick={handleAddAddress}>
											<div>Add New</div>
										</AddAddress>
										{fetchAddressData &&
											fetchAddressData.map((item, index) => {
												return (
													<AddressCard active={index === shippingIndexValue ? true : false}>
														<EditButton onClick={() => [
															setEditData(item.id)

														]} ><EditOutlined /></EditButton>
														<AddressName>
															{item.firstname} {item.lastname}
														</AddressName>
														<FullAddressContent>
															{item.street[0]}, {item.city}, {item.region.region}
														</FullAddressContent>
														<AddressPincode>Pincode : {item.postcode}</AddressPincode>
														<AddressPhone>Mobile : +91-{item.telephone === 'null' ? '' : item.telephone}</AddressPhone>
														<SelectButton onClick={() =>
															handleSelect(item, index)} loading={shippingLoadingData[index]} disabled={shippingLoadingData[index] ? false : btnLoading} >
															Deliver to this location
														</SelectButton>


													</AddressCard>
												)
											})}
									</AddressContainerList>
									<Modal
										title="UPDATE ADDRESS"
										visible={updateAddress}
										centered
										footer={null}
										onCancel={() => setUpdateAddress(false)}
									>
										<UpdateCustomerAddress editForm={editForm} closeModal={() => {
											setUpdateAddress(false)
											setRefresh(refresh + 1)
										}} />
									</Modal>

									<Checkbox style={{ margin: 20 }} onChange={(e) => {
										setSameAsBilling(e.target.checked)
										setOrderAddress({ ...orderAddress, ['billingAddress']: "" })
									}} checked={sameAsBilling}>Billing address same as shipping address.</Checkbox>
									{!sameAsBilling &&
										<AddressContent>
											Select Billing Address
										</AddressContent>
									}
									<AddressContainerList>
										{!sameAsBilling &&
											<>

												<AddAddress onClick={handleAddAddress}>
													<div>Add New</div>
												</AddAddress>
												{fetchAddressData &&
													fetchAddressData.map((item, index) => {
														return (
															<AddressCard active={index === billingIndexValue ? true : false}>
																<EditButton onClick={() => [
																	setEditData(item.id)

																]} ><EditOutlined /></EditButton>
																<AddressName>
																	{item.firstname} {item.lastname}
																</AddressName>
																<FullAddressContent>
																	{item.street[0]}, {item.city}, {item.region.region}
																</FullAddressContent>
																<AddressPincode>Pincode : {item.postcode}</AddressPincode>
																<AddressPhone>Mobile : +91-{item.telephone === 'null' ? '' : item.telephone}</AddressPhone>
																<SelectButton onClick={() =>
																	handleBillingAddress(item, index)} loading={billingLoadingData[index]} disabled={billingLoadingData[index] ? false : btnLoading} >
																	Set Billing Address
																</SelectButton>
															</AddressCard>
														)
													})}
											</>
										}
									</AddressContainerList>
								</AddressContainer>
								<SummaryContainer>
									<div style={{ marginTop: '15px' }} >
										{cartDataPrices && (
											<OrderSummary
												summaryPrices={cartDataPrices}
												fetchPaymentMethodRequest={fetchPaymentMethodRequest}
												isCheckout
												redirectToPayment={redirectToPayment}
												disabledRedirectToPayment={disabledRedirectToPayment}
											/>
										)}
									</div>
								</SummaryContainer>

							</CheckoutContainer>
							<Modal
								title="Add New Address"
								visible={isModalVisible}
								centered
								footer={null}
								onCancel={handleCancel}
							>
								<NewAddressForm closeModal={() => {
									setIsModalVisible(false)
									setRefresh(refresh + 1)
								}} />
							</Modal>
						</>
					}
				</Container>
			}
		</>
	)
}

const mapStateToProps = (state) => ({
	// stateData: state.fetchState.stateData,
	addressData: state.addAddress.addressData,
	fetchAddressData: state.fetchAddress.fetchAddressData,
	cartDataPrices: state.cartData.cartDataPrices,
	cartDataItems: state.cartData.cartDataItems,
	isLoading: state.loader.isLoading,
	paymentMethodData: state.paymentMethod.paymentMethodData,
	getShippigoTokenState: state.getShippigoToken,
	checkServiceAvailabilityState: state.checkServiceAvailability,
	billingAddressState: state.billingAddress,
	setShippingAddressState: state.setShippingAddress,
	setShippingMethodState: state.setShippingMethod,
	fetchCartDataState: state.fetchCartData,
	updateCustomerAddressState: state.updateCustomerAddress,
	mergeCartsState: state.mergeCarts,
	removeItemCartState: state.removeItemCart,
	updateListItems: state.updateItem.updateListItems,

})

const mapDispatchToProps = (dispatch) => ({
	// fetchStateRequest: (params) => dispatch(fetchStateRequest(params)),
	addAddressRequest: (params) => dispatch(addAddressRequest(params)),
	fetchAddressRequest: (params) => dispatch(fetchAddressRequest(params)),
	fetchCartData: (params) => dispatch(fetchCartData(params)),
	setShippingRequest: (params) => dispatch(setShippingRequest(params)),
	fetchShippingAmountRequest: (params) => dispatch(fetchShippingAmountRequest(params)),
	fetchPlaceOrderRequest: (params) => dispatch(fetchPlaceOrderRequest(params)),
	fetchPaymentMethodRequest: (params) => dispatch(fetchPaymentMethodRequest(params)),
	setBillingAddressRequest: (params) => dispatch(setBillingAddressRequest(params)),
	getShippigoToken: (params) => dispatch(getShippigoToken(params)),
	getShippigoTokenReset: () => dispatch(getShippigoTokenReset()),
	checkServiceAvailability: (params) => dispatch(checkServiceAvailability(params)),
	checkServiceAvailabilityReset: () => dispatch(checkServiceAvailabilityReset()),
	billingAddress: (params) => dispatch(billingAddress(params)),
	billingAddressReset: () => dispatch(billingAddressReset()),
	setShippingAddress: (params) => dispatch(setShippingAddress(params)),
	setShippingAddressReset: () => dispatch(setShippingAddressReset()),
	setShippingMethod: (params) => dispatch(setShippingMethod(params)),
	setShippingMethodReset: () => dispatch(setShippingMethodReset()),
	updateCustomerAddressReset: () => dispatch(updateCustomerAddressReset()),
	getCartCount: (params) => dispatch(getCartCount(params)),
	removeItemCart: (params) => dispatch(removeItemCart(params)),
	removeItemCartReset: () => dispatch(removeItemCartReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
