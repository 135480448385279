import styled, { css } from "styled-components";
import { Button, Dropdown } from "antd";

export const Container = styled.section`
  display: table-row;
  background: black;
  /* height: 40px; */
  color: white;
  position: fixed;
  bottom: 17px;
  padding: 0;
  left: 50%;
  transform: translateX(-50%);
  /* height: 64px; */
  border-radius: 19px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
  /* background-color: ${(props) => props.theme.backgroundColor.white}; */
  display: flex;
  align-items: center;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
    z-index: 999;
    
    .ant-drawer-body{
      background: red !important;
    }
 

  @media ${(props) => props.theme.device.tablet} {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateX(0);
    border-radius: 0;
    justify-content: space-around;
  }
`;

export const MenuButton = styled(Button)`
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
   padding: 1.5rem 1rem !important;
   background: transparent;

  ${(props) =>
    props.active &&
    css`
      opacity: 1;
    `}

  &:hover, &:focus {
    color: #ffffff;
    opacity: 1;
    background: #ffa9401f;
  }
  @media ${(props) => props.theme.device.tablet}{
    display: flex;
    flex-direction: column;
  }
`;
export const FooterSmallText = styled.span`
display: none !important;
 
  @media ${(props) => props.theme.device.tablet}{
    display: block !important;
     font-size: 10px;
     margin-top: 2px;
     color: ${(props) => props.theme.color.black};
     text-transform: capitalize;
  }
`

// document.getElementsByClassName('')

export const FooterItemText = styled.span`
  font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  font-weight: ${(props) => props.theme.fontWeight};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  margin-left:5px;
  padding-left: 10px;
text-transform: capitalize;
  @media ${(props) => props.theme.device.tablet} {
    display: none !important;
  }
`;

export const DropdownContainer = styled(Dropdown)`
  // &&
  //   .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  //   padding-top: 20px !important;
  // }
  // & .ant-dropdown ant-dropdown-placement-bottomLeft {

  // }

`;
export const WishListButton = styled.div`
  display:  flex;
  align-items: center;
  @media ${(props) => props.theme.device.tablet}{
  flex-direction: column;
display: flex;
flex-direction: column;
  }
  
`
