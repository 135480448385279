import {
    GUEST_SALES_PLACE_ORDER,
    GUEST_SALES_PLACE_ORDER_SUCCESS,
    GUEST_SALES_PLACE_ORDER_FAIL,
    GUEST_SALES_PLACE_ORDER_RESET
} from '../actions/guestSalesPlaceOrderAction'

const initialState = {
    apiState: "",
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GUEST_SALES_PLACE_ORDER:
            return {
                ...state,
                apiState: "loading"
            }
        case GUEST_SALES_PLACE_ORDER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }
        case GUEST_SALES_PLACE_ORDER_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case GUEST_SALES_PLACE_ORDER_RESET:
            return initialState

        default:
            return state
    }
}
