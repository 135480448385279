import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    CHECK_SERVICE_AVAILABILITY,
    checkServiceAvailabilitySuccess,
    checkServiceAvailabilityFail
} from '../actions/checkServiceAvailabilityAction'

// api
import checkServiceAvailabilityApi from '../apis/checkServiceAvailabilityApi'

export function* checkServiceAvailabilitySaga(action) {
    try {
        const response = yield call(() => checkServiceAvailabilityApi(action.params))
        if (response.success === false) {
            yield put(checkServiceAvailabilityFail(response, action))
        }
        else {
            yield put(checkServiceAvailabilitySuccess(response, action))
        }
    }
    catch (e) {
        yield put(checkServiceAvailabilityFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(CHECK_SERVICE_AVAILABILITY, checkServiceAvailabilitySaga)
}