import React, { useState, useEffect } from "react";
import {
    SummaryContainer,
    OrderHeading,
    HorizontalLine,
    SummaryContent,
    ContentContainer,
    ContentName,
    ContentPrice,
    CheckoutButton,
    DeliveryContainer,
    PinCodeInput,
    PinCodeButton,
} from "./style";
import { connect } from "react-redux";
import _ from 'lodash'
import Authentication from '../Authentication/Authentication'

// action
import { setShippingRequest } from '../../actions/setShippingAction'
import { fetchShippingAmountRequest } from '../../actions/fetchShippingAmountAction'
import { fetchCartData } from '../../actions/fetchCartDataAction'


// others
import { getCartId, getCustomerToken } from "../../utils"

const OrderSummary = (props) => {
    const {
        enableButton,
        summaryPrices,
        handleCheckout,
        addCouponCode,
        removeCouponCode,
        payButton,
        fetchPaymentMethodRequest,
        couponCheck,
        appliedCoupon,
        isCart,
        isCheckout,
        isPlaceOrder,
        handlePay,
        placeOrderState,
        shippingData,
        disabledRedirectToPayment,
        getShippigoTokenState,
        setShippingMethodState,
    } = props;
    const [coupon, setCoupon] = useState(null);
    const [visiable, setVisiable] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const onCheckoutClick = () => {
        if (isCart) {
            props.handledrawerClose()
            handleCheckout();
        }
        if (isPlaceOrder) {
            const cartId = getCartId();
            const data = {
                cartId: cartId,
            };
            fetchPaymentMethodRequest(data)
            // handlePay();
            // return;
        }
    };

    const onProceedTocontinue = () => {
        props.redirectToPayment()
        setButtonLoading(true)
    };

    useEffect(() => {
        if (setShippingMethodState.apiState === "success") {
            setButtonLoading(false)
        }
    }, [setShippingMethodState])

    // callbacks

    useEffect(() => {
        const token = localStorage.getItem("token");
    }, [])

    const handleAddCoupon = () => {
        const cartId = getCartId();
        const data = {
            cartId: cartId,
            coupon: coupon,
        };
        addCouponCode(data);
    };

    const handleRemoveCoupon = () => {
        const cartId = getCartId();
        const data = {
            cartId: cartId,
        };
        removeCouponCode(data)
    }

    const handleOpenDrawer = () => {
        setVisiable(true)
    }

    const handleCloseDrawer = () => {
        setVisiable(false)
        props.handledrawerClose()
    }
    const handleCallbackClick = () => {
        props.handleRefreshCart()
        onCheckoutClick()
    }

    // variables
    const { setShipState } = props

    useEffect(() => {
    }, [setShipState])


    return (
        <>
            {summaryPrices ? (
                <SummaryContainer>
                    <OrderHeading>Order Summary</OrderHeading>
                    <HorizontalLine></HorizontalLine>
                    <SummaryContent>
                        <ContentContainer>
                            <ContentName>Sub Total</ContentName>
                            <ContentPrice>
                                ₹{" "}
                                {summaryPrices.subtotal_including_tax
                                    ? summaryPrices.subtotal_including_tax.value
                                    : 0}
                            </ContentPrice>
                        </ContentContainer>
                        {(!isCart && !isCheckout) && <>
                            <ContentContainer>
                                <ContentName>Shipping</ContentName>
                                <ContentPrice>
                                    {"₹"} {shippingData[0]?.amount?.value}
                                </ContentPrice>
                            </ContentContainer>
                            {summaryPrices.discounts && summaryPrices.discounts[0]?.amount?.value &&
                                <ContentContainer>
                                    <ContentName>Discount</ContentName>
                                    <ContentPrice>
                                        ₹ {summaryPrices.discounts && summaryPrices.discounts[0]?.amount?.value ? summaryPrices.discounts[0].amount.value : 0}
                                    </ContentPrice>
                                </ContentContainer>
                            }
                            <ContentContainer>
                                <ContentName>Total</ContentName>
                                <ContentPrice>₹ {summaryPrices.grand_total?.value}</ContentPrice>
                            </ContentContainer>
                        </>}
                    </SummaryContent>
                    <HorizontalLine></HorizontalLine>
                    <>
                        {
                            !getCustomerToken() ?
                                <CheckoutButton onClick={handleOpenDrawer} loading={placeOrderState.apiState === "loading" ? true : false} >
                                    {!getCustomerToken() ? 'proceed to checkout' : ""}
                                </CheckoutButton>
                                :
                                <>
                                    {!isCheckout &&
                                        <>
                                            <CheckoutButton onClick={onCheckoutClick} loading={placeOrderState.apiState === "loading" ? true : false} >
                                                {getCustomerToken() && isCart ? 'proceed to checkout' : ''}
                                            </CheckoutButton>

                                        </>}

                                </>}

                        {isCheckout &&
                            <>
                                <CheckoutButton onClick={onProceedTocontinue} loading={buttonLoading} disabled={disabledRedirectToPayment ? true : false} >
                                    proceed to continue {disabledRedirectToPayment}
                                </CheckoutButton>
                            </>
                        }


                        {(!isCart && !isCheckout) && <>
                            <DeliveryContainer>
                                <PinCodeInput
                                    placeholder="Coupon Code"
                                    value={appliedCoupon || coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                    disabled={couponCheck ? couponCheck : false}
                                />
                                {couponCheck ?
                                    <PinCodeButton onClick={handleRemoveCoupon}>Remove</PinCodeButton> :
                                    <PinCodeButton onClick={handleAddCoupon}>Apply</PinCodeButton>
                                }
                            </DeliveryContainer>
                        </>}
                    </>
                </SummaryContainer>
            ) : null
            }
            <Authentication
                drawerState={visiable}
                handleCloseDrawer={handleCloseDrawer}
                handleCallbackClick={handleCallbackClick}
                withoutLogin={true}
            />
        </>
    );
};
const mapStateToProps = (state) => ({
    setShipState: state.fetchState.setShip,
    setShippingRequestState: state.setShippingRequest,
    placeOrderState: state.placeOrder,
    fetchCartDataState: state.fetchCartData,
    getShippigoTokenState: state.getShippigoToken,
    setShippingMethodState: state.setShippingMethod,

});

const mapDispatchToProps = (dispatch) => ({
    fetchCartData: (params) => dispatch(fetchCartData(params)),
    setShippingRequest: (params) => dispatch(setShippingRequest(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);