export const CREATE_CUSTOMER_ADDRESS = 'CREATE_CUSTOMER_ADDRESS'
export const CREATE_CUSTOMER_ADDRESS_SUCCESS = 'CREATE_CUSTOMER_ADDRESS_SUCCESS'
export const CREATE_CUSTOMER_ADDRESS_FAIL = 'CREATE_CUSTOMER_ADDRESS_FAIL'
export const CREATE_CUSTOMER_ADDRESS_RESET = 'CREATE_CUSTOMER_ADDRESS_RESET'

export const createCustomerAddress = (params) => {
    return { type: CREATE_CUSTOMER_ADDRESS, params }
}

export const createCustomerAddressSuccess = (response) => {
    return { type: CREATE_CUSTOMER_ADDRESS_SUCCESS, response }
}

export const createCustomerAddressFail = (response) => {
    return { type: CREATE_CUSTOMER_ADDRESS_FAIL, response }
}

export const createCustomerAddressReset = () => {
    return { type: CREATE_CUSTOMER_ADDRESS_RESET }
}