import React, { useState, useEffect } from "react";
import { Card, Row, Col, Tag } from "antd";
import { connect } from "react-redux";
import {
  CardContainer,
  HeartContainer,
  ProductContainer,
  ProductCard,
  ProductListName,
  CardItem,
  CardName,
  CardPrice,
  ProductCardItem,
  ProductHeader,
  ProductCount,
  BrandName,
  ArrowNameContainer,
  ArrowContainer,
  RelatedProductBlock
} from "./style";
import {
  SmileTwoTone,
  HeartTwoTone,
  HeartFilled,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import DrawerComponent from "../../components/DrawerComponent/drawer";
import NoDataContianer from "../../components/NoDataContainer/NoData";
import _ from 'lodash'

// action
import { getBrandInfo, getBrandInfoReset } from '../../actions/getBrandInfoAction'
import { brandRequest } from '../../actions/fetchBrandAction'

const RelatedProducts = (props) => {
  const { visible, relatedProductsData, history, resetCloseStateMain, getBrandInfo, getBrandInfoState, brandRequest } = props;
  const [cardData, setCardData] = useState(null);
  const [favouritesCount, setFavouritesCount] = useState(null);
  const [showRelProduct, setShowRelatedProduct] = useState(visible)
  const { Meta } = Card;
  const [brandData, setBrandData] = useState(null)



  useEffect(() => {
    getBrandInfo()
    brandRequest({
      successAction: (data) => {
        const brandItems =
          data.data.customAttributeMetadata.items[0].attribute_options;
        setBrandData(brandItems);
      },
    })
  }, [])


  const productListClick = (item) => {
    history.push({
      pathname: `/product-detail/${item.url_key}`,
      //  state: response.data.custom_attributes,
    });
    resetCloseStateMain()
    setShowRelatedProduct(false)
  };

  const onOutlineHeartClick = (data) => {
    const updatedData = [...cardData];
    updatedData.map((item) => {
      if (item.id === data.id) {
        item.isHeartFilled = true;
        setFavouritesCount((favouritesCount) => favouritesCount + 1);
      }
    });

    setCardData(updatedData);
  };

  const onFilledHeartClick = (data) => {
    const updatedData = [...cardData];
    updatedData.map((item) => {
      if (item.id === data.id) {
        item.isHeartFilled = false;
        setFavouritesCount((favouritesCount) => favouritesCount - 1);
      }
    });
    setCardData(updatedData);
  };



  const showData = () => {
    return (
      <ProductContainer noData={relatedProductsData.length}>
        {relatedProductsData && relatedProductsData.length > 0 ? (
          relatedProductsData.map((item) => {
            let brandName = item.brand_name ? (_.find(getBrandInfoState.options, ['value', item.brand_name.toString()])?.label) : ""
            return (
              <ProductCard onClick={() => productListClick(item)}>
                <ProductCardItem>
                  {/* <CardContainer> */}

                  <CardItem
                    // hoverable
                    //  style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        height="100%"
                        src={item.thumbnail.url}
                      //  src="https://staging.laduindia.com/pub/media/catalog/product/cache/e43961bd10b230de7ab49ab3c322a3d4/d/s/dscf0644.jpg"
                      />
                    }
                  >
                    {/* <CardBodyContent> */}
                    <Meta title={item.name} />
                    {/* <CardName> {item.name}</CardName> */}
                    <BrandName>{brandName}</BrandName>
                    <CardPrice>
                      {" "}
                      {/* {item.price_range.minimum_price.final_price.currency}{" "} */}
                      ₹ {item.price_range.minimum_price.final_price.value}
                    </CardPrice>
                  </CardItem>
                  {/* </CardContainer> */}
                </ProductCardItem>
              </ProductCard>
            );
          })
        ) : (
          <NoDataContianer animationType={"searchAnimation"} />
        )}
      </ProductContainer>
    );
  };
  return (
    <RelatedProductBlock>
      <DrawerComponent
        DrawerTitle="Related Products"
        placement="bottom"
        visible={showRelProduct}
        resetCloseState={resetCloseStateMain}
        showData={showData}
      ></DrawerComponent>
    </RelatedProductBlock>
  );
};

const mapStateToProps = (state) => ({
  relatedProductsData: state.getRelatedProducts.relatedProductsData,
  getBrandInfoState: state.getBrandInfo,
  brandRequestState: state.brandRequest,

});

const mapDispatchToProps = (dispatch) => ({
  getBrandInfo: (params) => dispatch(getBrandInfo(params)),
  brandRequest: (params) => dispatch(brandRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelatedProducts);
