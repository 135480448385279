import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const getMergeCartIdApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        mergeCarts(source_cart_id: "${data.params.guestId}", destination_cart_id: "${data.params.customerId}") {
            items {
                id
              product {
                    sku
                    stock_status
                    name
                    price_range{
                      minimum_price{
                        final_price{
                          currency
                          value
                        }
                      }
                    }
                    media_gallery{
                        position
                            url
                    }
                      short_description{
                            html
                        }
                        description{
                            html
                        }     
                  }
                quantity
              }
                prices{
                  subtotal_including_tax{
                    value
                  }
                  grand_total{
                    value
                  }
                  discounts{
                    amount{
                      value
                    }
                    label
                  }
                }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default getMergeCartIdApi;
