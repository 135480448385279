export const FETCH_ADDRESS_REQUEST = 'FETCH_ADDRESS_REQUEST'
export const FETCH_ADDRESS_SUCCESS = 'FETCH_ADDRESS_SUCCESS'
export const FETCH_ADDRESS_FAIL = 'FETCH_ADDRESS_FAIL'

export const fetchAddressRequest = (params) => {
    return { type: FETCH_ADDRESS_REQUEST, params }
}

export const fetchAddressSuccess = (response) => {
    return { type: FETCH_ADDRESS_SUCCESS, response }
}

export const fetchAddressFail = (response) => {
    return { type: FETCH_ADDRESS_FAIL, response }
}