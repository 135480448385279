import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';

const createCustomerCartApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                    customerCart{
                        id
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default createCustomerCartApi;
