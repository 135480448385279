import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    GUEST_REMOVE_COUPON_TO_CART,
    guestRemoveCouponToCartSuccess,
    guestRemoveCouponToCartFail
} from '../actions/guestRemoveCouponToCartAction'

// api
import guestRemoveCouponToCartApi from '../apis/guestRemoveCouponToCartApi'

export function* guestRemoveCouponToCartSaga(action) {
    try {
        const response = yield call(() => guestRemoveCouponToCartApi(action.params))
        yield put(guestRemoveCouponToCartSuccess(response, action))

    } catch (e) {
        yield put(guestRemoveCouponToCartFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GUEST_REMOVE_COUPON_TO_CART, guestRemoveCouponToCartSaga)
}