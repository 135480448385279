import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';

const placeRazorpayOrderApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                mutation {
                    placeRazorpayOrder (
                        cart_id: "${params.cart_id}"
                    ){
                        success
                        rzp_order_id
                        order_quote_id
                        amount
                        currency
                        message
                    }
                }
            `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        },
    });
    return response.data;
};

export default placeRazorpayOrderApi;
