import styled from "styled-components"

export const Container = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
`

export const Box = styled.div`
    width: 300px;
    border: 1px solid rgba(var(--b6a,219,219,219),1);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.05);
    padding: 24px;
    border-radius: 8px;
    background: #fff;
`