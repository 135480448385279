import axios from 'axios'
import { BASE_URL } from '../utils';

const updateCartProductApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
            updateCartItems(input: {cart_id: "${params.cart_id}", cart_items: [{cart_item_id: ${params.cart_item_id}, quantity: ${params.quantity}}]}) {
                cart {
                items {
                    product {
                    name
                    }
                    quantity
                }
                prices {
                    grand_total {
                    value
                    currency
                    }
                }
                }
            }
            }`
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    })

    return response;
}

export default updateCartProductApi
