export const GET_CUSTOMER_INFO = 'GET_CUSTOMER_INFO'
export const GET_CUSTOMER_INFO_SUCCESS = 'GET_CUSTOMER_INFO_SUCCESS'
export const GET_CUSTOMER_INFO_FAIL = 'GET_CUSTOMER_INFO_FAIL'
export const GET_CUSTOMER_INFO_RESET = 'GET_CUSTOMER_INFO_RESET'

export const getCustomerInfo = (params) => {
    return { type: GET_CUSTOMER_INFO, params }
}

export const getCustomerInfoSuccess = (response) => {
    return { type: GET_CUSTOMER_INFO_SUCCESS, response }
}

export const getCustomerInfoFail = (response) => {
    return { type: GET_CUSTOMER_INFO_FAIL, response }
}

export const getCustomerInfoReset = () => {
    return { type: GET_CUSTOMER_INFO_RESET }
}