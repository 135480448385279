import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    FETCH_GUEST_STATE_REQUEST,
    fetchGuestStateSuccess,
    fetchGuestStateFail,
} from '../actions/fetchGuestStateAction'

import fetchGuestStateApi from '../apis/fetchGuestStateApi';


export function* fetchGuestStateSaga(action) {

    try {
        // yield put(globalLoaderRequest(true))
        const response = yield call(() => fetchGuestStateApi(action))
        // yield put(globalLoaderRequest(false))

        if (response.errors) {
            yield put(fetchGuestStateFail(response, action))
        } else {
            yield put(fetchGuestStateSuccess(response, action))
            // yield put(globalLoaderSuccess())
        }
    } catch (e) {
        yield put(fetchGuestStateFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_GUEST_STATE_REQUEST, fetchGuestStateSaga);
}