import React from "react";
import { CloseOutlined } from '@ant-design/icons'

import {
    Container,
    TopBannerInner,
    TopBannerButton
} from "./NewsBannerHeaderStyle";

// custom components
import { CrossIcon } from '../CustomIcons'

const NewsBannerHeader = (props) => {

    // variables
    const handleClose = () => {
        localStorage.setItem('newsbanner', JSON.stringify(true))
        props.handleCallbackBanner()
    }
    return (
        <>
            <Container>
                <TopBannerInner>
                    Enjoy free shipping across India.
                </TopBannerInner>
                <TopBannerButton type="link" onClick={handleClose} icon={<CloseOutlined />} />
            </Container>


        </>
    );
};



export default NewsBannerHeader;
