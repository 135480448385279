import styled from "styled-components";
import { Input, Select, Button } from "antd";


export const Container = styled.div`
height: 100%;
//display: flex;
//justify-content: center;
`

export const CheckoutContainer = styled.div`
display: flex;
justify-content: center;
@media ${(props) => props.theme.device.tablet} {
    flex-direction: column;
}
`


export const AddressContainer = styled.div`
flex:3;
`

export const SummaryContainer = styled.div`
flex:1.5;
`
export const AddressContent = styled.div`
text-transform: uppercase;
margin: 20px;
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;

`

export const FullAddressContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-transform: capitalize;
text-align: left;
`

export const AddressFormWrapper = styled.div`
display:flex;
flex-direction: row;
flex-wrap: wrap;
width:100%;
`
export const InputContainer = styled(Input)`
max-width:200px;
margin:10px;
`
export const SelectContainer = styled(Select)`
width:200px;
margin:10px;
`
export const InputAddressContainer = styled(Input)`
margin:10px;
max-width:420px;
`
export const InfoContainer = styled.div`
/* background-color: #2f2f2f; */
border-radius:5px;
display: flex;
`

export const AddressHeading = styled.div`

`


export const AddAddress = styled.div`
border: 1px dashed rgb(204,204,204);
flex: 0 0 40%;
padding: 20px;
margin:20px;
display:flex;
cursor: pointer;
border-radius: 8px;
justify-content: center;
align-items: center;

@media ${(props) => props.theme.device.tablet} {
  flex: 0 0 80%;

}
`
export const AddressCard = styled.div`
    flex: 0 0 40%;
    margin: 20px;
    height: auto;
    border: 1px dashed rgb(204,204,204);
   border-radius: ${(props) => props.theme.border.borderRadius};
    padding: 15px 15px;
    background-color: ${props => props.active ? "#f3fdf3" : "transparent"};

@media ${(props) => props.theme.device.tablet} {
  flex: 0 0 80%;

}

`
export const AddressPhone = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
margin-bottom: 10px;
`
export const AddressName = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-transform: capitalize;
text-align: left;
`

export const AddressContainerList = styled.div`
display : flex;
flex-direction: row;
flex-wrap: wrap;
`

export const AddressPincode = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-transform: capitalize;
text-align: left;
`
export const SelectButton = styled(Button)`
/* width: 100px; */
//height:100%;
padding: 8px 20px;
height: auto;
border-radius: ${(props) => props.theme.border.borderRadius};
background-color: ${(props) => props.theme.backgroundColor.orange};
color: ${(props) => props.theme.color.white};
border-color: white;
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
&:hover,
&:focus,
&:active {
  background-color: ${(props) => props.theme.backgroundColor.orange};
  color: ${(props) => props.theme.color.white};
  border-color: white;
}
@media ${(props) => props.theme.device.tablet} {
  margin: 10px 0;
}

`
export const EditButton = styled(Button)`
/* width: 100px; */
//height:100%;

float: right;
border: 1px solid dashed;
border-radius: 50px;
padding: 10px 8px;
display: flex;
align-items: center;


`


