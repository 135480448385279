import { call, put, takeLatest } from 'redux-saga/effects'

// action
import { REMOVE_CART_PRODUCT, removeCartProductSuccess, removeCartProductFail } from '../actions/removeCartProductAction'

// api
import removeCartProductApi from '../apis/removeCartProductApi'


export function* removeCartProductSaga(action) {
    try {
        const response = yield call(() => (removeCartProductApi(action)))
        if (response.errors) {
            yield put(removeCartProductFail(response, action))
        } else {
            yield put(removeCartProductSuccess(response, action))
        }
    }
    catch (e) {
        yield put(() => removeCartProductFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(REMOVE_CART_PRODUCT, removeCartProductSaga)
}