import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';


import {
    FETCH_SEARCH_DATA,
    fetchSearchDataSuccess,
    fetchSearchDataFail,
} from '../actions/fetchSearchAction'

import getSearchApi from '../apis/fetchSearchApi';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* fetchSearchSaga(action) {

    try {
        const response = yield call(() => getSearchApi(action.params))
        if (response.errors) {
            yield put(fetchSearchDataFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(fetchSearchDataSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(fetchSearchDataFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_SEARCH_DATA, fetchSearchSaga);
}