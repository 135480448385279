import { GET_WISH_LIST, GET_WISH_LIST_SUCCESS, GET_WISH_LIST_FAIL, GET_WISH_LIST_RESET } from '../actions/getWishListAction'


const initialState = {
    apiState: "", //Loading, Success, Error, 
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_WISH_LIST:
            return {
                ...state,
                apiState: "loading"
            }
        case GET_WISH_LIST_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            }
        case GET_WISH_LIST_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case GET_WISH_LIST_RESET:
            return initialState
        default:
            return state
    }
}