export const SET_RZP_PAYMENT_DETAILS_ON_CART = 'SET_RZP_PAYMENT_DETAILS_ON_CART'
export const SET_RZP_PAYMENT_DETAILS_ON_CART_SUCCESS = 'SET_RZP_PAYMENT_DETAILS_ON_CART_SUCCESS'
export const SET_RZP_PAYMENT_DETAILS_ON_CART_FAIL = 'SET_RZP_PAYMENT_DETAILS_ON_CART_FAIL'
export const SET_RZP_PAYMENT_DETAILS_ON_CART_RESET = 'SET_RZP_PAYMENT_DETAILS_ON_CART_RESET'

export const setRzpPaymentDetailsOnCart = (params) => {
    return { type: SET_RZP_PAYMENT_DETAILS_ON_CART, params }
}

export const setRzpPaymentDetailsOnCartSuccess = (response) => {
    return { type: SET_RZP_PAYMENT_DETAILS_ON_CART_SUCCESS, response }
}

export const setRzpPaymentDetailsOnCartFail = (response) => {
    return { type: SET_RZP_PAYMENT_DETAILS_ON_CART_FAIL, response }
}
export const setRzpPaymentDetailsOnCartReset = () => {
    return { type: SET_RZP_PAYMENT_DETAILS_ON_CART_RESET }
}