import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    GET_CUSTOMER_INFO,
    getCustomerInfoSuccess,
    getCustomerInfoFail,
} from '../actions/getCustomerInfoAction'

import getCustomerInfoApi from '../apis/getCustomerInfoApi'


export function* getCustomerInfoSaga(action) {
    try {
        const response = yield call(() => getCustomerInfoApi(action.params))

        if (response.errors) {
            yield put(getCustomerInfoFail(response, action))
        } else {
            yield put(getCustomerInfoSuccess(response, action))
        }
    } catch (e) {
        yield put(getCustomerInfoFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(GET_CUSTOMER_INFO, getCustomerInfoSaga);
}