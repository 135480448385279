import React, { useEffect, useState } from "react"
import { Button, Col, Form, Input, Row, Select, notification, Modal, Alert, Checkbox } from "antd"
import { useHistory, Link } from 'react-router-dom'
import { connect } from "react-redux"
import { jaipurPincodeData } from '../../utils'
import { CloseOutlined, CheckOutlined, EnvironmentOutlined } from '@ant-design/icons'
import _ from 'lodash'


import {
    GuestCheckoutWrepper, LeftBlock,
    RightBlock, SummaryContainer, OrderHeading,
    ContentPrice, SummaryContent, HorizontalLine,
    ContentName, AddAddressBlock, LeftSection,
    AddressHead, MobileGuestCheckoutWrepper, MobileLeftBlock,
    MobileAddressHead, MobileRightBlock, MobileSummaryContainer,
    MobileOrderHeading, MobileHorizontalLine, MobileSummaryContent,
    MobileContentName, MobileContentPrice, BlockWrapper, MobileBlockWrapper,
    SelectButton, MobileSelectButton, DeliverySuccessMessage, DeliveryErrorMessage

} from './GuestCheckoutStyle'
// custom components
import GuestCheckoutSkeleton from '../../components/GuestCheckoutSkeleton/GuestCheckoutSkeleton'
// import GuestPaymentSkeleton from '../../components/GuestPaymentSkeleton/GuestPaymentSkeleton'
// actions
import { fetchStateRequest } from "../../actions/fetchStateAction"
import { fetchCartData, fetchCartDataReset } from '../../actions/fetchCartDataAction'
import { fetchGuestCartData, fetchGuestCartDataReset } from '../../actions/fetchGuestCartDataAction'
import { removeItemCart, removeItemCartReset } from '../../actions/removeItemCartAction'
import { updateProductCart, updateProductCartReset } from "../../actions/updateCartAction";
import { createGuestCustomerAddress, createGuestCustomerAddressReset } from '../../actions/createGuestCustomerAddressAction'
import { fetchGuestStateRequest } from "../../actions/fetchGuestStateAction"
import { getGuestShippigoToken, getGuestShippigoTokenReset } from '../../actions/getGuestShippigoTokenAction'
import { checkGuestServiceAvailability, checkGuestServiceAvailabilityReset } from '../../actions/checkGuestServiceAvailabilityAction'
import { guestSetShippingAddress, guestSetShippingAddressReset } from '../../actions/guestSetShippingAddressAction'
import { guestBillingAddress, guestBillingAddressReset } from '../../actions/guestBillingAddressAction'
import { setShippingMethod, setShippingMethodReset } from '../../actions/setShippingMethodAction'
import { setGuestEmailCart, setGuestEmailCartReset } from '../../actions/setGuestEmailCartAction'

// others
import { getCustomerToken, getCartId } from "../../utils"
// import { guestBillingAddressReset } from "../../actions/guestBillingAddressAction"
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import NoDataContianer from "../../components/NoDataContainer/NoData";

const GuestCheckout = props => {
    // variables
    // const cart_id = getCartId();
    const [inputData, setInputData] = useState([])
    const [visiableData, setVisiableData] = useState(false)
    const isMobile = window.innerWidth < 768
    const {
        fetchGuestCartData, fetchGuestCartDataState, fetchGuestCartDataReset,
        removeItemCart, removeItemCartState, removeItemCartReset,
        getCartCount, updateProductCart, updateProductCartReset,
        fetchGuestStateRequest, fetchGuestStateData,
        guestBillingAddress, guestBillingAddressState, guestBillingAddressReset,
        guestSetShippingAddress, guestSetShippingAddressReset, guestSetShippingAddressState,
        setShippingMethod, setShippingMethodState, setShippingMethodReset,
        setGuestEmailCart, setGuestEmailCartState, setGuestEmailCartReset,
        getGuestShippigoToken, getGuestShippigoTokenState, getGuestShippigoTokenReset,
        checkGuestServiceAvailability, checkGuestServiceAvailabilityState, checkGuestServiceAvailabilityReset,
        updateListItems
    } = props

    var priceData = fetchGuestCartDataState.data.prices
    const history = useHistory()
    const [shippingMethodData, setShippingMethodData] = useState(null)
    const [btnLoading, setButtonLoading] = useState(false)
    const [refresh, setRefresh] = useState(0)
    const [form] = Form.useForm()
    const [sameAsBilling, setSameAsBilling] = useState(false)
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        state_code: "",
        state_id: "",
        mobile: "",
        email: ""
    })
    const [billingFormData, setBillingFormData] = useState({
        givenName: "",
        familyName: "",
        billingAddress: "",
        billingAddressPincode: "",
        billingAddressCity: "",
        billingAddressState: "",
        billingAddressState_code: "",
        billingAddressState_id: "",
        billingAddressMobile: "",
        billingAddressEmail: ""
    })
    const [pincodeData, setPincodeData] = useState({
        pinCode: ""
    })

    // callbacks
    const cartId = getCartId()
    useEffect(() => {
        const cart_id = getCartId()
        fetchGuestCartData({
            cart_id: cart_id,
        })
    }, [])



    useEffect(() => {
        if (fetchGuestCartDataState.apiState === "success") {
            var cartData = fetchGuestCartDataState.data.items
            if ((_.find(cartData, ['product.jaipur_only_delivery', 1]))) {
                setVisiableData(true)
            }
            else {
                setVisiableData(false)
            }
            let userInfo = [...inputData]
            fetchGuestCartDataState.data.shipping_addresses.map((info) => {
                userInfo.push(info)
            })
            setInputData(userInfo)
            // fetchGuestCartDataReset()
        }
        // if (fetchGuestCartDataState.data.items && fetchGuestCartDataState.data.items.length <= 0) {
        //     history.push("/")
        //     notification["error"]({
        //         message: "Some product is out of stock.",
        //         placement: "topRight"
        //     });
        //     fetchGuestCartDataReset()
        // }
        if (fetchGuestCartDataState.apiState === "error") {
            fetchGuestCartDataState.data.map((item) => {
                if (item !== null) {
                    if (item.product.stock_status === "OUT_OF_STOCK" || item.product.only_x_left_in_stock === null) {
                        let itemId = item.id
                        removeItemCart({
                            cart_id: cartId,
                            cart_item_id: itemId,
                        })
                    }
                    else {
                        if (item.quantity > item.product.only_x_left_in_stock) {
                            let decreseQuantity = item.quantity - item.product.only_x_left_in_stock
                            if (decreseQuantity) {
                                const updatedData = {
                                    cart_id: cartId,
                                    cart_item_id: item.id,
                                    quantity: item.product.only_x_left_in_stock
                                }
                                updateProductCart({
                                    updatedData,
                                    successAction: (data) => {
                                        fetchGuestCartData({ cart_id: cartId })
                                    }
                                })
                            }
                        }
                    }
                }
            })
            fetchGuestCartDataReset()
        }
    }, [fetchGuestCartDataState])
    useEffect(() => {
        if (removeItemCartState.apiState === "success" || removeItemCartState.apiState === "error") {
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken()
            })
            fetchGuestCartData({ cart_id: cartId, })
            removeItemCartReset()
        }
    }, [removeItemCartState])
    useEffect(() => {
        if (updateListItems) {
            fetchGuestCartData({ cart_id: cartId, })
        }
    }, [updateListItems])

    useEffect(() => {
        return (() => {
            // fetchGuestStateRequest()
            guestBillingAddressReset()
            guestSetShippingAddressReset()
            setShippingMethodReset()
            setGuestEmailCartReset()
            getGuestShippigoTokenReset()
            checkGuestServiceAvailabilityReset()
        })
    }, [])

    useEffect(() => {
        fetchGuestStateRequest()
        const cart_id = getCartId()
    }, [])

    useEffect(() => {
        if (fetchGuestCartDataState.apiState === "success") {
            if (fetchGuestCartDataState.data.email !== null) {
                setFormData({
                    ...formData,
                    ['firstname']: fetchGuestCartDataState.data.shipping_addresses[0].firstname,
                    ['lastname']: fetchGuestCartDataState.data.shipping_addresses[0].lastname,
                    ['address']: fetchGuestCartDataState.data.shipping_addresses[0].street,
                    ['pincode']: fetchGuestCartDataState.data.shipping_addresses[0].postcode,
                    ['city']: fetchGuestCartDataState.data.shipping_addresses[0].city,
                    ['state']: fetchGuestCartDataState.data.shipping_addresses[0].region.label,
                    ['state_code']: fetchGuestCartDataState.data.shipping_addresses[0].region.code,
                    ['state_id']: fetchGuestCartDataState.data.shipping_addresses[0].region.region_id,
                    ['mobile']: fetchGuestCartDataState.data.shipping_addresses[0].telephone,
                    ['email']: fetchGuestCartDataState.data.email,
                })
                setBillingFormData({
                    ...billingFormData,
                    ['givenName']: fetchGuestCartDataState.data.billing_address.firstname,
                    ['familyName']: fetchGuestCartDataState.data.billing_address.lastname,
                    ['billingAddress']: fetchGuestCartDataState.data.billing_address.street,
                    ['billingAddressPincode']: fetchGuestCartDataState.data.billing_address.postcode,
                    ['billingAddressCity']: fetchGuestCartDataState.data.billing_address.city,
                    ['billingAddressState']: fetchGuestCartDataState.data.billing_address.region.label,
                    ['billingAddressState_code']: fetchGuestCartDataState.data.billing_address.region.code,
                    ['billingAddressState_id']: fetchGuestCartDataState.data.billing_address.region.region_id,
                    ['billingAddressMobile']: fetchGuestCartDataState.data.billing_address.telephone,
                    ['billingAddressEmail']: fetchGuestCartDataState.data.email,
                })
                form.setFieldsValue({
                    firstname: fetchGuestCartDataState.data.shipping_addresses[0].firstname,
                    lastname: fetchGuestCartDataState.data.shipping_addresses[0].lastname,
                    address: fetchGuestCartDataState.data.shipping_addresses[0].street,
                    pincode: fetchGuestCartDataState.data.shipping_addresses[0].postcode,
                    city: fetchGuestCartDataState.data.shipping_addresses[0].city,
                    state: fetchGuestCartDataState.data.shipping_addresses[0].region.label,
                    mobile: fetchGuestCartDataState.data.shipping_addresses[0].telephone,
                    email: fetchGuestCartDataState.data.email,
                })
                form.setFieldsValue({
                    givenName: fetchGuestCartDataState.data.billing_address.firstname,
                    familyName: fetchGuestCartDataState.data.billing_address.lastname,
                    billingAddress: fetchGuestCartDataState.data.billing_address.street,
                    billingAddressPincode: fetchGuestCartDataState.data.billing_address.postcode,
                    billingAddressCity: fetchGuestCartDataState.data.billing_address.city,
                    billingAddressState: fetchGuestCartDataState.data.billing_address.region.label,
                    billingAddressMobile: fetchGuestCartDataState.data.billing_address.telephone,
                    billingAddressEmail: fetchGuestCartDataState.data.email,
                })
            }
        }
    }, [fetchGuestCartDataState])

    useEffect(() => {
        if (getGuestShippigoTokenState.apiState === "loading" || setShippingMethodState.apiState === "loading") {
            setButtonLoading(true)
        }
    }, [getGuestShippigoTokenState, setShippingMethodState])


    useEffect(() => {
        if (getGuestShippigoTokenState.apiState === "success") {
            const token = getGuestShippigoTokenState.data.token
            checkGuestServiceAvailability({
                token: token,
                pincode: formData.pincode
            })
            setRefresh(refresh + 1)
            getGuestShippigoTokenReset()
        }
        if (getGuestShippigoTokenState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "topRight"
            })
            getGuestShippigoTokenReset()
            setButtonLoading(false)
        }
    }, [getGuestShippigoTokenState])
    useEffect(() => {
        if (checkGuestServiceAvailabilityState.apiState === "success") {
            guestSetShippingAddress({
                firstname: formData.firstname,
                lastname: formData.lastname,
                address: formData.address,
                pincode: formData.pincode,
                city: formData.city,
                state: formData.state,
                state_code: formData.state_code,
                state_id: formData.state_id,
                mobile: formData.mobile,
                cartId: cartId,
            })
        }
        if (checkGuestServiceAvailabilityState.apiState === "error") {
            notification["error"]({
                message: "Delivery Not Avaliable For This Pincode",
                placement: "topRight"
            })
            getGuestShippigoTokenReset()
            checkGuestServiceAvailabilityReset()
            setButtonLoading(false)
        }
    }, [checkGuestServiceAvailabilityState])
    useEffect(() => {
        if (guestSetShippingAddressState.apiState === "success") {
            if (sameAsBilling) {
                guestBillingAddress({
                    firstname: formData.firstname,
                    lastname: formData.lastname,
                    address: formData.address,
                    pincode: formData.pincode,
                    city: formData.city,
                    state: formData.state,
                    state_code: formData.state_code,
                    state_id: formData.state_id,
                    mobile: formData.mobile,
                    cartId: cartId,
                })
            }
            else {
                guestBillingAddress({
                    firstname: billingFormData.givenName,
                    lastname: billingFormData.familyName,
                    address: billingFormData.billingAddress,
                    pincode: billingFormData.billingAddressPincode,
                    city: billingFormData.billingAddressCity,
                    state: billingFormData.billingAddressState,
                    state_code: billingFormData.billingAddressState_code,
                    state_id: billingFormData.billingAddressState_id,
                    mobile: billingFormData.billingAddressMobile,
                    cartId: cartId,
                })
            }
            let shippingMethodData = guestSetShippingAddressState.data.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods
            setShippingMethodData(shippingMethodData)
            guestSetShippingAddressReset()
        }
        if (guestSetShippingAddressState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "topRight"
            })
            guestSetShippingAddressReset()
        }
    }, [guestSetShippingAddressState])

    useEffect(() => {
        if (guestBillingAddressState.apiState === "success") {
            const carrier_code = shippingMethodData[0].carrier_code
            const method_code = shippingMethodData[0].method_code
            setShippingMethod({
                cartId: cartId,
                carrier_code: carrier_code,
                method_code: method_code
            })
            guestBillingAddressReset()
        }
        if (guestBillingAddressState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "topRight"
            })
            guestBillingAddressReset()
        }
    }, [guestBillingAddressState])

    useEffect(() => {
        if (setShippingMethodState.apiState === "success") {
            setGuestEmailCart({
                cartId: cartId,
                email: formData.email
            })
            setShippingMethodReset()
        }
        if (setShippingMethodState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "topRight"
            })
            setShippingMethodReset()
        }
    }, [setShippingMethodState])

    useEffect(() => {
        if (setGuestEmailCartState.apiState === "success") {
            history.push("/guest-payment")
            setGuestEmailCartReset()
            const cart_id = getCartId()

        }
        if (setGuestEmailCartState.apiState === "error") {
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "topRight"
            })
            setGuestEmailCartReset()
        }
    }, [setGuestEmailCartState])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
        setPincodeData({ ...pincodeData, [e.target.name]: e.target.value })
        checkGuestServiceAvailabilityState.apiState = ""
    }
    const handleBillingAddressChange = e => {
        setBillingFormData({
            ...billingFormData,
            [e.target.name]: e.target.value
        })
    }
    const handlePincodeChange = (e) => {


    }

    const handleShippingAddress = () => {
        const postCodeResult = formData.pincode
        if (visiableData && !(_.find(jaipurPincodeData, ['pincode', Number(postCodeResult)]))) {
            notification["error"]({
                message: "Some of the items in your cart can only be delivered to Jaipur. Please remove these from your cart or choose a delivery address in Jaipur to proceed with checkout.",
                placement: "bottomRight"
            })
        }
        else {
            getGuestShippigoToken()
        }
    }
    const handleSelect = (v) => {
        setFormData({
            ...formData,
            ["state_id"]: v.split("-")[0],
            ["state_code"]: v.split("-")[1],
            ["state"]: v.split("-")[2],
        })
    }
    const handleBillingSelect = (v) => {
        setBillingFormData({
            ...billingFormData,
            ["billingAddressState_id"]: v.split("-")[0],
            ["billingAddressState_code"]: v.split("-")[1],
            ["billingAddressState"]: v.split("-")[2],
        })
    }

    return (
        <>
            {
                fetchGuestCartDataState.apiState === "loading" &&
                <div style={{ width: '100%' }} >
                    <GuestCheckoutSkeleton />
                </div>
            }

            {fetchGuestCartDataState.apiState === "success" && fetchGuestCartDataState.data.items && fetchGuestCartDataState.data.items.length <= 0 ?
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', flexDirection: 'column' }} >
                    <NoDataContianer
                        showText="No Items available in cart."
                        animationType={"cartAnimation"}
                    />
                    <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                </div>
                :
                <>
                    {
                        fetchGuestCartDataState.apiState === "success" &&
                        <Form
                            layout="vertical"
                            onFinish={handleShippingAddress}
                            form={form}
                        >
                            <>
                                {
                                    !isMobile ? (
                                        <GuestCheckoutWrepper>
                                            <AddressHead>Enter Your Shipping Address</AddressHead>
                                            <div>
                                                {
                                                    visiableData &&
                                                    <div style={{ width: '100%', height: '100%', }}>
                                                        <Alert style={{ marginTop: 20 }} message="Delivery is only available in Jaipur, Rajasthan." banner />
                                                    </div>
                                                }
                                            </div>

                                            <BlockWrapper>
                                                <LeftBlock>
                                                    <GuestCheckoutWrepper>
                                                        <Row gutter="16" >
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="firstname"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Input name="firstname" placeholder="First Name" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="lastname"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Input name="lastname" placeholder="Last Name" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Form.Item
                                                            name="email"
                                                            rules={[
                                                                { required: true, message: 'Required' },
                                                                {
                                                                    type: 'email',
                                                                    message: 'Enter valid email.',
                                                                }
                                                            ]}
                                                        >
                                                            <Input name="email" placeholder="Email Address" onChange={handleOnChange} />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="address"
                                                            rules={[
                                                                { required: true, message: 'Required' },
                                                            ]}
                                                        >
                                                            <Input name="address" placeholder="Address" onChange={handleOnChange} />
                                                        </Form.Item>

                                                        <Row gutter="16" >
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="pincode"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                        {
                                                                            pattern: new RegExp("^[0-9]{6}$"),
                                                                            message: 'Enter valid pincode.',
                                                                        }]
                                                                    }
                                                                >
                                                                    <Input name="pincode" placeholder="PIN code" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="city"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Input name="city" placeholder="City" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row gutter="16" >
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="state"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Select name="state" placeholder="State"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        onSelect={handleSelect}
                                                                    >
                                                                        {(fetchGuestStateData || []).map(item => (
                                                                            <Select.Option
                                                                                key={`${item.id}-${item.code}-${item.name}`} value={`${item.id}-${item.code}-${item.name}`} >
                                                                                {item.name}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="mobile"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                        {
                                                                            pattern: new RegExp('^[6-9]\\d{9}$'),
                                                                            message: 'Mobile number is not valid',
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input name="mobile" placeholder="Mobile" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Checkbox
                                                            style={{ marginBottom: 30 }}
                                                            onChange={(e) => {
                                                                setSameAsBilling(e.target.checked)
                                                                setFormData({ ...formData, ["guestBillingAddress"]: "" })

                                                            }} checked={sameAsBilling}>Billing address same as shipping address.</Checkbox>
                                                        {
                                                            !sameAsBilling &&
                                                            <div style={{ width: '100%' }}>
                                                                <AddressHead>Enter Your Billing Address</AddressHead>
                                                                <div style={{ marginTop: 30 }} >

                                                                    <Row gutter="16" >
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="givenName"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Input name="givenName" placeholder="First Name" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="familyName"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Input name="familyName" placeholder="Last Name" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    {/* <Form.Item
                                                                name="billingAddressEmail"
                                                                rules={[
                                                                    { required: true, message: 'Required' },
                                                                    {
                                                                        type: 'email',
                                                                        message: 'Enter valid email.',
                                                                    }
                                                                ]}
                                                            >
                                                                <Input name="billingAddressEmail" placeholder="Email Address" onChange={handleBillingAddressChange} />
                                                            </Form.Item> */}

                                                                    <Form.Item
                                                                        name="billingAddress"
                                                                        rules={[
                                                                            { required: true, message: 'Required' },
                                                                        ]}
                                                                    >
                                                                        <Input name="billingAddress" placeholder="Address" onChange={handleBillingAddressChange} />
                                                                    </Form.Item>

                                                                    <Row gutter="16" >
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressPincode"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                    {
                                                                                        pattern: new RegExp("^[0-9]{6}$"),
                                                                                        message: 'Enter valid pincode.',
                                                                                    }

                                                                                ]
                                                                                }
                                                                            >
                                                                                <Input name="billingAddressPincode" placeholder="PIN code" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>

                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressCity"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Input name="billingAddressCity" placeholder="City" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row gutter="16" >
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressState"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Select name="billingAddressState" placeholder="State"
                                                                                    showSearch
                                                                                    filterOption={(input, option) =>
                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    onSelect={handleBillingSelect}
                                                                                >
                                                                                    {(fetchGuestStateData || []).map(item => (
                                                                                        <Select.Option
                                                                                            key={`${item.id}-${item.code}-${item.name}`} value={`${item.id}-${item.code}-${item.name}`} >
                                                                                            {item.name}
                                                                                        </Select.Option>
                                                                                    ))}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressMobile"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                    {
                                                                                        pattern: new RegExp('^[6-9]\\d{9}$'),
                                                                                        message: 'Mobile number is not valid',
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <Input name="billingAddressMobile" placeholder="Mobile" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                            </div>
                                                        }

                                                    </GuestCheckoutWrepper>
                                                </LeftBlock>
                                                <RightBlock>
                                                    <SummaryContainer>
                                                        <OrderHeading>Order Summary</OrderHeading>
                                                        <HorizontalLine></HorizontalLine>
                                                        <SummaryContent>
                                                            <ContentName>Sub Total</ContentName>
                                                            <ContentPrice>
                                                                ₹{" "}
                                                                {
                                                                    priceData &&
                                                                    <>
                                                                        {priceData.subtotal_including_tax
                                                                            ? priceData.subtotal_including_tax.value
                                                                            : 0}
                                                                    </>
                                                                }
                                                            </ContentPrice>
                                                        </SummaryContent>
                                                        <HorizontalLine></HorizontalLine>
                                                        <SelectButton
                                                            htmlType="submit"
                                                            loading={btnLoading}>
                                                            Proceed to Checkout
                                                        </SelectButton>
                                                    </SummaryContainer>

                                                </RightBlock>

                                            </BlockWrapper>
                                        </GuestCheckoutWrepper>
                                    ) : (
                                        <MobileGuestCheckoutWrepper>
                                            <MobileAddressHead>Enter Your Shipping Address</MobileAddressHead>
                                            {
                                                visiableData &&
                                                <div style={{ width: '100%', height: '100%', }}>
                                                    <Alert style={{ marginTop: 20 }} message="Delivery is only available in Jaipur, Rajasthan." banner />
                                                </div>
                                            }
                                            <MobileBlockWrapper>
                                                <MobileLeftBlock>
                                                    <GuestCheckoutWrepper>
                                                        <Row gutter="16" >
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="firstname"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Input name="firstname" placeholder="First Name" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="lastname"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Input name="lastname" placeholder="Last Name" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Form.Item
                                                            name="email"
                                                            rules={[
                                                                { required: true, message: 'Required' },
                                                                {
                                                                    type: 'email',
                                                                    message: 'Enter valid email.',
                                                                }
                                                            ]}
                                                        >
                                                            <Input name="email" placeholder="Email Address" onChange={handleOnChange} />
                                                        </Form.Item>

                                                        <Form.Item
                                                            name="address"
                                                            rules={[
                                                                { required: true, message: 'Required' },
                                                            ]}
                                                        >
                                                            <Input name="address" placeholder="Address" onChange={handleOnChange} />
                                                        </Form.Item>

                                                        <Row gutter="16" >
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="pincode"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                        {
                                                                            pattern: new RegExp("^[0-9]{6}$"),
                                                                            message: 'Enter valid pincode.',
                                                                        }]
                                                                    }
                                                                >
                                                                    <Input name="pincode" placeholder="PIN code" onChange={handleOnChange} />
                                                                </Form.Item>

                                                            </Col>

                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="city"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Input name="city" placeholder="City" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>

                                                        <Row gutter="16" >
                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="state"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                    ]}
                                                                >
                                                                    <Select name="state" placeholder="State"
                                                                        showSearch
                                                                        filterOption={(input, option) =>
                                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                        }
                                                                        onSelect={handleSelect}
                                                                    >
                                                                        {(fetchGuestStateData || []).map(item => (
                                                                            <Select.Option
                                                                                key={`${item.id}-${item.code}-${item.name}`} value={`${item.id}-${item.code}-${item.name}`} >
                                                                                {item.name}
                                                                            </Select.Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col span="12" >
                                                                <Form.Item
                                                                    name="mobile"
                                                                    rules={[
                                                                        { required: true, message: 'Required' },
                                                                        {
                                                                            pattern: new RegExp('^[6-9]\\d{9}$'),
                                                                            message: 'Mobile number is not valid',
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input name="mobile" placeholder="Mobile" onChange={handleOnChange} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                        <Checkbox
                                                            style={{ marginBottom: 20 }}
                                                            onChange={(e) => {
                                                                setSameAsBilling(e.target.checked)
                                                                setFormData({ ...formData, ["guestBillingAddress"]: "" })

                                                            }} checked={sameAsBilling}>Billing address same as shipping address.</Checkbox>
                                                        {
                                                            !sameAsBilling &&
                                                            <div style={{ width: '100%' }}>
                                                                <AddressHead>Enter Your Billing Address</AddressHead>
                                                                <div style={{ marginTop: 30 }} >

                                                                    <Row gutter="16" >
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="givenName"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Input name="givenName" placeholder="First Name" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="familyName"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Input name="familyName" placeholder="Last Name" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                    {/* <Form.Item
                                                                name="billingAddressEmail"
                                                                rules={[
                                                                    { required: true, message: 'Required' },
                                                                    {
                                                                        type: 'email',
                                                                        message: 'Enter valid email.',
                                                                    }
                                                                ]}
                                                            >
                                                                <Input name="billingAddressEmail" placeholder="Email Address" onChange={handleBillingAddressChange} />
                                                            </Form.Item> */}

                                                                    <Form.Item
                                                                        name="billingAddress"
                                                                        rules={[
                                                                            { required: true, message: 'Required' },
                                                                        ]}
                                                                    >
                                                                        <Input name="billingAddress" placeholder="Address" onChange={handleBillingAddressChange} />
                                                                    </Form.Item>

                                                                    <Row gutter="16" >
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressPincode"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                    {
                                                                                        pattern: new RegExp("^[0-9]{6}$"),
                                                                                        message: 'Enter valid pincode.',
                                                                                    }

                                                                                ]
                                                                                }
                                                                            >
                                                                                <Input name="billingAddressPincode" placeholder="PIN code" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressCity"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Input name="billingAddressCity" placeholder="City" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>

                                                                    <Row gutter="16" >
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressState"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                ]}
                                                                            >
                                                                                <Select name="billingAddressState" placeholder="State"
                                                                                    showSearch
                                                                                    filterOption={(input, option) =>
                                                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                                                    }
                                                                                    onSelect={handleBillingSelect}
                                                                                >
                                                                                    {(fetchGuestStateData || []).map(item => (
                                                                                        <Select.Option
                                                                                            key={`${item.id}-${item.code}-${item.name}`} value={`${item.id}-${item.code}-${item.name}`} >
                                                                                            {item.name}
                                                                                        </Select.Option>
                                                                                    ))}
                                                                                </Select>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col span="12" >
                                                                            <Form.Item
                                                                                name="billingAddressMobile"
                                                                                rules={[
                                                                                    { required: true, message: 'Required' },
                                                                                    {
                                                                                        pattern: new RegExp('^[6-9]\\d{9}$'),
                                                                                        message: 'Mobile number is not valid',
                                                                                    }
                                                                                ]}
                                                                            >
                                                                                <Input name="billingAddressMobile" placeholder="Mobile" onChange={handleBillingAddressChange} />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                            </div>
                                                        }

                                                    </GuestCheckoutWrepper>
                                                </MobileLeftBlock>
                                                {/* <MobileGuestCheckoutWrepper> */}
                                                <MobileRightBlock>
                                                    <MobileSummaryContainer>
                                                        <MobileOrderHeading>Order Summary</MobileOrderHeading>
                                                        <MobileHorizontalLine></MobileHorizontalLine>
                                                        <MobileSummaryContent>
                                                            <MobileContentName>Sub Total</MobileContentName>
                                                            <MobileContentPrice>
                                                                ₹{" "}
                                                                {
                                                                    priceData &&
                                                                    <>
                                                                        {priceData.subtotal_including_tax
                                                                            ? priceData.subtotal_including_tax.value
                                                                            : 0}
                                                                    </>
                                                                }
                                                            </MobileContentPrice>
                                                        </MobileSummaryContent>
                                                        <MobileSelectButton
                                                            htmlType="submit"
                                                            loading={btnLoading}>
                                                            Proceed to Checkout
                                                        </MobileSelectButton>
                                                        <MobileHorizontalLine></MobileHorizontalLine>
                                                    </MobileSummaryContainer>
                                                </MobileRightBlock>
                                            </MobileBlockWrapper>
                                        </MobileGuestCheckoutWrepper>
                                    )
                                }
                            </>

                        </Form>
                    }

                </>

            }


        </>

    )
}

const mapStateToProps = (state) => ({
    fetchStateData: state.fetchState.stateData,
    // fetchCartDataState: state.fetchCartData,
    cartDataPrices: state.cartData.cartDataPrices,
    // cartDataItems: state.cartData.cartDataItems,
    fetchGuestCartDataState: state.fetchGuestCartData,
    removeItemCartState: state.removeItemCart,
    createGuestCustomerAddressState: state.createGuestCustomerAddress,
    fetchGuestStateData: state.fetchGuestState.stateData,
    guestBillingAddressState: state.guestBillingAddress,
    guestSetShippingAddressState: state.guestSetShippingAddress,
    setShippingMethodState: state.setShippingMethod,
    setGuestEmailCartState: state.setGuestEmailCart,
    getGuestShippigoTokenState: state.getGuestShippigoToken,
    checkGuestServiceAvailabilityState: state.checkGuestServiceAvailability,
    updateListItems: state.updateItem.updateListItems,
});

const mapDispatchToProps = (dispatch) => ({
    fetchStateRequest: () => dispatch(fetchStateRequest()),
    fetchCartData: (params) => dispatch(fetchCartData(params)),
    fetchCartDataReset: () => dispatch(fetchCartDataReset()),
    fetchGuestCartData: (params) => dispatch(fetchGuestCartData(params)),
    removeItemCart: (params) => dispatch(removeItemCart(params)),
    removeItemCartReset: () => dispatch(removeItemCartReset()),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
    updateProductCart: (params) => dispatch(updateProductCart(params)),
    updateProductCartReset: () => dispatch(updateProductCartReset()),
    fetchGuestCartDataReset: () => dispatch(fetchGuestCartDataReset()),
    createGuestCustomerAddress: (params) => dispatch(createGuestCustomerAddress(params)),
    createGuestCustomerAddressReset: () => dispatch(createGuestCustomerAddressReset()),
    fetchGuestStateRequest: () => dispatch(fetchGuestStateRequest()),
    guestBillingAddress: (params) => dispatch(guestBillingAddress(params)),
    guestBillingAddressReset: () => dispatch(guestBillingAddressReset()),
    guestSetShippingAddress: (params) => dispatch(guestSetShippingAddress(params)),
    guestSetShippingAddressReset: () => dispatch(guestSetShippingAddressReset()),
    setShippingMethod: (params) => dispatch(setShippingMethod(params)),
    setShippingMethodReset: () => dispatch(setShippingMethodReset()),
    setGuestEmailCart: (params) => dispatch(setGuestEmailCart(params)),
    setGuestEmailCartReset: () => dispatch(setGuestEmailCartReset()),
    getGuestShippigoToken: (params) => dispatch(getGuestShippigoToken(params)),
    getGuestShippigoTokenReset: () => dispatch(getGuestShippigoTokenReset()),
    checkGuestServiceAvailability: (params) => dispatch(checkGuestServiceAvailability(params)),
    checkGuestServiceAvailabilityReset: () => dispatch(checkGuestServiceAvailabilityReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(GuestCheckout);