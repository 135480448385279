import styled from 'styled-components'

export const BrandListWrapper = styled.div`
 display: flex;
   align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 10px;
`
export const BrandBlock = styled.div`
 max-width: 270px;
`