import React, { useState, useEffect } from "react";
import _ from "lodash"
import {
    Container,
    MenuButton,
    FooterItemText,
    DropdownContainer,
    WishListButton,
    FooterSmallText
} from "./FooterStyle";
import { MoreOutlined } from "@ant-design/icons";
import { Menu, Dropdown, notification } from "antd";
import { FireIcon, FourSquareIcon, LocationIcon, MoreIcon } from "../../icons";
import { HideOnMobileL } from "../Xcomponent";
import RelatedProducts from "../RelatedProducts/relatedProducts";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
    FacebookShareButton,
    WhatsappShareButton,
    TwitterShareButton,
    ViberShareButton
} from "react-share";
import { HeartFilled } from '@ant-design/icons'
import { HeartIcon } from '../CustomIcons'

// custom component
import Authentication from '../../components/Authentication/Authentication'

// actions
import { addProductToWishList, addProductToWishListReset } from '../../actions/addProductToWishListAction'
import { getWishList, getWishListReset } from '../../actions/getWishListAction'
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import {
    deleteWishList, deleteWishListReset
} from '../../actions/deleteWishListAction'

// others
import { getCustomerToken, getCartId } from '../../utils'
import { element } from "prop-types";

const Footer = (props) => {
    // variables
    const { history, relatedProductsData, sku } = props;
    const [relatedProductsVisible, setRelatedProductsVisible] = useState(null);
    const handleRelatedProduct = () => {
        setRelatedProductsVisible(true);
    };
    const [disable, setDisable] = useState(true)
    const { openRelatedProductDrawer, relatedProductsDrawerState,
        addProductToWishList, addProductToWishListState, addProductToWishListReset,
        getWishList, getWishListState, getWishListReset, getCartCount, getCartCountReset,
        deleteWishList, deleteWishListState, deleteWishListReset, display,
        createCustomerCartState, brandData, brandId, brandUrlKey
    } = props;
    const historyPath = useHistory();
    const [visiable, setVisiable] = useState(false)
    const [refresh, setRefresh] = useState(0)
    // const [display, setDisplay] = useStat(false)


    // callbacks
    useEffect(() => {
    }, [])

    useEffect(() => {
        return (() => {
            getWishListReset()
            getCartCountReset()
            deleteWishListReset()
        })
    }, [])

    useEffect(() => {
        getWishList({ customerToken: getCustomerToken() })
    }, [])



    useEffect(() => {
        if (addProductToWishListState.apiState === "success") {
            addProductToWishListReset()
            notification["success"]({
                message: 'Added to wishlist',
                placement: "bottomRight"
            });
            getWishList({ customerToken: getCustomerToken() })
        }

        if (addProductToWishListState.apiState === "error") {
            addProductToWishListReset()
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "bottomRight"
            })
        }
    }, [addProductToWishListState])

    useEffect(() => {
        if (deleteWishListState.apiState === "success") {
            deleteWishListReset()
            notification["success"]({
                message: `${props.productName} was removed from your wishlist.`,
                placement: "bottomRight"
            });
            deleteWishListReset()
            getWishList({ customerToken: getCustomerToken() })
        }

        if (deleteWishListState.apiState === "error") {
            deleteWishListReset()
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "bottomRight"
            })
            deleteWishListReset()
        }
    }, [deleteWishListState])
    useEffect(() => {
        if (createCustomerCartState.refreshItemCount) {
            setRefresh(refresh + 1)
            createCustomerCartState.refreshItemCount = false
        }
    }, [createCustomerCartState])

    useEffect(() => {
        getCartCount({
            cart_id: getCartId(),
            customerToken: getCustomerToken(),
        })
    }, [refresh])

    const handleWishListClick = () => {
        if (getCustomerToken() === null) {
            setVisiable(true)
        }
        else {
            addProductToWishList({
                sku: sku,
                customerToken: getCustomerToken()
            });
        }

    }
    const handleRemoveWishList = (id) => {
        deleteWishList({ id: id });
    }
    const handleCloseDrawer = () => {
        setVisiable(false)
    }
    const handleClose = () => {
        setRelatedProductsVisible(false)
    }
    const handleCallbackClick = () => {
        // setRefresh(refresh + 1)
    }

    const menu = (
        <Menu style={{ padding: '5px 16px' }} >
            <Menu.Item>
                <FacebookShareButton
                    url={"https://laduindia.com" + historyPath.location.pathname}
                    quote={"Explore a product on laduindia"}
                    hashtag="#taprifacebook"
                >
                    Facebook
                </FacebookShareButton>
            </Menu.Item>
            <Menu.Item>
                <WhatsappShareButton
                    url={"https://laduindia.com" + historyPath.location.pathname}
                    title={"Explore a product on laduindia"}
                    separator=":: "
                    hashtag="#tapriWhatsapp"
                >
                    WhatsApp
                </WhatsappShareButton>
            </Menu.Item>
            <Menu.Item>
                <TwitterShareButton
                    url={"https://laduindia.com" + historyPath.location.pathname}
                    quote={"Explore a product on laduindia"}
                    hashtag="#tapritwitter"
                >
                    Twitter
                </TwitterShareButton>
            </Menu.Item>
            <Menu.Item>
                <ViberShareButton
                    url={"https://laduindia.com" + historyPath.location.pathname}
                    quote={"Explore a product on laduindia"}
                    hashtag="#tapritwitter"
                >
                    Viber
                </ViberShareButton>
            </Menu.Item>
        </Menu>
    );

    return (
        <Container>
            {relatedProductsVisible && (
                <RelatedProducts
                    visible={relatedProductsVisible}
                    history={history}
                    resetCloseStateMain={() => setRelatedProductsVisible(false)}
                />
            )}
            {props.isProductDetail && (
                <>
                    <MenuButton type="text" active>

                        {" "}
                        {/* <a>
                            <img
                                src={`${process.env.PUBLIC_URL}/images/ladu-footer-heart.svg`}
                                alt="ladu-india"
                                width="20"
                                height="20"
                            />
                        </a> */}
                        {getWishListState.data.wishlist ?
                            (_.find(getWishListState.data.wishlist.items, ['product.sku', sku ? sku.toString() : ""]) ?
                                <WishListButton onClick={() => handleRemoveWishList(_.find(getWishListState.data.wishlist.items, ['product.sku', sku.toString()])['id'])} >
                                    <HeartFilled style={{ fontSize: '20px' }} />
                                    <FooterItemText >wishlist</FooterItemText>
                                    <FooterSmallText>wishlist</FooterSmallText>
                                </WishListButton>
                                : <WishListButton onClick={() => handleWishListClick()}>
                                    <HeartIcon size="20" />
                                    <FooterItemText >wishlist</FooterItemText>
                                    <FooterSmallText>wishlist</FooterSmallText>
                                </WishListButton>)
                            : <WishListButton onClick={() => handleWishListClick()}>
                                <HeartIcon size="20" />
                                <FooterItemText >wishlist</FooterItemText>
                                <FooterSmallText>wishlist</FooterSmallText>
                            </WishListButton>
                        }

                    </MenuButton>


                    {
                        brandId !== null && brandUrlKey !== null &&
                        <MenuButton>
                            <Link to={`/brand-products/${brandUrlKey}/${brandId}`} >
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-brands.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                                <FooterItemText type="text" active>shop this brand</FooterItemText>
                                <FooterSmallText>shop this brand</FooterSmallText>
                            </Link>
                        </MenuButton>
                    }
                    {relatedProductsData && relatedProductsData.length > 0 && (
                        <MenuButton type="text" onClick={handleRelatedProduct}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-link.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>related products</FooterItemText>
                            <FooterSmallText>related products</FooterSmallText>
                        </MenuButton>
                    )}
                    <Dropdown overlay={menu} placement="topCenter" trigger="click" arrow>
                        <MenuButton type="text"  >

                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-share.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>share</FooterItemText>
                            <FooterSmallText>share</FooterSmallText>
                        </MenuButton>
                    </Dropdown>
                </>
            )
            }

            {
                props.isShop && (
                    <>
                        {/* <MenuButton style={{ borderTopLeftRadius: 19, borderBottomLeftRadius: 19 }} type="text" onClick={() => history.push("/products?type=new")}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-new.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>NEW</FooterItemText>
                        </MenuButton>
                        <MenuButton type="text" onClick={() => history.push("/products?type=offer")}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-price-tag.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>offers</FooterItemText>
                        </MenuButton>
                        <MenuButton type="text" onClick={() => history.push("/products?type=gift")}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-gift.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>gifting</FooterItemText>
                        </MenuButton> */}
                        {
                            display === false ? "" :
                                <MenuButton type="text" onClick={() => history.push("/categories")}>
                                    {" "}
                                    <a>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/ladu-brands.svg`}
                                            alt="ladu-india"
                                            width="20"
                                            height="20"
                                        />
                                    </a>
                                    <FooterItemText>shop </FooterItemText>
                                    <FooterSmallText>shop</FooterSmallText>
                                </MenuButton>
                        }
                        {/* <MenuButton type="text" onClick={() => history.push("/category/tapri-meals/167")}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/tapri-meals.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>tapri meals </FooterItemText>
                            <FooterSmallText>tapri meals</FooterSmallText>
                        </MenuButton>
                        <MenuButton type="text" onClick={() => history.push("/category/barni/156")}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/barni.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>barni</FooterItemText>
                            <FooterSmallText>barni</FooterSmallText>
                        </MenuButton> */}
                        <MenuButton type="text" onClick={() => history.push("/brands")}>
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-brands.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>

                            <FooterItemText>brands</FooterItemText>
                            <FooterSmallText>brands</FooterSmallText>

                        </MenuButton>
                        {
                            display === false ? "" :
                                <MenuButton style={{ borderTopRightRadius: 19, borderBottomRightRadius: 19 }} type="text" onClick={() => history.push("/backHome")}>
                                    {" "}
                                    <a>
                                        <img
                                            src={`${process.env.PUBLIC_URL}/images/ladu-more.svg`}
                                            alt="ladu-india"
                                        />
                                    </a>
                                    {/* <FooterItemText>more</FooterItemText> */}
                                    {/* <FooterSmallText>more</FooterSmallText> */}
                                </MenuButton>
                        }

                    </>
                )
            }
            {
                props.isProductList && (
                    <>
                        <MenuButton type="text">
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-dashboard.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>ladu points</FooterItemText>
                            <FooterSmallText>ladu points</FooterSmallText>
                        </MenuButton>
                        <MenuButton type="text">
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-sort.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>sort</FooterItemText>
                            <FooterSmallText>sort</FooterSmallText>
                        </MenuButton>
                        <MenuButton type="text">
                            {" "}
                            <a>
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-filter.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                            </a>
                            <FooterItemText>filter</FooterItemText>
                            <FooterSmallText>filter</FooterSmallText>
                        </MenuButton>
                    </>
                )
            }

            {
                props.isBackHeader && (
                    <>
                        <MenuButton style={{ borderTopLeftRadius: 19, borderBottomLeftRadius: 19 }} type="text">
                            {" "}
                            <a href="https://facebook.com/laduindia/" target="_blank">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-facebook.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                                <FooterItemText>Facebook</FooterItemText>
                                <FooterSmallText>facebook</FooterSmallText>
                            </a>
                        </MenuButton>
                        <MenuButton type="text">
                            {" "}
                            <a href="https://www.instagram.com/laduindia/?hl=en" target="_blank">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-Instagram.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                                <FooterItemText>Instagram</FooterItemText>
                                <FooterSmallText>instagram</FooterSmallText>
                            </a>
                        </MenuButton>
                        <MenuButton style={{ borderTopRightRadius: 19, borderBottomRightRadius: 19 }} type="text">
                            {" "}
                            <a href="https://tapri.net" target="_blank">
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-Tapri.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                />
                                <FooterItemText>Tapri</FooterItemText>
                                <FooterSmallText>tapri</FooterSmallText>
                            </a>
                        </MenuButton>
                        <MenuButton style={{ borderTopRightRadius: 19, borderBottomRightRadius: 19 }} type="text" onClick={() => history.push("/")}>
                            {" "}
                            <a >
                                <img
                                    src={`${process.env.PUBLIC_URL}/images/ladu-more.svg`}
                                    alt="ladu-india"
                                    width="20"
                                    height="20"
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                                {/* <FooterItemText>Back</FooterItemText> */}
                                {/* <FooterSmallText>back</FooterSmallText> */}
                            </a>
                        </MenuButton>
                    </>
                )
            }
            {
                visiable &&
                <Authentication
                    drawerState={visiable}
                    handleCloseDrawer={handleCloseDrawer}
                    handleCallbackClick={handleCallbackClick}
                />
            }
        </Container >
    );
};

const mapStateToProps = (state) => ({
    relatedProductsData: state.getRelatedProducts.relatedProductsData,
    addProductToWishListState: state.addProductToWishList,
    getWishListState: state.getWishList,
    deleteWishListState: state.deleteWishList,
    createCustomerCartState: state.createCustomerCart,
    getCartCountState: state.getCartCount
});

const mapDispatchToProps = (dispatch) => ({
    addProductToWishList: (params) => dispatch(addProductToWishList(params)),
    addProductToWishListReset: () => dispatch(addProductToWishListReset()),
    getWishList: (params) => dispatch(getWishList(params)),
    getWishListReset: () => dispatch(getWishListReset()),
    deleteWishList: (params) => dispatch(deleteWishList(params)),
    deleteWishListReset: () => dispatch(deleteWishListReset()),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
