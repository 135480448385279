import axios from "axios";
import { BASE_URL } from "../utils";


const guestSetPaymentMethodApi = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setPaymentMethodOnCart(input: {
            cart_id: "${params.cart_id}"
            payment_method: {
                code: "razorpay"
            }
        }) {
          cart {
            selected_payment_method {
              code
            }
          }
        }
      }`,
    }
  });
  return response;
};

export default guestSetPaymentMethodApi;
