import React, { useEffect, useState } from "react"
import { Button, Skeleton } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import _ from 'lodash'
import { getBrandDetails } from "../../actions/getBrandDetailsAction"

import { Left, Right, BrandButton } from "./BrandDetailsStyle"

const BrandDetails = props => {
    // variable
    const { getBrandDetails, getBrandDetailsState } = props
    // callbacks
    useEffect(() => {
        getBrandDetails({
            brand_url_key: props.match.params.brand_url_key
        })
    }, [])

    return (
        <>
            {/* <div style={{ height: 50 }} /> */}
            {getBrandDetailsState.apiState === "loading" &&
                <Skeleton active />
            }
            {getBrandDetailsState.apiState === "success" &&
                <div style={{ display: "flex", flexFlow: "wrap" }} >
                    <Left>
                        <div style={{ width: "100%" }} >
                            <Carousel showStatus={false} showIndicators={false} >
                                {_.filter(getBrandDetailsState.data.media_gallery, ['disabled', false]).map(item => {
                                    return (
                                        <div>
                                            <img src={item.url} />
                                        </div>
                                    )
                                })}
                            </Carousel>
                        </div>
                    </Left>
                    <Right>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: (getBrandDetailsState.data.description.html) || null
                            }}
                        />
                        <div>
                            <Link to={`/brand-products/${props.match.params.brand_url_key}/${getBrandDetailsState.data.brand_name}`}>
                                <BrandButton type="button" size="large" style={{ backgroundColor: '#f78c3e', color: '#fff', paddingLeft: 24, paddingRight: 24 }} >Shop This Brand</BrandButton>
                            </Link>
                        </div>
                    </Right>
                </div>
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    getBrandDetailsState: state.getBrandDetails,
});

const mapDispatchToProps = (dispatch) => ({
    getBrandDetails: (params) => dispatch(getBrandDetails(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetails);