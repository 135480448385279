import React, { useEffect, useState } from "react"
import { Button, Form, Input, Alert, notification } from "antd"
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import Lottie from "react-lottie"
import Authentication from '../../components/Authentication/Authentication'

// components
import { Container, Box } from "./ResetPasswordStyle"

// actions
import { resetPassword, resetPasswordReset } from '../../actions/resetPasswordAction'

// others
import { getCartId, setCartId, setCustomerToken } from '../../utils'

const ForgotPassword = props => {
    // variables
    const [redirect, setRedirect] = useState([false, ""])
    const guestCartId = getCartId()
    const [formData, setFormData] = useState({
        password: "",
    })
    const {
        resetPassword, resetPasswordReset, resetPasswordState,
    } = props
    let query = new URLSearchParams(useLocation().search)
    const email = query.get("email")
    const token = query.get("token")

    // callbacks
    useEffect(() => {
        return () => {
            // resetPasswordReset()
        }
    }, [])

    useEffect(() => {
        if (resetPasswordState.apiState === "error") {
            notification["error"]({
                message: resetPasswordState.message,
                placement: "bottomRight"
            });
        }

        if (resetPasswordState.apiState === "success") {
            notification["success"]({
                message: 'Password reset successfully.',
                placement: "bottomRight"
            });
            setRedirect([true, "/"])
        }
    }, [resetPasswordState])


    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        resetPassword({
            email: email,
            resetPasswordToken: token,
            password: formData.password
        })
    }
    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <Container>
                <Box>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} >
                        <h1 style={{ marginTop: 16 }} >Reset Password</h1>
                    </div>
                    <Form
                        layout="vertical"
                        onFinish={handleSubmit}
                    >
                        <Form.Item
                            name="email"
                        >
                            <Input name="email" defaultValue={email} readOnly />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                { required: true, message: 'Required' },
                            ]}
                        >
                            <Input.Password name="password" placeholder="Password" onChange={handleOnChange} />
                        </Form.Item>

                        <Form.Item name="confirm_password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Confirm your password',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('Your username and/or password do not match.');
                                    },
                                }),
                            ]}
                        >
                            <Input.Password name="confirm_password" placeholder="Confirm password" />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" block loading={resetPasswordState.apiState === "loading" ? true : false}  >SUBMIT</Button>
                    </Form>
                </Box>
            </Container>

        </>
    )
}

const mapStateToProps = (state) => ({
    resetPasswordState: state.resetPassword,
});

const mapDispatchToProps = (dispatch) => ({
    resetPassword: (params) => dispatch(resetPassword(params)),
    resetPasswordReset: () => dispatch(resetPasswordReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);