export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST'
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS'
export const GET_CATEGORY_LIST_FAIL = 'GET_CATEGORY_LIST_FAIL'

export const getCategoryList = (params) => {
    return { type: GET_CATEGORY_LIST, params }
}

export const getCategoryListSuccess = (response) => {
    return { type: GET_CATEGORY_LIST_SUCCESS, response }
}

export const getCategoryListFail = (response) => {
    return { type: GET_CATEGORY_LIST_FAIL, response }
}




export const TOGGLE_CATEGORY = 'TOGGLE_CATEGORY'

export const toggleCategory = () => {
    return { type: TOGGLE_CATEGORY }
}