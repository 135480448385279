import axios from "axios";
import { BASE_URL, isAuthorized } from "../utils";

const setBillingAddressApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setBillingAddressOnCart(
          input: {
            cart_id: "${data.data.cartId}"
            billing_address: {
              
                address: {
                  firstname: "${data.data.firstname}"
                  lastname: "${data.data.lastname}"
                  street: ["${data.data.street}"]
                  city: "${data.data.city}"
                  region: "${data.data.region}"
                  postcode:"${data.data.postcode}"
                  country_code: "${data.data.country_code}"
                  telephone: "${data.data.telephone}"
                  save_in_address_book: true
                }
              }
            
          }
        ) {
          cart {
            billing_address {
              firstname
              lastname
              company
              street
              city
              region {
                code
                label
              }
              postcode
              telephone
              country {
                code
                label
              }
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default setBillingAddressApi;
