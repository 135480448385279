import React, { useState, useEffect } from "react";
import { Link, withRouter, useLocation, Redirect } from "react-router-dom";
import {
    HeaderContainer,
    LeftSection,
    RightSection,
    ImageContainer,
    LogoContainer,
    TabsComponent,
    DropdownTitle,
    LogoContainerLink
} from "./style";
import { Dropdown, Menu } from "antd"
import NewsBannerHeader from "../NewsBannerHeader/NewsBannerHeader";

export const BackHeader = (props) => {
    // variables
    const [activeTab, setActiveTab] = useState("");
    const { TabPane } = TabsComponent;
    let query = new URLSearchParams(useLocation().search)
    let section_key = query.get("r")
    const [isAboutActive, setIsAboutActive] = useState(false)
    const [redirect, setRedirect] = useState([false, "/"])
    const location = useLocation()
    const currentPage = location.pathname
    const isMobile = window.innerWidth < 768;
    const [newsBanner, setNewsBanner] = useState(localStorage.getItem('newsbanner'))

    // customer component
    const aboutMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/about?r=about-ladu"  >
                    About Us
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/about?r=contact-us"  >
                    Contact Us
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/about?r=concierge" >
                    Concierge
                </Link>
            </Menu.Item>
        </Menu>
    );

    const helpMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/help-shipping/p"  >
                    Shipping
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/returns/p"  >
                    Returns
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/faq/p"  >
                    FAQ
                </Link>
            </Menu.Item>
        </Menu>
    );

    const policiesMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/terms-of-use/p"  >
                    Terms of Use
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/privacies-privacy-policy/p"  >
                    Privacy Policy
                </Link>
            </Menu.Item>
        </Menu>
    );

    const partnerMenu = (
        <Menu>
            <Menu.Item>
                <Link to="/become-a-vendor/p"  >
                    Become a Vendor
                </Link>
            </Menu.Item>
            <Menu.Item>
                <Link to="/advertise/p"  >
                    Advertise/Bulk Orders
                </Link>
            </Menu.Item>
            {/* <Menu.Item>
                <Link to="/about?r=bulk-orders"  >
                    Bulk Orders
                </Link>
            </Menu.Item> */}
        </Menu>
    )

    useEffect(() => {
        if (section_key) {
            handleScroll(section_key)
        }
    }, [section_key])

    useEffect(() => {
        setRedirect([false, "/"])
        if (currentPage === "/help") {
            setActiveTab("help")
            setIsAboutActive(false)
        } else if (currentPage === "/policies") {
            setActiveTab("policies")
            setIsAboutActive(false)
        } else if (currentPage === "/partner") {
            setActiveTab("partner")
            setIsAboutActive(false)
        } else if (currentPage === "/about") {
            setIsAboutActive(true)
            setActiveTab("")
        } else {
            setActiveTab("")
            setIsAboutActive(false)
        }
    }, [currentPage])

    const handleTabChange = key => {
        if (key === "help") {
            setRedirect([true, "/help"])
        }
        if (key === "policies") {
            setRedirect([true, "/policies"])
        }
        if (key === "partner") {
            setRedirect([true, "/partner"])
        }
    }

    const handleScroll = (section_key) => {
        if (document.querySelector(`#${section_key}`)) {
            document.querySelector(`#${section_key}`).scrollIntoView({
                behavior: 'smooth',
                block: "start"
            })
        }
    }

    const handleCallbackBanner = () => {
        setNewsBanner(localStorage.getItem('newsbanner'))
        props.handleCallbackBannerAppJs()
    }


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
            {newsBanner !== "true" &&
                <NewsBannerHeader handleCallbackBanner={handleCallbackBanner} />
            }
            <HeaderContainer height={newsBanner ? '0px' : '44px'}>
                <LeftSection>
                    <LogoContainerLink to="/">
                        <LogoContainer>
                            {" "}
                            <img
                                src={`${process.env.PUBLIC_URL}/images/Logo.svg`}
                                alt="ladu-india"
                                width="80"
                                style={{ marginTop: 20 }}
                            />
                        </LogoContainer>
                    </LogoContainerLink >
                </LeftSection>
                <RightSection>
                    <Dropdown overlay={aboutMenu} trigger={["click", "hover"]}  >
                        <DropdownTitle>about</DropdownTitle>
                    </Dropdown>
                    <Dropdown overlay={helpMenu} trigger={["click", "hover"]}  >
                        <DropdownTitle>help</DropdownTitle>
                    </Dropdown>
                    <Dropdown overlay={policiesMenu} trigger={["click", "hover"]}  >
                        <DropdownTitle>policies</DropdownTitle>
                    </Dropdown>
                    <Dropdown overlay={partnerMenu} trigger={["click", "hover"]}  >
                        {isMobile ?
                            <DropdownTitle>partner</DropdownTitle>
                            : <DropdownTitle>partner</DropdownTitle>
                        }
                    </Dropdown>
                </RightSection>
            </HeaderContainer>
        </>
    );
};

export default withRouter(BackHeader);
