export const GUEST_BILLING_ADDRESS = "GUEST_BILLING_ADDRESS"
export const GUEST_BILLING_ADDRESS_SUCCESS = "GUEST_BILLING_ADDRESS_SUCCESS"
export const GUEST_BILLING_ADDRESS_FAIL = "GUEST_BILLING_ADDRESS_FAIL"
export const GUEST_BILLING_ADDRESS_RESET = "GUEST_BILLING_ADDRESS_RESET"

export const guestBillingAddress = (params) => {
    return { type: GUEST_BILLING_ADDRESS, params }
}
export const guestBillingAddressSuccess = (response) => {
    return { type: GUEST_BILLING_ADDRESS_SUCCESS, response }
}
export const guestBillingAddressFail = (response) => {
    return { type: GUEST_BILLING_ADDRESS_FAIL, response }
}
export const guestBillingAddressReset = () => {
    return { type: GUEST_BILLING_ADDRESS_RESET }
}