import { call, put, takeLatest } from 'redux-saga/effects'

// Action
import { DELETE_WISH_LIST, deleteWishListSuccess, deleteWishListFail } from '../actions/deleteWishListAction'

// Api
import deleteWishListApi from '../apis/deleteWishListApi'


export function* deleteWishListSaga(action) {
    try {
        const response = yield call(() => deleteWishListApi(action.params))
        if (response.errors) {
            yield put(deleteWishListFail(response, action))
        }
        else {
            yield put(deleteWishListSuccess(response, action))
        }
    }
    catch (e) {
        yield put(deleteWishListFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(DELETE_WISH_LIST, deleteWishListSaga)
}