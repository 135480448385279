import axios from "axios";
import { isAuthorized,BASE_URL } from "../utils";

const brandApi = async () => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
        customAttributeMetadata(
          attributes: [
            {
              attribute_code: "brand_name"
              entity_type: "catalog_product"
            }
          ]
        ) {
          items {
            
            attribute_code
            attribute_type
            entity_type
            input_type
            attribute_options {
             value
             label
           }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default brandApi;
