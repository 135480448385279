import styled, { css } from "styled-components";
import { Input, Button, Dropdown, Tabs, AutoComplete, Drawer } from "antd";
import { SearchOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";

export const AutoCompleteDropDown = styled(AutoComplete)`
.ant-input-affix-wrapper{
  border-radius: 50px;
}

`
export const InputSearchIcon = styled(SearchOutlined)`
  margin-right: 10px;
  font-size: 20px;

 @media screen and (max-width: 400px){
    display: none;
}
`
export const Container = styled.section`
  // max-width: 1400px;
  padding: 0 20px;
  height: 64px;
  /* background-color: #ffffff; */
  position: fixed;
  top: ${(props) => props.height};
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  align-items: center;
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
  //    padding: 0 1rem;
  // @media ${(props) => props.theme.device.tablet} {
  //   height: 125px;
  //   flex-direction: column;
  //   z-index: 9;
  // }

/* @media screen and (max-width: 400px){
    padding: 0 10px;
} */

`;

export const SearchInput = styled(Input)`
  width: 450px;
  height: 48px;
  border-radius: 11px;
  border: solid 1px #727271;
  background-color: #ffffff;
  && .ant-input-group-wrapper {
  width:450px !important;
  }
`;
export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media ${(props) => props.theme.device.tablet} {
//    display: none;
  }
`;
export const SearchButton = styled.div`
  margin-right: 15px;
  display: none;
  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;

  /* @media ${(props) =>
    props.theme.device.laptop} {
         margin-left: -10px;
    } */

  @media ${(props) => props.theme.device.laptop} {
    ${SearchInput} {
      display: none;
    }
  }
`;



export const ItemSection = styled.div`
  display: flex;
  align-items: center;

  // @media ${(props) => props.theme.device.tablet} {
  //     ${MenuContainer} {
  //         display: none;
  //     }
  // }

`;
export const InputSearch = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0px 10px;

  @media screen and (max-width: 400px){
    margin: 0px;
    margin-left: 10px;

  & ::placeholder{
    font-size: 12px;
  }
}
`
export const SearchBlock = styled.div`
  display: flex;
  align-items: center ;
  justify-content: center;
  width: 100%;
`
export const Arrow = styled.div`
  display: none;
  @media screen and (min-width: 200px ) and (max-width: 768px){
    display: block;
    margin-right: 2px;
  }
  img{
    width: 20px;
    height: 20px;
  }
`

export const RightSection = styled.div`
  display: flex;
  align-items: center;

  // @media ${(props) => props.theme.device.tablet} {
  //     ${MenuContainer} {
  //         display: none;
  //     }
  // }
 
`;

export const LinkButton = styled(Button)`
  font-size: 12px;
  color: #3b3b3b;
  padding: 0px 10px;

  ${(props) =>
    props.active &&
    css`
      font-weight: bold;
    `}
`;

export const SearchAutocompleteInput = styled(Input)`
  .ant-input-lg{
      font-size: 14px;
  }
`
export const SearchBtn = styled(Button)`
    position: absolute;
    right: 0px;
    font-size: 14px;
    padding: 0 20px;
    /* margin: 3px; */
    margin-right: 3px;

    @media screen and (max-width: 400px){
    /* padding: 5px !important; */
    /* font-size: 12px; */
  }

`
export const SearchIconBtn = styled(Button)`
  position: absolute;
 right: 0px;
    font-size: 14px;
    /* padding: 0 20px; */
   margin-right: 3px;
    @media screen and (max-width: 400px){
    /* padding: 15px !important; */
      /* width: 40px; */
      /* padding: 0; */
  }

`

export const CurrencyDropdown = styled(Dropdown)`
  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
`;

export const AddToCart = styled.div`
  display: flex;
flex-flow: nowrap;
@media ${(props) => props.theme.device.tablet} {
//   display: none;
}`;

export const TabsComponent = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0px;
  }
  &.ant-tabs {
    height: 64px;
  }
  &.ant-tabs > .ant-tabs-nav {
    height: 100%;
  }
  && .ant-tabs-tab {
    margin: 0 40px 0 0;
    font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
    // font-weight: ${(props) => props.theme.fontWeightBold};
    font-stretch: ${(props) => props.theme.fontStretch};
    font-style: ${(props) => props.theme.fontStyle};
    font-family: ${(props) => props.theme.fontFamily};
    line-height: 1.5;
    letter-spacing: normal;
    
  }
  && .ant-tabs-tab-btn {
    // color: ${(props) => props.theme.color.white};
  }
  && .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    // color: ${(props) => props.theme.color.white};
  }
  && .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
    font-stretch: ${(props) => props.theme.fontStretch};
    font-style: ${(props) => props.theme.fontStyle};
    font-family: ${(props) => props.theme.fontFamily};
    line-height: 1.5;
    color: ${(props) => props.theme.color.black};
    font-weight: ${(props) => props.theme.fontWeightBold};
  }
  && .ant-tabs-tab.ant-tabs-tab-hover .ant-tabs-tab-btn{
    font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
    font-stretch: ${(props) => props.theme.fontStretch};
    font-style: ${(props) => props.theme.fontStyle};
    font-family: ${(props) => props.theme.fontFamily};
    line-height: 1.5;
    color: ${(props) => props.theme.color.black};
    font-weight: ${(props) => props.theme.fontWeightBold};
  }
  &&.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: unset;
    content: "";
  }
  && .ant-tabs-ink-bar {
    background: #f78c3e;
    height: 4px;
    border-radius: 50px;
  }

  @media ${(props) => props.theme.device.tablet} {
    // display: none;
  }
`;


export const ItemContent = styled.span`
margin-right:25px;
.ant-badge-count-sm{
  min-width: 18px;
    height: 18px;
    padding: 0;
    font-size: 10px;
    line-height: 18px;
    border-radius: 49px;
}
`

export const DrawerContainer = styled(Drawer)`
.ant-drawer-body{
   @media ${(props) => props.theme.device.tablet} {
  padding: 0;
  }
}
.ant-drawer-content-wrapper{
  width:54% !important;
  float: right;
  position: relative;
  @media ${(props) => props.theme.device.tablet} {
    width:100% !important;

  }
}

`


export const HamburgerContainer = styled.span`
display:none;
@media ${(props) => props.theme.device.tablet} {
   display:  ${(props) => (props.hide ? "none" : "block")};
}
`
export const LogoContainerLink = styled(Link)`
&:hover {
      img {
          transform: scale(1.05);
          transition: transform 0.3s ease-in-out 0s;
      }
  }

 img{
   transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
  }

`

export const HideSearch = styled.span`
 display: flex;
flex-flow: nowrap;
@media ${(props) => props.theme.device.tablet} {
  display: ${(props) => (props.hide && "none")};
}
`
export const DropdownContainer = styled(Dropdown)`
  // &&
  //   .ant-dropdown-menu.ant-dropdown-menu-light.ant-dropdown-menu-root.ant-dropdown-menu-vertical {
  //   padding-top: 20px !important;
  // }
  // & .ant-dropdown ant-dropdown-placement-bottomLeft {

  // }
`;

export const ProfileDropDownName = styled.p`
  margin: 0;
  cursor: pointer;
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeight};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.81;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  padding-left:7px;
  &:hover{
    background: #f5f5f5;
  }
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeHeading};
    margin: 0px 0px 20px 0px;
  }
`;
