export const FETCH_GUEST_CART_DATA = 'FETCH_GUEST_CART_DATA'
export const FETCH_GUEST_CART_DATA_SUCCESS = 'FETCH_GUEST_CART_DATA_SUCCESS'
export const FETCH_GUEST_CART_DATA_FAIL = 'FETCH_GUEST_CART_DATA_FAIL'
export const FETCH_GUEST_CART_DATA_RESET = 'FETCH_GUEST_CART_DATA_RESET'

export const fetchGuestCartData = (params) => {
    return { type: FETCH_GUEST_CART_DATA, params }
}

export const fetchGuestCartDataSuccess = (response) => {

    return { type: FETCH_GUEST_CART_DATA_SUCCESS, response }
}

export const fetchGuestCartDataFail = (response) => {
    return { type: FETCH_GUEST_CART_DATA_FAIL, response }
}

export const fetchGuestCartDataReset = () => {
    return { type: FETCH_GUEST_CART_DATA_RESET }
}