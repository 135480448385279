export const CHECK_SERVICE_AVAILABILITY = "CHECK_SERVICE_AVAILABILITY"
export const CHECK_SERVICE_AVAILABILITY_SUCCESS = "CHECK_SERVICE_AVAILABILITY_SUCCESS"
export const CHECK_SERVICE_AVAILABILITY_FAIL = "CHECK_SERVICE_AVAILABILITY_FAIL"
export const CHECK_SERVICE_AVAILABILITY_RESET = "CHECK_SERVICE_AVAILABILITY_RESET"


export const checkServiceAvailability = (params) => {
    return { type: CHECK_SERVICE_AVAILABILITY, params }
}
export const checkServiceAvailabilitySuccess = (response) => {
    return { type: CHECK_SERVICE_AVAILABILITY_SUCCESS, response }
}
export const checkServiceAvailabilityFail = (response) => {
    return { type: CHECK_SERVICE_AVAILABILITY_FAIL, response }
}
export const checkServiceAvailabilityReset = () => {
    return { type: CHECK_SERVICE_AVAILABILITY_RESET }
}
