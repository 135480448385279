import {
    FETCH_PLACE_ORDER_REQUEST,
    FETCH_PLACE_ORDER_SUCCESS,
    FETCH_PLACE_ORDER_FAIL,
} from "../actions/fetchPlaceOrderAction";
import get from 'lodash/get';

const initialState = {
    apiState: "", // loading, success, error 
    placeOrderData: null
};

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_PLACE_ORDER_REQUEST:
            return {
                ...state,
                apiState: "loading"
            };

        case FETCH_PLACE_ORDER_FAIL:
            return {
                ...state,
                apiState: "error"
            };

        case FETCH_PLACE_ORDER_SUCCESS:
            return {
                ...state,
                apiState: "success"
            };

        default:
            return state;
    }
}
