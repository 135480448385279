import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';


import {
    FETCH_PLACE_ORDER_REQUEST,
    fetchPlaceOrderSuccess,
    fetchPlaceOrderFail,
} from '../actions/fetchPlaceOrderAction'

import fetchPlaceOrderApi from '../apis/fetchPlaceOrderApi';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(...otherParams);
    }
}

export function* fetchPlaceOrderSaga(action) {

    try {
        const response = yield call(() => fetchPlaceOrderApi(action))
        if (response.errors) {
            yield put(fetchPlaceOrderFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(fetchPlaceOrderSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(fetchPlaceOrderFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(FETCH_PLACE_ORDER_REQUEST, fetchPlaceOrderSaga);
}