import styled from "styled-components";

export const Container = styled.div`
height: 100%;
display: flex;
align-items: center;
justify-content: center;
margin-top: 100px;
@media ${(props) => props.theme.device.tablet} {
    margin-top: 10px;
  }
`
export const PartnerHeading = styled.div`
color:${(props) => props.theme.color.black}
`

