import axios from "axios";
import { BASE_URL } from "../utils";

const getProductListApi = async (params) => {
    let filter = ""
    if (params.category_id) {
        filter = `{category_id: {eq: "${params.category_id}"}}`
    }

    if (params.brand_id) {
        filter = `{brand_name: {eq: "${params.brand_id}"}}`
    }

    if (params.product_tag) {
        if (params.product_tag === "new") {
            filter = `{product_tag:{eq:"67"}}`
        }
        if (params.product_tag === "offer") {
            filter = `{product_tag:{eq:"66"}}`
        }
        if (params.product_tag === "gift") {
            filter = `{product_tag:{eq:"68"}}`
        }
    }

    let sort = ""
    if (params.sortDirection) {
        sort = `sort:{ price:${params.sortDirection} }`
    }

    let cancelToken
    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source()

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        cancelToken: cancelToken.token,
        data: {
            query: `
                {
                    products(filter: ${filter},currentPage:${params.currentPage}, pageSize: 20, ${sort}) {
                        total_count
                        page_info {
                            current_page
                            page_size
                            total_pages
                        }
                        items {
                             ... on CustomizableProductInterface {
                                __typename

                                options {
                                ... on CustomizableFieldOption {
                                    title
                                }
                                ... on CustomizableRadioOption{
                                    title

                                }
                                }
                            }
                            id
                            name
                            sku
                            url_key
                            brand_name
                            product_tag
                            is_brand
                            image {
                                url
                            }
                            thumbnail {
                                position
                                url
                            }
                            special_price
                            price_range{
                                minimum_price{      
                                    regular_price {
                                        value
                                    }
                                    discount{
                                        percent_off
                                    }
                                }
                            }
                        }
                    }
                }
            `,
        }
    });
    return response.data;
};

export default getProductListApi;
