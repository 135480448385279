export const GET_BRAND_DETAILS = 'GET_BRAND_DETAILS'
export const GET_BRAND_DETAILS_SUCCESS = 'GET_BRAND_DETAILS_SUCCESS'
export const GET_BRAND_DETAILS_FAIL = 'GET_BRAND_DETAILS_FAIL'
export const GET_BRAND_DETAILS_RESET = 'GET_BRAND_DETAILS_RESET'

export const getBrandDetails = (params) => {
    return { type: GET_BRAND_DETAILS, params }
}

export const getBrandDetailsSuccess = (response) => {
    return { type: GET_BRAND_DETAILS_SUCCESS, response }
}

export const getBrandDetailsFail = (response) => {
    return { type: GET_BRAND_DETAILS_FAIL, response }
}

export const getBrandDetailsReset = () => {
    return { type: GET_BRAND_DETAILS_RESET }
}