import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    ADD_PRODUCT_TO_CART,
    addProductToCartSuccess,
    addProductToCartFail,
} from '../actions/addProductToCartAction'

import addProductToCartApi from '../apis/addProductToCartApi'


export function* addProductToCartSaga(action) {
    try {
        const response = yield call(() => addProductToCartApi(action.params))
        if (response.errors) {
            yield put(addProductToCartFail(response, action))
        } else {
            yield put(addProductToCartSuccess(response, action))
        }
    } catch (e) {
        yield put(addProductToCartFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(ADD_PRODUCT_TO_CART, addProductToCartSaga);
}