import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    SET_BILLING_ADDRESSS_REQUEST,
    setBillingAddressSuccess,
    setBillingAddressFail,
} from '../actions/setBillingAddressAction'


import setBillingAddressApi from '../apis/setBillingAddressApi'
import get from 'lodash/get';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}

export function* setBillingAddressSaga(action) {
    try {
        const response = yield call(() => setBillingAddressApi(action.params))
        if (response.errors) {
            yield put(setBillingAddressFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(setBillingAddressSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(setBillingAddressFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SET_BILLING_ADDRESSS_REQUEST, setBillingAddressSaga);
}