import styled from "styled-components"

export const Li = styled.li`
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 8px;

    &:hover {
        background: #e3d1c780;
    }
`