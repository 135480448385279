import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'

import reducer from './reducer'

//Sagas
import categorySaga from './sagas/categorySaga'
import productSaga from './sagas/productSaga'
import productListSaga from './sagas/productListSaga'
import cartIdSaga from './sagas/cartIdSaga';
import productCartSaga from './sagas/productCartSaga';
import removeItemCartSaga from './sagas/removeItemCartSaga';
import updateProductCartSaga from './sagas/updateCartSaga';
import signUpSaga from './sagas/signUpSaga';
import loginSaga from './sagas/loginSaga';
import fetchCartDataSaga from './sagas/fetchCartSaga';
import getMergeIdSaga from './sagas/mergeCartSaga';
import getCustomerIdSaga from './sagas/customerCartIdSaga';
import fetchStateSaga from './sagas/fetchStateSaga';
import addAddressSaga from './sagas/addAddressSaga';
import fetchAddressSaga from './sagas/fetchAddressSaga';
import fetchBrandSaga from './sagas/fetchBrandSaga';
import fetchSearchSaga from './sagas/fetchSearchSaga';
import setShippingSaga from './sagas/setShippingSaga';
import fetchShippingAmountSaga from './sagas/fetchShippingAddressSaga';
import removeCouponSaga from './sagas/removeCouponSaga';
import addCouponSaga from './sagas/addCouponSaga';
import fetchPlaceOrderSaga from './sagas/fetchPlaceOrderSaga';
import fetchPaymentMethodSaga from './sagas/fetchPaymentMethodSaga';
import setBillingAddressSaga from './sagas/setBillingAddressSaga';
import fetchOrderSaga from './sagas/fetchOrdersSaga';
import updateAddressSaga from './sagas/updateAddressSaga';
import fetchAboutSaga from './sagas/fetchAboutSaga';
import fetchHelpCmsSaga from './sagas/fetchHelpCmsSaga';
import fetchPartnerCmsSaga from './sagas/fetchPartnerCmsSaga';
import fetchPolicyCmsSaga from './sagas/fetchPolicyCmsSaga';
import addWishListSaga from './sagas/addWishListSaga';
import removeWishListSaga from './sagas/removeWishListSaga';
import createGuestCartSaga from './sagas/createGuestCartSaga';
import addProductToCartSaga from './sagas/addProductToCartSaga';
import createCustomerCartSaga from './sagas/createCustomerCartSaga';
import mergeCartsSaga from './sagas/mergeCartsSaga';
import getCartCountSaga from './sagas/getCartCountSaga';
import getCustomerInfoSaga from './sagas/getCustomerInfoSaga';
import getProductListSaga from './sagas/getProductListSaga';
import getBrandInfoSaga from './sagas/getBrandInfoSaga';
import createCustomerSaga from './sagas/createCustomerSaga';
import requestPasswordResetEmailSaga from './sagas/requestPasswordResetEmailSaga';
import resetPasswordSaga from './sagas/resetPasswordSaga';
import updateCustomerInfoSaga from './sagas/updateCustomerInfoSaga';
import changeCustomerPasswordSaga from './sagas/changeCustomerPasswordSaga';
import deleteCustomerAddressSaga from './sagas/deleteCustomerAddressSaga';
import createCustomerAddressSaga from './sagas/createCustomerAddressSaga';
import getCmsPageSaga from './sagas/getCmsPageSaga';
import getTopProductSaga from './sagas/getTopProductSaga';
import checkDeliverySaga from './sagas/checkDeliverySaga';
import addProductToWishListSaga from './sagas/addProductToWishListSaga';
import getWishListSaga from './sagas/getWishListSaga'
import deleteWishList from './sagas/deleteWishListSaga'
import getCartDataSaga from './sagas/getCartDataSaga'
import updateCartProductSaga from './sagas/updateCartProductSaga'
import removeCartProductSaga from './sagas/removeCartProductSaga'
import applyCouponToCartSaga from './sagas/applyCouponToCartSaga'
import removeCouponToCartSaga from './sagas/removeCouponToCartSaga'
import salesPlaceOrderSaga from './sagas/salesPlaceOrderSaga'
import setPaymentMethodSaga from './sagas/setPaymentMethodSaga'
import getShippigoTokenSaga from './sagas/getShippigoTokenSaga'
import checkServiceAvailabilitySaga from './sagas/checkServiceAvailabilitySaga'
import billingAddressSaga from './sagas/billingAddressSaga'
import setShippingAddressSaga from './sagas/setShippingAddressSaga'
import setShippingMethodSaga from './sagas/setShippingMethodSaga'
import getBrandListSaga from './sagas/getBrandListSaga'
import getBrandDetailsSaga from './sagas/getBrandDetailsSaga'
import getFeaturedImageListSaga from './sagas/getFeaturedImageListSaga'
import createGuestCustomerAddressSaga from './sagas/createGuestCustomerAddressSaga'
import fetchGuestStateSaga from './sagas/fetchGuestStateSaga'
import guestBillingAddressSaga from './sagas/guestBillingAddressSaga'
import guestSetShippingAddressSaga from './sagas/guestSetShippingAddressSaga'
import setGuestEmailCartSaga from './sagas/setGuestEmailCartSaga'
import getGuestShippigoTokenSaga from './sagas/getGuestShippigoTokenSaga'
import checkGuestServiceAvailabilitySaga from './sagas/checkGuestServiceAvailabilitySaga'
import getGuestCartDataSaga from './sagas/getGuestCartDataSaga'
import fetchGuestCartDataSaga from './sagas/fetchGuestCartDataSaga'
import guestRemoveCartProductSaga from './sagas/guestRemoveCartProductSaga'
import guestApplyCouponToCartSaga from './sagas/guestApplyCouponToCartSaga'
import guestRemoveCouponToCartSaga from './sagas/guestRemoveCouponToCartSaga'
import guestSetPaymentMethodSaga from './sagas/guestSetPaymentMethodSaga'
import guestSalesPlaceOrderSaga from './sagas/guestSalesPlaceOrderSaga'
import updateCustomerAddressSaga from './sagas/updateCustomerAddressSaga'
import placeRazorpayOrderSaga from './sagas/placeRazorpayOrderSaga'
import setRzpPaymentDetailsOnCartSaga from './sagas/setRzpPaymentDetailsOnCartSaga'
import fetchBrandByIdSaga from './sagas/fetchBrandByIdSaga'
import addConfigrableProductSaga from './sagas/addConfigrableProductSaga'
import subscribeEmailSaga from './sagas/subscribeEmailSaga'
import showOnHomePageBurniSectionSaga from './sagas/showOnHomePageBurniSectionSaga'
import showOnHomePageBrandSectionSaga from './sagas/showOnHomePageBrandSectionSaga'
import homePageBannerSaga from './sagas/homePageBannerSaga'
import homePageBannerMobileSaga from './sagas/homePageBannerMobileSaga'
import reOrderSaga from './sagas/reOrderSaga'


// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
// mount it on the Store
const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(sagaMiddleware)
    )
)


// then run the saga
sagaMiddleware.run(categorySaga)
sagaMiddleware.run(productSaga)
sagaMiddleware.run(productListSaga)
sagaMiddleware.run(cartIdSaga)
sagaMiddleware.run(productCartSaga)
sagaMiddleware.run(removeItemCartSaga)
sagaMiddleware.run(updateProductCartSaga)
sagaMiddleware.run(signUpSaga)
sagaMiddleware.run(loginSaga)
sagaMiddleware.run(fetchCartDataSaga)
sagaMiddleware.run(getMergeIdSaga)
sagaMiddleware.run(getCustomerIdSaga)
sagaMiddleware.run(fetchStateSaga)
sagaMiddleware.run(addAddressSaga)
sagaMiddleware.run(fetchAddressSaga)
sagaMiddleware.run(fetchBrandSaga)
sagaMiddleware.run(fetchSearchSaga)
sagaMiddleware.run(setShippingSaga)
sagaMiddleware.run(fetchShippingAmountSaga)
sagaMiddleware.run(removeCouponSaga)
sagaMiddleware.run(addCouponSaga)
sagaMiddleware.run(fetchPlaceOrderSaga)
sagaMiddleware.run(fetchPaymentMethodSaga)
sagaMiddleware.run(setBillingAddressSaga)
sagaMiddleware.run(fetchOrderSaga)
sagaMiddleware.run(updateAddressSaga)
sagaMiddleware.run(fetchAboutSaga)
sagaMiddleware.run(fetchHelpCmsSaga)
sagaMiddleware.run(fetchPartnerCmsSaga)
sagaMiddleware.run(fetchPolicyCmsSaga)
sagaMiddleware.run(addWishListSaga)
sagaMiddleware.run(removeWishListSaga)
sagaMiddleware.run(createGuestCartSaga)
sagaMiddleware.run(addProductToCartSaga)
sagaMiddleware.run(createCustomerCartSaga)
sagaMiddleware.run(mergeCartsSaga)
sagaMiddleware.run(getCartCountSaga)
sagaMiddleware.run(getCustomerInfoSaga)
sagaMiddleware.run(getProductListSaga)
sagaMiddleware.run(getBrandInfoSaga)
sagaMiddleware.run(createCustomerSaga)
sagaMiddleware.run(requestPasswordResetEmailSaga)
sagaMiddleware.run(resetPasswordSaga)
sagaMiddleware.run(updateCustomerInfoSaga)
sagaMiddleware.run(changeCustomerPasswordSaga)
sagaMiddleware.run(deleteCustomerAddressSaga)
sagaMiddleware.run(createCustomerAddressSaga)
sagaMiddleware.run(getCmsPageSaga)
sagaMiddleware.run(getTopProductSaga)
sagaMiddleware.run(checkDeliverySaga)
sagaMiddleware.run(addProductToWishListSaga)
sagaMiddleware.run(getWishListSaga)
sagaMiddleware.run(deleteWishList)
sagaMiddleware.run(getCartDataSaga)
sagaMiddleware.run(updateCartProductSaga)
sagaMiddleware.run(removeCartProductSaga)
sagaMiddleware.run(applyCouponToCartSaga)
sagaMiddleware.run(removeCouponToCartSaga)
sagaMiddleware.run(salesPlaceOrderSaga)
sagaMiddleware.run(setPaymentMethodSaga)
sagaMiddleware.run(getShippigoTokenSaga)
sagaMiddleware.run(checkServiceAvailabilitySaga)
sagaMiddleware.run(billingAddressSaga)
sagaMiddleware.run(setShippingAddressSaga)
sagaMiddleware.run(setShippingMethodSaga)
sagaMiddleware.run(getBrandListSaga)
sagaMiddleware.run(getBrandDetailsSaga)
sagaMiddleware.run(getFeaturedImageListSaga)
sagaMiddleware.run(createGuestCustomerAddressSaga)
sagaMiddleware.run(fetchGuestStateSaga)
sagaMiddleware.run(guestBillingAddressSaga)
sagaMiddleware.run(guestSetShippingAddressSaga)
sagaMiddleware.run(setGuestEmailCartSaga)
sagaMiddleware.run(getGuestShippigoTokenSaga)
sagaMiddleware.run(checkGuestServiceAvailabilitySaga)
sagaMiddleware.run(getGuestCartDataSaga)
sagaMiddleware.run(fetchGuestCartDataSaga)
sagaMiddleware.run(guestRemoveCartProductSaga)
sagaMiddleware.run(guestApplyCouponToCartSaga)
sagaMiddleware.run(guestRemoveCouponToCartSaga)
sagaMiddleware.run(guestSetPaymentMethodSaga)
sagaMiddleware.run(guestSalesPlaceOrderSaga)
sagaMiddleware.run(updateCustomerAddressSaga)
sagaMiddleware.run(placeRazorpayOrderSaga)
sagaMiddleware.run(setRzpPaymentDetailsOnCartSaga)
sagaMiddleware.run(fetchBrandByIdSaga)
sagaMiddleware.run(addConfigrableProductSaga)
sagaMiddleware.run(subscribeEmailSaga)
sagaMiddleware.run(showOnHomePageBurniSectionSaga)
sagaMiddleware.run(showOnHomePageBrandSectionSaga)
sagaMiddleware.run(homePageBannerSaga)
sagaMiddleware.run(homePageBannerMobileSaga)
sagaMiddleware.run(reOrderSaga)


export default store