import {
    SHOW_ON_HOME_PAGE_BURNI_SECTION,
    SHOW_ON_HOME_PAGE_BURNI_SECTION_FAIL,
    SHOW_ON_HOME_PAGE_BURNI_SECTION_SUCCESS,
    SHOW_ON_HOME_PAGE_BURNI_SECTION_RESET,
} from "../actions/showOnHomePageBurniSectionAction";

const initialState = {
    apiState: "",
    data: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SHOW_ON_HOME_PAGE_BURNI_SECTION:
            return {
                ...state,
                apiState: "loading"
            };

        case SHOW_ON_HOME_PAGE_BURNI_SECTION_FAIL:
            return {
                ...state,
                apiState: "error"
            };
        case SHOW_ON_HOME_PAGE_BURNI_SECTION_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response.data
            };

        case SHOW_ON_HOME_PAGE_BURNI_SECTION_RESET:
            return initialState

        default:
            return state;
    }
}
