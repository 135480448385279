import styled, { css } from "styled-components";
import { Tabs } from "antd";
import { Link } from "react-router-dom";

export const HeaderContainer = styled.div`
  padding: 0 10px;
  height: 64px;
  /* background: ${(props) => props.theme.backgroundColor.white}; */
  position: fixed;
  top: ${(props) => props.height};
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255, 255, 255, 0.72);
  z-index: 999;
`;
export const LogoContainerLink = styled(Link)`
&:hover {
      img {
          transform: scale(1.05);
          transition: transform 0.3s ease-in-out 0s;
      }
  }

 img{
   transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
  }

`

export const LeftSection = styled.div`
 // flex: 1;
  display: flex;
  justify-content: start;
  align-items: center;
`;

export const RightSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

export const ImageContainer = styled.div`
 margin-right: 14px;
`;

export const LogoContainer = styled.div`
  margin: 10px;
`;

export const TabsComponent = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0px;
  }
  &.ant-tabs {
    height: 64px;
  }
  &.ant-tabs > .ant-tabs-nav {
    height: 100%;
  }
  && .ant-tabs-tab {
    margin: 0 50px 0 0;
  }
  && .ant-tabs-tab-btn {
    color: ${(props) => props.theme.color.black};
  }
  && .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.color.black};
  }
  &&.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: unset;
    content: "";
  }
  && .ant-tabs-ink-bar {
    background: #f78c3e;
    height: 4px;
    border-radius: 50px;
  }
`;

export const DropdownTitle = styled.a`
  font-weight: 400;
  margin-right: 30px;
  color: #000;
  text-transform: capitalize;

  @media ${(props) => props.theme.device.tablet} {
   margin-right: 15px;
  }
`