import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Container, PolicyHeading } from "./style";
import { fetchPolicyCmsRequest } from "../../actions/fetchPolicyCmsAction";
import Footer from "../../components/Footer/Footer";


const Policies = (props) => {
    const { fetchPolicyCmsRequest, fetchPolicyCmsData, history } = props;

    useEffect(() => {
        fetchPolicyCmsRequest({
            errorAction: (data) => { },
            successAction: (data) => {
            },
        });
    }, []);

    return (
        <>
            <Container>
                <PolicyHeading>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (fetchPolicyCmsData && fetchPolicyCmsData.content) || null,
                        }}
                    />
                </PolicyHeading>
            </Container>
            <Footer isBackHeader history={history} />

        </>
    );
};

const mapStateToProps = (state) => ({
    fetchPolicyCmsData: state.policyCms.fetchPolicyCmsData,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPolicyCmsRequest: () => dispatch(fetchPolicyCmsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
