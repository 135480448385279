import {
    MERGE_CARTS,
    MERGE_CARTS_SUCCESS,
    MERGE_CARTS_FAIL,
    MERGE_CARTS_RESET,
} from '../actions/mergeCartsActions'


const initialState = {
    apiState: "", // loading, success, error
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case MERGE_CARTS:
            return {
                ...state,
                apiState: "loading",
            }

        case MERGE_CARTS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case MERGE_CARTS_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case MERGE_CARTS_RESET:
            return initialState

        default:
            return state
    }
}
