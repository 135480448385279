import {
    FETCH_GUEST_CART_DATA,
    FETCH_GUEST_CART_DATA_SUCCESS,
    FETCH_GUEST_CART_DATA_FAIL,
    FETCH_GUEST_CART_DATA_RESET
} from "../actions/fetchGuestCartDataAction";
import get from 'lodash/get';

const initialState = {
    cartDataItems: null,
    cartDataPrices: null,
    cartLoader: null,
    apiState: "",
    data: {}

};

export default function (state = initialState, action) {

    switch (action.type) {
        case FETCH_GUEST_CART_DATA:
            return {
                ...state,
                apiState: "loading",
                cartLoader: true
            };

        case FETCH_GUEST_CART_DATA_FAIL:
            return {
                ...state,
                apiState: "error",
                cartLoader: false,
                data: action.response.data.cart.items
            };

        case FETCH_GUEST_CART_DATA_SUCCESS:
            return {
                ...state,
                apiState: "success",
                // cartDataItems: get(action, "response.data.cart.items"),
                // cartDataPrices: get(action, "response.data.cart.prices"),
                data: action.response.data.cart,
                cartLoader: false
            };

        case FETCH_GUEST_CART_DATA_RESET:
            return initialState

        default:
            return state;
    }
}
