import React from "react"
import { Link } from "react-router-dom"
import { Container, SubContainer, ImgContainer, DetailContainer, Name, BrandCardWrapper } from './BrandCardStyle'

const BrandCard = props => {
    return (
        // <Link
        //     to={`/brand/${props.brand_id}`}
        //     style={{
        //         width: 250, background: "#f5f5f5",
        //         display: "flex", flexDirection: "column", alignItems: "center",
        //         margin: "8px", borderRadius: 8
        //     }}
        // >
        //     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 250, height: 250 }} >
        //         <img src={props.imgUrl} style={{ width: "90%", height: "auto" }} />
        //     </div>
        //     <div style={{ padding: 12, textTransform: "uppercase", fontWeight: 600, color: "#000" }}>{props.name}</div>
        // </Link>
        <Container>
            {/* <BrandCardWrapper> */}
            <Link to={`/brand/${props.url_key}`}>
                <SubContainer>
                    <ImgContainer>
                        <img src={props.imgUrl} alt="" style={{ maxWidth: "100%", height: "auto" }} />
                    </ImgContainer>
                    <DetailContainer>
                        <Name>{props.name}</Name>
                    </DetailContainer>
                </SubContainer>
            </Link>
            {/* </BrandCardWrapper> */}
        </Container>

    )
}

export default BrandCard