import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "antd";
import {
    Container,
    LeftSection,
    RightSection,
    ImageSkeleton,
    ImageSkeletonLeft,
    ImageSkeletonRight,
    LeftBlock,
    ImageSkeletonLeftRow,
    Row
} from "./GuestCheckoutSkeletonStyle";

const CheckoutSkeleton = (props) => {
    return (
        <Container>
            <LeftSection>
                <Row>
                    <ImageSkeletonLeft active />
                    <ImageSkeletonLeft active />
                </Row>
                <Row>
                    <ImageSkeletonLeft active />
                </Row>
                <Row>
                    <ImageSkeletonLeft active />
                </Row>
                <Row>
                    <ImageSkeletonLeft active />
                    <ImageSkeletonLeft active />
                </Row>
                <Row>
                    <ImageSkeletonLeft active />
                    <ImageSkeletonLeft active />
                </Row>
            </LeftSection>
            <RightSection>
                <ImageSkeletonRight value="square" active size={300} />
            </RightSection>
        </Container>
    );
};

CheckoutSkeleton.propTypes = {
    rows: PropTypes.number,
};

CheckoutSkeleton.defaultProps = {
    rows: 1,
};

export default CheckoutSkeleton;
