import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';

const getCustomerCartIdApi = async () => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
        customerCart{
          id
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default getCustomerCartIdApi;
