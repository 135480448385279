import styled from 'styled-components'

export const Container = styled.div`
   width: 100%;
    height: auto;
    font-family: 'Mandali',sans-serif;
    padding-bottom: 20px;
`

export const WishListCardContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`

export const CardContainer = styled.div`
  width: 47.7%;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
   background-color: #f8f8f8;
    padding: 15px;
    margin: 10px 0px;
    border-radius: 5px;
     @media ${props => props.theme.device.tablet} {
        width: 100%;
    }
`
export const Arrowwrapper = styled.div`
    display: flex;
    padding: 10px;
    width: max-content;
    align-items: center;

    & img{
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
    }

`;
export const WishListCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 15px;
    margin: 20px 0px;
    border-radius: 5px;
  flex-flow: row wrap;
  justify-content: space-around;

`
export const ProductInfo = styled.div`
    display: flex;
    align-items: center;
    width: 100%;


`
export const ProductPrice = styled.div`
width: 30%;
`
export const ArrowBlock = styled.div`
    display: block;
    float: left;
    border-radius: 50%;
    margin-right: 8px;

    &:hover{
    background-color: #e2e2e2;
  }
`
export const WishListFormWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 80vh;
    
`
export const WishListFormBlock = styled.div`
    width: 300px;
    height: 300px;
    padding: 20px;
    box-shadow: 1px 1px 5px #f5eded;
`
