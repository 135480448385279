import styled from "styled-components";
import { Card } from "antd";
export const CardContainer = styled.div``;

export const HeartContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right:10px;
  z-index: 9;

  cursor: pointer;
 // display:none;
  @media ${(props) => props.theme.device.tablet} {
    display: none;
  }
`;

export const RelatedProductBlock = styled.div`

.ant-drawer-body{
  background: red !important;
}
`

export const ProductContainer = styled.div`
  /* display: flex;
  align-items: strech;
  flex-wrap: wrap;
  height: ${(props) =>
    (props.noData === 0 && "unset")};
  justify-content: ${(props) => (props.noData == 0 ? "center" : "center")};
  max-width: 1000px;
  margin: auto;
  padding-bottom: 82px; */

    height: auto;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
    flex-wrap: wrap;


  // &:hover ${HeartContainer} {
  //   display: block;
  // }
  @media ${(props) => props.theme.device.tablet} {
    flex-direction: column;
    margin-bottom: 72px;

  }
`;

export const ProductCard = styled.div`
max-width: 270px;
`;
export const ProductCardItem = styled.div`
  position: relative;
    margin: 16px;

`;

export const ProductListName = styled.div`
  font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  //margin: 20px;
  @media ${(props) => props.theme.device.tablet} {
 //   margin: 10px;
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;

export const CardItem = styled(Card)`
&&.ant-card.ant-card-bordered.ant-card-hoverable{
       margin: 16px;
    position: relative;
}
&& .ant-card-body{
  padding: 5px 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: rgb(239,239,239) !important;
}
&& .ant-card-meta-title{
  padding:10px 10px 0 10px;
  font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
}
&&.ant-card-bordered .ant-card-cover img{
     height: 250px;
    overflow: hidden;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border: 1px solid rgb(239, 239, 239);
    border-radius: 8px 8px 0px 0px;
    @media ${(props) => props.theme.device.tablet} {
      height: auto;
      max-width:100%;
    }

}
`;

export const CardName = styled.div`
  padding: 5px 10px 10px 10px;
`;
// export const CardBodyContent = styled.div`
//   padding: 5px 10px;
//     background: rgba(234, 234, 234, 0.59);
//     border-bottom-left-radius: 8px;
//     border-bottom-right-radius: 8px;
// `;

export const CardPrice = styled.div`
padding: 0 10px 5px;
  font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;

export const BrandName = styled.div`
padding: 5px 10px 0 10px;
  font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  font-weight: ${(props) => props.theme.fontWeightBold};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
  }
`;

export const NoDataContianer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NoDataContent = styled.div``;

export const ProductHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin:20px 0px;
   @media ${(props) => props.theme.device.tablet} {
  //  justify-content: space-around;
    align-items: center;
    margin-top:0px;
  }
`;
export const ProductCount = styled.div`
 // margin: 20px;
  font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
  font-weight: ${(props) => props.theme.fontWeight};
  font-stretch: ${(props) => props.theme.fontStretch};
  font-style: ${(props) => props.theme.fontStyle};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-right: 12px;
  color: ${(props) => props.theme.color.black};
  @media ${(props) => props.theme.device.tablet} {
    font-size: ${(props) => props.theme.fontSize.fontSizeMobile};
 //   margin: 10px;
  }
`;

export const ArrowNameContainer = styled.div`
display:flex;
justify-content: center;
    align-items: center;
`


export const ArrowContainer = styled.div`
//margin:20px;
cursor:pointer;
margin-right:20px;
& img{
    border-radius: 50%;
    /* &:hover{
      background-color: #e2e2e2;
    } */
    padding: 10px;

    // @media ${(props) => props.theme.device.tablet} {
    //   padding-bottom: 5px;
    // }
}

`
