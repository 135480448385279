import styled, { css } from "styled-components";

export const Container = styled.section`
  height: 100%;
  width: 100%;
  background-color: rgb(254,254,254); 
  /* background-color: #f5f1ed; */
  //   max-width: 1400px;
`;
export const HomeContainer = styled.section`
  height: 100%;
  width: 100%;
  background-color: rgb(254,254,254); 
 padding-top: ${(props) => props.height}px;
  /* background-color: #f5f1ed; */
  //   max-width: 1400px;
`;
export const ProductDetailContainer = styled.section`
  height: 100%;
  width: 100%;
  background-color: #f3f3f3;
  //   max-width: 1400px;
`;

export const BodySection = styled.section`
  padding-top: ${(props) => props.height}px;
  padding-left:30px;
  padding-right:30px;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;



  /* @media ${(props) => props.theme.device.tablet} {
    padding-top: 15px;
    margin: 0;
    padding-left:10px;
    padding-right:10px;
  } */
  @media ${(props) => props.theme.device.mobileL} {
    padding-top: ${(props) => props.height + 8}px;
    margin: 0;
    padding-left:10px;
    padding-right:10px;
  }
  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => (props.bgColor ? "#f3f3f3" : "white")};
    //   background: white;
  }
`;

export const BodySection2 = styled.section`
  padding-top: 64px;
  padding-left:30px;
  padding-right:30px;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  background: radial-gradient(circle, rgba(254,237,245,1) 0%, rgba(254,237,245,1) 35%, rgba(253,240,233,1) 100%);

  @media ${(props) => props.theme.device.tablet} {
    padding-top: 15px;
    margin: 0;
    padding-left:10px;
    padding-right:10px;
  }
  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => (props.bgColor ? "#355854" : "white")};
    //   background: white;
  }
`;
export const BodySectionFullWidth = styled.section`
 padding-top: ${(props) => props.height}px;
  /* padding-left:30px;
  padding-right:30px; */
  height: auto;
  width: 100%;
  /* max-width: 1200px; */
  margin: auto;



  /* @media ${(props) => props.theme.device.tablet} {
    padding-top: 15px;
    margin: 0;
    padding-left:10px;
    padding-right:10px;
  } */
  @media ${(props) => props.theme.device.mobileL} {
    padding-top: ${(props) => props.height - 9}px;
    margin: 0;
    padding-left:10px;
    padding-right:10px;
  }
  &:before {
    content: "";
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${(props) => (props.bgColor ? "#355854" : "white")};
    //   background: white;
  }
`;
