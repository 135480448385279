export const RE_ORDER = 'RE_ORDER'
export const RE_ORDER_SUCCESS = 'RE_ORDER_SUCCESS'
export const RE_ORDER_FAIL = 'RE_ORDER_FAIL'
export const RE_ORDER_RESET = 'RE_ORDER_RESET'

export const reOrder = (params) => {
    return { type: RE_ORDER, params }

}

export const reOrderSuccess = (response) => {
    return { type: RE_ORDER_SUCCESS, response }
}

export const reOrderFail = (response) => {
    return { type: RE_ORDER_FAIL, response }
}
export const reOrderReset = () => {
    return { type: RE_ORDER_RESET }
}