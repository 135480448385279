import {
    GET_BRAND_INFO,
    GET_BRAND_INFO_SUCCESS,
    GET_BRAND_INFO_FAIL,
    GET_BRAND_INFO_RESET
} from '../actions/getBrandInfoAction'


const initialState = {
    apiState: "", // loading, success, error
    options: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_BRAND_INFO:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_BRAND_INFO_SUCCESS:
            return {
                ...state,
                apiState: "success",
                options: (action.response.data) ? action.response.data.customAttributeMetadata.items[0].attribute_options : []
            }

        case GET_BRAND_INFO_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_BRAND_INFO_RESET:
            return initialState


        default:
            return state
    }
}
