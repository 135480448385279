import { call, put, takeLatest } from 'redux-saga/effects';

// Action
import { GET_WISH_LIST, getWishListSuccess, getWishListFail, getWishListReset } from '../actions/getWishListAction'

// Api 
import getWishListApi from '../apis/getWishListApi'

export function* getWishListSaga(action) {
    try {
        const response = yield call(() => getWishListApi(action.params))
        if (response.errors) {
            yield put(getWishListFail(response, action))
        }
        else {
            yield put(getWishListSuccess(response, action))
        }
    }
    catch (e) {
        yield put(getWishListFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_WISH_LIST, getWishListSaga);
}