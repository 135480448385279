import { Button } from "antd"
import styled from "styled-components"

export const Left = styled.div`
    width:50%;
    padding: 20px;

    @media ${props => props.theme.device.tablet} {
        width:100%;
    }

.carousel .thumb.selected,
 .carousel .thumb:hover {
    border: 3px solid #f78c3e !important;
 }
`

export const Right = styled.div`
    width:50%;
    padding: 20px;

    @media ${props => props.theme.device.tablet} {
        width:100%;
    }
`
export const BrandButton = styled(Button)`
      border-radius: ${(props) => props.theme.border.borderRadius};
`