export const GET_FEATURED_IMAGE_LIST = 'GET_FEATURED_IMAGE_LIST'
export const GET_FEATURED_IMAGE_LIST_SUCCESS = 'GET_FEATURED_IMAGE_LIST_SUCCESS'
export const GET_FEATURED_IMAGE_LIST_FAIL = 'GET_FEATURED_IMAGE_LIST_FAIL'
export const GET_FEATURED_IMAGE_LIST_RESET = 'GET_FEATURED_IMAGE_LIST_RESET'

export const getFeaturedImageList = (params) => {
    return { type: GET_FEATURED_IMAGE_LIST, params }
}

export const getFeaturedImageListSuccess = (response) => {
    return { type: GET_FEATURED_IMAGE_LIST_SUCCESS, response }
}

export const getFeaturedImageListFail = (response) => {
    return { type: GET_FEATURED_IMAGE_LIST_FAIL, response }
}

export const getFeaturedImageListReset = () => {
    return { type: GET_FEATURED_IMAGE_LIST_RESET }
}