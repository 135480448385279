import React from "react"
import styled from "styled-components"

export const CrossIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? `${props.size}px` : "16px"} height="auto" viewBox="0 0 16.167 16.167">
            <g id="close" transform="translate(-117.177 -92.483) translate(117.177 92.483)">
                <g id="Group_5">
                    <rect id="Rectangle_4" width="20.926" height="1.938" fill="#545454" rx=".759" transform="rotate(45 .685 1.654)" />
                </g>
                <g id="Group_6">
                    <rect id="Rectangle_5" width="20.926" height="1.938" fill="#545454" rx=".759" transform="rotate(-45 17.862 7.399)" />
                </g>
            </g>
        </svg>
    )
}

export const MenuIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? `${props.size}px` : "16px"} height="auto" viewBox="0 0 18.981 16.167">
            <g id="menu" transform="translate(-75.463 -90.028)">
                <g id="Group_1" transform="translate(75.463 90.028)">
                    <rect id="Rectangle_1" width="18.981" height="1.758" fill="#545454" rx=".759" />
                </g>
                <g id="Group_2" transform="translate(75.463 104.438)">
                    <rect id="Rectangle_2" width="18.981" height="1.758" fill="#545454" rx=".759" />
                </g>
                <g id="Group_3" transform="translate(75.463 97.233)">
                    <rect id="Rectangle_3" width="18.981" height="1.758" fill="#545454" rx=".759" />
                </g>
            </g>
        </svg>
    )
}

export const HeartIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.size ? `${props.size}px` : "16px"} height="auto" viewBox="0 0 20.28 17.383">
            <g>
                <path fill="#545454" d="M482.9 61.063a5.445 5.445 0 0 0-3.973-1.4 4.487 4.487 0 0 0-1.431.244 5.779 5.779 0 0 0-1.358.656q-.629.413-1.081.775a10.494 10.494 0 0 0-.86.77 10.493 10.493 0 0 0-.86-.77q-.453-.362-1.081-.775a5.8 5.8 0 0 0-1.356-.663 4.487 4.487 0 0 0-1.431-.244 5.441 5.441 0 0 0-3.972 1.4 5.186 5.186 0 0 0-1.438 3.892 4.962 4.962 0 0 0 .266 1.562 6.839 6.839 0 0 0 .606 1.369 10.052 10.052 0 0 0 .769 1.1q.43.537.628.741a3.532 3.532 0 0 0 .312.294l7.061 6.813a.71.71 0 0 0 1 0l7.05-6.79q2.592-2.592 2.592-5.093a5.187 5.187 0 0 0-1.443-3.881zm-2.139 7.921L474.2 75.31l-6.575-6.337a5.948 5.948 0 0 1-2.116-4.018 4.938 4.938 0 0 1 .243-1.618 3.158 3.158 0 0 1 .623-1.115 2.993 2.993 0 0 1 .922-.673 4.13 4.13 0 0 1 1.064-.351 6.5 6.5 0 0 1 1.109-.09 3.257 3.257 0 0 1 1.267.288 5.787 5.787 0 0 1 1.251.725q.572.435.979.814a8.942 8.942 0 0 1 .679.7.742.742 0 0 0 1.109 0 8.761 8.761 0 0 1 .679-.7c.271-.252.6-.524.978-.814a5.811 5.811 0 0 1 1.251-.725 3.262 3.262 0 0 1 1.268-.288 6.52 6.52 0 0 1 1.109.09 4.124 4.124 0 0 1 1.063.351 3 3 0 0 1 .923.673 3.172 3.172 0 0 1 .622 1.115 4.937 4.937 0 0 1 .243 1.618 5.973 5.973 0 0 1-2.129 4.029z" transform="translate(-464.058 -59.659)" />
            </g>
        </svg>
    )
}