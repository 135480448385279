import { call, put, takeLatest } from 'redux-saga/effects'

import { GET_TOP_PRODUCT, getTopProductSuccess, getTopProductFail } from '../actions/getTopProductAction'

import getTopProductApi from '../apis/getTopProductApi'

export function* getTopProductSaga(action) {
    try {
        const response = yield call(() => getTopProductApi(action.params))
        if (response.errors) {
            yield put(getTopProductFail(response, action))
        } else {
            yield put(getTopProductSuccess(response, action))
        }
    }
    catch (e) {
        yield put(getTopProductFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_TOP_PRODUCT, getTopProductSaga);
}