import {
    PLACE_RAZORPAY_ORDER,
    PLACE_RAZORPAY_ORDER_SUCCESS,
    PLACE_RAZORPAY_ORDER_FAIL,
    PLACE_RAZORPAY_ORDER_RESET,
} from '../actions/placeRazorpayOrderAction'


const initialState = {
    apiState: "", // loading, success, error
    data: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PLACE_RAZORPAY_ORDER:
            return {
                ...state,
                apiState: "loading",
            }

        case PLACE_RAZORPAY_ORDER_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case PLACE_RAZORPAY_ORDER_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case PLACE_RAZORPAY_ORDER_RESET:
            return initialState

        default:
            return state
    }
}
