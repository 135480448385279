import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import axios from "axios"
import _ from "lodash"
import { useHistory, withRouter } from "react-router-dom"
import get from "lodash/get"
import { Breadcrumb, notification, Spin, Space, Skeleton, Form, Alert, Input, Button, Radio, Tooltip, Image, Modal } from "antd"
import { PhoneFilled } from '@ant-design/icons'
import { SideBySideMagnifier } from "react-image-magnifiers"
import { CloseOutlined, CheckOutlined, EnvironmentOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
import Lottie from "react-lottie"
import NotFoundAnimation from '../../lottie/product-not-found.json'

// custom components
import Footer from "../../components/Footer/Footer"
import CartModal from "../../components/CartModal/cart";
import {
    Container, LeftSection, ProductContainer, Name, Brand, SortDescription, HorizontalLine,
    QuantityContainer, IncreamentButton, DecreamentButton, InputQuantity, AddToCartButton,
    AddToCartContainer, DeliveryContainer, PinCodeInput, MoreItemButton, CartContent,
    DetailBackground, ManufactureContent, TableContainer, DrawerContainer, DeliveryErrorMessage,
    ProductTag, ProductBlock, SmallImageBlock, FreeShipingText, OutofStockContent, BreadcrumbText,
    BrandItem, ShippingMessage, CustomizableButton, SearchButton, CheckDeliveryLabel, ArrowContainer,
    VisiablePinCodeInputValue, CheckIcon, ConfigureButton, ConfigureButtonSwatch, ActiveButton, SizeChartLink
} from "./ProductDetailStyle"


// others
import {
    attribute_config_return,
    atrribute_data,
    attribute_config_dispatch,
} from "./constant"

// actions
import { createGuestCart, createGuestCartReset } from '../../actions/createGuestCartAction'
import { addProductToCart, addProductToCartReset } from '../../actions/addProductToCartAction'
import { createCustomerCart, createCustomerCartReset } from '../../actions/createCustomerCartAction'
import { fetchCartData } from "../../actions/fetchCartDataAction"
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import { getProductDetail, getProductDetailReset } from "../../actions/productActions"
import { fetchQuantity } from "../../actions/detailActions"
import { getProductCartList } from "../../actions/addProductCartAction"
import { brandRequest } from "../../actions/fetchBrandAction"
import { fetchBrandById, fetchBrandByIdReset } from "../../actions/fetchBrandByIdAction"
import { fetchRelatedProductRequest } from '../../actions/fetchRelatedProductsAction'
import { DynamicSkeleton } from "../../components/Xcomponent"
import { checkDelivery } from '../../actions/checkDeliveryAction'
import { addProductToWishList } from '../../actions/addProductToWishListAction'
import { getShippigoToken } from '../../actions/getShippigoTokenAction'
import { checkServiceAvailability, checkServiceAvailabilityReset } from '../../actions/checkServiceAvailabilityAction'
import { getBrandDetails, getBrandDetailsReset } from '../../actions/getBrandDetailsAction'
import { removeItemCart, removeItemCartReset } from "../../actions/removeItemCartAction"
import { updateProductCart, updateProductCartReset } from "../../actions/updateCartAction"
import { addConfigrableProduct, addConfigrableProductReset } from '../../actions/addConfigrableProductAction'

// others
import { getCartId, setCartId, getCustomerToken, setCustomerToken, callOptionNumber, SITE_URL } from '../../utils'
import BackButton from "../../components/BackButton/BackButton"


const ProductDetail = (props) => {
    // variables
    const {
        getProductDetail,
        productState, getProductDetailReset,
        fetchQuantity,
        productCartListItems,
        brandRequest,
        AddCartLoader,
        fetchRelatedProductRequest,
        history,
        productUidState,
        createGuestCart, createGuestCartReset, createGuestCartState,
        addProductToCart, addProductToCartReset, addProductToCartState,
        createCustomerCart, createCustomerCartReset, createCustomerCartState,
        fetchCartData,
        getCartCount,
        getShippigoToken, getShippigoTokenState,
        checkServiceAvailability, checkServiceAvailabilityState, checkServiceAvailabilityReset,
        fetchBrandById, fetchBrandByIdState, fetchBrandByIdReset,
        removeItemCart, removeItemCartState, removeItemCartReset, updateProductCart,
    } = props
    const [inputQuantity, setInputQuantity] = useState(1)
    const isMobile = window.innerWidth < 991
    // const { Option } = SelectContainer
    const tagsHistory = useHistory()
    const [visible, setVisible] = useState(false)
    const previousPath = useHistory()
    const [customAttributes, setCustomAttributes] = useState(
        props.location.state || null
    )
    const [brandName, setBrandName] = useState(null)
    // const [brandId, setBrandId] = useState(null)
    const [returnPolicy, setReturnPolicy] = useState(null)
    const [madeIn, setMadeIn] = useState(null)
    const [moreDetailsData, setMoreDetailsData] = useState(null)
    const [dispatch, setDispatch] = useState(null)
    const [visibleCart, setVisibleCart] = useState(false);
    const [brandData, setBrandData] = useState({
        brandName: "",
        urlKey: null,
        brandId: null,
        brandDetail: false
    })
    const [token, setToken] = useState(localStorage.getItem('token'))
    const DeliveryNotAvaliable = "Sorry, we do not ship to this pincode."
    const [visiableImgBorder, setVisiableImgBorder] = useState(true)
    const [selectItem, setSelectItem] = useState(null)
    const [itemPrice, setItemPrice] = useState(null)

    const [pincodeData, setPincodeData] = useState({
        pinCode: ""
    })
    const [instruction, setInstruction] = useState("")
    const [textItem, setTextItem] = useState(null)
    const [textPrice, setTextPrice] = useState(null)
    const [productItem, setProductItem] = useState(null)
    const [productPrice, setProductPrice] = useState(null)
    const [varientData, setVarientData] = useState([])
    const [configureProductPrice, setConfigureProductPrice] = useState(null)
    const [visiableInputData, setVisiableInputData] = useState(false)
    const [configureProductStock, setConfigureProductStock] = useState(null)
    const [checkAvailableState, setCheckAvailableState] = useState(false)
    const [configureProductData, setConfigureProductData] = useState([])
    const [configureProductImage, setConfigureProductImage] = useState([])
    const [image, setImage] = useState([])
    const [productType, setProductType] = useState([])
    const [configureProductSku, setConfigureProductSku] = useState(null)

    const [thumbnail, setThumbnail] = useState("")
    const [tags, setTags] = useState([])
    const { TextArea } = Input

    const [form] = Form.useForm()
    const [pincodeForm] = Form.useForm()
    const [selectedOptions, setSelectedOptions] = useState([])
    const [productVariantOptions, setProductVariantOptions] = useState([])
    const [productVarient, setProductVarient] = useState([])
    const [isSelectedOptionsChanged, setIsSelectedOptionsChanged] = useState(false)
    const [selectedOptionsProduct, setSelectedOptionsProduct] = useState({})
    const [quantity, setQuantity] = useState()
    const nodataOptions = {
        loop: true,
        autoplay: true,
        animationData: NotFoundAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    const [sizeChartVisible, setSizeChartVisible] = useState(false);

    const handleTextChange = (e, price, uid) => {
        setInstruction(e.target.value)
        if (e.target.value) {
            setTextPrice(price)
            setTextItem(uid)
        }
        else {
            setTextPrice(null)
            setTextItem(null)
        }
    }



    const handlePincodeChange = (e) => {
        setPincodeData({ ...pincodeData, [e.target.name]: e.target.value })
        checkServiceAvailabilityState.apiState = ""

    }

    const handleChange = (value) => {
        setSelectItem(value[1])
        setItemPrice(value[0])
    }

    const clearOption = () => {
        setSelectItem(null)
        setItemPrice(null)
    }

    const InputValid = "Required"

    // callbacks

    useEffect(() => {
        return (() => {
            checkServiceAvailabilityReset()
            fetchBrandByIdReset()
            setSelectedOptions([])
            setQuantity()
            setSelectedOptionsProduct({})
            addProductToCartReset()
            setProductType([])
            clearOption()
            setTextPrice(null)
            getProductDetailReset()
            setImage([])
            setProductVarient([])
            setProductVariantOptions([])
        })
    }, [])

    useEffect(() => {
        getProductDetail({
            urlKey: props.match.params.productKey,
            errorAction: (data) => {
            },
            successAction: (data) => {
                const relatedProducts = get(data, 'data.products.items[0].related_products')
                let tags = data.data.products.items[0].product_tags
                if (tags) {
                    let splitedTags = tags.split(",")
                    setTags(splitedTags)
                }
                fetchRelatedProductRequest(relatedProducts)
                const skuKey = data.data.products.items[0].sku
                handleproductRestApi(skuKey)
                setThumbnail(data.data.products.items[0].thumbnail ? data.data.products.items[0].thumbnail.url : data.data.products.items[0].image.url)

            },
        })

    }, [previousPath.location.pathname])
    // }, [previousPath.location.pathname])

    useEffect(() => {
        if (isSelectedOptionsChanged) {
            if (selectedOptions.length === configureProductData.length) {
                let findOptions = _.find(productVarient, { 'valueIndex': selectedOptions[0].value_index })
                let sku = ""
                let stockQuantity = null
                findOptions.varientData.map(item => {
                    let exist = false
                    selectedOptions.map(data => {
                        exist = _.some(item.attributes, { 'value_index': data.value_index })
                    })
                    if (exist) {
                        if (item.product.only_x_left_in_stock !== null) {
                            sku = item.product.sku
                            stockQuantity = item.product.only_x_left_in_stock
                            setQuantity(stockQuantity)
                            setConfigureProductPrice(item.product.price_range.minimum_price.regular_price.value)
                            let images = item.product.thumbnail && item.product.thumbnail.url
                            let small_images = []
                            item.product.media_gallery.map(image => {
                                small_images.push(
                                    image.url
                                )
                            })
                            setImage(small_images)
                            setConfigureProductImage(images)
                        }
                        else {
                            sku = ""
                            setQuantity(null)
                            let images = item.product.thumbnail && item.product.thumbnail.url
                            let small_images = []
                            item.product.media_gallery.map(image => {
                                small_images.push(
                                    image.url
                                )
                            })
                            console.log("small_images", small_images)
                            setImage(small_images)
                            setConfigureProductImage(images)

                        }
                    }
                })
                setSelectedOptionsProduct({ ...selectedOptionsProduct, ['sku']: sku })
            }
            setIsSelectedOptionsChanged(false)
        }

    }, [isSelectedOptionsChanged])

    useEffect(() => {
        if (image.length > 0) {
            var images = document.getElementsByClassName("product-images")
            for (var i = 0; i < images.length; i++) {
                images[i].style.display = "none"
            }
            document.getElementsByClassName(`product-image-0`)[0].style.display = "block";
            setVisiableImgBorder(true)
        }
    }, [image])

    useEffect(() => {
        if (productCartListItems) fetchQuantity(productCartListItems.length)
    }, [productCartListItems])

    useEffect(() => {
        let brandArr = []
        if (customAttributes) {
            customAttributes.map((item) => {
                if (item.attribute_code === "return_policy") {
                    return attribute_config_return.map((data) => {
                        if (Object.keys(data)[0] === item.value) {
                            setDispatch(Object.values(data)[0])
                            brandArr.push({
                                attribute_name: "Return Policy",
                                value: Object.values(data)[0],
                            })
                            // if (Object.values(data)[0] !== "Non Returnable") {
                            //     brandArr.push({
                            //         attribute_name: "Return",
                            //         value: `<a href="/terms-of-use/p" style="color:rgba(0,0,0,0.85)" >T&C</a>`
                            //     })
                            // }

                        }
                    })
                }

                if (item.attribute_code === "dispatch_readiness") {
                    return attribute_config_dispatch.map((data) => {
                        if (Object.keys(data)[0] === item.value) {
                            setReturnPolicy(Object.values(data)[0])
                        }
                    })
                }

                if (item.attribute_code === "made_in") {
                    setMadeIn(item.value)
                }
            })
            const arr = []

            const attributeMap = customAttributes.map((item) => {
                return atrribute_data.map((data) => {
                    if (data.attribute_code === item.attribute_code) {
                        return arr.push({ attribute_name: data.name, value: item.value })
                    }
                })
            })

            const arr_call = []
            if (productState.product.call_option === 1) {
                arr_call.push({
                    attribute_name: "Call", value: `<a href="tel:${callOptionNumber}" style="color:rgba(0,0,0,0.85)" > ${callOptionNumber}</a>`
                })
            }

            let updatedArr = [...arr, ...brandArr, ...arr_call]
            setMoreDetailsData(updatedArr)
        }
    }, [customAttributes])


    useEffect(() => {
        if (createGuestCartState.apiState === "success") {
            setCartId(createGuestCartState.data.createEmptyCart)
            if (productState.product.__typename === "ConfigurableProduct") {
                if (selectedOptionsProduct.sku) {
                    addProductToCart({
                        cart_id: createGuestCartState.data.createEmptyCart,
                        quantity: inputQuantity,
                        sku: selectedOptionsProduct.sku,
                        parent_sku: productState.product.sku,
                    })
                }
            }
            else {
                addProductToCart({
                    cart_id: createGuestCartState.data.createEmptyCart,
                    quantity: inputQuantity,
                    sku: productState.product.sku,
                    uid: selectItem,
                    textUid: textItem,
                    productUid: productItem,
                    instruction: instruction,
                })
            }
            createGuestCartReset()
        }
    }, [createGuestCartState])

    useEffect(() => {
        if (productState.apiState === "success" && productState.product !== undefined) {
            fetchBrandById({ brand_name: productState.product.brand_name })
            let obj = _.find(productState.product.options, { '__typename': "CustomizableRadioOption" })
            if (obj) {
                if (obj.radio_value[0].uid) {
                    setProductItem(obj.radio_value[0].uid)
                }
            }
            /* Creating combinations in an array for the perpose to match with selected options */
            let arr = []
            let configureableDataValue = []
            if (productState.product.configurable_options) {
                let productVarientObj = productVarient
                productState.product.configurable_options.map((items) => {
                    let values = []
                    items.values.map((value) => {
                        value.status = true
                        values.push({
                            value: value,
                        })
                        let varientDataObj = productState.product.variants
                        let varientData = []
                        varientDataObj.map((data) => {
                            let attributeObj = data.attributes
                            let valueIndex = _.some(attributeObj, { 'value_index': value.value_index })
                            if (valueIndex) {
                                varientData.push(data)
                            }
                        })
                        productVarientObj.push({
                            code: items.attribute_code,
                            label: value.label,
                            valueIndex: value.value_index,
                            varientData: varientData,
                        })
                    })
                    configureableDataValue.push({
                        label: items.label,
                        __typename: items.__typename,
                        product_id: items.product_id,
                        id: items.id,
                        attribute_code: items.attribute_code,
                        values: items.values,
                    })
                })
            }

            setProductVariantOptions(arr)
            setConfigureProductData(configureableDataValue)
        }
    }, [productState])

    useEffect(() => {
        if (fetchBrandByIdState.apiState === "success") {
            if (fetchBrandByIdState.data && fetchBrandByIdState.data.products && fetchBrandByIdState.data.products.items && fetchBrandByIdState.data.products.items.length > 0) {
                setBrandData({ ...brandData, ["brandName"]: fetchBrandByIdState.data.products.items[0].name, ["urlKey"]: fetchBrandByIdState.data.products.items[0].url_key, ["brandId"]: fetchBrandByIdState.data.products.items[0].brand_name, ["brandDetail"]: true })
            }
            else {
                brandRequest({
                    errorAction: (data) => { },
                    successAction: (data) => {
                        const brandItems = _.find(data.data.customAttributeMetadata.items[0].attribute_options, { value: String(productState.product.brand_name) })
                        setBrandData({ ...brandData, ["brandName"]: brandItems.label, ["urlKey"]: encodeURI(brandItems.label), ["brandId"]: brandItems.value, ["brandDetail"]: false })
                    },
                })
            }
        }
    }, [fetchBrandByIdState])
    useEffect(() => {
        if (createCustomerCartState.apiState === "success") {
            if (createCustomerCartState.preventAddToCart === false) {
                setCartId(createCustomerCartState.data.customerCart.id)
                if (productState.product.__typename === "ConfigurableProduct") {
                    if (selectedOptionsProduct.sku) {
                        addProductToCart({
                            cart_id: createCustomerCartState.data.customerCart.id,
                            quantity: inputQuantity,
                            sku: selectedOptionsProduct.sku,
                            parent_sku: productState.product.sku,
                            customerToken: getCustomerToken()
                        })
                    }
                }
                else {
                    addProductToCart({
                        cart_id: createCustomerCartState.data.customerCart.id,
                        quantity: inputQuantity,
                        sku: productState.product.sku,
                        uid: selectItem,
                        textUid: textItem,
                        productUid: productItem,
                        instruction: instruction,
                        customerToken: getCustomerToken()
                    })
                }

                createCustomerCartReset()
            }
        }
    }, [createCustomerCartState])
    const handleTags = (item) => {
        productState.productTagData = item
        tagsHistory.push('/search')
    }


    useEffect(() => {
        fetchCartData({
            cart_id: getCartId(),
            errorAction: (error) => {
                if (error.data.cart.items) {
                    let errorData = error.data.cart.items
                    errorData.map((item) => {
                        if (item !== null) {
                            if (item.product.stock_status === "OUT_OF_STOCK" || item.product.only_x_left_in_stock === null) {
                                let itemId = item.id
                                removeItemCart({
                                    cart_id: getCartId(),
                                    cart_item_id: itemId,
                                })
                            }
                            else {
                                if (item.quantity > item.product.only_x_left_in_stock) {
                                    let decreseQuantity = item.quantity - item.product.only_x_left_in_stock
                                    if (decreseQuantity) {
                                        const updatedData = {
                                            cart_id: getCartId(),
                                            cart_item_id: item.id,
                                            quantity: item.product.only_x_left_in_stock
                                        }
                                        updateProductCart({
                                            updatedData
                                        })
                                    }
                                }
                            }
                        }

                    })
                }
            },

        })
        getCartCount({
            cart_id: getCartId(),
            customerToken: getCustomerToken()
        })
    }, [])

    useEffect(() => {
        if (checkServiceAvailabilityState.apiState === "success") {
            setVisiableInputData(true)
        }
    }, [checkServiceAvailabilityState])

    useEffect(() => {
        if (addProductToCartState.apiState === "success") {
            notification["success"]({
                message: 'Added to cart',
                placement: "bottomRight",
                duration: 5,
                style: { backgroundColor: '#e49045', cursor: "pointer" },
                className: "notification-type-info",
                onClick() {
                    handleShowDrawer()
                }

            })
            setSelectedOptions([])
            setQuantity()
            setSelectedOptionsProduct({})
            // setConfigureProductPrice(null)
            addProductToCartReset()
            setProductType([])
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken()
            })
            clearOption()
            form.resetFields()
            setTextPrice(null)
        }

        if (addProductToCartState.apiState === "error") {
            if (addProductToCartState.errorType === "graphql-input") {
                notification["error"]({
                    message: 'Requested quanity is not available in the stock.',
                    placement: "bottomRight"
                })
                addProductToCartReset()
                setSelectedOptions([])
                setQuantity()
                setProductType([])
                // setConfigureProductPrice(null)
                setSelectedOptionsProduct({})
            }

            if (addProductToCartState.errorType === "graphql-no-such-entity") {
                localStorage.removeItem("cartId")
                addToCart()
            }

            if (addProductToCartState.errorType === "system") {
                notification["error"]({
                    message: 'Something is not right.',
                    placement: "topRight"
                })
            }
        }
    }, [addProductToCartState])
    useEffect(() => {
        if (removeItemCartState.apiState === "success" || removeItemCartState.apiState === "error") {
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken()
            })
            fetchCartData({
                cart_id: getCartId(),
            })
            removeItemCartReset()
        }
    }, [removeItemCartState])

    useEffect(() => {
        if (getShippigoTokenState.apiState === "success") {
            const token = getShippigoTokenState.data.token
            checkServiceAvailability({
                pinCode: pincodeData.pinCode,
                token: token
            })
        }
        if (getShippigoTokenState.apiState === "error") {
            notification["error"]({
                message: 'Something is not right.',
                placement: "topRight"
            })
        }
    }, [getShippigoTokenState])



    /* ========================== */
    /* functions */
    /* ========================== */
    const decreamentQuantity = () => {
        setInputQuantity((prevState) => parseInt(prevState) - 1)
    }


    const increamentQuantity = () => {
        setInputQuantity((prevState) => parseInt(prevState) + 1)
    }

    const goToPreviousPath = () => {
        previousPath.goBack()
    }

    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
        setVisibleCart(false)
    }
    const addToCart = () => {
        if (getCartId()) {
            if (productState.product.__typename === "ConfigurableProduct") {
                if (selectedOptionsProduct.sku) {
                    addProductToCart({
                        cart_id: getCartId(),
                        quantity: inputQuantity,
                        sku: selectedOptionsProduct.sku,
                        parent_sku: productState.product.sku,
                        customerToken: getCustomerToken()
                    })
                }
            }
            else {
                addProductToCart({
                    cart_id: getCartId(),
                    quantity: inputQuantity,
                    sku: productState.product.sku,
                    uid: selectItem,
                    textUid: textItem,
                    productUid: productItem,
                    instruction: instruction,
                    customerToken: getCustomerToken()
                })
            }
        } else {
            if (getCustomerToken()) {
                createCustomerCart({
                    customerToken: getCustomerToken()
                })
            } else {
                createGuestCart()
            }
        }
    }
    const showHtml = (record) => {
        const checkHtml = /<\/?[a-z][\s\S]*>/i.test(record)
        if (checkHtml) {
            let html = { __html: record }
            return <div dangerouslySetInnerHTML={html}></div>
        } else {
            return record
        }
    }

    const handleproductRestApi = (skuKey) => {
        const AuthStr = "Bearer ".concat("4uhq3y2jb7kxhnwpn272h7ci0z6rp2vy")
        axios
            .get(`${SITE_URL()}/rest/default/V1/products/${skuKey}`, {
                headers: { Authorization: AuthStr },
            })
            .then((response) => {
                // If request is good...
                setCustomAttributes(response.data.custom_attributes)
            })
            .catch((error) => {
            })
    }
    const columns = [
        {
            //  title: "",

            dataIndex: "attribute_name",
            key: "attribute_name",
        },
        {
            //  title: "",
            dataIndex: "value",
            key: "value",
            render: (record) => showHtml(record),
        },
    ]

    const tableCSS = {
        margin: "40px 120px",
        backgroundColor: "white",
        "& table": {
            borderCollapse: "collapse",
        },
        "& thead > tr > th": {
            backgroundColor: "darkblue",
            color: "white",
        },
        "& thead > tr": {
            borderWidth: "2px",
            borderColor: "yellow",
            borderStyle: "solid",
        },
        "& thead > tr > th > td": {
            fontWeight: 'bold'
        }
    }

    const handleThumbnailClick = (index) => {
        setVisiableImgBorder(false)
        var images = document.getElementsByClassName("product-images")
        for (var i = 0; i < images.length; i++) {
            images[i].style.display = "none"
        }
        document.getElementsByClassName(`product-image-${index}`)[0].style.display = "block";

    }
    let Click = document.querySelectorAll("span")
    Click.forEach(span => {
        span.addEventListener('click', function () {
            Click.forEach(btn => btn.classList.remove("active"))
            this.classList.add("active")
        })
    })

    const handleProductChange = (uid, price) => {
        setProductItem(uid)
        setProductPrice(price)
    }

    /* 
    craete comnbination for all variant product
    match combination
    if matcg => change
    */
    const handleConfigureProductClick = (code, value_index, status) => {
        let findOptions = _.find(productVarient, { 'valueIndex': value_index })
        if (findOptions) {
            configureProductData.map((data) => {
                setProductType(data)
                data.values.map(value => {
                    let status = false
                    if (data.attribute_code !== code) {
                        findOptions.varientData.map(product => {
                            // setConfigureProductPrice(product.product.price_range.minimum_price.regular_price.value)
                            let images = product.product.thumbnail && product.product.thumbnail.url
                            setConfigureProductImage(images)
                            product.product.media_gallery.map(image => {
                                setImage([image.url])
                            })
                            if (_.some(product.attributes, { 'value_index': value.value_index }) && product.product.stock_status === "IN_STOCK") {
                                status = true
                            }
                        })
                        value.status = status
                    }
                    else {
                        value.status = true
                    }
                })

                // setConfigureProductStock(product.product.stock_status)
            })
        }

        let arr = selectedOptions
        if (_.findIndex(arr, { 'code': code }) >= 0) {
            arr = arr.filter(obj => obj.code != code)
        }
        arr.push({
            code: code,
            value_index: value_index
        })
        setSelectedOptions(arr)
        setIsSelectedOptionsChanged(true)
    }
    const handleShowDrawer = () => {
        setVisibleCart(true);
    };
    const handleClose = () => {
        setVisibleCart(false)
    }

    // This is used to render suggestion images for the product
    const renderView = () => {
        const filteredMedia = _.filter(productState.product.media_gallery, { 'disabled': false, 'label': null });
        const sortedMedia = _.sortBy(filteredMedia, 'position');
        return sortedMedia.map((item, index) => {
            return (
                <SmallImageBlock key={index}>
                    <span
                        style={{ border: (index === 0 && visiableImgBorder) ? "3px solid #e49045" : "3px solid #f5f5f500" }}>

                        <img
                            src={item.url}
                            className={`small-images${index}`}
                            style={{
                                width: "50px", height: "50px",
                            }}
                            onClick={() => handleThumbnailClick(index)}
                        />
                    </span>
                </SmallImageBlock>
            );
        });
    }

    // This is used to render Main image that user has selected
    const renderImageView = () => {
        const sortedMediaGallery = _.sortBy(productState.product.media_gallery, 'position');
        return sortedMediaGallery
            .filter(item => !item.disabled && item.label === null)
            .map((item, index) => (
                <>
                    {isMobile ? (
                        <>
                            {productType && productType.__typename === 'ConfigurableProductOptions' ? (
                                <img
                                    src={image[index]}
                                    style={{
                                        width: '100%',
                                        display: index === 0 ? 'block' : 'none',
                                    }}
                                    className={`product-images product-image-${index}`}
                                />
                            ) : (
                                <img
                                    src={item.url}
                                    style={{
                                        width: '100%',
                                        display: index === 0 ? 'block' : 'none',
                                    }}
                                    className={`product-images product-image-${index}`}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {productType && productType.__typename === 'ConfigurableProductOptions' ? (
                                <SideBySideMagnifier
                                    alwaysInPlace={true}
                                    imageSrc={image[index]}
                                    style={{
                                        maxHeight: '400px',
                                        maxWidth: '400px',
                                        display: index === 0 ? 'block' : 'none',
                                        overflow: 'hidden',
                                    }}
                                    className={`product-images product-image-${index}`}
                                />
                            ) : (
                                <SideBySideMagnifier
                                    alwaysInPlace={true}
                                    imageSrc={item.url}
                                    style={{
                                        maxHeight: '400px',
                                        maxWidth: '400px',
                                        display: index === 0 ? 'block' : 'none',
                                        overflow: 'hidden',
                                    }}
                                    className={`product-images product-image-${index}`}
                                />
                            )}
                        </>
                    )}
                </>
            ));
    }
    return (
        <>

            {productState.apiState === "loading" &&
                <DetailBackground>
                    <Container style={{ marginTop: 56 }}>
                        <LeftSection>
                            <DynamicSkeleton h="300px" active />
                        </LeftSection>
                        <ProductContainer>
                            <Skeleton active />
                        </ProductContainer>
                    </Container>
                </DetailBackground>
            }
            {
                productState.apiState === "error" &&
                <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", height: "100%", flexDirection: "column" }} >
                    <Lottie
                        options={nodataOptions}
                        height={300}
                        width={300}
                        style={{ margin: "none" }}
                    />
                    <h1 style={{ color: "#000", fontSize: 20, }} >This product is no longer available</h1>
                    <Link to="/categories"><Button type="primary" style={{ fontSize: 16, display: 'flex', alignItems: "center" }} >Explore Category</Button></Link>
                </div>
            }
            {(productState.apiState === "success" && productState.product !== undefined) &&
                <DetailBackground>
                    <Helmet>‍
                        <title>{productState.product.meta_title}</title>
                        <meta name="description" content={productState.product.meta_description} />
                        <meta name="keywords" content={productState.product.meta_keyword} />
                        <meta property="og:title" content={productState.product.meta_title} />
                        <meta property="og:description" content={productState.product.meta_description} />
                        <meta property="og:image" content={productState.product.thumbnail ? productState.product.thumbnail.url : productState.product.image.url} />
                        <meta property="og:url" content={`https://laduindia.com/product-detail/${productState.product.url_key}`} />
                        <meta property="og:site_name" content="Ladu India" />
                        <meta property="og:locale" content="en_US" />
                        <meta property="og:type" content="article" />
                    </Helmet>
                    <ArrowContainer onClick={goToPreviousPath}>
                        <img
                            src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                            alt="ladu-india"
                            width="36"
                        />
                    </ArrowContainer>
                    <Container>
                        <LeftSection>
                            {
                                productState.product.media_gallery.length > 0 ?
                                    <>
                                        {renderImageView()}
                                        {/* {_.filter(productState.product.media_gallery, { 'disabled': false, 'label': null }).map((item, index) => {
                                            return (
                                                <>
                                                    {
                                                        isMobile ?
                                                            <>
                                                                {
                                                                    productType && productType.__typename === "ConfigurableProductOptions" ?
                                                                        <img src={image[index]} style={{
                                                                            width: "100%",
                                                                            display: index === 0 ? "block" : "none",
                                                                        }}
                                                                            className={`product-images product-image-${index}`}
                                                                        /> 
                                                                        :
                                                                        <img src={item.url} style={{
                                                                            width: "100%",
                                                                            display: index === 0 ? "block" : "none",
                                                                        }}
                                                                            className={`product-images product-image-${index}`}
                                                                        />
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    productType && productType.__typename === "ConfigurableProductOptions" ?
                                                                        <SideBySideMagnifier
                                                                            alwaysInPlace={true}
                                                                            imageSrc={image[index]}
                                                                            style={{
                                                                                maxHeight: "400px",
                                                                                maxWidth: "400px",
                                                                                display: index === 0 ? "block" : "none",
                                                                                overflow: 'hidden'
                                                                            }}
                                                                            className={`product-images product-image-${index}`}
                                                                        /> :
                                                                        <SideBySideMagnifier
                                                                            alwaysInPlace={true}
                                                                            imageSrc={item.url}
                                                                            style={{
                                                                                maxHeight: "400px",
                                                                                maxWidth: "400px",
                                                                                display: index === 0 ? "block" : "none",
                                                                                overflow: 'hidden'
                                                                            }}
                                                                            className={`product-images product-image-${index}`}
                                                                        />
                                                                }
                                                            </>

                                                    }
                                                </>
                                            )
                                        })
                                        } */}
                                    </> :
                                    <>
                                        {
                                            isMobile ?
                                                <>
                                                    {
                                                        productType && productType.__typename === "ConfigurableProductOptions" ?
                                                            <img style={{ width: '100%' }} src={configureProductImage} /> :
                                                            <img style={{ width: '100%' }} src={productState.product.thumbnail.url} />
                                                    }
                                                </>
                                                :
                                                <>
                                                    {
                                                        productType && productType.__typename === "ConfigurableProductOptions" ?
                                                            <SideBySideMagnifier
                                                                alwaysInPlace={true}
                                                                imageSrc={configureProductImage}
                                                                style={{
                                                                    maxHeight: "400px",
                                                                    maxWidth: "400px",
                                                                }}
                                                            /> :
                                                            <SideBySideMagnifier
                                                                alwaysInPlace={true}
                                                                imageSrc={productState.product.thumbnail.url}
                                                                style={{
                                                                    maxHeight: "400px",
                                                                    maxWidth: "400px",
                                                                }}
                                                            />
                                                    }

                                                </>
                                        }
                                    </>
                            }
                            {productState.product.media_gallery.length > 0 &&
                                <div
                                    style={{
                                        display: "flex",
                                        flexFlow: "wrap",
                                        marginBottom: 16,
                                        cursor: "pointer",
                                        marginTop: "8px",
                                    }}
                                >
                                    <Space style={{ flexFlow: "wrap" }}>
                                        {
                                            productType && productType.__typename === "ConfigurableProductOptions" ?
                                                image.map((item, index) => {
                                                    return (
                                                        <SmallImageBlock>
                                                            <span
                                                                style={{ border: (index === 0 && visiableImgBorder) ? "3px solid #e49045" : "3px solid #f5f5f500" }}>

                                                                <img
                                                                    src={item}
                                                                    className={`small-images${index}`}
                                                                    style={{
                                                                        width: "50px", height: "50px",
                                                                        // border: index === 0 ? "3px solid #f78c3e" : "none",
                                                                    }}
                                                                    onClick={() => handleThumbnailClick(index)}
                                                                />
                                                            </span>
                                                        </SmallImageBlock>

                                                    )
                                                })
                                                : renderView()
                                            // : _.filter(productState.product.media_gallery, { 'disabled': false, 'label': null }).map((item, index) => {
                                            //     return (
                                            //         <SmallImageBlock>
                                            //             <span
                                            //                 style={{ border: (index === 0 && visiableImgBorder) ? "3px solid #e49045" : "3px solid #f5f5f500" }}>

                                            //                 <img
                                            //                     src={item.url}
                                            //                     className={`small-images${index}`}
                                            //                     style={{
                                            //                         width: "50px", height: "50px",
                                            //                         // border: index === 0 ? "3px solid #f78c3e" : "none",
                                            //                     }}
                                            //                     onClick={() => handleThumbnailClick(index)}
                                            //                 />
                                            //             </span>
                                            //         </SmallImageBlock>

                                            //     )
                                            // })
                                        }


                                    </Space>
                                </div>
                            }
                        </LeftSection>
                        <ProductContainer>
                            <Breadcrumb separator=">" style={{ marginBottom: 10 }}>
                                {productState.product.categories.map((data) => {
                                    return (
                                        <Breadcrumb.Item>
                                            <BreadcrumbText>
                                                <Link to={`/category/${data.url_key}/${data.id}`}>{data.name}</Link>
                                            </BreadcrumbText>
                                        </Breadcrumb.Item>
                                    )
                                })}
                                {/* <Breadcrumb.Item>
                                    <BreadcrumbText>{productState.product.categories[0]?.name}</BreadcrumbText>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <BreadcrumbText>{productState.product.categories[1]?.name}</BreadcrumbText>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <BreadcrumbText>{productState.product.categories[2]?.name}</BreadcrumbText>
                                </Breadcrumb.Item> */}
                            </Breadcrumb>
                            {productState.apiState === "success" && productState.product && (
                                <>
                                    <Name>{productState.product.name || ""}</Name>
                                    {brandData.urlKey && brandData.brandDetail ?
                                        <Link to={`/brand/${brandData.urlKey}`}>
                                            <Brand>{(brandData.brandName && brandData.brandName) || ""}</Brand>
                                        </Link>
                                        :
                                        <Link>
                                            <Brand>{(brandData.brandName && brandData.brandName) || ""}</Brand>
                                        </Link>
                                    }
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: '5px' }} >
                                        {productState.product.special_price &&
                                            <>
                                                <span style={{
                                                    fontSize: 20,
                                                    marginRight: 8
                                                }} >₹{productState.product.special_price}</span>
                                                <span style={{
                                                    fontSize: 16,
                                                    color: "#000000bd",
                                                    marginRight: 8
                                                }} ><s>₹{productState.product.price_range.minimum_price.regular_price.value}</s></span>
                                                <span style={{
                                                    fontSize: 16,
                                                    color: "#2d7b41",
                                                    padding: "4px 8px",
                                                    fontWeight: "bold",
                                                    marginRight: 4
                                                }} >({productState.product.price_range.minimum_price.discount.percent_off}% OFF)</span>
                                            </>
                                        }
                                        {
                                            (productType && productType.__typename === "ConfigurableProductOptions" && configureProductPrice) ?
                                                <span style={{
                                                    fontSize: 20,
                                                    marginRight: 8,
                                                    marginRight: 4
                                                }} >₹{configureProductPrice}</span> :
                                                <>
                                                    {
                                                        productUidState ?
                                                            <>
                                                                {!productState.product.special_price &&
                                                                    <span style={{
                                                                        fontSize: 20,
                                                                        marginRight: 8,
                                                                        marginRight: 4
                                                                    }} >₹{productPrice + productState.product.price_range.minimum_price.regular_price.value}</span>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {!productState.product.special_price &&
                                                                    <span style={{
                                                                        fontSize: 20,
                                                                        marginRight: 8,
                                                                        marginRight: 4
                                                                    }} >₹{productState.product.price_range.minimum_price.regular_price.value}</span>
                                                                }
                                                            </>
                                                    }
                                                </>
                                        }
                                        {productState.product.product_tag && productState.product.product_tag.split(",").includes("67") &&
                                            <span style={{
                                                fontSize: 12,
                                                color: "#fff",
                                                background: "#f78c3e",
                                                padding: "4px 8px",
                                                borderRadius: 10
                                            }} >NEW</span>
                                        }
                                    </div>
                                    {
                                        (productState.product.only_x_left_in_stock === null && productState.product.__typename !== "ConfigurableProduct") ?
                                            <OutofStockContent>Currently Out Of Stock</OutofStockContent>
                                            :
                                            <>
                                                {
                                                    (productState.product.only_x_left_in_stock >= 0 && productState.product.only_x_left_in_stock <= 3 && productState.product.only_x_left_in_stock !== null) &&
                                                    <OutofStockContent>Only {productState.product.only_x_left_in_stock} left in stock.</OutofStockContent>
                                                }
                                            </>
                                    }
                                    {
                                        (quantity === null && productState.product.__typename === "ConfigurableProduct") ?
                                            <OutofStockContent>Currently Out Of Stock</OutofStockContent>
                                            :
                                            <>
                                                {
                                                    (quantity >= 0 && quantity <= 3 && quantity !== null) &&
                                                    <OutofStockContent>Only {quantity} left in stock.</OutofStockContent>
                                                }
                                            </>
                                    }
                                    <AddToCartContainer>
                                        <Form
                                            onFinish={addToCart}
                                            layout="vertical"
                                            form={form}
                                        >
                                            {
                                                productUidState && productUidState.map((item) => {
                                                    return (
                                                        <>
                                                            {productUidState && item.__typename === "CustomizableRadioOption" &&
                                                                <Space style={{ marginBottom: 16 }} >
                                                                    {item.radio_value.map((value, index) => {
                                                                        return (
                                                                            <CustomizableButton type={value.uid == productItem ? "primary" : "dashed"} onClick={() => handleProductChange(value.uid, value.price)} >{value.title}</CustomizableButton>
                                                                        )
                                                                    })}
                                                                </Space>
                                                            }
                                                            {
                                                                productUidState && item.__typename === "CustomizableFieldOption" &&
                                                                <>
                                                                    <Form.Item
                                                                        label={`${item.title}`}
                                                                        rules={[
                                                                            { required: item.required, message: 'This field is required' },
                                                                        ]}
                                                                        name="textField"
                                                                    >
                                                                        <Input type="text" name="textField" maxLength={item.text_value.max_characters} onChange={(e) => handleTextChange(e, item.text_value.price, item.text_value.uid)} />
                                                                    </Form.Item>
                                                                    <small>Maximum {item.text_value.max_characters} characters</small>
                                                                </>
                                                            }

                                                        </>

                                                    )
                                                })
                                            }
                                            {
                                                productState.product.__typename === "ConfigurableProduct" &&
                                                <Space style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginBottom: 10 }} >
                                                    {
                                                        configureProductData.map((options) => {
                                                            return (
                                                                <Form.Item
                                                                    name={`option${options.attribute_code}`}
                                                                    rules={[{ required: true, message: 'This field is required' }]}
                                                                >
                                                                    <div style={{ display: 'flex', flexDirection: "column", marginBottom: 10 }} >
                                                                        <span style={{ fontWeight: 'bold', width: '100%', marginBottom: 5 }} >{options.label}</span>
                                                                        <Radio.Group>
                                                                            <div style={{ display: 'flex', flexDirection: "row" }} >
                                                                                {options.values.map((item) => {

                                                                                    return (
                                                                                        <>
                                                                                            {
                                                                                                item.swatch_data === null ?
                                                                                                    <Tooltip title={item.label} color={"#808080"}>
                                                                                                        <ConfigureButton value={item.value_index} disabled={!item.status} onClick={() => handleConfigureProductClick(options.attribute_code, item.value_index, item.status)} >{item.label}</ConfigureButton>
                                                                                                    </Tooltip>
                                                                                                    :
                                                                                                    <Tooltip title={item.label} color={"#808080"} >
                                                                                                        <ActiveButton className={(_.some(selectedOptions, { 'value_index': item.value_index })) ? "selected" : ""} >
                                                                                                            <ConfigureButtonSwatch value={item.value_index} disabled={!item.status} onClick={() => handleConfigureProductClick(options.attribute_code, item.value_index, item.status)} style={{ background: item.swatch_data !== null ? item.swatch_data.value : '#808080', border: 'none' }} >&nbsp;</ConfigureButtonSwatch>
                                                                                                        </ActiveButton>
                                                                                                    </Tooltip>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                })
                                                                                }
                                                                            </div>
                                                                        </Radio.Group>
                                                                    </div>
                                                                </Form.Item>
                                                            )
                                                        })
                                                    }
                                                    {_.some(productState.product.media_gallery, { 'disabled': false, 'label': "size-chart" }) &&
                                                        <>
                                                            <SizeChartLink
                                                                onClick={() => setSizeChartVisible(true)}
                                                            >
                                                                Size Chart
                                                            </SizeChartLink>
                                                            <DrawerContainer
                                                                title="Size Chart"
                                                                placement="bottom"
                                                                onClose={() => setSizeChartVisible(!sizeChartVisible)}
                                                                visible={sizeChartVisible}
                                                                width={isMobile ? "100% !important" : "50% !important"}
                                                                height={"100%"}
                                                            >
                                                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", height: "100%" }}>
                                                                    {_.filter(productState.product.media_gallery, { 'disabled': false, 'label': "size-chart" }).map((item) => {
                                                                        return (

                                                                            <img
                                                                                src={item.url}
                                                                                style={{
                                                                                    width: "98%",
                                                                                }}
                                                                            />

                                                                        )
                                                                    })}
                                                                </div>
                                                            </DrawerContainer>
                                                            {/* Alter Modal UI of Size chart */}
                                                            {/* <Modal
                                                                title="Size Chart"
                                                                centered
                                                                visible={sizeChartVisible}
                                                                onCancel={() => setSizeChartVisible(false)}
                                                                footer={null}
                                                                width={1000}
                                                                height={1000}
                                                            >
                                                                <div style={{ display: "flex", justifyContent: "space-around" }}>
                                                                    {_.filter(productState.product.media_gallery, { 'disabled': false, 'label': "size-chart" }).map((item) => {
                                                                        return (

                                                                            <img
                                                                                src={item.url}
                                                                                style={{
                                                                                    width: "800px",
                                                                                }}
                                                                            />

                                                                        )
                                                                    })}
                                                                </div>
                                                            </Modal> */}
                                                        </>
                                                    }
                                                </Space>
                                            }
                                            {
                                                (productState.product.only_x_left_in_stock !== null || (productState.product.__typename === "ConfigurableProduct" && (quantity !== null || quantity > 0))) &&
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <QuantityContainer>
                                                        <DecreamentButton
                                                            onClick={decreamentQuantity}
                                                            disabled={inputQuantity < 2}
                                                        >-</DecreamentButton>
                                                        <InputQuantity
                                                            // value={productState.product.__typename === "ConfigurableProduct" ? quantity : inputQuantity}
                                                            value={inputQuantity}
                                                            onChange={(value) => setInputQuantity(value)}
                                                            min={0}
                                                            disabled
                                                        />
                                                        <IncreamentButton
                                                            onClick={increamentQuantity}
                                                            disabled={productState.product.__typename === "ConfigurableProduct" && quantity > 0 ? inputQuantity > quantity - 1 : inputQuantity > productState.product.only_x_left_in_stock - 1}
                                                        >+</IncreamentButton>
                                                    </QuantityContainer>
                                                    <Form.Item style={{ marginBottom: 0 }} >
                                                        <AddToCartButton htmlType="submit">
                                                            <CartContent>
                                                                {AddCartLoader ? <Spin /> : "add to cart"}
                                                            </CartContent>
                                                        </AddToCartButton>
                                                    </Form.Item>
                                                </div>
                                            }
                                        </Form>
                                    </AddToCartContainer>
                                    {
                                        (productState.product.description && productState.product.description.html) &&
                                        <>
                                            <HorizontalLine></HorizontalLine>
                                            <SortDescription
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        (productState.product.description &&
                                                            productState.product.description.html) ||
                                                        null,
                                                }}
                                            />
                                        </>
                                    }
                                    <div style={{ marginTop: -8, marginBottom: 5 }} >
                                        {madeIn && <ManufactureContent>
                                            {" "}
                                            <b> Made in India </b><span  >({(madeIn && madeIn) || ""})</span>
                                        </ManufactureContent>}
                                    </div>
                                    <div style={{ marginBottom: 8 }}>
                                        <MoreItemButton
                                            onClick={showDrawer}
                                        >
                                            more details
                                        </MoreItemButton>
                                    </div>
                                    <ProductTag>
                                        {
                                            productState.product.product_tags &&
                                            tags.map((item) => {
                                                return (
                                                    <>
                                                        <ProductBlock onClick={() => handleTags(item)} >#{item}</ProductBlock>
                                                    </>
                                                )
                                            })
                                        }
                                    </ProductTag>
                                </>
                            )}
                            {
                                productState.product.jaipur_only_delivery === 1 ?
                                    <Alert style={{ marginTop: 10 }} message="Delivery is only available in Jaipur, Rajasthan." banner />
                                    :
                                    <>
                                        <CheckDeliveryLabel><EnvironmentOutlined /> <span style={{ marginLeft: 2 }}>Check Delivery</span></CheckDeliveryLabel>
                                        <DeliveryContainer>
                                            <Form onFinish={getShippigoToken} form={pincodeForm}  >
                                                {
                                                    visiableInputData ?
                                                        <VisiablePinCodeInputValue onClick={() => {
                                                            setVisiableInputData(false)
                                                            pincodeForm.resetFields()
                                                            setPincodeData({ ...pincodeData, ["pinCode"]: "" })
                                                        }}><span style={{ display: 'flex', fontWeight: 600, alignItems: 'center' }} >{pincodeData.pinCode}<CheckIcon><CheckOutlined /></CheckIcon></span> <span style={{
                                                            fontWeight: "bold", cursor: "pointer", color: "#e49045", borderTopRightRadius: '10px',
                                                            borderBottomRightRadius: '10px'
                                                        }} >Change</span></VisiablePinCodeInputValue>
                                                        :
                                                        <Form.Item
                                                            name="pinCode"
                                                            rules={[{
                                                                required: true,
                                                                message: 'Enter PinCode',
                                                            }, {
                                                                pattern: new RegExp("^[0-9]{6}$"),
                                                                message: 'Enter valid pincode.',
                                                            }
                                                            ]}
                                                            onChange={handlePincodeChange}
                                                        >
                                                            <PinCodeInput name="pinCode" placeholder="Enter PIN code" enterButton={<SearchButton htmlType="submit" disabled={pincodeData.pinCode.length === 6 ? false : true} style={{
                                                                color: pincodeData.pinCode.length === 6 ? "#e49045" : "#bfc0c6", borderTopRightRadius: '10px', background: 'none',
                                                                borderBottomRightRadius: '10px', backgroundColor: 'none'
                                                            }} >Check</SearchButton>} />

                                                        </Form.Item>
                                                }
                                                {/* <Form.Item >
                                                // <PinCodeButton type="primary" htmlType="submit" loading={getShippigoTokenState.apiState === "loading" ? true : false} >check delivery</PinCodeButton>
                                                // </Form.Item> */}
                                            </Form>
                                        </DeliveryContainer>
                                    </>

                            }
                            <div style={{ marginTop: productState.product.jaipur_only_delivery === 1 ? 10 : -15 }} >
                                {pincodeData.pinCode.length > 0 ?
                                    <>
                                        {
                                            checkServiceAvailabilityState.apiState === "error" &&
                                            <DeliveryErrorMessage><CloseOutlined style={{ marginRight: '5px' }} /> {checkServiceAvailabilityState.apiState === "error" ? DeliveryNotAvaliable : null}</DeliveryErrorMessage>
                                        }
                                    </> : null
                                }

                                <ShippingMessage>
                                    <BrandItem>{(returnPolicy && returnPolicy) || ""} </BrandItem>
                                    <FreeShipingText>&nbsp;(Enjoy free shipping across India)</FreeShipingText>
                                </ShippingMessage>
                            </div>
                            <br />

                        </ProductContainer>
                    </Container>
                    <DrawerContainer
                        title="Product Details"
                        placement="bottom"
                        onClose={onClose}
                        visible={visible}
                        width={isMobile ? "100% !important" : "50% !important"}
                        height={"100%"}
                    >
                        <TableContainer
                            columns={columns}
                            dataSource={moreDetailsData}
                            className={tableCSS}
                            pagination={false}
                        />
                    </DrawerContainer>
                </DetailBackground>
            }
            <DrawerContainer
                title="Cart"
                placement="right"
                onClose={onClose}
                visible={visibleCart}
                width={isMobile ? "100% !important" : "50% !important"}
                height={"100%"}
            >
                <CartModal handleClose={handleClose} />
            </DrawerContainer>
            {
                (productState.apiState === "success" && productState.product !== undefined) &&
                <Footer isProductDetail productName={productState.product.name} sku={productState.product.sku} shareImage={thumbnail} history={history} brandId={brandData.brandId} brandUrlKey={brandData.urlKey} />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    productState: state.product,
    productUidState: state.product.productUid,
    configureProductState: state.product.configureProduct,
    configureProductValuesState: state.product.configureProductValues,
    isLoading: state.product.loading,
    productCartListItems: state.productCart.productCartListItems,
    AddCartLoader: state.productCart.isLoading,
    createGuestCartState: state.createGuestCart,
    addProductToCartState: state.addProductToCart,
    createCustomerCartState: state.createCustomerCart,
    checkDeliveryState: state.checkDelivery,
    addProductToWishListState: state.addProductToWishList,
    getCartCountState: state.getCartCount,
    getShippigoTokenState: state.getShippigoToken,
    checkServiceAvailabilityState: state.checkServiceAvailability,
    getBrandDetailsState: state.getBrandDetails,
    removeItemCartState: state.removeItemCart,
    fetchBrandByIdState: state.fetchBrandById,
    addConfigrableProductState: state.addConfigrableProduct,
})

const mapDispatchToProps = (dispatch) => ({
    getProductDetail: (params) => dispatch(getProductDetail(params)),
    getProductDetailReset: () => dispatch(getProductDetailReset()),
    fetchQuantity: (params) => dispatch(fetchQuantity(params)),
    getProductCartList: (params) => dispatch(getProductCartList(params)),
    brandRequest: (params) => dispatch(brandRequest(params)),
    fetchRelatedProductRequest: (params) => dispatch(fetchRelatedProductRequest(params)),
    createGuestCart: () => dispatch(createGuestCart()),
    createGuestCartReset: () => dispatch(createGuestCartReset()),
    addProductToCart: (params) => dispatch(addProductToCart(params)),
    addProductToCartReset: () => dispatch(addProductToCartReset()),
    createCustomerCart: (params) => dispatch(createCustomerCart(params)),
    createCustomerCartReset: () => dispatch(createCustomerCartReset()),
    fetchCartData: (params) => dispatch(fetchCartData(params)),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
    checkDelivery: (params) => dispatch(checkDelivery(params)),
    addProductToWishList: (params) => dispatch(addProductToWishList(params)),
    getShippigoToken: (params) => dispatch(getShippigoToken(params)),
    checkServiceAvailability: (params) => dispatch(checkServiceAvailability(params)),
    checkServiceAvailabilityReset: () => dispatch(checkServiceAvailabilityReset()),
    getBrandDetails: (params) => dispatch(getBrandDetails(params)),
    getBrandDetailsReset: () => dispatch(getBrandDetailsReset()),
    removeItemCart: (params) => dispatch(removeItemCart(params)),
    removeItemCartReset: () => dispatch(removeItemCartReset()),
    updateProductCart: (params) => dispatch(updateProductCart(params)),
    fetchBrandById: (params) => dispatch(fetchBrandById(params)),
    fetchBrandByIdReset: (params) => dispatch(fetchBrandByIdReset(params)),
    addConfigrableProduct: (params) => dispatch(addConfigrableProduct(params)),
    addConfigrableProductReset: (params) => dispatch(addConfigrableProductReset(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductDetail))
