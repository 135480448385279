import React, { useEffect, useState } from "react"
import { connect } from "react-redux";
import moment from 'moment'
import { Tag, Skeleton, Button, Space, notification } from 'antd'
import axios from "axios"
import { Redirect, Link } from "react-router-dom";

// custom components

import ProfileTabs from '../../components/ProfileTabs/ProfileTabs'
import {
    Container, Left, Right, OrderContainer, Top, PayBtn, AddressInfo,
    ShippingAddressInfo, BillingAddressInfo, EnteredOptionLabel,
    EnteredOptionValue, Price, ReorderButton
} from "./OrdersStyle"
import { DynamicSkeleton, Xpagination } from '../../components/Xcomponent'
import NoDataContianer from "../../components/NoDataContainer/NoData";

// actions
import { getCustomerInfo } from '../../actions/getCustomerInfoAction'
import { reOrder, reOrderReset } from '../../actions/reOrderActions'
import { getCartCount } from '../../actions/getCartCountAction'

// others
import { getCustomerToken, inr, SITE_URL, getCartId } from "../../utils"

const Orders = props => {
    // variables
    const {
        getCustomerInfo, getCustomerInfoState,
        reOrder, reOrderState, reOrderReset,
        getCartCount
    } = props

    const [currentPage, setCurrentPage] = useState(1)
    const [redirect, setRedirect] = useState([false, ""])
    const [visiable, setVisiable] = useState(false)

    // callbacks
    useEffect(() => {
        return (() => {
            reOrderReset()
        })
    }, [])
    useEffect(() => {
        getCustomerInfo({
            customerToken: getCustomerToken(),
            currentPage: currentPage,
        })
    }, [currentPage])

    useEffect(() => {
        if (reOrderState.apiState === "success") {
            if (reOrderState.data.length > 0) {
                notification["success"]({
                    message: `Some item can not be added to your cart.`,
                    placement: "bottomRight"
                })
            }
            getCartCount({
                cart_id: getCartId(),
                customerToken: getCustomerToken(),
            })
        }
        else if (reOrderState.apiState === "error") {
            notification["error"]({
                message: `An error occurred during processing. Please try again.`,
                placement: "bottomRight"
            })
        }
    }, [reOrderState])

    // functions
    const handlePaginationPageChange = (page, pageSize) => {
        setCurrentPage(page)
    }

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }
    useEffect(() => {
        if (getCustomerInfoState.apiState === "success") {
            getCustomerInfoState.data.customer.orders.items.length > 0 ? setVisiable(true) : setVisiable(false)
        }
    }, [getCustomerInfoState])

    async function displayRazorpay(order) {

        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        const result = await axios({
            method: 'post',
            url: `${SITE_URL()}/rest/default/V1/rzpay/first-api/`,
            data: {
                order_id: order.number
            },
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "authorization": `Bearer uchz0at3uos0eagb4ngmmnespkermn3g`
            }
        })


        if (!result) {
            alert("Server error. Are you online?");
            return;
        }



        const rzpData = JSON.parse(result.data)

        const options = {
            key: "rzp_live_CQNGe9SqJUIEOz", // Enter the Key ID generated from the Dashboard
            amount: rzpData.rzp_amount.toString(),
            currency: "INR",
            name: "",
            description: "",
            image: `${process.env.PUBLIC_URL}/images/Logo.svg`,
            order_id: rzpData.rzp_order_id,
            handler: async function (response) {
                setRedirect([true, "/thanks"])
                localStorage.removeItem("cartId")
            },
            prefill: {
                name: `${getCustomerInfoState.data.customer.firstname} " " ${getCustomerInfoState.data.customer.lastname}`,
                email: getCustomerInfoState.data.customer.email,
                contact: order.billing_address.telephone,
            },
            notes: {
                order_quote_id: rzpData.order_quote_id,
            },
            theme: {
                color: "#61dafb",
            },
            // modal: {
            //     ondismiss: function () {
            //         setRedirect([true, "/orders"])
            //     }
            // }
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const callReOrder = (orderNumber) => {
        reOrder({
            customerToken: getCustomerToken(),
            orderNumber: orderNumber
        })
    }


    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />
            }
            <Container>
                <Left>
                    <ProfileTabs active="orders" />
                </Left>
                <Right>
                    {getCustomerInfoState.apiState === "loading" &&
                        <Skeleton active />
                    }

                    {getCustomerInfoState.apiState === "error" &&
                        <h2>Something is not right</h2>}

                    {getCustomerInfoState.apiState === "success" &&
                        <>
                            {getCustomerInfoState.data.customer.orders.items.map(order => {

                                return (
                                    <OrderContainer>
                                        <div style={{ display: 'flex', alignItems: "center", justifyContent: "flex-end" }} >
                                            {
                                                (order.shipments && order.shipments.length > 0 && order.shipments[0].tracking.length > 0) &&
                                                <>
                                                    <span style={{ display: 'flex', justifyContent: "flex-start", width: '100%' }} >Track your order:
                                                        <Link to={{ pathname: `https://shiprocket.co/tracking/${order.shipments[0].tracking[0].number}` }} target="_blank"> {order.shipments[0].tracking[0].number}</Link>
                                                    </span>
                                                </>
                                            }

                                            <Space style={{ display: "flex", justifyContent: 'flex-end', marginBottom: 10 }} >
                                                <ReorderButton loading={reOrderState.apiState === "loading"} onClick={() => callReOrder(order.number)}>Reorder</ReorderButton>
                                                {order.status === "Pending" &&
                                                    <Tag color="gold">PENDING PAYMENT</Tag>
                                                }
                                                {order.status === "Processing" &&
                                                    <Tag color="success">PROCESSING</Tag>}
                                                {order.status === "Complete" &&
                                                    <Tag color="success">COMPLETE</Tag>}
                                                {order.status === "Canceled" &&
                                                    <Tag color="red">CANCELED</Tag>}
                                            </Space>
                                        </div>
                                        <Top>
                                            <div style={{ display: "flex" }} >
                                                <div style={{ display: "flex", flexDirection: "column", marginRight: 12 }} >
                                                    <span style={{ fontSize: 12, color: "rgb(86, 85, 85)", fontWeight: "bold" }} >Order ID:</span>
                                                    <span style={{ fontSize: 12, color: "#000" }}>{order.number}</span>
                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column", marginRight: 12 }} >
                                                    <span style={{ fontSize: 12, color: "rgb(86, 85, 85)", fontWeight: "bold" }} >Placed on:</span>
                                                    <span style={{ fontSize: 12, color: "#000" }}>{moment(order.order_date).format('DD-MMM-YYYY h:mm a')}</span>
                                                </div>
                                            </div>
                                        </Top>

                                        <AddressInfo>
                                            <ShippingAddressInfo>
                                                <span style={{ fontSize: 12, color: "rgb(86, 85, 85)", fontWeight: "bold" }} >Shipping Address:</span>
                                                <br />
                                                <span>{`${order.shipping_address.firstname} ${order.shipping_address.lastname}`}</span>
                                                <br />
                                                {order.shipping_address.street.map(text => (
                                                    <span>{text}</span>
                                                ))}
                                                <br />
                                                <span>{`${order.shipping_address.postcode}, ${order.shipping_address.city} ${order.shipping_address.region}`}</span>
                                                <br />
                                                <span>+91-{order.shipping_address.telephone}</span>
                                            </ShippingAddressInfo>
                                            <BillingAddressInfo>
                                                <span style={{ fontSize: 12, color: "rgb(86, 85, 85)", fontWeight: "bold" }} >Billing Address:</span>
                                                <br />
                                                <span>{`${order.billing_address.firstname} ${order.billing_address.lastname}`}</span>
                                                <br />
                                                {order.billing_address.street.map(text => (
                                                    <span>{text}</span>
                                                ))}
                                                <br />
                                                <span>{`${order.billing_address.postcode}, ${order.billing_address.city} ${order.billing_address.region}`}</span>
                                                <br />
                                                <span>+91-{order.billing_address.telephone}</span>
                                            </BillingAddressInfo>
                                        </AddressInfo>

                                        <div style={{ marginTop: 16 }} >
                                            {order.items.map(item => {
                                                return (

                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-between', marginBottom: 8 }} >
                                                        {/* <div style={{ width: 50, height: 50, background: "#ddd", borderRadius: 5 }} ></div> */}

                                                        <div>
                                                            <Link to={`/product-detail/${item.product_url_key}`}>
                                                                <div style={{ fontWeight: "bold" }} >{item.product_name}</div>
                                                            </Link>

                                                            <div style={{ display: 'flex' }} >
                                                                {
                                                                    item.entered_options &&
                                                                    <>
                                                                        {
                                                                            item.entered_options.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <EnteredOptionLabel>{item.label}: </EnteredOptionLabel>
                                                                                        <EnteredOptionValue>{item.value}</EnteredOptionValue>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            <div style={{ display: 'flex' }} >
                                                                {
                                                                    item.selected_options &&
                                                                    <>
                                                                        {
                                                                            item.selected_options.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <EnteredOptionLabel>{item.label}: </EnteredOptionLabel>
                                                                                        <EnteredOptionValue>{item.value}</EnteredOptionValue>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                            <div>
                                                                <span style={{ fontSize: 12, color: "rgb(86, 85, 85)", fontWeight: "bold" }} >QTY:</span>
                                                                <span style={{ marginLeft: 8 }} >{item.quantity_ordered}</span>
                                                            </div>

                                                        </div>
                                                        <Price>₹ {item.product_sale_price.value}</Price>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div style={{ borderTop: "1px dashed rgb(134 131 131)", marginTop: 16, marginBottom: 16 }} />
                                        <div style={{ marginTop: 8, paddingBottom: 8, display: "flex", justifyContent: "flex-end" }} >
                                            <table >
                                                <tr>
                                                    <td style={{ paddingRight: 16 }} >Subtotal</td>
                                                    <td>₹{inr(order.total.subtotal.value)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingRight: 16 }} >Tax</td>
                                                    <td>₹{inr(order.total.total_tax.value)}</td>
                                                </tr>
                                                {(order.total.discounts && order.total.discounts.length > 0) &&
                                                    <>
                                                        {order.total.discounts.map(row => (
                                                            <tr>
                                                                <td style={{ paddingRight: 16 }} >Discount ({row.label})</td>
                                                                <td>-₹{inr(row.amount.value)}</td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                }
                                                <tr>
                                                    <td style={{ paddingRight: 16 }} >{"Shipping & Handling"}</td>
                                                    <td>₹{inr(order.total.shipping_handling.total_amount.value)}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ paddingRight: 16 }} ><b>Grand Total</b></td>
                                                    <td><b>₹{inr(order.total.grand_total.value)}</b></td>
                                                </tr>
                                                {/* <tr>
                                                <td colSpan="2" style={{ textAlign: "right" }} >
                                                    {order.status === "Pending" &&
                                                        <PayBtn size="middle" onClick={() => displayRazorpay(order)}  >Pay Now</PayBtn>}
                                                </td>
                                            </tr> */}
                                            </table>
                                        </div>
                                    </OrderContainer>
                                )
                            })}

                            {
                                visiable &&
                                <Xpagination
                                    showSizeChanger={false}
                                    onChange={handlePaginationPageChange}
                                    current={getCustomerInfoState.data.customer.orders.page_info.current_page}
                                    defaultPageSize={5}
                                    total={getCustomerInfoState.data.customer.orders.total_count}
                                />
                            }

                        </>
                    }
                    {
                        getCustomerInfoState.apiState === "success" &&
                        <>
                            {
                                getCustomerInfoState.data.customer.orders.total_count === 0 &&
                                <>
                                    <NoDataContianer
                                        showText="No Order available."
                                        animationType={"cartAnimation"}
                                    />
                                    <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                                </>
                            }
                        </>
                    }

                </Right>


            </Container>
            {/* {
                getCustomerInfoState.apiState === "success" &&
                <>
                    {
                        getCustomerInfoState.data.customer.orders.total_count === 0 &&
                        <>
                            <NoDataContianer
                                showText="No Order available."
                                animationType={"cartAnimation"}
                            />
                            <Link to="/categories" style={{ display: 'flex', justifyContent: 'center', marginTop: '8px' }} ><Button>EXPLORE CATEGORY</Button></Link>
                        </>
                    }
                </>
            } */}
        </>
    )
}

const mapStateToProps = (state) => ({
    getCustomerInfoState: state.getCustomerInfo,
    reOrderState: state.reOrder,
});

const mapDispatchToProps = (dispatch) => ({
    getCustomerInfo: (params) => dispatch(getCustomerInfo(params)),
    getCartCount: (params) => dispatch(getCartCount(params)),
    reOrder: (params) => dispatch(reOrder(params)),
    reOrderReset: () => dispatch(reOrderReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Orders);