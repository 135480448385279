import {
    CREATE_CUSTOMER_ADDRESS,
    CREATE_CUSTOMER_ADDRESS_SUCCESS,
    CREATE_CUSTOMER_ADDRESS_FAIL,
    CREATE_CUSTOMER_ADDRESS_RESET,
} from '../actions/createCustomerAddressAction'


const initialState = {
    apiState: "", // loading, success, error
    data: {},
    message: ""
}

export default function (state = initialState, action) {
    switch (action.type) {
        case CREATE_CUSTOMER_ADDRESS:
            return {
                ...state,
                apiState: "loading",
            }

        case CREATE_CUSTOMER_ADDRESS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case CREATE_CUSTOMER_ADDRESS_FAIL:
            return {
                ...state,
                apiState: "error",
                message: action.response.errors[0] && action.response.errors[0].message || "Somthing is not right"
            }

        case CREATE_CUSTOMER_ADDRESS_RESET:
            return initialState


        default:
            return state
    }
}
