export const GET_CMS_PAGE = 'GET_CMS_PAGE'
export const GET_CMS_PAGE_SUCCESS = 'GET_CMS_PAGE_SUCCESS'
export const GET_CMS_PAGE_FAIL = 'GET_CMS_PAGE_FAIL'
export const GET_CMS_PAGE_RESET = 'GET_CMS_PAGE_RESET'

export const getCmsPage = (params) => {
    return { type: GET_CMS_PAGE, params }
}

export const getCmsPageSuccess = (response) => {
    return { type: GET_CMS_PAGE_SUCCESS, response }
}

export const getCmsPageFail = (response) => {
    return { type: GET_CMS_PAGE_FAIL, response }
}

export const getCmsPageReset = () => {
    return { type: GET_CMS_PAGE_RESET }
}