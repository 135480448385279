import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux";
import { LazyLoadImage } from 'react-lazy-load-image-component'
import InfiniteScroll from "react-infinite-scroll-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet'
//Components
import Footer from '../../components/Footer/Footer'
import Loader from '../../components/Loader/Loader'

//Style
import { Container, MainContainer, ArrowContainer, Arrow, BackButton } from "./backHomePageComponentStyle"
// Actions
import { getFeaturedImageList, getFeaturedImageListReset } from "../../actions/getFeaturedImageListAction";

const BackHomePageComponent = props => {
    const { history } = props;
    // variables
    const {
        getFeaturedImageList, getFeaturedImageListState, getFeaturedImageListReset
    } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [featuredImageData, setFeaturedImageData] = useState([])
    const [count, setCount] = useState(0)
    const [renderList, setRenderList] = useState("") // loading, success, error
    const prevCurrentPageRef = useRef();


    // Callback
    useEffect(() => {
        prevCurrentPageRef.current = currentPage
    }, [])

    const prevPage = prevCurrentPageRef.current

    useEffect(() => {
        window.scrollTo(0, 0)
        return (() => {
            getFeaturedImageListReset()
        })
    }, [])
    useEffect(() => {
        getFeaturedImageList({ attributeType: 'showcase_on_back_home_page', currentPage: currentPage })
    }, [currentPage])
    useEffect(() => {
        if (featuredImageData.length === 0) {
            setRenderList("loading")
        }
        if (getFeaturedImageListState.apiState === "success") {
            const imageListTotalCount = getFeaturedImageListState.data.products.total_count
            if (currentPage !== prevPage) {

                let data = [...featuredImageData]
                getFeaturedImageListState.data.products.items.map((item) => {
                    data.push(item)
                })
                setFeaturedImageData(data)
                handleCount(imageListTotalCount, data.length)
            }
            else {
                setFeaturedImageData(getFeaturedImageListState.data.products.items)
                handleCount(getFeaturedImageListState.data.products.items.length)
            }

        }

    }, [getFeaturedImageListState])

    const handleCount = (imageListTotalCount, dataLength) => {
        if (imageListTotalCount > dataLength) {
            setHasMore(true)
        }
        else {
            setHasMore(false)
        }
        setCount(count + 1)
        getFeaturedImageListReset()
    }

    useEffect(() => {
        if (count > 0) {
            setRenderList("success")
        }
    }, [count])
    const previousPath = useHistory();

    const goToPreviousPath = () => {
        previousPath.goBack();
    };

    return (
        <>
            {(renderList === "loading") &&
                <span style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', minHeight: '80vh', height: '100vh' }} >
                    <Loader height={60} width={80} />
                </span>
            }
            {/* {
                renderList === "success" &&
                <ArrowContainer onClick={goToPreviousPath}>
                    <Arrow>

                        <img
                            src={`${process.env.PUBLIC_URL}/images/ladu-arrow-back.svg`}
                            alt="ladu-india"
                            width="36"
                        />
                    </Arrow>
                    <BackButton>Back</BackButton>
                </ArrowContainer>
            } */}

            {featuredImageData.length > 0 &&
                <>
                    <InfiniteScroll
                        dataLength={featuredImageData.length}
                        next={() => {
                            setCurrentPage(currentPage + 1)
                        }
                        }
                        hasMore={hasMore}
                    >
                        {(renderList === "success" && featuredImageData.length > 0) &&
                            <Container >
                                <MainContainer>
                                    {/* <Link to="/">
                                        <BackButton />
                                    </Link> */}
                                    <div id="columns">
                                        {featuredImageData.map((item) => {
                                            return (
                                                <>
                                                    <figure>
                                                        {item.is_brand === 1 ?
                                                            <Link
                                                                to={`/brand/${item.url_key}`}>
                                                                <LazyLoadImage
                                                                    src={item.small_image.url}
                                                                    effect="blur"
                                                                />
                                                            </Link>
                                                            :
                                                            <Link to={`/product-detail/${item.url_key}`}>
                                                                <LazyLoadImage
                                                                    src={item.small_image.url}
                                                                    effect="blur"
                                                                />
                                                            </Link>
                                                        }


                                                    </figure>

                                                </>
                                            )
                                        })}
                                        <Helmet>
                                            <meta property="og:locale" content="en_US" />
                                            <meta property="og:type" content="article" />
                                            <meta property="og:site_name" content="Ladu India" />
                                        </Helmet>
                                    </div>
                                </MainContainer>
                            </Container>
                        }
                    </InfiniteScroll>
                    <div style={{ paddingBottom: 60, paddingTop: 10, display: "flex", justifyContent: "center" }} >
                        {getFeaturedImageListState.apiState === "loading" &&
                            <Loader />
                        }
                    </div>
                </>
            }
            {/* <Footer isBackHeader history={history} /> */}
        </>
    )
}

const mapStateToProps = (state) => ({
    searchTextState: state.searchText,
    getFeaturedImageListState: state.getFeaturedImageList,
});

const mapDispatchToProps = (dispatch) => ({
    getFeaturedImageList: (params) => dispatch(getFeaturedImageList(params)),
    getFeaturedImageListReset: () => dispatch(getFeaturedImageListReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(BackHomePageComponent);
