import {
    GET_MERGE_CART_ID,
    GET_MERGE_CART_ID_SUCCESS,
    GET_MERGE_CART_ID_FAIL,
  } from "../actions/mergeCartIdAction";
  import get from 'lodash/get';


  const initialState = {
    getMergeCartIdData:null,
    getMergeCartIdDataPrices:null,
  };
  
  export default function (state = initialState, action) {
   
    switch (action.type) {
      case GET_MERGE_CART_ID:
        return {
          ...state,
        };
  
      case GET_MERGE_CART_ID_FAIL:
        return {
          ...state,
  
        };
  
      case GET_MERGE_CART_ID_SUCCESS:
        return {
          ...state,
          getMergeCartIdData:get(action,"response.data.mergeCarts.items"),
          getMergeCartIdDataPrices:get(action,"response.data.mergeCarts.prices"),

        };
  
      default:
        return state;
    }
  }
  