import {
    SET_SHIPPING_ADDRESS,
    SET_SHIPPING_ADDRESS_SUCCESS,
    SET_SHIPPING_ADDRESS_FAIL,
    SET_SHIPPING_ADDRESS_RESET
} from '../actions/setShippingAddressAction'


const initialState = {
    apiState: "",
    data: {}
}


export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SHIPPING_ADDRESS:
            return {
                ...state,
                apiState: "loading"
            }
        case SET_SHIPPING_ADDRESS_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: action.response
            }
        case SET_SHIPPING_ADDRESS_FAIL:
            return {
                ...state,
                apiState: "error"
            }
        case SET_SHIPPING_ADDRESS_RESET:
            return initialState
        default:
            return state
    }
}