import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
 /* height: 100%; */
    

  
    #columns {
      column-width: 300px;
      column-gap: 0px;
      width: 100%;
      /* max-width: 1100px; */
      margin: 24px auto;
    }

    div#columns figure {
      background: #fefefe;
      margin: 0px;
      padding: 2px;
      /* padding-bottom: 5px; */
      transition: opacity .4s ease-in-out;
      display: inline-block;
      column-break-inside: avoid;
    }

    div#columns figure img {
      width: 100%; height: auto;
      margin-bottom: 0px;
      border-radius: 2px;
    }

    
 @media ${(props) => props.theme.device.tablet} {
    #columns {
        /* column-gap: 0px;  */
         column-width: 280px;
      /* column-gap: 8px; */
      width: 98%;
      max-width: 1100px;
      margin: 14px auto;

      }
      #columns figure { width: 100%;}
       div#columns figure {
      margin: 0px;
    }
  }
    /* @media screen and (max-width: 750px) { 
      #columns { 
         column-width: 150px;
      column-gap: 10px;
      width: 90%;
      max-width: 1100px;
      margin: 14px auto;
     
      }
      #columns figure { width: 100%;}
       div#columns figure {
      margin: 0 2px 5px;
    }
    } */
`
export const PolicyHeading = styled.div`
color:${(props) => props.theme.color.black};
`

export const MainContainer = styled.div`
margin-top: 0px;
@media ${props => props.theme.device.tablet} {
        margin-top: 0px;
    }
`

