import axios from 'axios'
import { BASE_URL } from '../utils';

const getTopProductApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
               {
                products(search: "${params.serachterm}", pageSize: 10) {
                    items {
                    name
                    url_key
                    is_brand
                    brand_name
                     thumbnail {
                            position
                                url
                            }
                    }
                }
            }
            `,
        }
    })
    return response.data;
}

export default getTopProductApi