import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    REMOVE_COUPON_TO_CART,
    removeCouponToCartSuccess,
    removeCouponToCartFail
} from '../actions/removeCouponToCartAction'

// api
import removeCouponToCartApi from '../apis/removeCouponToCartApi'

export function* removeCouponToCartSaga(action) {
    try {
        const response = yield call(() => removeCouponToCartApi(action.params))
        yield put(removeCouponToCartSuccess(response, action))

    } catch (e) {
        yield put(removeCouponToCartFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(REMOVE_COUPON_TO_CART, removeCouponToCartSaga)
}