import {
  FETCH_RELATED_PRODUCTS_REQUEST,
  FETCH_RELATED_PRODUCTS_SUCCESS,
} from "../actions/fetchRelatedProductsAction";
import { FETCH_FAVOURITE_COUNT_REQUEST } from "../actions/productListAction";
import get from "lodash/get";

const initialState = {
  getFavouriteCount: null,
  relatedProductsData: null,
  loading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FAVOURITE_COUNT_REQUEST:
      return {
        ...state,
        getFavouriteCount: action.payload,
      };

    //   case FETCH_RELATED_PRODUCTS_REQUEST:
    //     return {
    //       ...state,
    //       apiState: "loading",
    //       loading: true,
    //     };

    case FETCH_RELATED_PRODUCTS_REQUEST:
      const updatedData = get(action, "response.data.products.items");

      const data = action.params.map((item) => {
        return {
          ...item,
          isHeartFilled: false,
        };
      });
      return {
        ...state,
        apiState: "success",
        loading: false,
        relatedProductsData: data,
      };

    default:
      return state;
  }
}
