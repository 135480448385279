import styled from "styled-components";
import { Button, Input } from "antd";


export const SummaryContainer = styled.div`
background-color: #f8f8f8;
padding:20px;
margin:20px;
border-radius:5px;



`
export const OrderHeading = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
padding: 0 20px;
color: ${(props) => props.theme.color.black};
`
export const HorizontalLine = styled.div`
 background: #707070;
 margin: 12px 0;
 height: 2px;
 `

export const SummaryContent = styled.div`
 padding: 0 20px;
 `
export const ContentContainer = styled.div`
 display:flex;
 `
export const ContentName = styled.div`
 flex:1;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
 `
export const ContentPrice = styled.div`
 flex:1;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
//text-align: center;
margin-left:20px;
color: ${(props) => props.theme.color.black};
 `

export const CheckoutButton = styled(Button)`
 width: 100%;
 height:100%;
 border-radius: ${(props) => props.theme.border.borderRadius};
 padding: 10px 0;
 background-color: ${(props) => props.theme.backgroundColor.orange};
 color: ${(props) => props.theme.color.white};
 border-color: white;
 text-transform: capitalize;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
 font-weight: ${(props) => props.theme.fontWeight};
 font-stretch: ${(props) => props.theme.fontStretch};
 font-style: ${(props) => props.theme.fontStyle};
 font-family: ${(props) => props.theme.fontFamily};
 line-height: 1.5;
 letter-spacing: normal;
 &:hover,
 &:focus,
 &:active {
   background-color: ${(props) => props.theme.backgroundColor.orange};
   color: ${(props) => props.theme.color.white};
   border-color: white;
 }
 @media ${(props) => props.theme.device.tablet} {
   margin: 10px 0;
 }

 `

export const DeliveryContainer = styled.div`
 display: flex;
 //width: 250px;
 margin: 10px 0 20px 0;
`;
export const PinCodeInput = styled(Input)`
  margin-right: 10px;
 border-radius: ${(props) => props.theme.border.borderRadius} ;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
 font-weight: ${(props) => props.theme.fontWeight};
 font-stretch: ${(props) => props.theme.fontStretch};
 font-style: ${(props) => props.theme.fontStyle};
 font-family: ${(props) => props.theme.fontFamily};
 line-height: 1.5;
 letter-spacing: normal;
 color: #000000;
 && .ant-input:focus,
 .ant-input-focused {
   outline: 0;
 }

 &.ant-input-number:hover {
   border-color: #d9d9d9;
   border-right-width: 0;
 }
 &.ant-input-number:focus,
 .ant-input-number-focused {
   outline: 0;
   box-shadow: unset;
 }
`;
export const PinCodeButton = styled(Button)`
 border-radius: ${(props) => props.theme.border.borderRadius} ;
width: 100%;
 /* border-bottom-left-radius: 0;
 border-top-left-radius: 0; */
 background-color: #4b4b4b;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
 font-weight: ${(props) => props.theme.fontWeight};
 font-stretch: ${(props) => props.theme.fontStretch};
 font-style: ${(props) => props.theme.fontStyle};
 font-family: ${(props) => props.theme.fontFamily};
 line-height: 1.5;
 letter-spacing: normal;
 color: #ffffff;
 &:hover,
 &:focus,
 &:active {
   background-color: #4b4b4b;
   color: white;
   border-color: white;
 }
`;
