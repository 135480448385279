import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";
import {
  REMOVE_COUPON_REQUEST,
  removeCouponSuccess,
  removeCouponFail,
} from "../actions/removeCouponAction";
import reoveCouponApi from "../apis/removeCouponApi";
import get from 'lodash/get';


function* common(action = { params: {} }, success, otherParams) {
  if (typeof get(action, 'params.successAction') === 'function' && success) {
    yield action.params.successAction(otherParams);
  }
  if (typeof get(action, 'params.errorAction') === 'function' && !success) {
    yield action.params.errorAction(otherParams);
  }
}

export function* removeCouponSaga(action) {

  try {
    const response = yield call(() => reoveCouponApi(action));
    if (response.errors) {
      yield put(removeCouponFail(response, action));
      yield common(action, false, response);

    } else {
      yield put(removeCouponSuccess(response, action));
      yield common(action, true, response);

    }
  } catch (e) {
    yield put(removeCouponFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(REMOVE_COUPON_REQUEST, removeCouponSaga);
}
