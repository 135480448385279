import axios from "axios";
import { isAuthorized,BASE_URL } from "../utils";

const billingAddress = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setBillingAddressOnCart(
          input: {
            cart_id: "${params.cartId}"
            billing_address: {
                address: {
                  firstname: "${params.item.firstname}"
                  lastname: "${params.item.lastname}"
                  street: ["${params.item.street}"]
                  city: "${params.item.city}"
                  region: "${params.item.region.region_id}"
                  postcode:"${params.item.postcode}"
                  country_code: "${params.item.country_code}"
                  telephone: "${params.item.telephone}"
                  save_in_address_book: false
                }
              }
          }
        ) {
          cart {
            billing_address {
              firstname
              lastname
              company

              street
              city
              region {
                code
                label
              }
              postcode
              telephone
              country {
                code
                label
              }
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });

  return response.data;
};

export default billingAddress;
