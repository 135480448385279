import {
  FETCH_PAYMENT_METHOD_REQUEST,
  FETCH_PAYMENT_METHOD_SUCCESS,
  FETCH_PAYMENT_METHOD_FAIL,
  FETCH_PAYMENT_METHOD_RESET
} from "../actions/fetchPaymentMethodAction";
import get from 'lodash/get';

const initialState = {
  paymentMethodData: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        apiState: "loading"
      };

    case FETCH_PAYMENT_METHOD_FAIL:
      return {
        ...state,
        apiState: "error"
      };

    case FETCH_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        apiState: "success",
        paymentMethodData: get(action, 'response.data.setPaymentMethodOnCart.cart.selected_payment_method.code'),
      };
    case FETCH_PAYMENT_METHOD_RESET:
      return initialState

    default:
      return state;
  }
}
