import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "antd";
import {
  Container,
  LeftSection,
  RightSection,
  ImageSkeleton,
  ImageSkeletonLeft,
  ImageSkeletonRight,
  LeftBlock,
} from "./style";

const CheckoutSkeleton = (props) => {
  return (
    <Container>
      <LeftSection>
        <LeftBlock>
          <ImageSkeletonLeft active />
          <ImageSkeletonLeft active />
        </LeftBlock>
        <LeftBlock>
          <ImageSkeletonLeft active />
          <ImageSkeletonLeft active />
        </LeftBlock>
        <LeftBlock>
          <ImageSkeletonLeft active />
          <ImageSkeletonLeft active />
        </LeftBlock>
        <LeftBlock>
          <ImageSkeletonLeft active />
          <ImageSkeletonLeft active />
        </LeftBlock>
      </LeftSection>
      <RightSection>
        <ImageSkeletonRight value="square" active size={300} />
      </RightSection>
    </Container>
  );
};

CheckoutSkeleton.propTypes = {
  rows: PropTypes.number,
};

CheckoutSkeleton.defaultProps = {
  rows: 1,
};

export default CheckoutSkeleton;
