import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const guestSetShippingAddress = async (params) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setShippingAddressesOnCart(
          input: {
            cart_id: "${params.cartId}"
            shipping_addresses: [
              {
                address: {
                  firstname: "${params.firstname}"
                  lastname: "${params.lastname}"
                  street: ["${params.address}"]
                  city: "${params.city}"
                  region: "${params.state_id}"
                  postcode:"${params.pincode}"
                  country_code: "IN"
                  telephone: "${params.mobile}"
                  save_in_address_book: false
                }
              }
            ]
          }
        ) {
          cart {
            shipping_addresses {
              firstname
              lastname
              company
              street
              city
              region {
                code
                label
                region_id
              }
              postcode
              telephone
              country {
                code
                label
              }
              available_shipping_methods{
                  carrier_code
                  carrier_title
                  method_code
                  method_title
                  available
                  amount {
                    currency
                    value
                  }
              }
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });

  return response.data;
};

export default guestSetShippingAddress;

