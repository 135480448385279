import React from 'react'
import { Container, SubContainer } from "./HomePageCarosuelSkeletonStyle"
import { DynamicSkeleton } from '../Xcomponent'
const HomePageCarosuelSkeleton = () => {
    // variables
    const mobileL = window.innerWidth < 1024
    const mobileS = window.innerWidth < 570
    return (
        <Container>
            <SubContainer>
                <DynamicSkeleton active h="450px" />
            </SubContainer>
            {
                mobileS ?
                    <div></div> :
                    <SubContainer>
                        <DynamicSkeleton active h="450px" />
                    </SubContainer>
            }

            {
                mobileL ?
                    <div></div> :
                    <SubContainer>
                        <DynamicSkeleton active h="450px" />
                    </SubContainer>
            }

        </Container>
    )
}

export default HomePageCarosuelSkeleton
