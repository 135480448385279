export const GET_MERGE_CART_ID = "GET_MERGE_CART_ID";
export const GET_MERGE_CART_ID_SUCCESS = "GET_MERGE_CART_ID_SUCCESS";
export const GET_MERGE_CART_ID_FAIL = "GET_MERGE_CART_ID_FAIL";

export const getMergeCartId = (params) => {
  return { type: GET_MERGE_CART_ID, params };
};

export const getMergeCartIdSuccess = (response) => {
  return { type: GET_MERGE_CART_ID_SUCCESS, response };
};

export const getMergeCartIdFail = (response) => {
  return { type: GET_MERGE_CART_ID_FAIL, response };
};
