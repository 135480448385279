import styled from "styled-components";

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
@media ${(props) => props.theme.device.tablet} {
    margin-top: 10px;
  }
`
export const HelpHeading = styled.div`
color:${(props) => props.theme.color.black}
`

