import {
    GET_CATEGORY_LIST,
    GET_CATEGORY_LIST_SUCCESS,
    GET_CATEGORY_LIST_FAIL,

    TOGGLE_CATEGORY,
} from '../actions/categoryActions'
import _ from "lodash"

const initialState = {
    apiState: "", // loading, success, error
    list: [],
    visible: false,
    loading: true,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CATEGORY_LIST:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_CATEGORY_LIST_SUCCESS:
            let categories = (action.response.data.category) ? action.response.data.category.children : []
            return {
                ...state,
                apiState: "success",
                loading: false,
                list: { children: _.sortBy(categories, ['position']) }
            }

        case GET_CATEGORY_LIST_FAIL:
            return {
                ...state,
                apiState: "error",
            }




        case TOGGLE_CATEGORY:
            return {
                ...state,
                visible: !state.visible,
            }

        default:
            return state
    }
}
