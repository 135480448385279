export const ADD_COUPON_REQUEST = "ADD_COUPON_REQUEST";
export const ADD_COUPON_SUCCESS = "ADD_COUPON_SUCCESS";
export const ADD_COUPON_FAIL = "ADD_COUPON_FAIL";

export const addCouponRequest = (params) => {
  return { type: ADD_COUPON_REQUEST, params };
};

export const addCouponSuccess = (response) => {
  return { type: ADD_COUPON_SUCCESS, response };
};

export const addCouponFail = (response) => {
  return { type: ADD_COUPON_FAIL, response };
};
