import React, { useEffect, useState } from "react"
import { Button, Col, Form, Input, Row, Select, notification } from "antd"
import { connect } from "react-redux"

// actions
// import { createCustomerAddress, createCustomerAddressReset } from "../../actions/createCustomerAddressAction"
import { fetchStateRequest } from "../../actions/fetchStateAction"
import { updateCustomerAddress, updateCustomerAddressReset } from '../../actions/updateCustomerAddressAction'
import { getCustomerInfo, getCustomerInfoReset } from '../../actions/getCustomerInfoAction'

// others
import { getCustomerToken } from "../../utils"

const UpdateCustomerAddress = props => {
    // variables
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        address: "",
        pincode: "",
        city: "",
        state: "",
        state_code: "",
        state_id: "",
        mobile: "",
    })
    const [refresh, setRefresh] = useState(0)
    const [form] = Form.useForm()

    const {
        getCustomerInfoState, getCustomerInfoReset,
        fetchStateRequest, fetchStateData,
        updateCustomerAddress, updateCustomerAddressState, updateCustomerAddressReset, editForm,
    } = props
    const prefix = (
        <Button style={{ border: "none" }}>+ 91</Button>
    )


    // callbacks
    useEffect(() => {
        fetchStateRequest()
    }, [refresh])


    useEffect(() => {
        if (updateCustomerAddressState.apiState === "success") {
            notification["success"]({
                message: "Address saved successfully",
                placement: "topRight"
            })
            setRefresh(refresh + 1)
            updateCustomerAddressReset()
            // updateAddress(false)
        }
        if (updateCustomerAddressState.apiState === "error") {
            notification["error"]({
                message: "Failed to edit address",
                placement: "topRight"
            })
            updateCustomerAddressReset()
        }
    }, [updateCustomerAddressState])


    useEffect(() => {
        setFormData({
            ...formData,
            ["first_name"]: editForm.firstname,
            ["last_name"]: editForm.lastname,
            ["address"]: editForm.street,
            ["pincode"]: editForm.postcode,
            ["city"]: editForm.city,
            ["state"]: editForm.region.region,
            ["state_code"]: editForm.region.region_code,
            ["state_id"]: editForm.region.region_id,
            ["mobile"]: editForm.telephone,
        })
        form.setFieldsValue({
            first_name: editForm.firstname,
            last_name: editForm.lastname,
            address: editForm.street,
            pincode: editForm.postcode,
            city: editForm.city,
            state: editForm.region.region,
            mobile: editForm.telephone,
        })
    }, [editForm])

    // functions
    const handleOnChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = () => {
        updateCustomerAddress({
            formId: editForm.id,
            first_name: formData.first_name,
            last_name: formData.last_name,
            address: formData.address,
            pincode: formData.pincode,
            city: formData.city,
            state: formData.state,
            state_code: formData.state_code,
            state_id: formData.state_id,
            mobile: formData.mobile,
            customerToken: getCustomerToken()
        })
    }

    const handleSelect = (v) => {
        setFormData({
            ...formData,
            ["state_id"]: v.split("-")[0],
            ["state_code"]: v.split("-")[1],
            ["state"]: v.split("-")[2],
        })
    }

    return (
        <Form
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
        >
            <Row gutter="16" >
                <Col span="12" >
                    <Form.Item
                        name="first_name"
                        rules={[
                            { required: true, message: 'Required' },
                        ]}
                    >
                        <Input name="first_name" placeholder="First Name" onChange={handleOnChange} />
                    </Form.Item>
                </Col>

                <Col span="12" >
                    <Form.Item
                        name="last_name"
                        rules={[
                            { required: true, message: 'Required' },
                        ]}
                    >
                        <Input name="last_name" placeholder="Last Name" onChange={handleOnChange} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                name="address"
                rules={[
                    { required: true, message: 'Required' },
                ]}
            >
                <Input name="address" placeholder="Address" onChange={handleOnChange} />
            </Form.Item>

            <Row gutter="16" >
                <Col span="12" >
                    <Form.Item
                        name="pincode"
                        rules={[
                            { required: true, message: 'Required' },
                            {
                                pattern: new RegExp("^[0-9]{6}$"),
                                message: 'Enter valid pincode.',
                            }
                        ]
                        }
                    >
                        <Input name="pincode" placeholder="PIN code" onChange={handleOnChange} />
                    </Form.Item>
                </Col>

                <Col span="12" >
                    <Form.Item
                        name="city"
                        rules={[
                            { required: true, message: 'Required' },
                        ]}
                    >
                        <Input name="city" placeholder="City" onChange={handleOnChange} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter="16" >
                <Col span="12" >
                    <Form.Item
                        name="state"
                        rules={[
                            { required: true, message: 'Required' },
                        ]}
                    >
                        <Select name="state" placeholder="State"
                            showSearch
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={handleSelect}
                        >
                            {(fetchStateData || []).map(item => (
                                <Select.Option
                                    key={`${item.id}-${item.code}-${item.name}`} value={`${item.id}-${item.code}-${item.name}`} >
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span="12" >
                    <Form.Item
                        name="mobile"
                        rules={[
                            { required: true, message: 'Required' },
                            {
                                pattern: new RegExp('^[6-9]\\d{9}$'),
                                message: 'Mobile number is not valid',
                            }
                        ]}
                    >
                        <Input style={{ padding: 0 }} prefix={prefix} name="mobile" placeholder="Mobile" onChange={handleOnChange} />
                    </Form.Item>
                </Col>
            </Row>
            <Button type="primary"
                htmlType="submit"
                loading={updateCustomerAddressState.apiState === "loading" ? true : false} >Update</Button>
        </Form>
    )
}

const mapStateToProps = (state) => ({
    fetchStateData: state.fetchState.stateData,
    updateCustomerAddressState: state.updateCustomerAddress,
    getCustomerInfoState: state.getCustomerInfo
});

const mapDispatchToProps = (dispatch) => ({
    fetchStateRequest: () => dispatch(fetchStateRequest()),
    updateCustomerAddress: (params) => dispatch(updateCustomerAddress(params)),
    updateCustomerAddressReset: () => dispatch(updateCustomerAddressReset()),
    getCustomerInfoReset: () => dispatch(getCustomerInfoReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomerAddress);