import axios from 'axios'
import { BASE_URL } from '../utils';

const guestRemoveCouponToCartApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                    removeCouponFromCart(
                        input:
                        { cart_id: "${params.cart_id}" }
                        ) {
                        cart {
                        applied_coupons {
                            code
                        }
                        }
                    }
                    }`
        }
    });
    return response
}

export default guestRemoveCouponToCartApi
