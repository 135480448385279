import styled from "styled-components";
import { Button, InputNumber, Input } from 'antd'


export const Container = styled.div`
    width: 100%;
    display: flex;
    padding: 0px 32px;
    flex-wrap: wrap;
  
`
export const CardWrapper = styled.div`
    width: 70%;

    @media only screen and (max-width: 991px){
      width: 100% !important;
    }
`

export const CardBlock = styled.div`
    background-color: rgb(248, 248, 248);
    padding: 15px;
    display: flex;
    margin: 20px 0px;
    border-radius: 5px;
    align-items:center;
    justify-content: space-between;
    flex: 1.5;
    @media ${(props) => props.theme.device.tablet}{
      display: flex;
      justify-content: flex-start;
    }
`
export const CustomizeableContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
    margin: 0px 10px;
color: ${(props) => props.theme.color.black};
`;
export const MobileCustomizeableContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
font-weight: ${(props) => props.theme.fontWeightBold};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
text-align: left;
color: ${(props) => props.theme.color.black};
`;
export const DeleteItem = styled.div`
  margin: 0px 10px;
`

export const ImgBlock = styled.div`
    width: 100px;
    height: 100px;
    margin: 0px 10px;
`

export const CartSummery = styled.div`
    width: 30%;
    margin-left:32px;
    flex: 5.5;
    margin: 20px;

  @media only screen and (max-width: 991px){
    margin-left: 0px;
    margin-right: 0px;

  }

`
export const ProductPrice = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Mandali', sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-right: 12px;
    
`


export const ProductInfo = styled.div`
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.device.tablet}{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`
export const CardImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`


export const ProductName = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Mandali', sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 0, 0);
    max-width: 400px;
    margin: 0px 10px;


`

export const CartSummeryBlock = styled.div`
    background-color: rgb(248, 248, 248);
    font-family: 'Mandali',sans-serif;
    line-height: 1.5;
    padding: 20px;

`
export const OrderSummary = styled.div`
    background-color: rgb(248, 248, 248);
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    font-family: 'Mandali',sans-serif;
    text-align: left;
    color: rgb(0,0,0);
    margin-bottom: 10px;
`

export const QuantityContainer = styled.div`
  display: flex;

  //  flex: 0.5;
`;
export const MobileCardBlock = styled.div`
    background-color: rgb(248, 248, 248);
    padding: 15px;
    display: flex;
    margin: 20px 0px;
    border-radius: 5px;
    width: 100%;
    align-items:center;
    justify-content: space-between;
    flex: 1.5;
 
`
export const MobileCardImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  
`
export const MobileImgBlock = styled.div`
    width: 100px;
    height: 100px;
    margin-left: 10px;
`
export const MobileProductInfo = styled.div`
  display: flex;
  background-color: rgb(248, 248, 248);
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`
export const MobileProductName = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Mandali', sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 0, 0);
  width: 100%;

`
export const MobileProductPrice = styled.div`
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Mandali', sans-serif;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: rgb(0, 0, 0);
    margin-right: 12px;
    
`
export const MobileQuantityContainer = styled.div`
  display: flex;

  //  flex: 0.5;
`;

export const DecreamentButton = styled(Button)`
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right: 0;
  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #d9d9d9;
  }
`;
export const InputQuantity = styled(InputNumber)`
  border-radius: 0;
  max-width: 40px;
  border-left: 0;
  border-right: 0;
  .ant-input-number-handler-wrap {
    display: none;
  }
  
  &&.ant-input-number-disabled{
    background-color:#ffffff !important;
    color:#000;
  }

  && .ant-input:focus,
  .ant-input-focused {
    outline: 0;
  }

  &.ant-input-number:hover {
    border-color: #d9d9d9;
    border-right-width: 0;
  }
  &.ant-input-number:focus,
  .ant-input-number-focused {
    outline: 0;
    box-shadow: unset;
  }
`;
export const IncreamentButton = styled(Button)`
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-left: 0;
  &.ant-btn:hover,
  &.ant-btn:focus {
    border-color: #d9d9d9;
  }
`;
export const SubTotal = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Shipping = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const Total = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const DisCount = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const CheckoutButton = styled(Button)`
 width: 100%;
 height:100%;
 border-radius: 10px;
 text-transform: capitalize;
 padding: 10px 0;
 background-color: ${(props) => props.theme.backgroundColor.orange};
 color: ${(props) => props.theme.color.white};
 border-color: white;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
 font-weight: ${(props) => props.theme.fontWeight};
 font-stretch: ${(props) => props.theme.fontStretch};
 font-style: ${(props) => props.theme.fontStyle};
 font-family: ${(props) => props.theme.fontFamily};
 line-height: 1.5;
 letter-spacing: normal;
 &:hover,
 &:focus,
 &:active {
   background-color: ${(props) => props.theme.backgroundColor.orange};
   color: ${(props) => props.theme.color.white};
   border-color: white;
 }
 @media ${(props) => props.theme.device.tablet} {
   margin: 10px 0;
 }

 `
export const HorizontalLine = styled.div`
 border: solid 1px #707070;
 margin: 15px 0;
 `
export const ArrowContainer = styled.div`
margin: 20px 0 0 20px;
cursor:pointer;
& img{
  border-radius: 50%;
  &:hover{
    background-color: #e2e2e2;
  }
  padding: 10px;

  @media ${(props) => props.theme.device.tablet} {
    padding-top:5px;
  }
}
@media ${(props) => props.theme.device.tablet} {
  margin: 10px;
}
`;
export const PinCodeInput = styled(Input)`
 border-bottom-right-radius: 0;
 border-top-right-radius: 0;
 margin-right: 10px;
 border-radius: ${(props) => props.theme.border.borderRadius} ;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
 font-weight: ${(props) => props.theme.fontWeight};
 font-stretch: ${(props) => props.theme.fontStretch};
 font-style: ${(props) => props.theme.fontStyle};
 font-family: ${(props) => props.theme.fontFamily};
 line-height: 1.5;
 letter-spacing: normal;
 color: #000000;
 && .ant-input:focus,
 .ant-input-focused {
   outline: 0;
 }

 &.ant-input-number:hover {
   border-color: #d9d9d9;
   border-right-width: 0;
 }
 &.ant-input-number:focus,
 .ant-input-number-focused {
   outline: 0;
   box-shadow: unset;
 }
`;
export const PinCodeButton = styled(Button)`
 width: 100%;
 border-bottom-left-radius: 0;
 border-top-left-radius: 0;
 background-color: #4b4b4b;
  border-radius: ${(props) => props.theme.border.borderRadius} ;
 font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
 font-weight: ${(props) => props.theme.fontWeight};
 font-stretch: ${(props) => props.theme.fontStretch};
 font-style: ${(props) => props.theme.fontStyle};
 font-family: ${(props) => props.theme.fontFamily};
 line-height: 1.5;
 letter-spacing: normal;
 color: #ffffff;
 &:hover,
 &:focus,
 &:active {
   background-color: #4b4b4b;
   color: white;
   border-color: white;
 }
`;

