import styled from "styled-components";
import { Tabs } from "antd";

export const Container = styled.div`
height: 100%;
display: flex;
align-items: center;
justify-content: center;
`
export const ContactHeading = styled.div`
//color:${(props) => props.theme.color.white}
`

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  @media ${(props) => props.theme.device.tablet} {
//    display: none;
  }
`;

export const TabsComponent = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0px;
  }
  &.ant-tabs {
    height: 64px;
  }
  &.ant-tabs > .ant-tabs-nav {
    height: 100%;
  }
  && .ant-tabs-tab {
    margin: 0 40px 0 0;
    font-size: ${(props) => props.theme.fontSize.fontSizeTablet};
    font-stretch: ${(props) => props.theme.fontStretch};
    font-style: ${(props) => props.theme.fontStyle};
    font-family: ${(props) => props.theme.fontFamily};
    line-height: 1.5;
    letter-spacing: normal;
  }
  && .ant-tabs-tab-btn {
    // color: ${(props) => props.theme.color.white};
  }
  && .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props) => props.theme.color.black};
    font-weight: ${(props) => props.theme.fontWeightBold};
  }
  &&.ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    position: absolute;
    right: 0;
    left: 0;
    border-bottom: unset;
    content: "";
  }
  && .ant-tabs-ink-bar {
    background: #f78c3e;
    height: 4px;
    border-radius: 50px;
  }
  @media ${(props) => props.theme.device.tablet} {
    // display: none;
  }
`;

