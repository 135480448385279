import "regenerator-runtime/runtime";
import { call, put, takeLatest } from "redux-saga/effects";

import {
  FETCH_ADDRESS_REQUEST,
  fetchAddressSuccess,
  fetchAddressFail,
} from "../actions/fetchAddressAction";
import { globalLoaderRequest, globalLoaderSuccess, globalLoaderFail } from '../actions/showGlobalLoaderAction';


import fetchAddressApi from "../apis/fetchAddressApi";

export function* fetchAddressSaga(action) {

  try {
    yield put(globalLoaderRequest(true))

    const response = yield call(() => fetchAddressApi(action));
    yield put(globalLoaderRequest(false))

    if (response.errors) {
      yield put(fetchAddressFail(response, action));
    } else {
      yield put(fetchAddressSuccess(response, action));
      yield put(globalLoaderSuccess())

    }
  } catch (e) {
    yield put(fetchAddressFail(e.message, action));
  }
}

export default function* mySaga() {
  yield takeLatest(FETCH_ADDRESS_REQUEST, fetchAddressSaga);
}
