export const ADD_PRODUCT_TO_WISH_LIST = "ADD_PRODUCT_TO_WISH_LIST"
export const ADD_PRODUCT_TO_WISH_LIST_SUCCESS = "ADD_PRODUCT_TO_WISH_LIST_SUCCESS"
export const ADD_PRODUCT_TO_WISH_LIST_FAIL = "ADD_PRODUCT_TO_WISH_LIST_FAIL"
export const ADD_PRODUCT_TO_WISH_LIST_RESET = "ADD_PRODUCT_TO_WISH_LIST_RESET"

export const addProductToWishList = (params) => {
    return { type: ADD_PRODUCT_TO_WISH_LIST, params }
}
export const addProductToWishListSuccess = (response) => {
    return { type: ADD_PRODUCT_TO_WISH_LIST_SUCCESS, response }
}
export const addProductToWishListFail = (response) => {
    return { type: ADD_PRODUCT_TO_WISH_LIST_FAIL, response }
}
export const addProductToWishListReset = () => {
    return { type: ADD_PRODUCT_TO_WISH_LIST_RESET }
}