import React, { useState, useEffect } from "react"
import { notification } from "antd"
import { Redirect } from "react-router-dom"
import { connect } from 'react-redux'

// action

import { getCartCountReset } from '../actions/getCartCountAction'

const Logout = props => {
    // variables

    const { getCartCountReset } = props

    const [redirect, setRedirect] = useState([false, ""])

    // callbackes
    useEffect(() => {
        localStorage.removeItem('customerToken')
        localStorage.removeItem('cartId')
        notification.success({
            message: "You have successfully logged out!",
            placement: "bottomRight"
        })
        setRedirect([true, "/"])
        getCartCountReset()
    }, [])

    return (
        <>
            {redirect[0] &&
                <Redirect to={redirect[1]} />}
        </>
    )
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
    getCartCountReset: () => dispatch(getCartCountReset()),

});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);