import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    SET_SHIPPING_REQUEST,
    setShippingSuccess,
    setShippingFail,
} from '../actions/setShippingAction'


import setShippingApi from '../apis/setShippingApi'
import get from 'lodash/get';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}

export function* setShippingSaga(action) {
    try {
        const response = yield call(() => setShippingApi(action.params))
        if (response.errors) {
            yield put(setShippingFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(setShippingSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(setShippingFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SET_SHIPPING_REQUEST, setShippingSaga);
}