import {
  FETCH_SEARCH_DATA,
  FETCH_SEARCH_DATA_SUCCESS,
  FETCH_SEARCH_DATA_FAIL,
  FETCH_SEARCH_DATA_RESET
} from "../actions/fetchSearchAction";
import { FETCH_FAVOURITE_COUNT_REQUEST } from "../actions/productListAction";
import get from "lodash/get";

const initialState = {
  getFavouriteCount: null,
  searchData: "",
  loading: false,
  searchCountData: "",
  apiState: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_FAVOURITE_COUNT_REQUEST:
      return {
        ...state,
        getFavouriteCount: action.payload,
      };

    case FETCH_SEARCH_DATA:
      return {
        ...state,
        apiState: "loading",
        loading: true,
      };

    case FETCH_SEARCH_DATA_SUCCESS:
      const updatedData = get(action, "response.data.products.items");
      const searchCount = get(action, "response.data.products");

      const data = updatedData.map((item) => {
        return {
          ...item,
          isHeartFilled: false,
        };
      });
      return {
        ...state,
        apiState: "success",
        loading: false,
        searchData: data,
        searchCountData: searchCount
      };

    case FETCH_SEARCH_DATA_FAIL:
      return {
        ...state,
        apiState: "error",
      };

    case FETCH_SEARCH_DATA_RESET:
      return initialState

    default:
      return state;
  }
}
