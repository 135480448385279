import axios from "axios";
import { BASE_URL } from "../utils";

const signUpApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        createCustomer(
          input: {
            firstname: "${data.params.data.firstname}"
            lastname: "${data.params.data.lastname}"
            email: "${data.params.data.email}"
            password: "${data.params.data.password}"
            is_subscribed: true
          }
        ) {
          customer {
            firstname
            lastname
            email
            is_subscribed
          }
        }
      }`,
    },
    headers: {},
  });
  return response.data;
};

export default signUpApi;

