export const GUEST_SALES_PLACE_ORDER = 'GUEST_SALES_PLACE_ORDER'
export const GUEST_SALES_PLACE_ORDER_SUCCESS = 'GUEST_SALES_PLACE_ORDER_SUCCESS'
export const GUEST_SALES_PLACE_ORDER_FAIL = 'GUEST_SALES_PLACE_ORDER_FAIL'
export const GUEST_SALES_PLACE_ORDER_RESET = 'GUEST_SALES_PLACE_ORDER_RESET'

export const guestSalesPlaceOrder = (params) => {
    return { type: GUEST_SALES_PLACE_ORDER, params }
}

export const guestSalesPlaceOrderSuccess = (response) => {
    return { type: GUEST_SALES_PLACE_ORDER_SUCCESS, response }
}

export const guestSalesPlaceOrderFail = (response) => {
    return { type: GUEST_SALES_PLACE_ORDER_FAIL, response }
}
export const guestSalesPlaceOrderReset = () => {
    return { type: GUEST_SALES_PLACE_ORDER_RESET }
}

