import styled from "styled-components"
import { Button, Drawer } from 'antd'

export const HeartContainer = styled.div`
    position: absolute;
    cursor: pointer;
    transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
    opacity: 0;
    height: 24px;

    &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out 0s;
    }
     @media only screen and (max-width: 991px){
        opacity: 1;
    }
`

export const WhishlistedContainer = styled(Button)`
    position: absolute;
    opacity: 1;
    border: none;
    background: none !important;
`
export const LabelContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    
`
export const WishListBlock = styled.span`
    background: #ffffffb3;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

     @media only screen and (max-width: 991px){
        opacity: 1;
    }
    
`
export const HeartBlock = styled.span`
    background: #ffffffb3;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    opacity: 0;
    backdrop-filter: saturate(180%) blur(20px);

     @media only screen and (max-width: 991px){
        opacity: 1;
    }
    
`

export const Label = styled.span`
    background: #2d7b41e0;
   border-radius: ${(props) => props.theme.border.borderRadius};
    color: #fff;
    padding: 0px 6px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 4px;
`
export const Label2Container = styled.div`
    position: absolute;
    top: 25px;
    left: 5px;
`

export const Label2 = styled.span`
    color: #2d7b41e0;
    padding: 0px 6px;
    font-size: 12px;
    font-weight: 700;
    margin-right: 4px;
`
export const AddToCartBtn = styled.span`
    width: 100%;
    /* background: rgba(0,0,0,0.6); */
     backdrop-filter: saturate(180%) blur(20px);
    /* background-color: rgba(255,255,255,0.72); */
    background-color: rgba(0,0,0,0.40);
    z-index: 999;
    padding: 10px 0px;
    color: #fff;
    display: none;
    transition: 0.8s ease-in-out !important;
    text-align: center;
    position: absolute;
    bottom: 0;

`

export const Container = styled.div`
    margin: 16px;
    position: relative;
    

    &:hover ${HeartBlock} {
        opacity: 1;
    }
    &:hover ${HeartContainer} {
        opacity: 1;
    }
    &:hover ${AddToCartBtn} {
        display: block;
        width: 100%;
        text-align: center;
        cursor: pointer;
        @media screen and (max-width: 991px){
            display: none;
        }
    }
`


export const SubContainer = styled.div`
    background: #f5f5f5;
    border-radius: 8px;
    

    &:hover {
        box-shadow: rgb(0 0 0 / 10%) 0px 2px 3px 0px;
        transition: box-shadow 0.2s linear 0s;

        .product_img {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out 0s;
        }
    }

    .product_img {
        transform: scale(1.0);
        transition: transform 0.3s ease-in-out 0s;
    }

`

export const DetailContainer = styled.div`
    padding: 10px 10px;
    background: #eaeaea96;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

export const Name = styled.div`
    font-weight: 600;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
    line-height: 18px;
    margin-top: 8px;
`

export const Brand = styled.div`
    font-weight: 500;
    font-size: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
    /* color: ${props => props.theme.color.primary}; */
    line-height: 18px;
`

export const Price = styled.span`
    font-weight: 600;
    margin-right: 8px;
    color: #000;
    line-height: 18px;
    font-size: 14px;
`

export const SpecialPrice = styled.span`
    font-weight: 600;
    margin-right: 8px;
    color: #000;
    line-height: 18px;
`

export const Off = styled.span`
    color: ${props => props.theme.color.primary};
    font-weight: 600;
    line-height: 18px;
`
export const ImgBlock = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`

export const ImgContainer = styled.div`
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: solid 1px #efefef;
    border-radius: 8px 8px 0 0;

    /* @media only screen and (max-width: 1200px){
        height: auto;
    } */
`
export const AddToCartButton = styled.span`
    background: rgba(0,0,0,0.6);
    color: ${(props) => props.theme.color.white};
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    /* opacity: 0; */
    display: none;
     &:hover{
        background: rgba(0,0,0,0.6);
        color: ${(props) => props.theme.color.white};
    }
    @media only screen and (max-width: 991px){
        display: flex;
        align-items: center;
        justify-content: center;
    }

`

export const DrawerContainer = styled(Drawer)`
.ant-drawer-body{
   @media ${(props) => props.theme.device.tablet} {
  padding: 0;
  }
}
.ant-drawer-content-wrapper{
  width:54% !important;
  float: right;
  position: relative;
  @media ${(props) => props.theme.device.tablet} {
    width:100% !important;

  }
}

`