import { call, put, takeLatest } from 'redux-saga/effects'

// action
import { GET_SHIPPIGO_TOKEN, getShippigoTokenSuccess, getShippigoTokenFail } from '../actions/getShippigoTokenAction'

// api
import getShippigoTokenApi from '../apis/getShippigoTokenApi'

export function* getShippigoTokenSaga(action) {
    try {
        const response = yield call(() => getShippigoTokenApi(action.params))
        if (response.data.status === "FAILED") {
            yield put(getShippigoTokenFail(response, action))
        }
        else {
            yield put(getShippigoTokenSuccess(response, action))
        }
    }
    catch (e) {
        yield put(getShippigoTokenFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(GET_SHIPPIGO_TOKEN, getShippigoTokenSaga)
}