import axios from 'axios'
import { BASE_URL } from '../utils';

const getWishListApi = async (params) => {
    const response = await axios({
        method: "post",
        url: `${BASE_URL()}`,
        data: {
            query: `
                    {
                wishlist {
                    items_count
                    name
                    sharing_code
                    updated_at
                    items {
                    id
                    qty
                    description
                    added_at
                    product {
                        sku
                        name
                        url_key
                        image{
                            url
                        }
                        thumbnail{
                            url
                        }
                        media_gallery {
                            url
                        }
                         special_price
                            price_range{
                                minimum_price{
                                    regular_price {
                                        value
                                    }
                                    discount{
                                        percent_off
                                    }
                                }
                            }
                    }
                    }
                }
            }`
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data
}

export default getWishListApi