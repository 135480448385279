import { call, put, take, takeLatest } from 'redux-saga/effects'

// action
import {
    SHOW_ON_HOME_PAGE_BRAND_SECTION,
    showOnHomePageBrandSectionFail,
    showOnHomePageBrandSectionSuccess
} from '../actions/showOnHomePageBrandSectionAction'

// api
import showOnHomePageBrandSectionApi from '../apis/showOnHomePageBrandSectionApi'

export function* showOnHomePageBrandSectionSaga(action) {
    try {
        const response = yield call(() => showOnHomePageBrandSectionApi(action.params))
        if (response) {
            yield put(showOnHomePageBrandSectionSuccess(response, action))
        }
    }
    catch (e) {
        yield put(showOnHomePageBrandSectionFail(e.response, action))
    }
}

export default function* mySaga() {
    yield takeLatest(SHOW_ON_HOME_PAGE_BRAND_SECTION, showOnHomePageBrandSectionSaga)
}