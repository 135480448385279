import {
    GET_CUSTOMER_CART_ID,
    GET_CUSTOMER_CART_ID_SUCCESS,
    GET_CUSTOMER_CART_ID_FAIL,
  } from "../actions/customerCartIdAction";
  import get from 'lodash/get';

  const initialState = {
  getCustomerId:null
  };
  
  export default function (state = initialState, action) {
   
    switch (action.type) {
      case GET_CUSTOMER_CART_ID:
        return {
          ...state,
        };
  
      case GET_CUSTOMER_CART_ID_FAIL:
        return {
          ...state,
  
        };
  
      case GET_CUSTOMER_CART_ID_SUCCESS:
        return {
          ...state,
          getCustomerId:get(action,"response.data.customerCart.id"),
        };
  
      default:
        return state;
    }
  }
  