import axios from "axios";
import { BASE_URL } from "../utils";

const getGuestCartDataApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                     customer {
                        firstname
                        lastname
                        email
                     }
                    cart(cart_id: "${params.cart_id}") {
                       billing_address {
                        city
                        country {
                        code
                        label
                        }
                        firstname
                        lastname
                        postcode
                        region {
                        code
                        label
                        }
                        street
                        telephone
                        }
                        items {
                        id
                        product { 
                            sku
                            stock_status
                            name
                            price_range {
                            minimum_price {
                                final_price {
                                currency
                                value
                                }
                            }
                            }
                            image {
                                url
                            }
                            thumbnail{
                                url
                            }
                            media_gallery {
                            position
                            url
                            }
                            short_description {
                            html
                            }
                            description {
                            html
                            }
                        }
                        quantity
                        }
                        shipping_addresses {
                        customer_notes
                        pickup_location_code
                        postcode
                        available_shipping_methods {
                            error_message
                            method_code
                            method_title
                            amount {
                            currency
                            value
                            }
                        }
                        }
                        applied_coupons {
                        code
                        }
                        prices {
                        subtotal_including_tax {
                            value
                        }
                        grand_total {
                            value
                        }
                        discounts {
                            amount {
                            value
                            }
                            label
                        }
                        }
                    }
                }
            `,
        },
    });
    return response.data;
};

export default getGuestCartDataApi;
