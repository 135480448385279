import axios from "axios";
import { isAuthorized, BASE_URL } from '../utils';


const fetchOrderApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `{
        customer {
          orders( pageSize: 20) {
            total_count
            items {
              id
              number
              order_date
              status
              shipments{
                id
                comments{
                  message
                  timestamp
                }
                tracking{
                  carrier
                  number
                }
              }
              shipping_address{
                city
                firstname
                lastname
                street
                region
                telephone
                postcode
              }
              items {
                product_name
                product_sku
                product_url_key
                product_sale_price {
                  value
                }
                product_sale_price {
                  value
                  currency
                }
                quantity_ordered
                quantity_invoiced
                quantity_shipped
              }
              carrier
              shipments {
                id
                number
                items {
                  product_name
                  quantity_shipped
                }
              }
              total {
                base_grand_total {
                  value
                  currency
                }
                grand_total {
                  value
                  currency
                }
                total_tax {
                  value
                }
                subtotal {
                  value
                  currency
                }
                taxes {
                  amount {
                    value
                    currency
                  }
                  title
                  rate
                }
                total_shipping {
                  value
                }
                shipping_handling {
                  amount_including_tax {
                    value
                  }
                  amount_excluding_tax {
                    value
                  }
                  total_amount {
                    value
                  }
                  taxes {
                    amount {
                      value
                    }
                    title
                    rate
                  }
                }
                discounts {
                  amount {
                    value
                    currency
                  }
                  label
                }
              }
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default fetchOrderApi;
