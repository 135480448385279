import axios from "axios";
import { BASE_URL } from "../utils";

const getCmsPageApi = async (params) => {

    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `
                {
                    cmsPage(identifier:"${params.url_key}") {
                        content
                    }
                }
            `,
        }
    });
    return response.data;
};

export default getCmsPageApi;
