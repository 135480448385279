export const GET_TOP_PRODUCT = "GET_TOP_PRODUCT"
export const GET_TOP_PRODUCT_SUCCESS = "GET_TOP_PRODUCT_SUCCESS"
export const GET_TOP_PRODUCT_FAIL = "GET_TOP_PRODUCT_FAIL"
export const GET_TOP_PRODUCT_RESET = "GET_TOP_PRODUCT_RESET"


export const getTopProduct = (params) => {
    return { type: GET_TOP_PRODUCT, params }
}

export const getTopProductSuccess = (response) => {
    return { type: GET_TOP_PRODUCT_SUCCESS, response }
}

export const getTopProductFail = (response) => {
    return { type: GET_TOP_PRODUCT_FAIL, response }
}

export const getTopProductReset = () => {
    return { type: GET_TOP_PRODUCT_RESET }
}