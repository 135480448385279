import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const removeCartProductApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        removeItemFromCart(
          input: {
            cart_id: "${data.params.cart_id}",
            cart_item_id: ${data.params.cart_item_id}
          }
        )
       {
        cart {
          prices{
            subtotal_including_tax{
              value
            }
            grand_total{
              value
            }
            discounts{
              amount{
                value
              }
              label
            }
          }
          items {
            id
            product {
              sku
              stock_status
              url_key
              name
              price_range{
                minimum_price{
                  final_price{
                    currency
                    value
                  }
                }
              }
              image {
                  url
              }
              media_gallery{
                position
                url
              }
              
              short_description{
                html
              }
              description{
                  html
              }  
            }
            quantity
          }
          prices {
            grand_total{
              value
              currency
            }
          }


        }
       }
      }`,
    },
    headers: isAuthorized(),
  });
  return response
};

export default removeCartProductApi;
