import { GET_TOP_PRODUCT, GET_TOP_PRODUCT_SUCCESS, GET_TOP_PRODUCT_FAIL, GET_TOP_PRODUCT_RESET } from '../actions/getTopProductAction'

const initialState = {
    apiState: "", // Loading, success, fail
    items: []
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_TOP_PRODUCT:
            return {
                ...state,
                apiState: 'loading'
            }
        case GET_TOP_PRODUCT_SUCCESS:
            return {
                ...state,
                apiState: 'success',
                items: action.response.data && action.response.data.products && action.response.data.products.items || []
            }
        case GET_TOP_PRODUCT_FAIL:
            return {
                ...state,
                apiState: 'error'
            }
        case GET_TOP_PRODUCT_RESET:
            return initialState

        default:
            return state
    }
}