import {
  ADD_WISHLIST_REQUEST,
  ADD_WISHLIST_SUCCESS,
  ADD_WISHLIST_FAIL,
} from "../actions/addWishListAction";
import get from "lodash/get";


const initialState = {
  addWishListData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_WISHLIST_REQUEST:
      return {
        ...state,
      };

    case ADD_WISHLIST_FAIL:
      return {
        ...state,
      };

    case ADD_WISHLIST_SUCCESS:
      return {
        ...state,
        //  addWishListData: get(action, "response.data.country.available_regions"),
      };

    default:
      return state;
  }
}
