import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    CREATE_CUSTOMER_CART,
    createCustomerCartSuccess,
    createCustomerCartFail,
} from '../actions/createCustomerCartAction'

import createCustomerCartApi from '../apis/createCustomerCartApi'


export function* createCustomerCartSaga(action) {
    try {
        const response = yield call(() => createCustomerCartApi(action.params))

        if (response.errors) {
            yield put(createCustomerCartFail(response, action))
        } else {
            yield put(createCustomerCartSuccess(response, action))
        }
    } catch (e) {
        yield put(createCustomerCartFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(CREATE_CUSTOMER_CART, createCustomerCartSaga);
}