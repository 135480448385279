import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    SET_PAYMENT_METHOD,
    setPaymentMethodSuccess,
    setPaymentMethodFail
} from '../actions/setPaymentMethodAction'

// api
import setPaymentMethodApi from '../apis/setPaymentMethodApi'


export function* setPaymentMethodSaga(action) {
    try {
        const response = yield call(() => setPaymentMethodApi(action.params))
        if (response.errors) {
            yield put(setPaymentMethodFail(response, action))
        }
        else {
            yield put(setPaymentMethodSuccess(response, action))
        }
    }
    catch (e) {
        yield put(setPaymentMethodFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SET_PAYMENT_METHOD, setPaymentMethodSaga)
}