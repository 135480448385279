import styled from 'styled-components'

export const Container = styled.div`
  margin: 16px;
    position: relative;
`
export const SubContainer = styled.div`
    background: #f5f5f5;
    border-radius: 8px;
    

    &:hover {
        box-shadow: rgb(0 0 0 / 10%) 0px 2px 3px 0px;
        transition: box-shadow 0.2s linear 0s;

        img {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out 0s;
        }
    }

    img {
        transform: scale(1.0);
        transition: transform 0.3s ease-in-out 0s;
    }

`
export const ImgContainer = styled.div`
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border: solid 1px #efefef;
    border-radius: 8px 8px 0 0;
`
export const DetailContainer = styled.div`
   padding: 5px 10px;
    background: #eaeaea96 !important;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`
export const Name = styled.div`
   font-weight: 600;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
    line-height: 18px;
    margin: 8px;
`
export const BrandCardWrapper = styled.div`
   max-width: 270px;
`
