export const GET_WISH_LIST = "GET_WISH_LIST"
export const GET_WISH_LIST_SUCCESS = "GET_WISH_LIST_SUCCESS"
export const GET_WISH_LIST_FAIL = "GET_WISH_LIST_FAIL"
export const GET_WISH_LIST_RESET = "GET_WISH_LIST_RESET"

export const getWishList = (params) => {
    return { type: GET_WISH_LIST, params }
}
export const getWishListSuccess = (response) => {
    return { type: GET_WISH_LIST_SUCCESS, response }
}
export const getWishListFail = (response) => {
    return { type: GET_WISH_LIST_FAIL, response }
}
export const getWishListReset = () => {
    return { type: GET_WISH_LIST_RESET }
}