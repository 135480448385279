import {
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_ADDRESS_FAIL,
} from "../actions/addAddressAction";
import get from "lodash/get";

const initialState = {
  addressData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_ADDRESS_REQUEST:
      return {
        ...state,
      };

    case ADD_ADDRESS_FAIL:
      return {
        ...state,
      };

    case ADD_ADDRESS_SUCCESS:
      return {
        ...state,
        addressData: get(action, "response.data.country.available_regions"),
      };

    default:
      return state;
  }
}
