import { Skeleton } from "antd"
import React, { useEffect } from "react"
import { connect } from "react-redux"

// actions
import { getCmsPage, getCmsPageReset } from '../../actions/getCmsPageAction'

const CmsPage = props => {
    // variables
    const {
        getCmsPage, getCmsPageReset, getCmsPageState
    } = props
    const url_key = props.match.params.url_key

    // callbacks
    useEffect(() => {
        getCmsPage({
            url_key: url_key
        })

        return () => {
            getCmsPageReset()
        }
    }, [url_key])

    return (
        <>
            <div style={{
                background: "#fff",
                padding: 12
            }} >
                {getCmsPageState.apiState === "loading" &&
                    <>
                        <Skeleton active />
                        <Skeleton active />
                    </>
                }

                {getCmsPageState.apiState === "success" &&
                    <div
                        dangerouslySetInnerHTML={{
                            __html: (getCmsPageState.data.cmsPage.content) || null,
                        }}
                    />
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    getCmsPageState: state.getCmsPage,
})
const mapDispatchToProps = (dispatch) => ({
    getCmsPage: (params) => dispatch(getCmsPage(params)),
    getCmsPageReset: () => dispatch(getCmsPageReset()),
})
export default connect(mapStateToProps, mapDispatchToProps)(CmsPage)