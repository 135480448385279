import styled from "styled-components";

export const NoDataContianer = styled.div`
  display: flex;
 justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
`;

export const NoDataContent = styled.div`
font-size: ${(props) => props.theme.fontSize.fontSizeDesktop};
font-weight: ${(props) => props.theme.fontWeight};
font-stretch: ${(props) => props.theme.fontStretch};
font-style: ${(props) => props.theme.fontStyle};
font-family: ${(props) => props.theme.fontFamily};
line-height: 1.5;
letter-spacing: normal;
`;
