import React, { useEffect, useState, useRef } from "react"
import { connect } from "react-redux"
import _, { set } from "lodash"
import Lottie from "react-lottie"
import { Link, useLocation, Redirect, useHistory } from "react-router-dom"
import { Button, Pagination, Dropdown, Menu } from "antd"
import { SortAscendingOutlined } from "@ant-design/icons"
import { notification } from 'antd'
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet"
// custom component
import { Container, Box, SortContainer } from "./ProductListStyle"
import ProductCard from '../../components/ProductCard/ProductCard'
import ProductCardSkeleton from '../../components/ProductCardSkeleton/ProductCardSkeleton'
import BackButton from '../../components/BackButton/BackButton'
import Loader from '../../components/Loader/Loader'
import Authentication from '../../components/Authentication/Authentication'
// actions
import { getProductList, getProductListReset } from '../../actions/getProductListAction'
import { getBrandInfo } from '../../actions/getBrandInfoAction'
import { addProductToWishList, addProductToWishListReset } from '../../actions/addProductToWishListAction'
import { getWishList, getWishListReset } from '../../actions/getWishListAction'
import { deleteWishList, deleteWishListReset } from '../../actions/deleteWishListAction'
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
// others
import nodataAnimation from "../../lottie/nodata.json"
import { getCustomerToken, getCartId } from '../../utils'
import Footer from "../../components/Footer/Footer"

const ProductList = props => {
    // varibales
    const category_id = props.match.params.id
    const categoryKey = props.match.params.categoryKey
    const brand_id = props.match.params.brand_id
    const categoryName = categoryKey ? categoryKey.replace(/-/g, " ") : null
    const {
        getProductList, getProductListState, getProductListReset,
        getBrandInfo, getBrandInfoState,
        addProductToWishList, addProductToWishListState, addProductToWishListReset,
        getWishList, getWishListState, getWishListReset,
        deleteWishList, deleteWishListState, deleteWishListReset, createCustomerCartState,
        getCartCount, getCartCountState, getCartCountReset
    } = props
    const [renderList, setRenderList] = useState("") // loading, success, error
    const nodataOptions = {
        loop: true,
        autoplay: true,
        animationData: nodataAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }
    let query = new URLSearchParams(useLocation().search)
    let type = query.get("type")
    const [currentPage, setCurrentPage] = useState(1)
    const [sortDirection, setSortDirection] = useState(null)
    const [hasMore, setHasMore] = useState(true)
    const [productListData, setProductListData] = useState([])
    const [count, setCount] = useState(0)
    const [refresh, setRefresh] = useState(0)
    const [visiable, setVisiable] = useState(false)
    const [productName, setProductName] = useState(null)
    let history = useHistory()

    const prevCurrentPageRef = useRef();

    const prevPage = prevCurrentPageRef.current
    // callbacks
    useEffect(() => {
        prevCurrentPageRef.current = currentPage
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        getBrandInfo();
        getWishList({ customerToken: getCustomerToken() })
        return (() => {
            getProductListReset()
            setProductListData([])
            deleteWishListReset()
        })

    }, [])

    useEffect(() => {
        getProductList({
            category_id: !type ? category_id : null,
            product_tag: type ? type : null,
            currentPage: currentPage,
            brand_id: brand_id ? brand_id : null,
            sortDirection: sortDirection,
        })
        // getBrandInfo();
        // getWishList({ customerToken: getCustomerToken() })

    }, [currentPage, refresh])

    useEffect(() => {
        if (sortDirection) {
            setProductListData([])
            setCurrentPage(1)
            getProductList({
                category_id: !type ? category_id : null,
                product_tag: type ? type : null,
                currentPage: 1,
                brand_id: brand_id ? brand_id : null,
                sortDirection: sortDirection,
            })
        }

        // getBrandInfo();
        // getWishList({ customerToken: getCustomerToken() })
    }, [sortDirection])

    useEffect(() => {
        if (getProductListState.apiState === "success" && getBrandInfoState.apiState === "success" && getWishListState.apiState === "success") {
            // setRenderList("success")
        }
        if (getProductListState.apiState === "error" || getBrandInfoState.apiState === "error" || getWishListState.apiState === "error") {
            // setRenderList("error")
        }
    }, [getProductListState, getBrandInfoState, getWishListState])

    useEffect(() => {
        if (addProductToWishListState.apiState === "success") {
            addProductToWishListReset()
            // getProductList({
            //     category_id: !type ? category_id : null,
            //     product_tag: type ? type : null,
            //     currentPage: currentPage,
            //     brand_id: brand_id ? brand_id : null,
            //     sortDirection: sortDirection
            // })
            // getBrandInfo();
            getWishList({ customerToken: getCustomerToken() })
            notification["success"]({
                message: 'Added to wishlist',
                placement: "bottomRight"
            });
        }

        if (addProductToWishListState.apiState === "error") {
            addProductToWishListReset()
            notification["error"]({
                message: "An error occurred during processing. Please try again.",
                placement: "bottomRight"
            })
        }
    }, [addProductToWishListState])

    // useEffect(() => {
    //     if (getProductListState.apiState === "loading" || getBrandInfoState.apiState === "loading" || getWishListState.apiState === "loading") {
    //         setRenderList("loading")
    //     }
    // }, [])

    useEffect(() => {
        if (productListData.length === 0) {
            setRenderList("loading")
        }
        if (getProductListState.apiState === "success") {
            const ProductListCount = getProductListState.data.products.total_count
            if (currentPage !== prevPage) {
                let data = [...productListData]
                getProductListState.data.products.items.map((item) => {
                    data.push(item)
                })
                setProductListData(data)
                handleCount(ProductListCount, data.length)
            }
            else {
                setProductListData(getProductListState.data.products.items)
                handleCount(ProductListCount, getProductListState.data.products.items.length)
            }
        }
    }, [getProductListState])

    const handleCount = (ProductListCount, dataLength) => {
        if (ProductListCount > dataLength) {
            setHasMore(true)
        }
        else {
            setHasMore(false)
        }
        setCount(count + 1)
        getProductListReset()
    }

    useEffect(() => {
        if (createCustomerCartState.refreshItemCount) {
            setRefresh(refresh + 1)
            createCustomerCartState.refreshItemCount = false
        }
    }, [createCustomerCartState])

    useEffect(() => {
        getCartCount({
            cart_id: getCartId(),
            customerToken: getCustomerToken(),
        })

    }, [refresh])

    useEffect(() => {
        if (count > 0) {
            setRenderList("success")
        }
    }, [count])

    // functions
    const handleWishListClick = (sku) => {
        if (getCustomerToken() === null) {
            // history.push("/login")
            setVisiable(true)
        }

        else {
            addProductToWishList({
                sku: sku,
                customerToken: getCustomerToken()
            });
        }
    }
    useEffect(() => {
        if (deleteWishListState.apiState === "success") {
            // setRenderList("success")
            notification["success"]({
                message: `${productName} removed to your wishlist.`,
                placement: "bottomRight"
            });
            deleteWishListReset()
            getWishList({ customerToken: getCustomerToken() })

        } else if (deleteWishListState.apiState === "loading") {
            // setRenderList("loading")
        } else if (deleteWishListState.apiState === "error") {
            notification["error"]({
                message: 'An error occurred during processing. Please try again.',
                placement: "bottomRight"
            });
            deleteWishListReset()
        }
    }, [deleteWishListState])


    const handleRemoveWishList = (id, name) => {
        setProductName(name)
        deleteWishList({ id: id, });
    }

    const handleCloseDrawer = () => {
        setVisiable(false)
    }
    const handleCallbackClick = () => {
        // setRefresh(refresh + 1)
    }
    // let addBtn, cart;
    // const speed = 500,
    //     curveDelay = 300,
    //     position = "fixed"; // or absolute

    // function init() {
    //     addBtn = document.querySelectorAll("[data-addToCart]");
    //     cart = document.querySelector("#cart button");
    //     console.log(`init ${addBtn}`);

    //     for (let btn of addBtn) {
    //         console.log(`add event`);

    //         btn.addEventListener("click", addItem);
    //     }
    // }

    // function addItem(e) {
    //     let btnY =
    //         position === "fixed"
    //             ? e.currentTarget.getBoundingClientRect().top
    //             : e.currentTarget.offsetTop,
    //         btnX =
    //             position === "fixed"
    //                 ? e.currentTarget.getBoundingClientRect().left
    //                 : e.currentTarget.offsetLeft,
    //         flyingBtn = e.currentTarget.cloneNode();

    //     cart.classList.remove("addedCount");

    //     flyingBtn.classList.add("flyingBtn");
    //     flyingBtn.style.position = position;
    //     flyingBtn.style.top = `${btnY}px`;
    //     flyingBtn.style.left = `${btnX}px`;
    //     flyingBtn.style.opacity = "1";
    //     flyingBtn.style.transition = `all ${speed / 1000}s ease, top ${(speed +
    //         curveDelay) /
    //         1000}s ease, left ${speed / 1000}s ease, transform ${speed /
    //         1000}s ease ${(speed - 10) / 1000}s`;

    //     document.body.appendChild(flyingBtn);

    //     flyingBtn.style.top = `${cart.offsetTop + cart.offsetHeight - 16}px`;
    //     flyingBtn.style.left = `${cart.offsetLeft + cart.offsetWidth - 16}px`;
    //     flyingBtn.style.height = "1rem";
    //     flyingBtn.style.width = "1rem";
    //     flyingBtn.style.transform = "scale(0)";

    //     setTimeout(() => {
    //         flyingBtn.remove();
    //         storeItems();
    //     }, speed * 1.5);
    // }

    // function storeItems() {
    //     let itmsInCart = cart.getAttribute("data-count");
    //     cart.classList.add("addedCount");

    //     if (!itmsInCart) {
    //         cart.setAttribute("data-count", 1);
    //     } else {
    //         cart.setAttribute("data-count", parseInt(itmsInCart, 10) + 1);
    //     }
    // }


    return (
        <>

            <div style={{ display: "flex", alignItems: "center", marginTop: 16 }} >
                <Link to={"/categories"} >
                    <BackButton />
                </Link>
                <span style={{ fontSize: 16, fontWeight: 600, marginLeft: 8, textTransform: "uppercase" }} >
                    {type === "new" && <>New Products</>}
                    {type === "offer" && <>Products with offer</>}
                    {type === "gift" && <>Products for gift</>}
                    {categoryName && categoryName}
                </span>
            </div>

            {renderList === "success" &&
                <>
                    <Container >
                        {productListData.length === 0 &&
                            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center", marginTop: 50 }} >
                                <Lottie
                                    options={nodataOptions}
                                    height={200}
                                    width={200}
                                />
                                <h1 style={{ fontSize: 18, fontWeight: 600, marginTop: 8 }} >Products not found under selected category.</h1>
                                <Link to="/categories" >
                                    <Button style={{ marginTop: 8 }} >EXPLORE OTHERS</Button>
                                </Link>
                            </div>
                        }
                        {productListData.length > 0 && getBrandInfoState.apiState === "success" &&
                            <InfiniteScroll
                                dataLength={productListData.length}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '100%',
                                    padding: '10px',
                                    flexWrap: 'wrap'
                                }}
                                next={() => {
                                    setCurrentPage(currentPage + 1)
                                }
                                }
                                hasMore={hasMore}
                            // loader={<div style={{ display: "flex", marginTop: 20 }}><Loader /></div>}
                            // endMessage={
                            //     <p style={{ textAlign: "center" }}>
                            //         <b>You have seen it all product</b>
                            //     </p>
                            // }

                            >
                                {productListData.map(product => {
                                    // console.log(product.brand_name)
                                    if (brand_id && product.is_brand === 1) {
                                    }
                                    else {
                                        let img = ""
                                        if (product.thumbnail) {
                                            img = product.thumbnail.url
                                        } else {
                                            img = product.image.url
                                        }
                                        let tags = []
                                        let is_new = false
                                        if (product.product_tag) {
                                            tags = product.product_tag.split(",")
                                            if (tags.includes("67")) {
                                                is_new = true
                                            }
                                        }
                                        return (
                                            <Box key={product.id}>
                                                <ProductCard
                                                    url_key={product.url_key}
                                                    img={img}
                                                    name={product.name}
                                                    brand={product.brand_name !== null ? (_.find(getBrandInfoState.options, ['value', product.brand_name.toString()]).label) : ""}
                                                    special_price={product.special_price}
                                                    price={product.price_range.minimum_price.regular_price.value}
                                                    per_off={product.price_range.minimum_price.discount.percent_off}
                                                    is_new={is_new}
                                                    sku={product.sku}
                                                    handleWishListClick={handleWishListClick}
                                                    handleRemoveWishList={handleRemoveWishList}
                                                    is_wishlisted={getWishListState.data.wishlist ? (_.find(getWishListState.data.wishlist.items, ['product.sku', product.sku.toString()]) ? true : false) : false}
                                                    id={getWishListState.data.wishlist ? (_.find(getWishListState.data.wishlist.items, ['product.sku', product.sku.toString()]) ? _.find(getWishListState.data.wishlist.items, ['product.sku', product.sku.toString()])['id'] : "") : false}
                                                    productStatus={product.options}
                                                    productType={product.__typename}
                                                />
                                                <Helmet>
                                                    <meta property="og:title" content={product.name} />
                                                    <meta property="og:locale" content="en_US" />
                                                    <meta property="og:type" content="article" />
                                                    <meta property="og:site_name" content="Ladu India" />
                                                </Helmet>
                                            </Box>
                                        )

                                    }
                                })

                                }


                            </InfiniteScroll>

                        }
                        <div style={{ paddingBottom: 60, paddingTop: 10, display: "flex", justifyContent: "center" }} >
                            {getProductListState.apiState === "loading" &&
                                <Loader />
                            }
                        </div>
                    </Container>

                    <SortContainer>
                        <Dropdown overlay={
                            <Menu >
                                <Menu.Item onClick={() => setSortDirection("ASC")}>
                                    Price Low to High
                                </Menu.Item>
                                <Menu.Item onClick={() => setSortDirection("DESC")} >
                                    Price High to Low
                                </Menu.Item>
                            </Menu>
                        } placement="topCenter" trigger="click"
                        >
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ padding: "14px 22px", color: "#000" }} >
                                <SortAscendingOutlined />
                                <span style={{ marginLeft: 8, fontWeight: "600" }} >Sort</span>
                            </a>
                        </Dropdown>
                    </SortContainer>
                    {/* <div style={{ paddingBottom: 80, display: "flex", justifyContent: "center" }} >
                        <Pagination
                            defaultCurrent={currentPage}
                            total={getProductListState.data.products.total_count}
                            onChange={(page) => setCurrentPage(page)}
                            pageSize="20"
                            hideOnSinglePage
                        />
                    </div> */}
                    {/* <div style={{
                        position: "fixed",
                        bottom: 10,
                        left: 0,
                        right: 0,
                        background: "transparent",
                        display: "flex",
                        justifyContent: "center"
                    }} >
                        <div style={{
                            display: "flex",
                            borderRadius: 12,
                            boxShadow: "0 0 6px 0 rgb(0 0 0 / 11%)",
                            backdropFilter: "saturate(180%) blur(20px)",
                            backgroundColor: "rgba(255,255,255,0.72)",
                        }} >
                            <Dropdown overlay={
                                <Menu >
                                    <Menu.Item onClick={() => setSortDirection("ASC")}>
                                        Price Low to High
                                    </Menu.Item>
                                    <Menu.Item onClick={() => setSortDirection("DESC")} >
                                        Price High to Low
                                    </Menu.Item>
                                </Menu>~
                            } placement="topCenter" trigger="click"
                            >
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()} style={{ padding: "14px 22px", color: "#000", backdropFilter: "saturate(180%) blur(20px)" }} >
                                    <SortAscendingOutlined />
                                    <span style={{ marginLeft: 8, fontWeight: "600" }} >SORT</span>
                                </a>
                            </Dropdown>
                        </div>
                    </div> */}
                    {
                        visiable &&
                        <Authentication
                            drawerState={visiable}
                            handleCloseDrawer={handleCloseDrawer}
                            handleCallbackClick={handleCallbackClick}
                        />
                    }
                </>

            }
            {
                renderList === "loading" &&
                <>
                    <Container>
                        {[1, 2, 3, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4].map((item) => (
                            <Box>
                                <ProductCardSkeleton />
                            </Box>
                        ))}
                    </Container>
                </>
            }
            {
                renderList === "error" &&
                <Container>
                    <h1>Something is not right</h1>
                </Container>
            }


        </>
    )
}
const mapStateToProps = (state) => ({
    getProductListState: state.getProductList,
    getBrandInfoState: state.getBrandInfo,
    addProductToWishListState: state.addProductToWishList,
    getWishListState: state.getWishList,
    deleteWishListState: state.deleteWishList,
    createCustomerCartState: state.createCustomerCart,
    getCartCountState: state.getCartCount
});

const mapDispatchToProps = (dispatch) => ({
    getProductList: (params) => dispatch(getProductList(params)),
    getBrandInfo: (params) => dispatch(getBrandInfo(params)),
    addProductToWishList: (params) => dispatch(addProductToWishList(params)),
    addProductToWishListReset: () => dispatch(addProductToWishListReset()),
    getWishList: (params) => dispatch(getWishList(params)),
    getWishListReset: () => dispatch(getWishListReset()),
    deleteWishList: (params) => dispatch(deleteWishList(params)),
    deleteWishListReset: () => dispatch(deleteWishListReset()),
    getProductListReset: () => dispatch(getProductListReset()),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: () => dispatch(getCartCountReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

