import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';

const createCustomerAddressApi = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                        createCustomerAddress(input: {
                            region: {
                                region_id: ${params.state_id}
                                region: "${params.state}"
                            }
                            country_code: IN
                            street: ["${params.address}"]
                            telephone: "${params.mobile}"
                            postcode: "${params.pincode}"
                            city: "${params.city}"
                            firstname: "${params.first_name}"
                            lastname: "${params.last_name}"
                            default_shipping: false
                            default_billing: false
                         }) {
                                id
                                firstname
                                lastname
                                region{
                                    region
                                    region_id
                                    region_code
                                }
                                street
                                postcode
                                city
                                telephone    }
                    }
                    `,
        },
        headers: {
            Authorization: `Bearer ${params.customerToken}`
        }
    });
    return response.data;
};

export default createCustomerAddressApi;
