import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const setShippingMethod = async (params) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: `mutation {
                    setShippingMethodsOnCart(input: {
                        cart_id: "${params.cartId}"
                        shipping_methods: [
                        {
                            carrier_code: "${params.carrier_code}"
                            method_code: "${params.method_code}"
                        }
                        ]
                        })
                        {
                        cart {
                        shipping_addresses {
                            selected_shipping_method {
                            carrier_code
                            method_code
                            carrier_title
                            method_title
                            }
                        }
                        }
                        }
                            }   
`,
        },
        headers: isAuthorized(),
    });

    return response.data;
};

export default setShippingMethod;

