export const SALES_PLACE_ORDER = 'SALES_PLACE_ORDER'
export const SALES_PLACE_ORDER_SUCCESS = 'SALES_PLACE_ORDER_SUCCESS'
export const SALES_PLACE_ORDER_FAIL = 'SALES_PLACE_ORDER_FAIL'
export const SALES_PLACE_ORDER_RESET = 'SALES_PLACE_ORDER_RESET'

export const salesPlaceOrder = (params) => {
    return { type: SALES_PLACE_ORDER, params }
}

export const salesPlaceOrderSuccess = (response) => {
    return { type: SALES_PLACE_ORDER_SUCCESS, response }
}

export const salesPlaceOrderFail = (response) => {
    return { type: SALES_PLACE_ORDER_FAIL, response }
}
export const salesPlaceOrderReset = () => {
    return { type: SALES_PLACE_ORDER_RESET }
}

