import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'

import {
    CREATE_GUEST_CUSTOMER_ADDRESS,
    createGuestCustomerAddressSuccess,
    createGuestCustomerAddressFail,
} from '../actions/createGuestCustomerAddressAction'

import createGuestCustomerAddressApi from '../apis/createGuestCustomerAddressApi'


export function* createGuestCustomerAddressSaga(action) {
    try {
        const response = yield call(() => createGuestCustomerAddressApi(action.params))

        if (response.errors) {
            yield put(createGuestCustomerAddressFail(response, action))
        } else {
            yield put(createGuestCustomerAddressSuccess(response, action))
        }
    } catch (e) {
        yield put(createGuestCustomerAddressFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(CREATE_GUEST_CUSTOMER_ADDRESS, createGuestCustomerAddressSaga);
}