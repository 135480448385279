import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { HeartFilled, HeartOutlined } from '@ant-design/icons'
import { connect } from "react-redux"
import { Button, notification, Badge } from 'antd'
import _ from "lodash"

// custom components
import {
    Container, SubContainer, DetailContainer, Name, Brand,
    Price, SpecialPrice, ImgContainer, HeartContainer, WhishlistedContainer, LabelContainer, Label,
    Label2, AddToCartButton, AddToCartBtn, ImgBlock, WishListBlock, HeartBlock, DrawerContainer
} from "./ProductCardStyle"
import { getCartId, getCustomerToken, setCartId } from '../../utils'
import CartModal from "../CartModal/cart";

// actions
import { addProductToCart, addProductToCartReset } from '../../actions/addProductToCartAction'
import { createGuestCart, createGuestCartReset } from '../../actions/createGuestCartAction'
import { createCustomerCart, createCustomerCartReset } from '../../actions/createCustomerCartAction'
import { getCartCount, getCartCountReset } from '../../actions/getCartCountAction'
import { fetchCartData } from "../../actions/fetchCartDataAction";


import { HeartIcon } from '../CustomIcons'

const ProductCard = (props) => {
    // variables
    const {
        addProductToCart, addProductToCartReset, addProductToCartState,
        createGuestCart, createGuestCartReset, createGuestCartState,
        getCartCount, getCartCountReset, fetchCartData,
    } = props
    const cartId = getCartId()
    const [storeSku, setStoreSku] = useState([])
    const isMobile = window.innerWidth < 991
    const [visibleCart, setVisibleCart] = useState(false);
    const history = useHistory()
    // callbacks
    useEffect(() => {
        if (storeSku === props.sku) {
            addProductToCartReset()
            createGuestCartReset()
            getCartCountReset()
        }
    }, [])

    useEffect(() => {
        if (addProductToCartState.apiState === "success") {
            if (storeSku === props.sku) {
                notification["success"]({
                    message: 'Added to cart',
                    placement: "bottomRight",
                    duration: 5,
                    style: { backgroundColor: '#e49045', cursor: 'pointer' },
                    className: "notification-type-info",
                    onClick() {
                        showDrawer()
                    }
                });
                getCartCount({
                    cart_id: cartId,
                    customerToken: getCustomerToken()
                })
                setStoreSku("")
            }
            addProductToCartReset()
        }
        if (addProductToCartState.apiState === "error") {
            if (addProductToCartState.errorType === "graphql-input") {
                notification["error"]({
                    message: 'Requested quanity is not available in the stock.',
                    placement: "bottomRight"
                });
                addProductToCartReset()
            }
        }
    }, [addProductToCartState])
    // useEffect(() => {
    //     if (createCustomerCartState.apiState === "success") {
    //         if (createCustomerCartState.preventAddToCart === false) {
    //             setCartId(createCustomerCartState.data.customerCart.id)
    //             addProductToCart({
    //                 cart_id: createCustomerCartState.data.customerCart.id,
    //                 sku: props.sku,
    //                 customerToken: getCustomerToken(),
    //                 uid: null,
    //                 textUid: null,
    //                 productUid: null,
    //                 instruction: "",
    //                 quantity: 1,
    //                 customerToken: getCustomerToken()
    //             })
    //             createCustomerCartReset()
    //         }
    //     }
    // }, [createCustomerCartState])

    useEffect(() => {
        if (createGuestCartState.apiState === "success") {
            if (storeSku === props.sku) {
                setCartId(createGuestCartState.data.createEmptyCart)
                addProductToCart({
                    cart_id: createGuestCartState.data.createEmptyCart,
                    sku: props.sku,
                    uid: null,
                    textUid: null,
                    productUid: null,
                    instruction: "",
                    quantity: 1,
                    customerToken: getCustomerToken(),
                })
                createGuestCartReset()
            }

        }
    }, [createGuestCartState])

    // functions
    const addToCart = () => {
        setStoreSku(props.sku)
        if (cartId) {
            addProductToCart({
                cart_id: cartId,
                sku: props.sku,
                customerToken: getCustomerToken(),
                uid: null,
                textUid: null,
                productUid: null,
                instruction: "",
                quantity: 1
            })
        }
        else {
            createGuestCart()
        }
    }
    const handleCheckout = () => {
        history.push("/checkout");
    };
    const showDrawer = () => {
        setVisibleCart(true);
        fetchCartData({
            cart_id: getCartId()
        })
    };
    const onClose = () => {
        setVisibleCart(false);
    };
    const handleClose = () => {
        setVisibleCart(false)
    }

    return (
        <Container>
            <SubContainer>
                <ImgBlock>
                    <Link to={props.is_brand === 1 ? `/brand/${props.url_key}` : `/product-detail/${props.url_key}`}>
                        <ImgContainer>
                            <img src={props.img} alt="" className="product_img" style={{ maxWidth: "100%", height: "auto" }} />
                        </ImgContainer>
                    </Link>
                    {
                        props.is_brand === 1 ?
                            < ></>
                            :
                            <>
                                {
                                    props.productStatus !== null || props.productType === "ConfigurableProduct" ?
                                        <Link to={props.is_brand === 1 ? `/brand/${props.url_key}` : `/product-detail/${props.url_key}`}>
                                            <AddToCartBtn >Select Option</AddToCartBtn>
                                        </Link> :
                                        <AddToCartBtn onClick={addToCart}  >Add To Cart</AddToCartBtn>
                                }
                            </>
                    }

                </ImgBlock>
                <DetailContainer>
                    <Link to={props.is_brand === 1 ? `/brand/${props.url_key}` : `/product-detail/${props.url_key}`}>
                        <Name>{props.name}</Name>
                        <Brand>{props.brand}</Brand>
                    </Link>
                    {props.is_brand === 1 ?
                        <div>
                            &nbsp;&nbsp;&nbsp;
                        </div>
                        :
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                            {props.special_price &&
                                <Link to={props.is_brand === 1 ? `/brand/${props.url_key}` : `/product-detail/${props.url_key}`}>
                                    <SpecialPrice style={{ fontSize: 16 }} >₹{props.special_price}</SpecialPrice>
                                    <Price><s>₹{props.price}</s></Price>
                                </Link>
                            }
                            {!props.special_price &&
                                <Link to={props.is_brand === 1 ? `/brand/${props.url_key}` : `/product-detail/${props.url_key}`}>
                                    <SpecialPrice>₹{props.price}</SpecialPrice>
                                </Link>
                            }
                            {
                                isMobile &&
                                    props.productStatus !== null || props.productType === "ConfigurableProduct" ? "" :
                                    <AddToCartButton onClick={addToCart} >
                                        <Badge size="small">
                                            <img
                                                src={`${process.env.PUBLIC_URL}/images/ladu-cart-addtocart.svg`}
                                                alt="ladu-india"
                                                width="15"
                                            />
                                        </Badge>
                                    </AddToCartButton>
                            }
                        </div>
                    }

                </DetailContainer>
            </SubContainer>
            {!props.is_wishlisted &&
                <HeartBlock>
                    <HeartContainer onClick={() => props.handleWishListClick(props.sku)}>
                        <HeartIcon size="26" />
                    </HeartContainer>
                </HeartBlock>
            }
            {props.is_wishlisted &&
                <WishListBlock>
                    <WhishlistedContainer style={{ background: '#ff0000d9' }} onClick={() => props.handleRemoveWishList(props.id, props.name)} >
                        <HeartFilled style={{ fontSize: '26px', color: '#ff0000d9' }} />
                    </WhishlistedContainer>
                </WishListBlock>
            }
            <LabelContainer>
                {props.per_off > 0 &&
                    <Label2>({props.per_off}% OFF)</Label2>
                }
                {props.is_new &&
                    <Label style={{
                        background: "#f78c3e"
                    }} >NEW</Label>
                }
            </LabelContainer>
            <DrawerContainer
                title="Cart"
                placement="right"
                onClose={onClose}
                visible={visibleCart}
                width={isMobile ? "100% !important" : "50% !important"}
                height={"100%"}
            >
                <CartModal handleCheckoutHeader={handleCheckout} handleClose={handleClose} />
            </DrawerContainer>
        </Container >
    )
}

export const mapStateToProps = (state) => ({
    addProductToCartState: state.addProductToCart,
    createGuestCartState: state.createGuestCart,
    createCustomerCartState: state.createCustomerCart,
})

export const mapDispatchToProps = (dispatch) => ({
    addProductToCart: (params) => dispatch(addProductToCart(params)),
    addProductToCartReset: (params) => dispatch(addProductToCartReset(params)),
    createGuestCart: (params) => dispatch(createGuestCart(params)),
    createGuestCartReset: (params) => dispatch(createGuestCartReset(params)),
    createCustomerCart: (params) => dispatch(createCustomerCart(params)),
    createCustomerCartReset: (params) => dispatch(createCustomerCartReset(params)),
    getCartCount: (params) => dispatch(getCartCount(params)),
    getCartCountReset: (params) => dispatch(getCartCountReset(params)),
    fetchCartData: (params) => dispatch(fetchCartData(params)),
})


export default connect(mapStateToProps, mapDispatchToProps)(ProductCard)