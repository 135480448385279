import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    APPLY_COUPON_TO_CART,
    applyCouponToCartSuccess,
    applyCouponToCartFail
} from '../actions/applyCouponToCartAction'

// api
import applyCouponToCartApi from '../apis/applyCouponToCartApi'

export function* applyCouponToCartSaga(action) {
    try {
        const response = yield call(() => applyCouponToCartApi(action.params))
        if (response.data.errors) {
            yield put(applyCouponToCartFail(response, action))
        } else {
            yield put(applyCouponToCartSuccess(response, action))
        }
    } catch (e) {
        yield put(applyCouponToCartFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(APPLY_COUPON_TO_CART, applyCouponToCartSaga)
}