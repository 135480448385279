import styled from "styled-components"

export const HeartContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px 5px 0 0;
    cursor: pointer;
    transform: scale(1.0);
    transition: transform 0.3s ease-in-out 0s;
    height: 0;
    transition: all 0.3s ease-in-out 0s;

    &:hover {
        transform: scale(1.1);
        transition: transform 0.3s ease-in-out 0s;
    }
`

export const Container = styled.div`
    margin: 16px;
    position: relative;

    &:hover ${HeartContainer} {
        height: 50px;
    }
`


export const SubContainer = styled.div`
    background: #f5f5f5;
    border-radius: 8px;
    width: 250px;    

    &:hover {
        box-shadow: rgb(0 0 0 / 10%) 0px 2px 3px 0px;
        transition: box-shadow 0.2s linear 0s;

        img {
            transform: scale(1.1);
            transition: transform 0.3s ease-in-out 0s;
        }
    }

    img {
        transform: scale(1.0);
        transition: transform 0.3s ease-in-out 0s;
    }

`

export const DetailContainer = styled.div`
    padding: 5px 10px;
    background: #eaeaea96;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

export const Name = styled.div`
    font-weight: 600;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
`

export const Brand = styled.div`
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${props => props.theme.color.primary};
`

export const Price = styled.span`
    font-weight: 600;
    margin-right: 8px;
    color: #000;
`

export const SpecialPrice = styled.span`
    font-weight: 600;
    margin-right: 8px;
    color: #000;
`

export const Off = styled.span`
    color: ${props => props.theme.color.primary};
    font-weight: 600;
`

export const ImgContainer = styled.div`
    height: 250px;

    overflow: hidden;
    display: flex;
    justify-content: center;
    border: solid 1px #efefef;
    border-radius: 8px 8px 0 0;
`

