import { call, put, takeLatest } from 'redux-saga/effects'

// action
import {
    CHECK_GUEST_SERVICE_AVAILABILITY,
    checkGuestServiceAvailabilitySuccess,
    checkGuestServiceAvailabilityFail
} from '../actions/checkGuestServiceAvailabilityAction'

// api
import checkGuestServiceAvailabilityApi from '../apis/checkGuestServiceAvailabilityApi'

export function* checkServiceAvailabilitySaga(action) {
    try {
        const response = yield call(() => checkGuestServiceAvailabilityApi(action.params))
        if (response.success === false) {
            yield put(checkGuestServiceAvailabilityFail(response, action))
        }
        else {
            yield put(checkGuestServiceAvailabilitySuccess(response, action))
        }
    }
    catch (e) {
        yield put(checkGuestServiceAvailabilityFail(e.message, action))
    }
}

export default function* mySaga() {
    yield takeLatest(CHECK_GUEST_SERVICE_AVAILABILITY, checkServiceAvailabilitySaga)
}