import "regenerator-runtime/runtime"
import { call, put, takeLatest } from 'redux-saga/effects'
import get from 'lodash/get';

import {
    SIGN_UP_REQUEST,
    signUpSuccess,
    signUpFail,
} from '../actions/signUpAction'

import signUpApi from '../apis/signUpApi';

function* common(action = { params: {} }, success, otherParams) {
    if (typeof get(action, 'params.successAction') === 'function' && success) {
        yield action.params.successAction(otherParams);
    }
    if (typeof get(action, 'params.errorAction') === 'function' && !success) {
        yield action.params.errorAction(otherParams);
    }
}

export function* signUpSaga(action) {

    try {
        const response = yield call(() => signUpApi(action))
        if (response.errors) {
            yield put(signUpFail(response, action))
            yield common(action, false, response);

        } else {
            yield put(signUpSuccess(response, action))
            yield common(action, true, response);

        }
    } catch (e) {
        yield put(signUpFail(e.message, action))
    }
}


export default function* mySaga() {
    yield takeLatest(SIGN_UP_REQUEST, signUpSaga);
}

