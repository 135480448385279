export const UPDATE_CUSTOMER_INFO = 'UPDATE_CUSTOMER_INFO'
export const UPDATE_CUSTOMER_INFO_SUCCESS = 'UPDATE_CUSTOMER_INFO_SUCCESS'
export const UPDATE_CUSTOMER_INFO_FAIL = 'UPDATE_CUSTOMER_INFO_FAIL'
export const UPDATE_CUSTOMER_INFO_RESET = 'UPDATE_CUSTOMER_INFO_RESET'

export const updateCustomerInfo = (params) => {
    return { type: UPDATE_CUSTOMER_INFO, params }
}

export const updateCustomerInfoSuccess = (response) => {
    return { type: UPDATE_CUSTOMER_INFO_SUCCESS, response }
}

export const updateCustomerInfoFail = (response) => {
    return { type: UPDATE_CUSTOMER_INFO_FAIL, response }
}

export const updateCustomerInfoReset = () => {
    return { type: UPDATE_CUSTOMER_INFO_RESET }
}