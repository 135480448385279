import {
    FETCH_ADDRESS_REQUEST,
    FETCH_ADDRESS_SUCCESS,
    FETCH_ADDRESS_FAIL,
  } from "../actions/fetchAddressAction";
  import get from "lodash/get";
  
  const initialState = {
    fetchAddressData: null,
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case FETCH_ADDRESS_REQUEST:
        return {
          ...state,
        };
  
      case FETCH_ADDRESS_FAIL:
        return {
          ...state,
        };
  
      case FETCH_ADDRESS_SUCCESS:
        return {
          ...state,
          fetchAddressData: get(action, "response.data.customer.addresses"),
        };
  
      default:
        return state;
    }
  }
  