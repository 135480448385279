import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from 'antd'
import { Container, LeftSection, RightSection, IconContainer, Title, SubTitle, Xskeleton, CircleSkeleton } from './RowListSkeletonStyle'


const RowListSkeleton = props => {
    const isMobile = window.innerWidth < 768;
    const RowSkeleton = () => (
        <Container>
            {/* <LeftSection>
                <IconContainer><CircleSkeleton shape={'circle'} size={isMobile ?30:70} active /></IconContainer>
            </LeftSection> */}
            <RightSection>
                <Title hideMobile><Xskeleton type="text" w="90%" h="15px" mb="5px" active /></Title>
                <SubTitle>
                    <Xskeleton type="text" w={isMobile ? "100%" : "100%"} h="20px" active />
                </SubTitle>
                <SubTitle hideMobile>
                    <Xskeleton type="text" w="200px" h="20px" shape="square" active />
                </SubTitle>
                <SubTitle hideMobile>
                    <Xskeleton type="text" w="200px" h="20px" active />
                </SubTitle>
                <SubTitle hideMobile>
                    <Xskeleton type="text" w="200px" h="20px" active />
                </SubTitle>
                <SubTitle hideMobile>
                    <Xskeleton type="text" w="200px" h="20px" active />
                </SubTitle>
                <SubTitle hideMobile>
                    <Xskeleton type="text" w="200px" h="20px" active />
                </SubTitle>
            </RightSection>
        </Container>
    )

    let rs = []
    for (var row = 1; row <= props.rows; row++) {
        rs.push(<RowSkeleton />)
    }

    return rs
}

RowListSkeleton.propTypes = {
    rows: PropTypes.number,
}

RowListSkeleton.defaultProps = {
    rows: 4
}

export default RowListSkeleton