export const REMOVE_COUPON_TO_CART = "REMOVE_COUPON_TO_CART"
export const REMOVE_COUPON_TO_CART_SUCCESS = "REMOVE_COUPON_TO_CART_SUCCESS"
export const REMOVE_COUPON_TO_CART_FAIL = "REMOVE_COUPON_TO_CART_FAIL"
export const REMOVE_COUPON_TO_CART_RESET = "REMOVE_COUPON_TO_CART_RESET"

export const removeCouponToCart = (params) => {
    return { type: REMOVE_COUPON_TO_CART, params }
}
export const removeCouponToCartSuccess = (response) => {
    return { type: REMOVE_COUPON_TO_CART_SUCCESS, response }
}
export const removeCouponToCartFail = (response) => {
    return { type: REMOVE_COUPON_TO_CART_FAIL, response }
}
export const removeCouponToCartReset = () => {
    return { type: REMOVE_COUPON_TO_CART_RESET }
}