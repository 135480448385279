import React, { useState, useEffect } from 'react'
import {
    CookiePolicyWrapper,
    CookieHead,
    CookieBody,
    CookieText,
    AcceptBtn,
    CloseBtn,
    FreeShippingContainer,
    FreeShippingIconContainer,
    Text
} from './CookiePolicyStyle'
import { ShoppingTwoTone } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { Modal, Button } from 'antd';
import { SITE_URL } from '../../utils';


const CookiePolicy = () => {
    const [cookie, setCookie] = useState(localStorage.getItem('cookie'))
    const [isModalVisible, setIsModalVisible] = useState(true);


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        handleStore();
    };

    const handleStore = () => {
        localStorage.setItem('cookie', JSON.stringify(true))
        setCookie("true")
        // document.getElementById("demo").style.marginBottom = "-450px"
    }


    return (
        <>
            {cookie !== "true" &&
                <Modal title="" visible={isModalVisible} onCancel={handleCancel} centered width={620}

                    footer={[
                        <AcceptBtn key="back" onClick={handleStore}>
                            Enter Website
                        </AcceptBtn>

                    ]}
                >
                    <div style={{ fontSize: 18, textAlign: 'center' }} >
                        <p style={{ fontWeight: 'bold' }}>नमस्कार</p>
                        <Text style={{ fontWeight: 'bold' }}>Life Around Daily Utilities: Ladu</Text>
                        <Text>(A retail store by Tapri, Jaipur)</Text>
                        <img src={`${SITE_URL()}/pub/media/wysiwyg/Screenshot_2021-01-09_at_2.28.59_PM_3x.jpg`} style={{ width: '250px' }} />
                        <Text style={{ fontWeight: 'bold' }}>Your privacy is important to us! Secure Payments Secure Data</Text>
                        <Text>We don't share/use any data for marketing and promotional activities. To know more, you can find our privacy policy <Link style={{ textDecoration: "underline" }} to="/privacies-privacy-policy/p">here</Link>.</Text>
                        <FreeShippingContainer>
                            <FreeShippingIconContainer><ShoppingTwoTone style={{ fontSize: 16 }} twoToneColor="#52c41a" /></FreeShippingIconContainer>
                            <span>Enjoy Free Shipping Across India</span>
                        </FreeShippingContainer>
                    </div>
                </Modal>

                // <CookiePolicyWrapper id="demo">
                //     <CookieHead>
                //         <h2 style={{ marginBottom: '0px', fontWeight: 'bold', textTransform: 'capitalize', fontSize: 18 }} >Your Privacy is important to us! <br />Secure Payments. Secure Data.</h2>
                //     </CookieHead>
                //     <CookieBody>
                //         <CookieText>
                //             This site does not use cookies, and any data collected during checkout is secure and for operational purposes only.
                //             We do not share/use any data for marketing and promotional activities. To know more, you can find our privacy policy <Link style={{ textDecoration: "underline" }} to="/privacies-privacy-policy/p">here</Link>.
                //         </CookieText>
                //         <AcceptBtn onClick={() => handleStore()}>OK</AcceptBtn>
                //     </CookieBody>
                // </CookiePolicyWrapper>
            }
        </>
    )
}

export default CookiePolicy
