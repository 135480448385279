import React from 'react'
import Lottie from 'react-lottie'

import { Xlink } from '../Xcomponent'
import LoaderAnimation from '../../lottie/loader.json'
import { Container } from "./LoaderStyle"

const Loader = props => {
    // variables
    const animationOptions = {
        loop: true,
        autoplay: true,
        animationData: LoaderAnimation,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    }

    return (
        <Container>
            <Lottie
                options={animationOptions}
                height={props.height ? props.height : 40}
                width={props.width ? props.width : 60}
            />
        </Container>
    )
}

export default Loader