import {
    GET_CMS_PAGE,
    GET_CMS_PAGE_SUCCESS,
    GET_CMS_PAGE_FAIL,
    GET_CMS_PAGE_RESET
} from '../actions/getCmsPageAction'


const initialState = {
    apiState: "", // loading, success, error
    data: {}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_CMS_PAGE:
            return {
                ...state,
                apiState: "loading",
            }

        case GET_CMS_PAGE_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            }

        case GET_CMS_PAGE_FAIL:
            return {
                ...state,
                apiState: "error",
            }

        case GET_CMS_PAGE_RESET:
            return initialState


        default:
            return state
    }
}
