import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "antd/dist/antd.less";
import { ThemeProvider } from "styled-components";

import { GlobalStyle } from "./globalStyle";
import { Container, BodySection, ProductDetailContainer, BodySectionFullWidth, HomeContainer } from "./AppStyle";
import theme from "./theme";
import history from "./history";
import BackHeader from "./components/BackHeader/backHeader";
// Components
import FrontHeader from "./components/FrontHeader/frontHeader";
import CookiePolicy from './components/CookiePolicy/CookiePolicy'
import Category from "./pages/Category/Category";
// Pages
// import Home from "./pages/Home/Home"
import Home from "./pages/Home/Home"
import ContactUs from "./pages/ContactUs/ContactUs";
import ProductList from "./pages/ProductList/ProductList";
import ProductDetail from "./pages/ProductDetail/ProductDetail";
import Local from "./pages/Local/local";
import Gift from "./pages/Gift/gift";
import Cart from "./pages/Cart/cart";
import Checkout from "./pages/Checkout/checkout";
import SignUp from "./pages/SignUp/signUp";
import Payment from './pages/Payment/Payment'
import Search from "./pages/Search/search";
import Orders from "./pages/Orders/Orders";
import MyProfile from "./pages/MyProfile/MyProfile";
import ManageAddress from "./pages/ManageAddress/ManageAddress";
import Help from "./pages/Help/help";
import Policies from "./pages/Policies/policies";
import Partner from "./pages/Partner/partner";
import New from "./pages/New/new";
import Offers from "./pages/Offers/offers";
import Gifting from "./pages/Gifting/gifting";
import Brands from "./pages/Brands/brands";
import PlaceOrder from './pages/PlaceOrder/placeOrder';
import BackHomePage from './pages/BackHomePage/backHome';
import LoginPage from './pages/LoginPage/LoginPage'
import Thanks from './pages/Thanks/Thanks'
import Logout from './pages/Logout'
import SignUpPage from './pages/SignUpPage/SignUpPage'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import CmsPage from './pages/CmsPage/CmsPage'
import WishList from './pages/ProductList/WishList'
import BrandList from './pages/BrandList/BrandList'
import BrandDetails from './pages/BrandDetails/BrandDetails'
import GuestCheckout from './pages/GuestCheckout/GuestCheckOut'
import GuestPayment from './pages/GuestPayment/GuestPayment'
import NotFound from './components/NotFound/NotFound'
import backHomePageComponent from "./pages/BackHomePageComponent/backHomePageComponent";

const App = () => {

    //Variables
    const [newsBanner, setNewsBanner] = useState(localStorage.getItem('newsbanner'))

    history.listen((location) => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    })

    // callback
    useEffect(() => {
        ReactGA.initialize('UA-206891026-1');
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [history])

    // Function
    const handleCallbackBannerAppJs = () => {
        setNewsBanner(localStorage.getItem('newsbanner'))
    }
    // variables
    const paths = [
        "/category/:categoryKey/:id",
        "/brand-products/:brand_url_key/:brand_id",
        "/local",
        "/pantry",
        "/cart",
        "/checkout",
        // "/login",
        "/signup",
        "/forgot-password",
        "/reset-password",
        "/payment",
        // "/search",
        "/orders",
        "/thanks",
        "/manage-address",
        "/my-profile",
        "/products",
        "/offers",
        "/gifting",
        "/brands",
        "/brand/:brand_id",
        "/placeOrder",
        "/logout",
        "/wishlist",
        "/guest-checkout",
        "/guest-payment"
    ]
    const allPaths = [
        "/product-detail/:productKey",
        "/categories",
        "/category/:categoryKey/:id",
        "/brand-products/:brand_url_key/:brand_id",
        "/local",
        "/pantry",
        "/cart",
        "/checkout",
        // "/login",
        "/signup",
        "/forgot-password",
        "/reset-password",
        "/payment",
        "/search",
        "/orders",
        "/thanks",
        "/manage-address",
        "/my-profile",
        "/products",
        "/offers",
        "/gifting",
        "/brands",
        "/brand/:brand_id",
        "/placeOrder",
        "/logout",
        "/wishlist",
        "/",
        "/about",
        "/help",
        "/policies",
        "/partner",
        "/backHome",
        "/:url_key/p",
        "/guest-checkout",
        "/guest-payment"
    ]
    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />

                <Router history={history}>
                    <Switch>
                        <Route
                            path={allPaths}
                            exact
                        >

                            <Router history={history}>
                                <Route
                                    path={paths}
                                    exact
                                >
                                    <Container>
                                        <FrontHeader history={history} handleCallbackBannerAppJs={handleCallbackBannerAppJs} />
                                        <CookiePolicy history={history} />

                                        <BodySection grendiant={"yes"} height={newsBanner ? 64 : 108}>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path="/category/:categoryKey/:id"
                                                    component={ProductList}
                                                />
                                                <Route
                                                    exact
                                                    path="/brand-products/:brand_url_key/:brand_id"
                                                    component={ProductList}
                                                />

                                                <Route
                                                    exact
                                                    path="/brands"
                                                    component={BrandList}
                                                />
                                                <Route
                                                    exact
                                                    path="/brand/:brand_url_key"
                                                    component={BrandDetails}
                                                />
                                                <Route path="/products" history={history} component={ProductList} />


                                                <Route
                                                    path="/wishlist"
                                                    component={WishList}
                                                />
                                                <Route path="/local" history={history} component={Local} />
                                                <Route path="/pantry" history={history} component={Gift} />
                                                <Route path="/cart" history={history} component={Cart} />
                                                <Route path="/guest-checkout" history={history} component={GuestCheckout} />
                                                <Route path="/guest-payment" history={history} component={GuestPayment} />
                                                <Route
                                                    path="/checkout"
                                                    history={history}
                                                    component={Checkout}
                                                />
                                                {/* <Route path="/login" history={history} component={LoginPage} /> */}
                                                <Route path="/signup" history={history} component={SignUpPage} />
                                                <Route path="/forgot-password" history={history} component={ForgotPassword} />
                                                <Route path="/reset-password" history={history} component={ResetPassword} />

                                                <Route path="/payment" component={Payment} />
                                                {/* <Route path="/search" component={Search} /> */}

                                                <Route path="/orders" history={history} component={Orders} />
                                                <Route path="/logout" history={history} component={Logout} />
                                                <Route path="/thanks" history={history} component={Thanks} />
                                                <Route
                                                    path="/manage-address"
                                                    history={history}
                                                    component={ManageAddress}
                                                />
                                                <Route
                                                    path="/my-profile"
                                                    history={history}
                                                    component={MyProfile}
                                                />

                                                <Route path="/offers" history={history} component={Offers} />
                                                <Route path="/gifting" history={history} component={Gifting} />
                                                <Route path="/brands" history={history} component={Brands} />
                                                <Route path="/placeOrder" history={history} component={PlaceOrder} />

                                            </Switch>
                                        </BodySection>
                                    </Container>
                                </Route>
                            </Router>


                            <Router history={history}>
                                <Route
                                    path={["/product-detail/:productKey", "/categories"]}
                                    exact
                                >
                                    <ProductDetailContainer>
                                        <FrontHeader history={history} handleCallbackBannerAppJs={handleCallbackBannerAppJs} />
                                        <CookiePolicy history={history} />

                                        <BodySection grendiant={"yes"} bgColor={"yes"} height={newsBanner ? 64 : 108}>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path="/categories"
                                                    history={history}
                                                    component={Category}
                                                />
                                                <Route
                                                    path="/product-detail/:productKey"
                                                    history={history}
                                                    component={ProductDetail}
                                                />

                                            </Switch>
                                        </BodySection>
                                    </ProductDetailContainer>
                                </Route>
                            </Router>



                            <Router history={history}>
                                <Route
                                    path={["/search"]}
                                    exact
                                >
                                    <Container>
                                        <FrontHeader history={history} handleCallbackBannerAppJs={handleCallbackBannerAppJs} />
                                        <BodySectionFullWidth height={newsBanner ? 64 : 108}>
                                            <Switch>
                                                <Route path="/search" exact component={Search} />
                                                <Route path="/search" exact component={backHomePageComponent} />
                                            </Switch>
                                        </BodySectionFullWidth>
                                    </Container>
                                </Route>
                            </Router>


                            <Router history={history}>
                                <Route
                                    path={["/"]}
                                    exact
                                >
                                    <HomeContainer height={newsBanner ? 60 : 108}>
                                        <FrontHeader history={history} handleCallbackBannerAppJs={handleCallbackBannerAppJs} />
                                        <CookiePolicy history={history} />
                                        <Switch>
                                            <Route exact path="/" history={history} component={Home} />
                                            <Route exact path="/" history={history} component={Home} />
                                        </Switch>
                                    </HomeContainer>
                                </Route>
                            </Router>

                            <Router history={history}>
                                <Route
                                    path={[
                                        "/about",
                                        "/help",
                                        "/policies",
                                        "/partner",
                                        "/:url_key/p",
                                    ]}
                                    exact
                                >
                                    <Container>
                                        <BackHeader history={history} handleCallbackBannerAppJs={handleCallbackBannerAppJs} />
                                        <CookiePolicy history={history} />
                                        <BodySection height={newsBanner ? 64 : 108}>
                                            <Switch>
                                                <Route
                                                    exact
                                                    path="/about"
                                                    history={history}
                                                    component={ContactUs}
                                                />
                                                <Route
                                                    exact
                                                    path="/help"
                                                    history={history}
                                                    component={Help} />
                                                <Route
                                                    exact
                                                    path="/policies"
                                                    history={history}
                                                    component={Policies}
                                                />
                                                <Route
                                                    exact
                                                    path="/partner"
                                                    history={history}
                                                    component={Partner}
                                                />
                                                <Route exact path="/:url_key/p" component={CmsPage} />
                                            </Switch>
                                        </BodySection>
                                    </Container>
                                </Route>

                            </Router>
                            <Router history={history}>
                                <Route
                                    path={[
                                        "/backHome",
                                    ]}
                                    exact
                                >
                                    <HomeContainer height={newsBanner ? 55 : 99}>
                                        <BackHeader history={history} handleCallbackBannerAppJs={handleCallbackBannerAppJs} />
                                        <CookiePolicy history={history} />
                                        <Switch>
                                            <Route
                                                exact
                                                path="/backHome"
                                                history={history}
                                                component={BackHomePage}
                                            />
                                            <Route exact path="/:url_key/p" component={CmsPage} />
                                        </Switch>
                                    </HomeContainer>
                                </Route>

                            </Router>


                        </Route>
                        <Route path="*" component={NotFound} status={404} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </Provider>
    )
}

export default App;
