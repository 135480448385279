import React, { useState, useEffect } from "react";
import {
  CardItem,
  DeleteItem,
  ImageContainer,
  CardContent,
  CardPrice,
  QuantityContainer,
  Content,
  SubContent,
  GiftContainer,
  CheckBox,
  DecreamentButton,
  InputQuantity,
  IncreamentButton,
  MobileCartItem,
  MobileDeleteItem,
  MobileImageContainer,
  MobileCardContent,
  MobileContent,
  MobileSubContent,
  MobileGiftContainer,
  MobileCheckBox,
  MobileCardPrice,
  MobileQuantityContainer,
  MobileDecreamentButton,
  MobileInputQuantity,
  MobileIncreamentButton,
  MobileImgContentContainer,
} from "./style";

const Cards = (props) => {
  const { data, removeItem, updateProductList } = props;
  const [cardData, setCardData] = useState(data);
  const isMobile = window.innerWidth < 768;

  const decreamentQuantity = (data) => {
    let arr = cardData.splice(0);
    let updateQuantity;
    arr.map((item) => {
      if (item.id === data.id) {
        item.quantity = item.quantity - 1;
        updateQuantity = {
          quantity: item.quantity,
          cart_item_id: item.id,
        };
      }
      return arr;
    });
    setCardData(arr);
    updateProductList(updateQuantity);
  };

  const increamentQuantity = (data) => {
    let arr = cardData.splice(0);
    let updateQuantity;
    arr.map((item) => {
      if (item.id === data.id) {
        item.quantity = item.quantity + 1;
        updateQuantity = {
          quantity: item.quantity,
          cart_item_id: item.id,
        };
      }
      return arr;
    });
    setCardData(arr);

    updateProductList(updateQuantity);
  };

  useEffect(() => {
    if (data) {
      setCardData(data);
    }
  }, [data]);
  const handleDeleteCard = (data) => {
    removeItem(data.id);
    let arr = [...cardData];
    arr = arr.filter((item) => item.id !== data.id);
    setCardData(arr);
  };

  return (
    <>
      {cardData &&
        cardData.map((item) => {
          return (
            <>
              {!isMobile ? (
                <CardItem>
                  <DeleteItem onClick={() => handleDeleteCard(item)}>
                    X
                  </DeleteItem>
                  <ImageContainer>
                    {" "}
                    <img
                      alt="example"
                      height="100%"
                      src={item.product && item.product.media_gallery[0]?.url}
                    />
                  </ImageContainer>
                  <CardContent>
                    <Content>{item.product?.name}</Content>
                    <SubContent>7 days replacement applicable</SubContent>
                    <GiftContainer>
                    </GiftContainer>
                  </CardContent>
                  <CardPrice>
                    {item.product.price_range &&
                      item.product.price_range.minimum_price.final_price
                        .currency}{" "}
                    {item.product.price_range &&
                      item.product.price_range.minimum_price.final_price.value}
                  </CardPrice>
                  <QuantityContainer>
                    <DecreamentButton
                      onClick={() => decreamentQuantity(item)}
                      disabled={item.quantity < 0}
                    >
                      -
                    </DecreamentButton>
                    <InputQuantity
                      // defaultValue={item.quantity}
                      value={item.quantity}
                      // onChange={(value) => setInputQuantity(value)}
                      min={0}
                    />
                    <IncreamentButton onClick={() => increamentQuantity(item)}>
                      +
                    </IncreamentButton>
                  </QuantityContainer>
                </CardItem>
              ) : (
                <MobileCartItem>
                  <MobileDeleteItem onClick={() => handleDeleteCard(item)}>
                    X
                  </MobileDeleteItem>
                  <MobileImgContentContainer>
                    <MobileImageContainer>
                      <img
                        alt="example"
                        height="100%"
                        src={item.product && item.product.media_gallery[0]?.url}
                      />
                    </MobileImageContainer>
                    <MobileCardContent>
                      <MobileContent>{item.product?.name}</MobileContent>
                      <MobileSubContent>
                        7 days replacement applicable
                    </MobileSubContent>
                      <MobileGiftContainer>
                      </MobileGiftContainer>
                      <MobileCardPrice>
                        {item.product.price_range &&
                          item.product.price_range.minimum_price.final_price
                            .currency}{" "}
                        {item.product.price_range &&
                          item.product.price_range.minimum_price.final_price
                            .value}
                      </MobileCardPrice>
                      <MobileQuantityContainer>
                        <MobileDecreamentButton
                          onClick={() => decreamentQuantity(item)}
                          disabled={item.quantity < 0}
                        >
                          -
                      </MobileDecreamentButton>
                        <MobileInputQuantity
                          // defaultValue={item.quantity}
                          value={item.quantity}
                          // onChange={(value) => setInputQuantity(value)}
                          min={0}
                        />
                        <MobileIncreamentButton
                          onClick={() => increamentQuantity(item)}
                        >
                          +
                      </MobileIncreamentButton>
                      </MobileQuantityContainer>
                    </MobileCardContent>
                  </MobileImgContentContainer>
                </MobileCartItem>
              )}
            </>
          );
        })}
    </>
  );
};

export default Cards;
