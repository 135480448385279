import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    padding: 24px;

    @media ${props => props.theme.device.tablet} {
       flex-direction: column;
    }
`

export const Left = styled.div`
    width: 20%;

    @media ${props => props.theme.device.tablet} {
       width: 100%;
       margin-bottom: 24px;
    }
`

export const Right = styled.div`
    width: 80%;
    padding-left: 24px;

    @media ${props => props.theme.device.tablet} {
       width: 100%;
       padding-left: 0px;
    }
`