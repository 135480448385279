import axios from "axios";
import { isAuthorized,BASE_URL } from '../utils';

const fetchGuestStateApi = async (data) => {
    const response = await axios({
        url: `${BASE_URL()}`,
        method: "post",
        data: {
            query: ` {
        country(id: "IN") {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
            available_regions {
                id
                code
                name
            }
        }
    }`,
        },
        headers: isAuthorized(),
    });
    return response.data;
};

export default fetchGuestStateApi;
