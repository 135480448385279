import React from 'react'
import PropTypes from "prop-types";
import {
    Container, CardContainer, ImageSkeleton, Xskeleton
} from './WishListSkeletonStyle'

const WishListSkeleton = (props) => {
    return (
        <Container>
            <CardContainer>
                <ImageSkeleton size={10} active />
                <Xskeleton type="text" w="100%" active ></Xskeleton>
            </CardContainer>
            <CardContainer>
                <ImageSkeleton size={10} active />
                <Xskeleton type="text" w="100%" active ></Xskeleton>
            </CardContainer>
            <CardContainer>
                <ImageSkeleton size={10} active />
                <Xskeleton type="text" w="100%" active ></Xskeleton>
            </CardContainer>
            <CardContainer>
                <ImageSkeleton size={10} active />
                <Xskeleton type="text" w="100%" active ></Xskeleton>
            </CardContainer>
        </Container >
    )
}

WishListSkeleton.propTypes = {
    rows: PropTypes.number,
};

WishListSkeleton.defaultProps = {
    rows: 4,
};


export default WishListSkeleton
