export const FETCH_BRAND_REQUEST = 'FETCH_BRAND_REQUEST'
export const FETCH_BRAND_SUCCESS = 'FETCH_BRAND_SUCCESS'
export const FETCH_BRAND_FAIL = 'FETCH_BRAND_FAIL'

export const brandRequest = (params) => {
    return { type: FETCH_BRAND_REQUEST, params }
}

export const brandSuccess = (response) => {
    return { type: FETCH_BRAND_SUCCESS, response }
}

export const brandFail = (response) => {
    return { type: FETCH_BRAND_FAIL, response }
}