import axios from "axios";
import { BASE_URL, isAuthorized } from '../utils';


const setShippingApi = async (data) => {
  const response = await axios({
    url: `${BASE_URL()}`,
    method: "post",
    data: {
      query: `mutation {
        setShippingAddressesOnCart(
          input: {
            cart_id: "${data.data.cartId}"
            shipping_addresses: [
              {
                address: {
                  firstname: "${data.data.firstname}"
                  lastname: "${data.data.lastname}"
                  street: ["${data.data.street}"]
                  city: "${data.data.city}"
                  region: "${data.data.region}"
                  postcode:"${data.data.postcode}"
                  country_code: "${data.data.country_code}"
                  telephone: "${data.data.telephone}"
                  save_in_address_book: false
                }
              }
            ]
          }
        ) {
          cart {
            shipping_addresses {
              firstname
              lastname
              company
              street
              city
              region {
                code
                label
              }
              postcode
              telephone
              country {
                code
                label
              }
              available_shipping_methods{
                  carrier_code
                  carrier_title
                  method_code
                  method_title
                  available
                  amount {
                    currency
                    value
                  }
              }
            }
          }
        }
      }`,
    },
    headers: isAuthorized(),
  });
  return response.data;
};

export default setShippingApi;

