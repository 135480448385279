import {
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDERS_FAIL,
} from "../actions/fetchOrdersAction";

import get from "lodash/get";

const initialState = {
  ordersData: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
      };

    case FETCH_ORDERS_FAIL:
      return {
        ...state,
      };

    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
     ordersData:action.response.data.customer.orders
      };

    default:
      return state;
  }
}
