import {
    GUEST_SET_PAYMENT_METHOD,
    GUEST_SET_PAYMENT_METHOD_SUCCESS,
    GUEST_SET_PAYMENT_METHOD_FAIL,
    GUEST_SET_PAYMENT_METHOD_RESET,
} from "../actions/guestSetPaymentMehtodAction";

const initialState = {
    apiState: "",
    data: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GUEST_SET_PAYMENT_METHOD:
            return {
                ...state,
                apiState: "loading"
            };

        case GUEST_SET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                apiState: "success",
                data: (action.response.data) ? action.response.data : []
            };

        case GUEST_SET_PAYMENT_METHOD_FAIL:
            return {
                ...state,
                apiState: "error"
            };
        case GUEST_SET_PAYMENT_METHOD_RESET:
            return initialState

        default:
            return state;
    }
}
