import { call, put, takeLatest } from "redux-saga/effects";

import {
    SUBSCRIBE_EMAIL,
    subscribeEmailSuccess,
    subscribeEmailFail,
} from "../actions/subscribeEmailAction";

// api
import subscribeEmailApi from "../apis/subscribeEmailApi";


export function* subscribeEmailSaga(action) {
    try {
        const response = yield call(() => subscribeEmailApi(action.params));
        if (response.errors) {
            yield put(subscribeEmailFail(response, action));
        } else {
            yield put(subscribeEmailSuccess(response, action));
        }

    } catch (e) {
        yield put(subscribeEmailFail(e.message, action));
    }
}

export default function* mySaga() {
    yield takeLatest(SUBSCRIBE_EMAIL, subscribeEmailSaga);
}
