export const SET_SHIPPING_REQUEST = 'SET_SHIPPING_REQUEST'
export const SET_SHIPPING_SUCCESS = 'SET_SHIPPING_SUCCESS'
export const SET_SHIPPING_FAIL = 'SET_SHIPPING_FAIL'
export const SET_SHIPPING_RESET = 'SET_SHIPPING_RESET'

export const setShippingRequest = (params) => {
    return { type: SET_SHIPPING_REQUEST, params }
}

export const setShippingSuccess = (response) => {
    return { type: SET_SHIPPING_SUCCESS, response }
}

export const setShippingFail = (response) => {
    return { type: SET_SHIPPING_FAIL, response }
}

export const setShippingReset = () => {
    return { type: SET_SHIPPING_RESET }
}
